<template>
  <!-- eslint-disable -->
  <svg
    width="15"
    height="7"
    viewBox="0 0 15 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="blockClassName"
  >
    <path
      :class="childrenClassName('path', [status])"
      d="M2.40112 5.48779H5.45972V6.67432H0.476318V5.86133L3.52612 1.4668H0.45874V0.275879H5.42896V1.06689L2.40112 5.48779Z"
    />
    <path
      :class="childrenClassName('path', [status])"
      d="M8.02155 5.53174H10.1881V6.67432H6.14948V5.84814L8.25886 3.06201H6.24177V1.91943H10.1397V2.71924L8.02155 5.53174Z"
    />
    <path
      :class="childrenClassName('path', [status])"
      d="M12.8334 5.53174H14.9999V6.67432H10.9613V5.84814L13.0707 3.06201H11.0536V1.91943H14.9516V2.71924L12.8334 5.53174Z"
    />
  </svg>
  <!-- eslint-enable -->
</template>
<script>
export default {
  name: 'AtomsIconsSnoozing',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
  $class-name: 'AtomsIconsSnoozing';

  .#{$class-name} {
    .#{$class-name}__path {
      &--success, &--warning, &--critical, &--locked, &-null, &--snoozing {
        fill: var(--neutral-700);
      }
    }
  }
</style>
