<template>
  <span
    v-cy
    class="linkslot"
  >
    <a
      v-if="
        props.formattedRow[props.column.field].hasOwnProperty('router_link') &&
          props.formattedRow[props.column.field]['router_link'] != ''
      "
      :href="'#' + props.formattedRow[props.column.field].router_link"
    >{{ props.formattedRow[props.column.field].router_text }}</a>
    <a
      v-else-if="props.formattedRow[props.column.field]['external_link'] != ''"
      :href="'http://' + props.formattedRow[props.column.field].external_link"
      target="_blank"
    >{{ props.formattedRow[props.column.field].router_text }}</a>
    <span v-else>{{ props.formattedRow[props.column.field]['router_text'] }}</span>
    <span
      v-if="internal"
      class=""
    >
      <span
        v-if="note.status"
        v-tooltip="'Installationsstatus (Alpha)'"
        class="cursor-help ml-3"
      >
        <i
          :class="
            `cursor-help
          kg
          kg-${constants.internalNote[note.status].icon} text-${constants.internalNote[note.status].color}`
          "
        />
      </span>
    </span>
    <CommentTooltip
      :comment="{
        internalComment: props.formattedRow[props.column.field].internalComment,
        comment: props.formattedRow[props.column.field].comment
      }"
    />
    <!-- <GroupTooltip :groups="props.formattedRow[props.column.field].groups"/> -->
  </span>
</template>

<script>
import constants from '@/utils/constants';
import CommentTooltip from '@/components/vueTableComponents/CommentTooltip';

export default {
  name: 'LinkSlot',
  components: {
    CommentTooltip,
    // GroupTooltip
  },
  props: {
    props: Object,
  },
  data() {
    return {
      constants,
    };
  },
  computed: {
    internal() {
      return this.hasUserKeycloakRole('Alpha-Feature');
    },
    note() {
      if (this.props.row[this.props.column.field] && this.props.row[this.props.column.field].note) {
        const note = `${this.props.row[this.props.column.field].note}`;
        const status = note
          ? note.split('\n')[0].replace(' ', '').replace('\r', '')
          : '';
        if (['0', '1', '2'].includes(`${status}`)) {
          return {
            status,
            tooltip: note.replace(status, ''),
          };
        }
        return {
          status: null,
          tooltip: note !== 'null' ? note : '',
        };
      }
      return {
        status: null,
        tooltip: '',
      };
    },
  },
};
</script>
<style lang="scss">
  .linkslot {
    display: flex;
    align-items: center;
  }
</style>
