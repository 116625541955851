<template>
  <!-- eslint-disable -->
    <svg viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4031_11926)">
            <path d="M32.8448 43.642V46.5627C32.8448 46.8037 32.6504 46.999 32.4094 46.999H25.7221C22.5311 46.999 21.7334 44.4701 21.7334 43.2057V38.5682C21.7334 38.3272 21.9288 38.1318 22.1697 38.1318H25.2857C25.5267 38.1318 25.7221 38.3272 25.7221 38.5682V42.7693C25.7221 43.0103 25.9174 43.2057 26.1584 43.2057H32.4084C32.6494 43.2057 32.8448 43.401 32.8448 43.642Z" fill="#D2E9FA"/>
            <path d="M32.3553 46.5627V43.642H33.3342V46.5627H32.3553ZM32.4084 43.6952H26.1584V42.7162H32.4084V43.6952ZM25.2326 42.7693V38.5682H26.2116V42.7693H25.2326ZM25.2857 38.6213H22.1697V37.6423H25.2857V38.6213ZM22.2229 38.5682V43.2057H21.2439V38.5682H22.2229ZM22.2229 43.2057C22.2229 43.7544 22.4002 44.5926 22.9214 45.2811C23.4249 45.9461 24.276 46.5095 25.7221 46.5095V47.4885C23.9772 47.4885 22.834 46.7875 22.1409 45.872C21.4655 44.9799 21.2439 43.9214 21.2439 43.2057H22.2229ZM25.7221 46.5095H32.4094V47.4885H25.7221V46.5095ZM22.1697 38.6213C22.1991 38.6213 22.2229 38.5975 22.2229 38.5682H21.2439C21.2439 38.0568 21.6584 37.6423 22.1697 37.6423V38.6213ZM25.2326 38.5682C25.2326 38.5975 25.2564 38.6213 25.2857 38.6213V37.6423C25.7971 37.6423 26.2116 38.0568 26.2116 38.5682H25.2326ZM26.1584 43.6952C25.6471 43.6952 25.2326 43.2807 25.2326 42.7693H26.2116C26.2116 42.74 26.1878 42.7162 26.1584 42.7162V43.6952ZM32.3553 43.642C32.3553 43.6714 32.3791 43.6952 32.4084 43.6952V42.7162C32.9197 42.7162 33.3342 43.1307 33.3342 43.642H32.3553ZM33.3342 46.5627C33.3342 47.0725 32.9222 47.4885 32.4094 47.4885V46.5095C32.3786 46.5095 32.3553 46.5348 32.3553 46.5627H33.3342Z" fill="#263344"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1779 3.77246H18.4001V0.447266H16.1779V3.77246Z" stroke="#263344" stroke-width="0.978992" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.8491 3.55078H24.9526V0.447266H21.8491V3.55078Z" stroke="#263344" stroke-width="0.978992" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.4019 3.77246H30.6241V0.447266H28.4019V3.77246Z" stroke="#263344" stroke-width="0.978992" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.45011 6.85486C8.4265 5.05415 8.4147 4.15379 8.95068 3.55345C9.48666 2.9531 10.3826 2.86317 12.1745 2.6833L22.9874 1.59788C23.1939 1.57716 23.2971 1.56679 23.4007 1.56679C23.5043 1.56679 23.6075 1.57716 23.814 1.59788L34.627 2.6833C36.4188 2.86317 37.3148 2.9531 37.8507 3.55345C38.3867 4.15379 38.3749 5.05415 38.3513 6.85487L37.9732 35.7028C37.9513 37.3734 37.9403 38.2087 37.444 38.7806C36.9476 39.3525 36.1221 39.4808 34.4713 39.7375L24.0365 41.3599C23.7192 41.4092 23.5606 41.4339 23.4007 41.4339C23.2408 41.4339 23.0822 41.4092 22.765 41.3599L12.3302 39.7375C10.6793 39.4808 9.85385 39.3525 9.35747 38.7806C8.86109 38.2087 8.85014 37.3734 8.82824 35.7028L8.45011 6.85486Z" fill="#D2E9FA" stroke="#263344" stroke-width="0.978992"/>
            <ellipse cx="23.3996" cy="9.86987" rx="3.88897" ry="3.8794" fill="white" stroke="#263344" stroke-width="0.978992"/>
            <path d="M26.3649 8.6301L20.2822 11.267" stroke="#263344" stroke-width="0.978992" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3997 33.6987H28.3999V30.3735H18.3997V33.6987Z" fill="#D2E9FA" stroke="#263344" stroke-width="0.978992" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M24.3923 18.1819C23.8746 18.4943 23.401 19.1222 23.401 19.7658C23.401 20.2917 23.6308 20.8255 23.9958 21.1504C24.2979 20.8804 24.5292 20.3844 24.5905 19.9636C25.5818 20.4236 26.1781 21.477 26.1781 22.5366C26.1781 24.0672 24.9335 25.3073 23.401 25.3073C21.8654 25.3073 20.6224 24.0672 20.6224 22.5366C20.6224 20.3969 22.3075 18.5304 24.3923 18.1819Z" fill="#EB9C69" stroke="#263344" stroke-width="0.978992" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_4031_11926">
                <rect width="48" height="48" fill="white" transform="translate(0.400391)"/>
            </clipPath>
        </defs>
    </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'AtomsIconsBoiler',
  props: {
    color: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
  $class-name: 'AtomsIconsBoiler';
</style>
