const path = require('path-browserify');

let backend_url = 'http://portal.kugu.local/auth';

if (process.env.NODE_ENV === 'production') { backend_url = ''; }
if (process.env.NODE_ENV === 'staging') { backend_url = 'https://staging.portal.kugu.cloud'; }
if (process.env.NODE_ENV === 'development') { backend_url = 'https://backend.dev.aws.portal.kugu.cloud'; }

/* eslint-disable */
module.exports = {
  publicPath: process.env.NODE_ENV === 'production'
    ? '/' : '/',
  terser: {
    minify: 'uglifyJs',
    terserOptions: {
      ecma: 6,
      compress: { drop_console: true },
      output: { comments: false, beautify: false }
  }
  },
  pluginOptions: {
    lintOnSave: 'error',
    webpackBundleAnalyzer: {
      openAnalyzer: false,
      logLevel: 'silent'
    },
      compression: {
        brotli: {
          filename: '[file].br[query]',
          algorithm: 'brotliCompress',
          include: /\.(js|css|html|svg|json)(\?.*)?$/i,
          minRatio: 0.8,
        },
        gzip: {
          filename: '[file].gz[query]',
          algorithm: 'gzip',
          include: /\.(js|css|html|svg|json)(\?.*)?$/i,
          minRatio: 0.8
        }
      },
    VUE_APP_AUTHENTICATION: {
      server_url: backend_url + '/auth',
      client_id: 'kugu-portal',
      localstorage_user_token: 'auth-access-token',
      NO_AUTH_DOMAIN: [
        'kugu-lgw-fairdemo.local',
        'kugu-lgw-fairdemo',
        'kugu-lgw-fairdemo:8888',
      ],
    },
    VUE_APP_API: {
      server_url: backend_url + '/api',
      flashy_url: process.env.NODE_ENV === 'production'
        ? 'https://flashy.dev2.kugu.cloud/api/' : 'https://flashy.dev2.kugu.cloud/api/',
    },
  },
  configureWebpack: {
    resolve: {
      alias: {
        '@root': path.join(__dirname, './'),
      },
    },
    module: {
      rules: [{
        test: /\.md$/,
        loader: 'raw-loader',
      }],
    },
  },
  css: {
    loaderOptions: {
      scss: {
        additionalData: 
        `@import "@/assets/styles/_main.scss";`
      }
    }
  }
};
/* eslint-enable */
