var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-item dropdown no-arrow"},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"id":"userDropdown","data-bs-toggle":"dropdown","data-cy":"user-dropdown","href":"#","role":"button"}},[_c('div',{staticClass:"nav-link__user-info"},[_c('span',{staticClass:"mr-2 d-lg-inline user-info__username text-dark",domProps:{"textContent":_vm._s(_vm.username())}}),_c('span',{staticClass:"mr-2 d-lg-inline user-info__role"},[_vm._v(_vm._s(_vm.formattedCurrentRoles))])]),_c('svg',{attrs:{"fill":"none","height":"50","viewBox":"0 0 200 200","width":"50","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"fill":"white","height":"60","width":"60"}}),_c('circle',{attrs:{"cx":"100","cy":"100","fill":"#ECECEC","r":"87"}}),_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M119.411 80.1807C119.411 91.5188 110.611 100.702 99.7457 100.702C88.8806 100.702 80.0803 91.5188 80.0803 80.1807C80.0803 68.8426 88.8806 59.6592 99.7457 59.6592C110.611 59.6592 119.411 68.8426 119.411 80.1807ZM60.4131 131.484C60.4131 117.837 86.6172 110.962 99.7438 110.962C112.87 110.962 139.075 117.837 139.075 131.484V141.745H60.4131V131.484Z","fill":"#1F447C"}})])]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right shadow animated--grow-in",attrs:{"aria-labelledby":"userDropdown"}},[_c('RoleSwitchPanel',{on:{"change-role":_vm.handleChangeRole}}),_c('form',{staticClass:"pr-2 row"},[_c('toggle-button',{staticClass:"col-3 offset-1 p-2",attrs:{"height":15,"value":_vm.consentGiven,"width":25},on:{"input":function($event){return _vm.enableCookies($event)}}}),_vm._m(0)],1),(_vm.keycloak.hasResourceRole('manage-users', 'realm-management'))?_c('div',{staticClass:"row"},[_c('atoms-icons',{staticClass:"col-4 offset-1 fa-sm fa-fw text-gray-400 p-2 m-0",attrs:{"icon":"kgs kgs-wrench"}}),_c('a',{staticClass:"text-primary col-7 p-2",attrs:{"href":this.conf.VUE_APP_AUTHENTICATION.server_url +
            '/admin/' +
            this.currentRealm +
            '/console/#/realms/' +
            this.currentRealm +
            '/users',"target":"_blank"}},[_vm._v(" Nutzerverwaltung ")])],1):_vm._e(),(_vm.auth.hasUserKeycloakRole('Vollzugriff'))?_c('div',{staticClass:"row"},[_c('atoms-icons',{staticClass:"col-4 offset-1 fa-sm fa-fw text-gray-400 p-2 m-0",attrs:{"icon":"fas fa-network-wired"}}),_c('router-link',{staticClass:"text-primary col-7 p-2",attrs:{"to":'/eras-2-connection'}},[_vm._v(" ERAS2/Absys Verbindung ")])],1):_vm._e(),(_vm.auth.hasUserKeycloakRole('Alpha-Feature'))?_c('div',{staticClass:"row"},[_c('atoms-icons',{staticClass:"col-4 offset-1 fa-sm fa-fw text-gray-400 p-2 m-0",attrs:{"icon":"fas fa-network-wired"}}),_c('router-link',{staticClass:"text-primary col-7 p-2",attrs:{"to":'/external-credentials'}},[_vm._v(" Externe Zugangsdaten ")])],1):_vm._e(),_c('div',{staticClass:"row"},[_c('atoms-icons',{staticClass:"col-4 offset-1 fa-sm fa-fw text-gray-400 p-2 m-0",attrs:{"icon":"fas fa-user-cog"}}),_c('a',{staticClass:"text-primary col-7 p-2",attrs:{"href":this.conf.VUE_APP_AUTHENTICATION.server_url +
            '/realms/' +
            this.currentRealm +
            '/account/',"target":"_blank"}},[_vm._v(" Konto ")])],1),(_vm.checkUserHasHeizungRole())?_c('div',{staticClass:"row"},[_c('atoms-icons',{staticClass:"col-4 offset-1 fa-sm fa-fw text-gray-400 p-2 m-0",attrs:{"icon":" kgs kgs-bell"}}),_c('router-link',{staticClass:"text-primary col-7 p-2",attrs:{"to":'/notification-management'}},[_vm._v(" Benachrichtigungs-einstellungen ")])],1):_vm._e(),_c('a',{staticClass:"text-dark row no-underline",attrs:{"href":"#"},on:{"click":_vm.logUserOut}},[_c('i',{staticClass:"col-4 offset-1 kg kg-log-out fa-sm fa-fw text-gray-400 p-2 m-0",attrs:{"data-cy":"user-logout"}}),_c('span',{staticClass:"col-7 p-2"},[_vm._v("Abmelden")])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"px-1 text-dark col-7 p-2"},[_c('a',{staticClass:"text d-inline-block align-middle",attrs:{"href":"https://www.kugu-home.com/datenschutz/","target":"_blank"}},[_vm._v("Nutzungsdaten")]),_vm._v(" erheben ")])
}]

export { render, staticRenderFns }