import { breadcrumbs } from '@/store/breadcrumbs/index';
import { graphs } from '@/store/graphs/index';
import { centralHeatingDashboard } from '@/store/central-heating/dashboard/index';
import { centralHeatingTable } from '@/store/central-heating/table/index';

export default {
  install(Vue) {
    Vue.prototype.$store = {
      breadcrumbs,
      graphs,
      centralHeatingDashboard,
      centralHeatingTable,
    };
  },
};
