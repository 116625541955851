<template>
  <span>
    <div
      v-for="(badge, badgeKey) in props.row.badgelist"
      :key="badgeKey"
      class="d-inline"
    >
      <span
        v-tooltip="badge.tooltip"
        :class="'badge ' + badge.styling"
      >{{ badge.text }}</span>
    </div>
  </span>
</template>

<script>
export default {
  name: 'BadgeListSlot',
  props: {
    props: Object,
  },
};
</script>
