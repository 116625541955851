<template>
  <div :class="blockClassName">
    <div :class="childrenClassName('stickyWrapper')">
      <molecules-navigations-topbar
        :hide-humburger="true"
      />
    </div>
    <div :class="childrenClassName('content')">
      <router-view
        :config="config"
      />
    </div>
  </div>
</template>
<script>
import MoleculesNavigationsTopbar from '@/atomic-design-components/molecules/navigations/Topbar';

export default {
  name: 'TemplatesSimple',
  components: {
    MoleculesNavigationsTopbar,
  },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss">
  $class-name: 'TemplatesSimple';

  .#{$class-name} {
    .#{$class-name}__stickyWrapper {
      position: fixed;
      width: 100%;
      top: 0%;
      z-index: 999999;
      transition: .5s;
    }

    @include mq('xxl') {
      .#{$class-name}__content {
        padding-left: 0;
        padding-right: 0;
        max-width: 1340px;
        margin: auto;
      }
    }

    .#{$class-name}__content {
      margin-top: 92px;
      padding-left: var(--spacing-xxl2);
      padding-right: var(--spacing-xxl2);
      padding-top: var(--spacing-xxl2);
    }
  }
</style>
