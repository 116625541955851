<template>
  <ul
    id="accordionSidebar"
    class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
  >
    <li class="center-horizontally">
      <a
        class="sidebar-brand"
        :href="keepUrlParams('/home')"
      >
        <img
          id="realm-logo"
          class="logo"
          height="60px"
          :src="appLogo"
          width="60%"
        >
      </a>
    </li>
    <li
      v-for="(item, key) in this.links"
      :key="key"
      :class="[markIfActive(item.route), ' nav-item']"
    >
      <hr
        v-if="item.title === 'Seperator'"
        class="sidebar-divider"
      >
      <router-link
        v-if="item.title !== 'Seperator' && (item.role !== undefined ? item.role : true)"
        class="nav-link"
        :class="colorContrastClassObjectFG"
        :to="item.route"
      >
        <div class="d-flex align-items-center">
          <atoms-icons
            v-if="item.icon"
            :icon="item.icon"
          />
          <span
            :style="{
              color: colorContrastClassObjectFG == 'white-contrasted' ? 'white' : 'black',
            }"
          >
            {{ item.title }}
          </span>
        </div>
      </router-link>

      <div
        v-if="currentPath.includes(item.childrenShowOn)"
        :class="[currentPath.includes(item.route) ? 'display' : '', 'collapse']"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <a
            v-if="isRouteParamSet('propertyId')"
            :class="'d-block collapse-header sidebar-link text-break '"
            :href="buildHref()"
          >
            <span
              v-if="'/property/' + currentId == currentPath"
              class="font-weight-bold text-primary h7 capitalized d-block w-100"
            >{{ currentAddress }}</span>
            <span
              v-else
              class="font-weight-normal text-dark h7 capitalized d-block w-100"
            >{{ currentAddress }}</span>
          </a>
          <hr class="divider-grey">
          <div
            v-for="(child, key2) in getPropertyChildren || []"
            :key="key2"
          >
            <h6
              v-if="child.separator"
              class="ml-3 mb-0 py-2 text-black-700 text-uppercase d-block font-weight-bold text-black-700 text-10"
            >
              {{ child.title }}
            </h6>
            <a
              v-if="!child.separator"
              :class="[
                markIfActive(keepUrlParams(child.route, true)),
                'd-flex align-items-center',
                'collapse-item',
                child.icon ? 'text-black-700' : '',
              ]"
              :href="keepUrlParams(child.route)"
            >
              <atoms-icons
                v-if="child.icon"
                class="text-black-700 pl-3"
                :icon="child.icon"
              />
              {{ child.title }}
            </a>
          </div>
        </div>
      </div>
      <div
        v-if="
          auth.hasUserKeycloakRole('Geräte-Administration', 'Beta-Feature', 'Alpha-Feature') &&
            currentPath.includes(item.showHardwareChildrenOn)
        "
        :class="[currentPath.includes(item.route) ? 'display' : '', 'collapse']"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <span
            v-for="(item, i) in hardwareManagerLinks"
            :key="i"
          >
            <router-link
              v-if="auth.hasUserKeycloakRole(...item.roles)"
              :class="getSubNavigationClass(item.to)"
              :to="item.to"
              @click="item.clickEvent || ''"
            ><span
              v-html="item.text"
            /></router-link>
          </span>
        </div>
      </div>
      <div
        v-if="currentPath.includes(item.showSupportChildrenOn)"
        :class="[currentPath.includes(item.route) ? 'display' : '', 'collapse']"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <span
            v-for="(item, i) in supportLinks"
            :key="i"
          >
            <router-link
              v-if="auth.hasUserKeycloakRole(...item.roles)"
              :class="getSubNavigationClass(item.to)"
              :to="item.to"
              @click="item.clickEvent || ''"
            ><span
              v-html="item.text"
            /></router-link>
          </span>
        </div>
      </div>
      <div
        v-if="
          auth.hasUserKeycloakRole('Heizungs-Contracting') && currentPath.includes(item.showCentralHeatingChildrenOn)
        "
        :class="[currentPath.startsWith('/central-heating') ? 'display' : '', 'collapse']"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <router-link
            :class="[markIfActive('/central-heating/contracting'), 'collapse-item']"
            to="/central-heating/contracting"
          >
            Contracting
          </router-link>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import auth from '@/utils/auth';
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index';
import { globalStorage, currentRealm, currentMandant } from '@/main';
import translations from '@/utils/lookups/translations.json';
import colors from '@/utils/colors';

const PROPERTY_MODULES = [
  {
    module: 'Portal-Module Billing',
    name: 'billing',
    requiredRole: ['Hausverwaltung', 'Vollzugriff', 'Heizkostenabrechnung'],
  },
  {
    module: 'Portal-Module Hardware',
    name: 'hardware',
    requiredRole: ['Vollzugriff', 'Heizkostenabrechnung'],
  },
  {
    module: 'Portal-Module Smoke-Detector',
    name: 'smoke-detector',
    requiredRole: ['Vollzugriff', 'Rauchmelder-Administration', 'Heizkostenabrechnung'],
  },
  {
    module: 'Portal-Module CHO',
    name: 'central-heating',
    requiredRole: ['Vollzugriff', 'Heizungs-Betrieb'],
  },
];

export default {
  name: 'Sidebar',
  components: {
    AtomsIcons,
  },
  props: {
    navigationItems: Array,
    contrastedColor: String,
    appLogo: {
      type: String,
      default: '/images/theme/portal_kugu/logo.png',
    },
  },
  computed: {
    colorContrastClassObjectFG() {
      return this.contrastedColor !== undefined
        ? this.contrastedColor
        : colors.getContrastedColorClass(null, '.bg-gradient-primary');
    },
    currentAddress() {
      const propertyPath = `/property/${this.currentId}`;
      const breadcrumbs = this.$store.breadcrumbs.getters.getBreadcrumbs();
      const currentProperty = breadcrumbs.find((p) => p.linkTo === propertyPath);
      return currentProperty ? currentProperty.text : '';
    },
    currentId() {
      return this.$route.params.propertyId;
    },
    getPropertyChildren() {
      let propertyChildren = [];
      propertyChildren.push({
        title: 'Modul',
        separator: true,
        route: '',
      });
      for (const m of PROPERTY_MODULES) {
        if (auth.hasUserKeycloakRole(...m.requiredRole)) {
          const mod = m.name;
          const modObj = {
            title: translations[mod],
            route: `/property/:propertyId/${mod}`,
          };
          if (
            (m.module === 'Portal-Module Billing' && globalStorage.currentProperty.billing_is_locked === 1)
            || (m.module === 'Portal-Module Hardware' && globalStorage.currentProperty.hardware_is_locked === 1)
            || (m.module === 'Portal-Module Smoke-Detector'
              && globalStorage.currentProperty.smoke_detector_is_locked === 1)
            || (m.module === 'Portal-Module CHO' && globalStorage.currentProperty.ch_is_locked === 1)
          ) {
            modObj.class = 'text-black-700 not-allowed ';
            modObj.icon = 'kgs kgs-lock';
            modObj.route = '';
          }
          propertyChildren.push(modObj);
        }
      }

      if (propertyChildren.length === 1) propertyChildren = [];

      if (auth.hasUserKeycloakRole('Alpha-Feature', 'Hausverwaltung', 'Vollzugriff', 'Heizkostenabrechnung')) {
        propertyChildren.push({
          title: 'Verwaltung',
          separator: true,
          route: '',
        });
      }

      if (
        auth.hasUserKeycloakRole('Hausverwaltung', 'Vollzugriff', 'Rauchmelder-Administration', 'Heizkostenabrechnung')
      ) {
        propertyChildren.push({
          title: 'Dokumente',
          route: '/property/:propertyId/document',
        });
        if (auth.hasUserKeycloakRole('Hausverwaltung', 'Heizkostenabrechnung')) {
          propertyChildren.push({
            title: 'Bewohner',
            route: '/property/:propertyId/tenants',
          });
        }
        if (auth.hasUserKeycloakRole('Vollzugriff', 'Heizkostenabrechnung')) {
          propertyChildren.push({
            title: 'Import / Export',
            route: '/property/:propertyId/import',
          });
        }
      }
      if (auth.hasUserKeycloakRole('Alpha-Feature')) {
        propertyChildren.push({
          title: 'Erstaufnahme (Alpha)',
          route: '/property/:propertyId/setup-pipeline',
        });
      }
      return propertyChildren;
    },
    currentPath() {
      return this.$route.path;
    },
    isRouteParamSet() {
      return (paramName) => {
        if (this.$route.params) {
          return this.$route.params[paramName];
        }
        return undefined;
      };
    },
    keepUrlParams() {
      return (url, normalLink) => {
        const params = this.$route.params || [];
        for (const key in params) {
          url = url.replace(`:${key}`, params[key]);
        }
        if (normalLink) {
          return url;
        }
        return this.$router.resolve(url).href;
        // return props.href;
      };
    },
  },
  methods: {
    getSubNavigationClass(route) {
      return [this.markIfActive(route), 'collapse-item'];
    },
    markIfActive(route) {
      return this.currentPath.startsWith(route) ? ' active' : '';
    },
    resetDeviceInboxFilter() {
      localStorage.removeItem('deviceInboxAddress');
      localStorage.removeItem('malincheId');
    },
    buildHref() {
      return this.keepUrlParams('/property/:propertyId');
    },
  },
  data: () => ({
    auth,
    currentRealm: currentRealm(),
    currentMandant: currentMandant(),
    keycloak: window.keycloak,
    links: [
      {
        title: 'Dashboard',
        route: '/home',
        icon: 'kgs kgs-dashboard',
      },
      {
        title: 'Kunden',
        route: '/customer',
        icon: 'kgs kgs-group',
        role: auth.hasUserKeycloakRole('Vollzugriff', 'Heizkostenabrechnung'),
      },
      {
        title: 'Liegenschaften',
        route: '/property',
        icon: 'kgs kgs-building',
        childrenShowOn: '/property/',
        childrenTopic: 'Liegenschaft',
        role: auth.hasUserKeycloakRole(
          'Hausverwaltung',
          'Heizkostenabrechnung',
          'Rauchmelder-Administration',
          'Heizungs-Betrieb',
          'Vollzugriff',
          'Alpha-Feature',
        ),
      },
      {
        title: 'Abrechnung (Beta)',
        route: '/billing',
        role: auth.hasUserKeycloakRole('Alpha-Role', 'Heizkostenabrechnung'),
        icon: 'kgs kgs-file',
      },
      {
        title: 'Rauchmelder',
        route: '/smoke-detector-manager',
        role: auth.hasUserKeycloakRole('Rauchmelder-Administration', 'Heizkostenabrechnung'),
        icon: 'kgs kgs-bullseye',
      },
      {
        title: 'Hardware-Manager',
        showHardwareChildrenOn: 'hardware-manager',
        route: '/hardware-manager',
        role: auth.hasUserKeycloakRole(
          'Vollzugriff',
          'Beta-Feature',
          'Alpha-Feature',
          'Geräte-Administration',
          'Heizkostenabrechnung',
        ),
        icon: 'kgs kgs-tachometer',
      },
      {
        title: 'Digitaler Heizungskeller',
        route: '/central-heating',
        role: auth.hasUserKeycloakRole('Vollzugriff', 'Heizungs-Betrieb', 'Heizungs-Contracting'),
        showCentralHeatingChildrenOn: 'central-heating',
        icon: 'kgs kgs-hot',
      },
      {
        title: 'Seperator',
      },
      {
        title: 'Hilfe',
        route: '/support',
        icon: 'kgs kgs-help-circle',
        showSupportChildrenOn: 'support',
      },
      {
        title: 'Mandanten',
        route: '/clients',
        icon: 'kgs kgs-user',
        role: auth.hasUserKeycloakRole('Mandanten-Administration'),
      },
    ],
    hardwareManagerLinks: [
      {
        to: '/hardware-manager/reception',
        text: 'Empfangsanalyse (Beta)',
        roles: ['Beta-Feature'],
      },
      {
        to: '/hardware-manager/device-inbox',
        text: 'Neue Geräte',
        roles: ['Geräte-Administration'],
        clickEvent: 'resetDeviceInboxFilter',
      },
      {
        to: '/hardware-manager/data-inlets',
        text: 'Daten-Eingang',
        roles: ['Geräte-Administration'],
      },
      {
        to: '/hardware-manager/lifetime-calculator',
        text: 'Lebensdauer',
        roles: ['Geräte-Administration'],
      },
      {
        to: '/hardware-manager/wmbus-decoder',
        text: 'WMBus Decoder',
        roles: ['Geräte-Administration'],
      },
    ],
    supportLinks: [
      {
        to: '/support/contact',
        text: 'Kontakt',
        roles: [undefined],
      },
      {
        to: '/support/faq',
        text: 'Fragen & Antworten',
        roles: [undefined],
      },
      {
        to: '/support/releases',
        text: 'Neuigkeiten',
        roles: [undefined],
      },
    ],
  }),
};
</script>

<style scoped>
.sidebar-link {
  margin-left: 8px !important;
  margin-right: 8px !important;
  border-radius: 5px;
}

.sidebar-link:hover {
  background: var(--neutral-200);
}

li.nav-item:first-of-type {
  margin-top: 3rem;
}

.sidebar .sidebar-divider {
  margin: 4px 16px;
}

.sidebar {
  height: 100% !important;
  width: 300px !important;
}

.nav-link,
.nav-link.bg-black span {
  color: black;
  opacity: 1;
  line-height: 1 !important;
}

.collapse-item {
  padding: 0.4rem 1rem !important;
}
.black-contrasted {
  color: black;
}
.white-contrasted {
  color: white;
}
.sidebar-dark .nav-link.black-contrasted span {
  color: black;
}
.sidebar-dark .nav-link.white-contrasted span {
  color: white;
}
.sidebar-dark .nav-link.black-contrasted i {
  color: rgba(000, 000, 000, 0.3);
}

.sidebar-dark .nav-link.white-contrasted i {
  color: rgba(255, 255, 255, 0.3);
}

.sidebar-dark .nav-item .nav-link.black-contrasted:active i,
.sidebar-dark .nav-item .nav-link.black-contrasted:focus i,
.sidebar-dark .nav-item .nav-link.black-contrasted:hover i {
  color: black;
}

.sidebar-dark .nav-item .nav-link.white-contrasted:active i,
.sidebar-dark .nav-item .nav-link.white-contrasted :focus i,
.sidebar-dark .nav-item .nav-link.white-contrasted:hover i {
  color: white;
}

.display {
  display: block !important;
}

.text-10 {
  font-size: 10px;
}

.h7 {
  font-size: var(--font-size);
  height: inherit;
  white-space: normal !important;
  color: var(--neutral) !important;
}

.sidebar i {
  font-size: 18px;
}

.h7:hover,
a:hover {
  text-decoration: none !important;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item.active.text-black-700 {
  cursor: not-allowed;
  font-weight: normal;
  color: #858796 !important;
}

.collapse-item:hover {
  background: var(--neutral-200) !important;
}

@media (min-width: 1023px) {
  .sidebar {
    padding-top: inherit;
    height: inherit;
  }
}
</style>
<style>
#realm-logo {
  max-width: 60%;
  max-height: 100px;
  width: auto;
  min-width: 200px;
  margin: auto;
  object-fit: scale-down;
  position: relative;
  display: inline-block;
}

.sidebar {
  width: 0px !important;
  transition: all 0.5s;
}

.open .sidebar {
  width: 300px !important;
}

.sidebar .sidebar-brand {
  display: inline-block;
  height: 90px !important;
  margin: 20px auto 10px auto;
  padding: 0;
}
.center-horizontally {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
</style>
