<template>
  <!--eslint-disable -->
  <svg
    :class="blockClassName"
    width="25"
    height="17"
    viewBox="0 0 25 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="childrenClassName('outsidePath', [status])"
      d="M12.7608 1.46484C6.49279 1.46484 1.41211 6.2812 1.41211 12.209V16.2844H24.1094V12.209C24.1094 6.2812 19.0288 1.46484 12.7608 1.46484Z"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.323 12.2105C14.323 13.0255 13.6218 13.6924 12.7577 13.6924C11.8937 13.6924 11.1924 13.0255 11.1924 12.2105C11.1924 11.3954 11.8937 10.7285 12.7577 10.7285C13.6218 10.7285 14.323 11.3954 14.323 12.2105Z"
      :class="childrenClassName('line', [status])"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.8682 11.1738L18.6323 6.65381"
      :class="childrenClassName('line', [status])"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      :class="childrenClassName('line', [status])"
      d="M3.39062 12.6909H5.36412"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      :class="childrenClassName('line', [status])"
      d="M22.1375 12.6909H20.1641"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      :class="childrenClassName('line', [status])"
      d="M21.4281 8.79785L19.6045 9.53883"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      :class="childrenClassName('line', [status])"
      d="M16.3519 4.021L15.5967 5.7251"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      :class="childrenClassName('line', [status])"
      d="M12.7607 3.31934V5.20869"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      :class="childrenClassName('line', [status])"
      d="M9.17676 4.021L9.93163 5.7251"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      :class="childrenClassName('line', [status])"
      d="M6.12793 5.94775L7.52225 7.24447"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      :class="childrenClassName('line', [status])"
      d="M4.09961 8.79785L5.92283 9.53883"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <!-- eslint-enable -->
  </svg>
</template>
<script>
export default {
  name: 'AtomsIconsMeter',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
  $class-name: 'AtomsIconsMeter';

  .#{$class-name} {
    .#{$class-name}__outsidePath {
      &--success, &--warning, &--critical {
        fill: var(--white);
        stroke: var(--neutral-700);
      }

      &--locked, &--null {
        fill: var(--neutral-400);
        stroke: var(--neutral-700);
      }
    }

    .#{$class-name}__line {
      &--success, &--warning, &--critical {
        stroke: var(--neutral-700);
      }

      &--locked, &--null {
        stroke: var(--neutral-700)
      }
    }
  }
</style>
