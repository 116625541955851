<template>
  <components
    :is="setLayout"
    :config="setLayoutConfig"
  >
    <template #sticky>
      <components
        :is="component.name"
        v-for="(component, index) in getStickyComponents"
        :key="index"
        :config="component.config"
      />
    </template>
  </components>
</template>
<script>
import TemplatesApp from '@/atomic-design-components/templates/layouts/App';
import OrganismsContentNavigation from '@/atomic-design-components/organisms/ContentNavigation.vue';
import TemplatesSimple from '@/atomic-design-components/templates/layouts/Simple';
import TemplatesEmpty from '@/atomic-design-components/templates/layouts/Empty';

export default {
  name: 'App',
  components: {
    app: TemplatesApp,
    tab: OrganismsContentNavigation,
    simple: TemplatesSimple,
    empty: TemplatesEmpty,
  },
  computed: {
    getLayout() {
      return this.$route.meta.layout || 'app';
    },

    getStickyComponents() {
      return this.$route.meta.stickyComponents || [];
    },

    setLayout() {
      return this.getLayout;
    },

    setLayoutConfig() {
      return this.$route.meta.layoutConfig || {};
    },
  },
};
</script>
