<template>
  <span
    v-cy
    v-tooltip="props.row[props.column.field + '_tooltip']"
  >
    <div class="slot-wrapper">
      <i
        :class="
          (typeof (
            getStatusCode(props.row[props.column.field].icon) !== 'undefined'
          )
            ? getStatusCode(props.row[props.column.field].icon).symbol
            : 'fas fa-question') +
            ' text-' +
            (typeof getStatusCode(props.row[props.column.field].icon) !==
              'undefined'
              ? getStatusCode(props.row[props.column.field].icon).color
              : 'secondary')
        "
      />
      <span clasS="pl-2">
        {{ props.column.date ? setFormatDate : setValue }}
      </span>
    </div>
  </span>
</template>

<script>
import constants from '@/utils/constants';
import { dates } from '@/mixins/formating/dates';

export default {
  name: 'WithIconSlot',
  mixins: [dates],
  props: {
    props: Object,
    withLink: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    setValue() {
      return this.props.row[this.props.column.field].text;
    },

    setFormatDate() {
      return this.formatToGerman(this.props.row[this.props.column.field].text);
    },
  },

  methods: {
    getLockedState() {
      return constants.State.LOCKED;
    },
    getStatusCode(num) {
      if (typeof num === 'object') {
        return constants.StatusCodes[num[0]];
      } return constants.StatusCodes[num];
    },
  },
};
</script>

<style lang="scss" scoped>
.slot-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
