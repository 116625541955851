<template>
  <span>
    <span
      v-for="(option, key) in props.row.copyOptions"
      :key="key"
      v-tooltip="tooltip"
      class="rounded-pill bg-black-300 p-1 cursor-pointer my-1 d-block col"
      @click="copyToClipboard(option.value)"
    >
      {{ option.label }}
      <atoms-icons
        class="px-1 d-inline float-right"
        icon="kg kg-paperclip"
      />
      <atoms-icons
        class="d-none text-black-300 p-absolute"
        icon="kg kg-check"
      />
    </span>
  </span>
</template>
<script>
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index';

export default {
  name: 'CopyOptionsSlot',
  components: {
    AtomsIcons,
  },
  props: {
    props: Object,
  },
  data() {
    return {
      tooltip: 'Kodierte Nachricht kopieren',
    };
  },
  methods: {
    copyToClipboard(v) {
      const el = document.createElement('textarea');
      el.value = v;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
  },
};
</script>

<style scoped>
.animate-copy-check {
    animation-name: check;
    animation-iteration-count: infinite;
    animation-duration: 1s;
}
@keyframes check {
    0% {
        display: none;
    }
    1% {
        display: inline-block;
    }
    50% {
        bottom: 5px;
    }
    100% {
        display: none;
        bottom: 0px;
    }
}
</style>
