import DateUtils from '@/utils/dateUtils';
import constants from './constants';
import utils from './utils';

const sortByObject = {};
sortByObject[constants.State.CRITICAL] = 1;
sortByObject[constants.State.WARNING] = 2;
sortByObject[constants.State.SUCCESS] = 3;
sortByObject[constants.State.PENDING] = 4;
sortByObject[constants.State.LOCKED] = 5;

export default {
  by(attribute, asc = true) {
    return function (a, b) {
      const multiplier = asc ? 1 : (-1);
      const aIsMoreThanB = a[attribute] > b[attribute];
      const aIsLessThanB = a[attribute] < b[attribute];
      const func = () => (aIsLessThanB ? multiplier * (-1) : 0);
      return aIsMoreThanB ? multiplier * 1 : func();
    };
  },

  sortByDate(date1, date2, attribute, direction = 'desc') {
    const isInvalid = (date) => date instanceof Date && !isNaN(date);
    const transform = (date) => new Date(DateUtils.toDBFormat(date));
    const parse = (date) => new Date(DateUtils.parseDate(date));
    const parsedDate1 = isInvalid(date1[attribute]) ? transform(date1[attribute]) : parse(date1[attribute]);
    const parsedDate2 = isInvalid(date2[attribute]) ? transform(date2[attribute]) : parse(date2[attribute]);
    const seconds1 = parsedDate1.getTime();
    const seconds2 = parsedDate2.getTime();
    const descFunc = seconds1 < seconds2 ? 1 : -1;
    const ascFunc = seconds1 - seconds2;
    return direction === 'desc' ? descFunc : ascFunc;
  },

  sortObjectByKey(unordered, sortfunction) {
    const ordered = Object.keys(unordered).sort(sortfunction).reduce(
      (obj, key) => {
        obj[key] = unordered[key];
        return obj;
      },
      {},
    );
    return ordered;
  },
  sortUnit(a, b) {
    const hasPrio = function (x) {
      const priorityUnit = 'kommission';
      return x.name.toLowerCase().includes(priorityUnit);
    };
    if (hasPrio(a) && hasPrio(b)) return 0;
    if (hasPrio(a)) return -1;
    if (hasPrio(b)) return 1;

    const aILessThanB = a.nameCalculated < b.nameCalculated;
    const aIsMoreThanB = a.nameCalculated > b.nameCalculated;
    const func = () => (aIsMoreThanB ? 1 : 0);
    return aILessThanB ? -1 : func();
  },
  sortByDirection(x, y) {
    const Xliteral = x.replaceAll(/[0-9]/g, '');
    const Yliteral = y.replaceAll(/[0-9]/g, '');
    const Xnumeric = x.replaceAll(/[a-z, A-Z]/g, '');
    const Ynumeric = y.replaceAll(/[a-z, A-Z]/g, '');

    const compareAptNum = function (a, b) {
      if (!Number.isNaN(parseInt(a)) && !Number.isNaN(parseInt(b))) {
        const aIsMoreThanB = Number.parseInt(a) < Number.parseInt(b);
        const aIsLessThanB = Number.parseInt(a) > Number.parseInt(b);
        const func = () => (aIsLessThanB ? 1 : 0);
        return aIsMoreThanB ? -1 : func();
      } return 0;
    };
    const mapper = {
      VL: 0,
      L: 1,
      HL: 2,
      ML: 3,
      VM: 4,
      M: 5,
      HM: 6,
      MR: 7,
      VR: 8,
      R: 9,
      HR: 10,
    };
    if (mapper[Xliteral] < mapper[Yliteral]) return -1;
    if (mapper[Xliteral] > mapper[Yliteral]) return 1;
    return compareAptNum(Xnumeric, Ynumeric);
  },
  sortPropertyDetails(propertyDetails) {
    const order = {
      Kunden: 0,
      Objektnummer: 2,
      'Übergeordnete Gruppen': 3,
      Nutzeinheiten: 4,
      Gesamtfläche: 5,
      Abrechnungsperioden: 6,
      'Heizung (Energieträger)': 7,
      Gebäudetyp: 8,
      Baujahr: 9,
      Adresse: 10,
      Geräte: 11,
      'Geräte Verwaltung (Beta)': 11.3,
      Warmwassertemperatur: 11.5,
      Kaltwassertemperatur: 11.6,
      'Letzter Datenempfang': 12,
      Nutzeinheitsgruppen: 13,
      Kostenverteilung: 14,
    };

    const ordered = {};

    for (const title of Object.keys(order)) {
      ordered[title] = propertyDetails[title];
    }

    return ordered;
  },
  sortStatusList(x, y) {
    const statusesX = x.map((a) => sortByObject[a.state]).reduce((a, b) => a + b, 0);
    const statusesY = y.map((b) => sortByObject[b.state]).reduce((a, b) => a + b, 0);
    const xIsMoreThanY = statusesX > statusesY;
    const xIsLessThanY = statusesX < statusesY;
    const func = () => (xIsLessThanY ? 1 : 0);

    return xIsMoreThanY ? -1 : func();
  },
  sortStatusByUrgency(x, y) {
    return (sortByObject[x] < sortByObject[y] ? -1 : (sortByObject[x] > sortByObject[y]));
  },
  sortStatuses(x, y) {
    if (x.hasOwnProperty('router_text')) {
      const xRouterIsLessThanY = (x, y) => sortByObject[x.router_text] < sortByObject[y.router_text];
      const xRouterIsMoreThanY = (x, y) => sortByObject[x.router_text] > sortByObject[y.router_text];
      const funcxRouter = (x, y) => (xRouterIsMoreThanY(x, y) ? 1 : 0);
      return xRouterIsLessThanY(x, y) ? -1 : funcxRouter(x, y);
    } if (typeof (x) === 'object') {
      const x0IsLessThanY = (x, y) => sortByObject[x[0]] < sortByObject[y[0]];
      const x0IsMoreThanY = (x, y) => sortByObject[x[0]] > sortByObject[y][0];
      const funcx0 = (x, y) => (x0IsMoreThanY(x, y) ? 1 : 0);
      return x0IsLessThanY(x, y) ? -1 : funcx0(x, y);
    }
    const xIsLessThanY = (x, y) => sortByObject[x] < sortByObject[y];
    const xIsMoreThanY = (x, y) => sortByObject[x] > sortByObject[y];
    const func = (x, y) => (xIsMoreThanY(x, y) ? 1 : 0);
    return xIsLessThanY(x, y) ? -1 : func(x, y);
  },
  sortFloors(data) {
    return data.sort((a, b) => {
      if (a === 'D') return 1;
      if (a === 'K' || a === 'UG') return -1;
      if (a > b) return -1;
      if (a < b) return 1;

      return 0;
    });
  },
  sortListByAttribute(list, attribute, asc = 1, sortMapping = null) {
    const multiplier = (asc === 1) ? 1 : (-1);
    if (sortMapping) {
      const sortAMapValueIsMoreThenB = (a, b) => sortMapping[a[attribute]] > sortMapping[b[attribute]];
      return list.sort((a, b) => (sortAMapValueIsMoreThenB(a, b) ? multiplier * 1 : multiplier * (-1)));
    }
    return list.sort((a, b) => ((a[attribute] > b[attribute]) ? multiplier * 1 : multiplier * (-1)));
  },
  sortArray(a, b) {
    const aIsLessThenB = a[0] < b[0];
    const aIsMoreThenB = a[0] > b[0];
    const func = () => (aIsMoreThenB ? 1 : 0);
    return aIsLessThenB ? -1 : func();
  },
  sortNumberFn(x, y) {
    return x - y;
  },
  sortStreet(a, b) {
    if (a.street === b.street) return 0;
    if (a.street === '' || a.street === null) return 1;
    if (b.street === '' || b.street === null) return -1;
    return a.street < b.street ? -1 : 1;
  },
  sortNumberWithUnit(x, y) {
    const dateStringX = utils.parseNumberByLocale('de-DE', (`${x}`).split(' ')[0]);
    const dateStringY = utils.parseNumberByLocale('de-DE', (`${y}`).split(' ')[0]);
    const parsedNumberX = Number.isNaN(dateStringX) ? 0 : dateStringX;
    const parsedNumberY = Number.isNaN(dateStringY) ? 0 : dateStringY;
    return parsedNumberX - parsedNumberY;
  },
  sortFn(x, y) {
    if (x && y) {
      let a = x || '';
      let b = y || '';
      if (x.hasOwnProperty('router_text')) {
        a = x.router_text || '';
        b = y.router_text || '';
      }
      const aIsLessThenB = a.toString().toLowerCase() < b.toString().toLowerCase();
      const aIsMoreThanB = a.toString().toLowerCase() > b.toString().toLowerCase();
      const func = () => (aIsMoreThanB ? 1 : 0);

      return aIsLessThenB ? -1 : func();
    } return 0;
  },
  sortDropdownFn(x, y) {
    let a = x || '';
    let b = y || '';
    if (x.hasOwnProperty('text') && y.hasOwnProperty('text')) {
      a = x.text || '';
      b = y.text || '';
    } else {
      a = `${a}`;
      b = `${b}`;
    }

    const aIsLessThenB = a.toString().toLowerCase() < b.toString().toLowerCase();
    const aIsMoreThenB = a.toString().toLowerCase() > b.toString().toLowerCase();
    const func = () => (aIsMoreThenB ? 1 : 0);
    return aIsLessThenB ? -1 : func();
  },
};
