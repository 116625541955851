<template>
  <!--eslint-disable -->
   <svg width="20" height="28" viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
        d="M16.6666 0.555176H3.33329C2.62605 0.555176 1.94777 0.833843 1.44767 1.32987C0.947577 1.82591 0.666626 2.49867 0.666626 3.20016V25.6825C0.666626 26.0333 0.807102 26.3697 1.05715 26.6177C1.3072 26.8657 1.64634 27.005 1.99996 27.005H18C18.3536 27.005 18.6927 26.8657 18.9428 26.6177C19.1928 26.3697 19.3333 26.0333 19.3333 25.6825V3.20016C19.3333 2.49867 19.0523 1.82591 18.5522 1.32987C18.0521 0.833843 17.3739 0.555176 16.6666 0.555176ZM8.66663 19.0701H4.66663V16.4251H8.66663V19.0701ZM8.66663 13.7801H4.66663V11.1351H8.66663V13.7801ZM8.66663 8.49014H4.66663V5.84515H8.66663V8.49014ZM15.3333 19.0701H11.3333V16.4251H15.3333V19.0701ZM15.3333 13.7801H11.3333V11.1351H15.3333V13.7801ZM15.3333 8.49014H11.3333V5.84515H15.3333V8.49014Z"
        fill="#C43B0F"
    />
   </svg>
</template>
<script>
export default {
  name: 'AtomsIconsBuildingMap',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
  $class-name: 'AtomsIconsBuildingMap';

  .#{$class-name} {
    .#{$class-name}__outsidePath {
      &--success, &--warning, &--critical {
        fill: var(--white);
        stroke: var(--neutral-700);
      }

      &--locked, &--null {
        fill: var(--neutral-400);
        stroke: var(--neutral-700);
      }
    }

    .#{$class-name}__line {
      &--success, &--warning, &--critical {
        stroke: var(--neutral-700);
      }

      &--locked, &--null {
        stroke: var(--neutral-700)
      }
    }
  }
</style>
