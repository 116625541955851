var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{directives:[{name:"cy",rawName:"v-cy"},{name:"tooltip",rawName:"v-tooltip",value:(_vm.props.row[_vm.props.column.field + '_tooltip']),expression:"props.row[props.column.field + '_tooltip']"}]},[_c('div',{staticClass:"slot-wrapper"},[_c('i',{class:(typeof (
          _vm.getStatusCode(_vm.props.row[_vm.props.column.field].icon) !== 'undefined'
        )
          ? _vm.getStatusCode(_vm.props.row[_vm.props.column.field].icon).symbol
          : 'fas fa-question') +
          ' text-' +
          (typeof _vm.getStatusCode(_vm.props.row[_vm.props.column.field].icon) !==
            'undefined'
            ? _vm.getStatusCode(_vm.props.row[_vm.props.column.field].icon).color
            : 'secondary')}),_c('span',{attrs:{"clasS":"pl-2"}},[_vm._v(" "+_vm._s(_vm.props.column.date ? _vm.setFormatDate : _vm.setValue)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }