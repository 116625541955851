<template>
  <div
    v-click-outside="closeDatePicker"
    :class="'position-relative initial-toggle' + _uid"
  >
    <button
      ref="datePickerButton"
      class="basic-button mr-2"
      @click="showDatePicker = true"
    >
      <span v-html="getDateDisplay" />
      <atoms-icons
        class="pl-1"
        icon="kg kg-calendar"
      />
    </button>
    <ExtendedDatePicker
      v-if="showDatePicker == true"
      :key="date"
      class="datepicker-button"
      :initial-date="date"
      :style="`left: ${pickerPos[0]}px; top: ${pickerPos[1]}px`"
      :tabs="tabs"
      @datePickerEmit="datePickerEmit"
      @picked="datePicked"
    /> <!-- @picked v-click-outside-->
  </div>
</template>

<script>
import ExtendedDatePicker from '@/components/ExtendedDatePicker';
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index';
import dateUtils from '@/utils/dateUtils';

export default {
  name: 'DatePickerButton',
  props: {
    picked: {
      type: String,
      default: new Date(),
    },
    tabs: {
      type: Array,
      default() {
        return ['Gespeichert', 'Durchlaufend'];
      },
    },
  },
  components: {
    ExtendedDatePicker,
    AtomsIcons,
  },
  data() {
    return {
      date: this.picked,
      showDatePicker: false,
      dateUtils,
      pickerPos: [0, 0],
    };
  },
  created() {
    this.date = this.picked;
    window.addEventListener('scroll', this.setPickerPos);
  },
  computed: {
    getDateDisplay() {
      return dateUtils.format(this.date);
    },
  },
  mounted() {
    setTimeout(() => {
      this.setPickerPos();
    }, 500);
  },
  destroyed() {
    window.removeEventListener('scroll', this.setPickerPos);
  },
  methods: {
    setPickerPos() {
      if (this.$refs.datePickerButton) {
        const rect = this.$refs.datePickerButton.getBoundingClientRect();
        this.pickerPos = [rect.left, rect.top + 50];
      } else this.pickerPos = [0, 0];
    },
    datePickerEmit(v, functionName, tabName) {
      this.date = `${v.id} 00:00:00`;
      this.showDatePicker = false;
      this.$emit('datePickerEmit', v, functionName, tabName);
    },
    datePicked(v, tabName) {
      this.date = v.id;
      this.showDatePicker = false;
      this.$emit('picked', v, tabName);
    },
    closeDatePicker(ev) {
      if (!ev.target.className.includes(`initial-toggle${this._uid}`)) {
        this.showDatePicker = false;
      }
    },
  },
};
</script>

<style scoped>
.datepicker-button {
    position: fixed;
    z-index: 1000;
}
</style>
