import Keycloak from 'keycloak-js';
import { pluginOptions } from '@/../vue.config';
import { fetchMandant } from '@/utils/auth';

const authentication = pluginOptions.VUE_APP_AUTHENTICATION;
export default {
  async loadKeycloak() {
    const propData = await fetchMandant();
    const keycloak = new Keycloak({
      url: authentication.server_url,
      realm: propData.keycloak_realm,
      clientId: authentication.client_id,
      post_logout_redirect_uri: window.location.origin,
    });
    window.keycloak = keycloak;
    return keycloak;
  },
};
