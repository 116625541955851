<template>
  <!-- eslint-disable -->
    <svg viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4031_8893)">
            <path d="M25.2002 1L36.2002 0.5L45.2002 8.5V47H25.2002V1Z" fill="#D0FBDA"/>
            <path d="M3.7002 0.999999C3.7002 0.723857 3.92405 0.5 4.2002 0.5H35.8231C35.9446 0.5 36.062 0.544263 36.1532 0.624516L45.197 8.57682L45.5151 8.21514L45.197 8.57682C45.305 8.67174 45.3669 8.80854 45.3669 8.9523V47C45.3669 47.2761 45.143 47.5 44.8669 47.5H16.2002C15.9241 47.5 15.7002 47.2761 15.7002 47V37.8C15.7002 36.9716 15.0286 36.3 14.2002 36.3H4.2002C3.92405 36.3 3.7002 36.0761 3.7002 35.8V0.999999Z" stroke="#263344"/>
            <path d="M25.4224 0.533447V9.60011M45.4224 18.1334H25.4224V14.9334V25.6001M25.4224 47.4668V34.1334" stroke="#263344"/>
            <path d="M14.3114 36.2666C14.3114 36.2666 14.6389 32.1026 12.5336 29.8666C10.4284 27.6306 6.75586 27.7698 6.75586 27.7698V36.2666" stroke="#263344"/>
            <path d="M25.4782 7.99982C25.4782 7.99982 21.9296 7.64834 20.0874 10.1969C18.2452 12.7455 18.7561 17.0659 18.7561 17.0659L25.4782 17.0658" stroke="#263344"/>
            <path d="M45.4221 27.2H43.311C42.7587 27.2 42.311 27.6477 42.311 28.2V37.4C42.311 37.9522 42.7588 38.4 43.311 38.4H45.4221" stroke="#263344"/>
        </g>
        <defs>
            <clipPath id="clip0_4031_8893">
                <rect width="48" height="48" fill="white" transform="translate(0.200195)"/>
            </clipPath>
        </defs>
    </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'AtomsIconsFloorplan',
  props: {
    color: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
  $class-name: 'AtomsIconsFloorplan';
</style>
