<template>
  <footer
    v-show="footerOptions.rowsCount > 0"
    class="table-footer"
  >
    <div
      v-if="footerOptions.rowsCount > 0"
      class="table-footer__page-information"
    >
      {{ minEntryNumber }}–{{ maxEntryNumber }} {{ footerOptions.ofText }} {{ footerOptions.rowsCount }}
    </div>
    <div
      v-else
      class="table-footer__page-information"
    >
      0 {{ footerOptions.ofText }} {{ footerOptions.totalRowsCount }}
    </div>
    <div class="table-footer__pagination">
      <button
        :class="['pagination__button', {'pagination__button--disabled': footerOptions.currentPage <= 1}]"
        type="button"
        @click="handlePageChange(footerOptions.currentPage - 1)"
      >
        <atoms-icons icon="kg kg-chevron-left" />
        <span>{{ footerOptions.prevText }}</span>
      </button>
      <form @submit.prevent="handleSubmit">
        <NumericInput
          ref="paginationInput"
          class="table-footer__page-input"
          :custom-value="customValue"
          :hide-arrows="true"
          :input-field="inputOptions"
          @input="handleCustomPageChange"
        />
      </form>
      <button
        :class="['pagination__button', {'pagination__button--disabled': footerOptions.currentPage >= maxPageNumber}]"
        type="button"
        @click="handlePageChange(footerOptions.currentPage + 1)"
      >
        <span>{{ footerOptions.nextText }}</span>
        <atoms-icons icon="kg kg-chevron-right" />
      </button>
    </div>
  </footer>
</template>

<script>
import NumericInput from '@/components/formInputs/NumericInput';
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index';

export default {
  name: 'CustomTableFooter',
  components: {
    NumericInput,
    AtomsIcons,
  },
  data() {
    return {
      inputOptions: {
        attributeName: 'heat_value',
        label: '',
        step: 1,
        errorMessage: 'Bitte geben Sie einen Heizwert an.',
        pointer: 'betrag',
        defaultValue: this.footerOptions.currentPage,
        saveOnBlur: true,
      },
      temporaryPageNumber: 0,
    };
  },
  computed: {
    minEntryNumber() {
      const { currentPage, currentPerPage } = this.footerOptions;
      return (currentPage - 1) * currentPerPage + 1;
    },
    maxEntryNumber() {
      const { currentPage, currentPerPage, rowsCount } = this.footerOptions;
      const pretendToBeMaxEntryNumber = currentPage * currentPerPage;
      return pretendToBeMaxEntryNumber > rowsCount ? rowsCount : pretendToBeMaxEntryNumber;
    },
    maxPageNumber() {
      const { currentPerPage, rowsCount } = this.footerOptions;
      return Math.ceil(rowsCount / currentPerPage);
    },
    customValue() {
      return this.temporaryPageNumber > 0 ? this.temporaryPageNumber : this.footerOptions.currentPage;
    },
  },
  props: {
    footerOptions: Object,
  },
  methods: {
    isPageNumberIncorrect(pageNumber) {
      return pageNumber <= 0 || pageNumber > this.maxPageNumber;
    },
    handlePageChange(newPage) {
      if (this.isPageNumberIncorrect(newPage)) return;
      this.$emit('change-page', newPage);
    },
    handleCustomPageChange(newPage) {
      const intNewPage = parseInt(newPage);
      if (this.isPageNumberIncorrect(intNewPage) || Number.isNaN(intNewPage)) {
        this.$emit('change-page', 1);
        this.temporaryPageNumber = 2;
        setTimeout(() => {
          this.temporaryPageNumber = 1;
        }, 100);
        setTimeout(() => {
          this.temporaryPageNumber = 0;
        }, 200);
        return;
      }
      this.$emit('change-page', intNewPage);
    },
    handleSubmit() {
      const paginationInput = this.$refs.paginationInput;
      const { value: paginationInputValue } = paginationInput;
      this.handleCustomPageChange(paginationInputValue);
    },
  },
};
</script>

<style lang="scss" scoped>
  .table-footer {
    padding: 12px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__page-information {
      font-size: 14px;
      color: var(--kugu-black);
    }
    &__pagination {
      display: flex;
      align-items: center;
    }
    &__page-input {
      width: 55px;
      input {
      text-align: center;
      }
    }
  }

  .pagination {
    &__button {
      color: var(--neutral-900);
      font-size: 14px;
      border: none;
      display: grid;
      grid-template-columns: repeat(2, max-content);
      align-items: center;
      grid-column-gap: 8px;
      background: transparent;
      &--disabled * {
        color: #dddfe6;
      }
    }
  }
</style>

<style lang="scss">
  .table-footer {
    &__page-input {
      &.frame {
        padding: 0;
        margin: 0 12px;
      }
      .row {
        display: none;
      }
      input {
        text-align: center;
        padding: 0 4px !important;
      }
    }
  }
</style>
