<template>
  <span>
    <template v-if="props.column.selector">
      <button
        v-if="!runValid()"
        class="primary-button"
        @click="this.handleClick"
      >
        {{ props.formattedRow.dateInput }}
        {{ props.column.button_text }}
      </button>
    </template>
    <template v-else>
      <a
        class="btn btn-light btn-icon-split"
        :href="props.formattedRow[props.column.field].button_link"
        role="button"
        target="_blank"
      >
        <span class="icon text-gray-600">
          <atoms-icons
            v-if="props.formattedRow[props.column.field].button_text == 'Pausieren'"
            icon="kg kg-time-period"
          />
          <atoms-icons
            v-else
            icon="kg kg-check-square"
          />
        </span>
        <span class="text">{{ props.formattedRow[props.column.field].button_text }}</span>
      </a>
    </template>
  </span>
</template>

<script>
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index';

export default {
  name: 'ButtonSlot',
  components: {
    AtomsIcons,
  },
  props: {
    props: Object,
  },

  methods: {
    runValid() {
      return this.props.column.valid(this.props);
    },

    handleClick() {
      this.props.column.func(this.props.row);
    },
  },
};
</script>
