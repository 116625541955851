import moment from 'moment';

export const methods = (state) => ({
  setGraphSeries(data, measurementName, period) {
    const { response, choId } = data;
    if (state.graphSeries[measurementName] === undefined) {
      state.graphSeries = {
        [measurementName]: {
          graphSeries: [{
            series: response,
            ...period,
            choId,
          }],
        },
        ...state.graphSeries,
      };
    } else {
      /* eslint-disable */
      const { [measurementName]: graphType } = state.graphSeries;
      const foundSeries = graphType.graphSeries.find((element) => {
        return this.isPeriodEqual(period, element) && this.isGroupEqual(period, element)
      });
      /* eslint-disable */
      if (!foundSeries) {
        graphType.graphSeries.push({
          series: response,
          ...period,
          choId
        }); 
      }
    }

  },
  setAnnotationsForGraph(data, measurementName, period) {
    if (state.graphSeries[measurementName] === undefined) {
      return
    }
      /* eslint-disable */
      const { [measurementName]: graphType } = state.graphSeries;
      const foundSeries = graphType.graphSeries.find((element) => this.isPeriodEqual(period, element) && this.isGroupEqual(period, element));
      /* eslint-disable */
      if (foundSeries) {
        foundSeries.annotations = data;
      }
  },
  isPeriodEqual(period, newPeriod) {
    const { start, end } = newPeriod;
    return moment(period.end).isSame(end)
    && moment(period.start).isSame(start);
  },
  isGroupEqual(period, newPeriod) {
    const { group } = newPeriod;
    return period.group === group;
  },
  
});
