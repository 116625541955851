<template>
  <!-- eslint-disable -->
  <svg
    :class="blockClassName"
    width="17"
    height="21"
    viewBox="0 0 17 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="childrenClassName('fire', [status])"
      opacity="0.87"
      d="M11.4366 0C9.86619 0.920685 8.42943 2.77123 8.42943 4.66812C8.42943 6.21802 9.12633 7.79119 10.2337 8.74889C11.1502 7.95312 11.8518 6.4911 12.038 5.25119C15.0451 6.60677 16.8542 9.71125 16.8542 12.8342C16.8542 17.3451 13.0785 21 8.42943 21C3.77079 21 0 17.3451 0 12.8342C0 6.52818 5.11208 1.0271 11.4366 0Z"
      fill="#FC7247"
      fill-opacity="0.87"
    />
  </svg>
  <!-- eslint-enable -->
</template>
<script>
export default {
  name: 'AtomsIconHealth',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
  $class-name: 'AtomsIconHealth';

  .#{$class-name} {
    .#{$class-name}__fire {

      &--success, &--warning, &--critical {
        fill: #FC7247;
      }

      &--locked, &--null {
        fill: var(--neutral-700);
      }
    }
  }
</style>
