<template>
  <span style="cursor:pointer;">
    <span
      v-for="(cost, key) in props.row.direct_costs"
      :key="key"
      class="d-inline-block w-100 text-center"
    >
      <p
        align="left"
        class="m-0"
      >
        <span style="display:inline-block;width: 60px">{{ cost.value }} €</span> - {{ cost.description }}
      </p>
    </span>
  </span>
</template>
<script>
export default {
  name: 'CostSlot',
  props: {
    props: Object,
  },
};
</script>
