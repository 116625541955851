<template>
  <div
    v-cy
    class="m-0 p-0"
  >
    <div v-if="hidden == false">
      <div
        v-cy="'button'"
        :class="[buttonType + '-button','cursor-pointer position-relative font-weight-normal',]"
        @click="showDropdown = !showDropdown"
      >
        {{ displayLabel }}
        <span
          v-if="!showDropdown"
          :class="['pl-2', buttonType == 'primary' ? 'text-white' : 'text-dark',]"
          v-html="showIconOpen? '<i class=\'kg kg-' + showIconOpen + '\'></i>': '&#9662;'"
        />
        <span
          v-else
          :class="['pl-2',buttonType == 'primary' ? 'text-white' : 'text-dark']"
          v-html="showIconClose? '<i class=\'kg kg-' + showIconClose + ' ></i>': '&#9656;'"
        />
      </div>
      <div
        v-if="showDropdown && typeof options[0] == 'string'"
        :class="['position-absolute dropdown-shown',buttonType + '-translate']"
      >
        <div v-if="withSearchBar">
          <input
            class="search-input"
            placeholder="Search"
            type="text"
            v-model="serachText"
          >
        </div>
        <p
          v-for="(option, key) in filteredOptions"
          :key="key"
          :class="[
            buttonType + '-link',buttonType === 'dropdown' && label === option ? 'text-bold' : '',
            'dropdown-item cursor-pointer ' + borderIfNotLast(key, options.length)
          ]"
          :style="isMobile && buttonType === 'dropdown' ?
            'padding: 10px ' + ((key < 3 ? key : 3) * 10 + 20) + 'px' : ''"
          @click="selectedChoice($event.target, false)"
        >
          {{ option }}
        </p>
      </div>
      <div
        v-else-if="showDropdown && typeof options[0] == 'object'"
        class="position-absolute dropdown-shown"
      >
        <div v-if="withSearchBar">
          <input
            v-cy="'searchInput'"
            class="search-input"
            placeholder="Search"
            type="text"
            v-model="serachText"
          >
        </div>
        <p
          v-for="(option, key) in filteredOptions"
          :key="key"
          v-cy="`option__${key}`"
          :class="'dropdown-item cursor-pointer ' + borderIfNotLast(key, options.length)"
          :name="option.label"
          :value="option.value"
          @click="selectedChoice($event.target, true)"
        >
          {{ option.label }}
        </p>
      </div>
    </div>
    <div
      v-else
      v-cy="`actionButton`"
      v-tooltip="tooltip"
      class="basic-button cursor-disabled font-weight-normal"
    >
      {{ displayLabel }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownButton',
  props: {
    hidden: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
    showSelectionInLabel: {
      type: Boolean,
      default: false,
    },
    withSearchBar: {
      type: Boolean,
      default: false,
    },
    label: String,
    options: Array,
    buttonType: {
      type: String,
      default: 'primary',
    },
    showIconOpen: {
      type: String,
      default: 'primary',
    },
    showIconClose: {
      type: String,
      default: 'primary',
    },
    isMobile: Boolean,
  },
  methods: {
    selectedChoice(target, isObject) {
      this.showDropdown = false;
      const selected = !isObject ? target.textContent : target;

      this.$emit('clicked', selected);
      this.$emit('choosen', target.getAttribute('value'));
      this.eventHub.$emit('selectDropdown', selected);
      this.serachText = '';
      if (this.showSelectionInLabel) {
        this.displayLabel = typeof selected === 'object'
          ? selected.getAttribute('name')
          : selected;
      }
    },
    borderIfNotLast(key, length) {
      return key === length - 1 ? '' : 'bottom-border';
    },
  },
  data() {
    return {
      showDropdown: false,
      selection: this.options[0],
      displayLabel: this.label,
      serachText: '',
      filteredOptions: this.options,
    };
  },
  watch: {
    options() {
      this.filteredOptions = this.options;
    },
    serachText() {
      if (this.serachText.length === 0) {
        this.filteredOptions = this.options;
      } else if (typeof this.options[0] === 'object') {
        this.filteredOptions = this.options.filter(
          (o) => o.label && o.label.toLowerCase().includes(this.serachText.toLowerCase()),
        );
      } else {
        this.filteredOptions = this.options.filter((o) => o.toLowerCase().includes(this.serachText.toLowerCase()));
      }
    },
    label() {
      this.displayLabel = this.label;
    },
  },
};
</script>

<style>
.dropdown-shown {
    max-height: 400px;
    overflow: auto;
    background: var(--neutral-200);
    border: var(--border);
}

.dropdown-shown,
.dropdown-link,
.dashboard-type-link {
    text-align: left;
}

.dropdown-check {
    color: #1cc88a;
    font-size: 12px;
    padding-right: 5px;
}

.text-bold {
    font-weight: bold;
}

.dropdown-translate {
    transform: translateY(15px);
}
.search-input {
  width: 100%;
}
</style>
