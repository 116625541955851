import Api from '@/utils/api';
import { BREADCRUMBS } from '@/dictionaries/breadcrumbs';

export const methods = (state) => ({
  setBreadcrumbs(module, context = {}) {
    if (BREADCRUMBS[module]) {
      const breadcrumbInstance = BREADCRUMBS[module];
      state.breadcrumbs = breadcrumbInstance.func(context);
      if (!breadcrumbInstance.dropdown) {
        state.names = [];
      }
    } else {
      state.breadcrumbs = undefined;
    }
  },

  setLabel(module, context) {
    const dropdownOption = BREADCRUMBS[module].dropdown;
    state.label = dropdownOption.label(context);
  },

  setDropdown(module, context) {
    const dropdownOption = BREADCRUMBS[module].dropdown;
    Api.get(dropdownOption.endpoint(context), {}, (response) => {
      state.data = dropdownOption.func(response);
      state.names = state.data.map((item) => item.displayName);
    });
  },

  reset() {
    state.label = '';
    state.names = [];
  },
});
