<template>
  <div class="container">
    <div class="content">
      <span class="label">Einträge pro Seite</span>
      <SelectInput
        class="dropdown"
        :input-field="rowsPerPageConfig"
        :value="value"
        @input="handleUpdateRowsPerPage"
      />
    </div>
  </div>
</template>

<script>
import SelectInput from '@/components/formInputs/SelectInput';

export default {
  name: 'RowsPerPageFilter',
  components: {
    SelectInput,
  },
  data() {
    const optionsPerTableType = {
      main: [
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: '75', value: 75 },
        { label: '100', value: 100 },
      ],
      sidebar: [
        { label: '10', value: 10 },
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: '75', value: 75 },
        { label: '100', value: 100 },
      ],
      custom: [
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
        { label: '250', value: 250 },
        { label: '500', value: 500 },
      ],
    };
    return {
      rowsPerPageConfig: {
        label: null,
        attributeName: 'rowsPerPage',
        type: 'select',
        columnWidth: 5,
        placeholder: {
          label: 'Wahl',
        },
        options: optionsPerTableType[this.tableType],
      },
      selectValue: null,
    };
  },
  props: {
    tableType: {
      validator: (type) => ['main', 'sidebar', 'custom'].includes(type),
      default: 'main',
    },
    value: {
      type: Object,
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.selectValue = this.value;
      }
    },
  },
  methods: {
    handleUpdateRowsPerPage(value) {
      if (value !== this.defaultValue) {
        this.$emit('update-rows-per-page', value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .container {
    padding: 0 8px 0 0;
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .content {
    display: grid;
    align-items: center;
    grid-template-columns: max-content 100px;
    grid-gap: 1rem;
  }
  .dropdown {
    margin-top: -12px;
  }
  .label {
    font-size: 14px;
  }
</style>
