<template>
  <!-- eslint-disable -->
  <svg
    :class="blockClassName"
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="childrenClassName('outsidePath', [status])"
      d="M6.92279 13.6684L14.3369 6.62628C15.4598 7.07754 16.8057 6.87096 17.7219 6.00068C18.6374 5.13187 18.8549 3.90703 18.379 2.84042L16.9036 4.29748H15.2678V2.63384L16.7447 1.23173C15.6202 0.78047 14.2736 0.987784 13.3589 1.85806C12.4434 2.72688 12.2252 4.00592 12.6995 5.07253L5.28695 12.1146C4.164 11.6634 2.81739 11.8699 1.90191 12.7395C0.9872 13.609 0.768935 14.8881 1.24403 15.9547L2.72021 14.5533H4.47019V16.1063L2.93694 17.5092C4.06066 17.9597 5.37796 17.7524 6.29344 16.8836C7.20892 16.0147 7.39788 14.735 6.92279 13.6684Z"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M9.81148 7.81558L6.92313 5.07215C7.39822 4.00555 7.18073 2.7265 6.26448 1.85769C5.34977 0.988139 4.061 0.780092 2.93728 1.23208L4.47053 2.6342V4.2971H2.72055L1.24437 2.84005C0.768507 3.90665 0.987543 5.15859 1.90225 6.02814C2.81773 6.89769 4.16435 7.09108 5.28729 6.64056L8.17642 9.37666"
      fill="white"
    />
    <path
      :class="childrenClassName('line', [status])"
      d="M9.81148 7.81558L6.92313 5.07215C7.39822 4.00555 7.18073 2.7265 6.26448 1.85769C5.34977 0.988139 4.061 0.780092 2.93728 1.23208L4.47053 2.6342V4.2971H2.72055L1.24437 2.84005C0.768507 3.90665 0.987543 5.15859 1.90225 6.02814C2.81773 6.89769 4.16435 7.09108 5.28729 6.64056L8.17642 9.37666"
      stroke="#263344"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      d="M9.81348 10.9231L12.7034 13.6688C12.2283 14.7354 12.4458 16.0144 13.362 16.884C14.2767 17.7535 15.6233 17.9594 16.7471 17.5088L15.2701 16.1067V14.553H16.9059L18.3821 15.9543C18.858 14.8877 18.639 13.6094 17.7242 12.7392C16.8088 11.8703 15.4622 11.663 14.3392 12.1143L11.4509 9.37012"
      fill="white"
    />
    <path
      :class="childrenClassName('line', [status])"
      d="M9.81348 10.9231L12.7034 13.6688C12.2283 14.7354 12.4458 16.0144 13.362 16.884C14.2767 17.7535 15.6233 17.9594 16.7471 17.5088L15.2701 16.1067V14.553H16.9059L18.3821 15.9544C18.858 14.8877 18.639 13.6094 17.7242 12.7392C16.8088 11.8703 15.4622 11.663 14.3392 12.1143L11.4509 9.37012"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
  </svg>
  <!-- eslint-enable -->
</template>
<script>
export default {
  name: 'AtomsIconsTool',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
  $class-name: 'AtomsIconsTool';

  .#{$class-name} {
    .#{$class-name}__outsidePath {
      &--success, &--warning, &--critical {
        fill: var(--white);
        stroke: var(--neutral-900);
      }

      &--locked, &--null {
        fill: var(--neutral-200);
        stroke: var(--neutral-700);
      }
    }

    .#{$class-name}__line {
      &--success, &--warning, &--critical {
        stroke: var(--neutral-900);
      }

      &--locked, &--null {
        stroke: var(--neutral-700)
      }
    }
  }
</style>
