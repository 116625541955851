<template>
  <p
    v-if="props.row['change']"
    align="left"
    class="m-0"
  >
    <span
      v-if="change[0]=='-'"
      class="text-danger"
    >-{{ Math.round(props.row['change'] * 100)/100 }}%</span>
    <span
      v-else-if="props.row['change'] == 0"
      class="text-secondary"
    >{{ 0 }}</span>
    <span
      v-else
      class="text-success"
    >{{ Math.round(props.row['change'] * 100)/100 }}</span>
  </p>
</template>
<script>
export default {
  name: 'ChangeIndicatorSlot',
  props: {
    props: Object,
  },
  computed: {
    change() {
      return `${this.props.row.change}`;
    },
  },
};
</script>
