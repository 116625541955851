<template>
  <!-- eslint-disable -->
<svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="17.043" y="15.7715" width="25.4571" height="29.2286" rx="1" fill="#D0FBDA" stroke="#263344"/>
  <path d="M18.9287 15.0172C18.9287 15.4338 19.2664 15.7715 19.683 15.7715H39.8601C40.2767 15.7715 40.6144 15.4338 40.6144 15.0172C40.6144 13.3509 39.2636 12.0001 37.5973 12.0001H21.9459C20.2795 12.0001 18.9287 13.3509 18.9287 15.0172Z" fill="#FCF4CB" stroke="#263344"/>
  <rect x="5.5" y="22" width="16" height="24" rx="0.5" fill="white" stroke="#263344"/>
  <rect x="8.5" y="26" width="3.70614" height="3.70614" rx="1" fill="#FCF4CB" stroke="#263344"/>
  <rect x="14.5" y="26" width="3.70614" height="3.70614" rx="1" fill="#FCF4CB" stroke="#263344"/>
  <rect x="8.5" y="32" width="3.70614" height="3.70614" rx="1" fill="#FCF4CB" stroke="#263344"/>
  <rect x="14.5" y="32" width="3.70614" height="3.70614" rx="1" fill="#FCF4CB" stroke="#263344"/>
  <rect x="8.5" y="38" width="3.70614" height="3.70614" rx="1" fill="#FCF4CB" stroke="#263344"/>
  <rect x="14.5" y="38" width="3.70614" height="3.70614" rx="1" fill="#FCF4CB" stroke="#263344"/>
  <circle cx="11" cy="9" r="5.5" fill="#CAD5E1" stroke="#263344"/>
  <path d="M8.76945 10.4133C8.07626 10.4133 7.57461 9.93878 7.57461 9.17857C7.57461 8.46939 8.02154 7.88265 8.75121 7.88265C9.07044 7.88265 9.34862 7.98469 9.56752 8.14286L9.71346 7.55102C9.43983 7.38775 9.10692 7.29592 8.75577 7.29592C7.71143 7.29592 7 8.09694 7 9.17857C7 10.2704 7.72511 11 8.75577 11C9.1434 11 9.53104 10.8827 9.80923 10.6786L9.71346 10.0969C9.49 10.2755 9.11604 10.4133 8.76945 10.4133Z" fill="#263344"/>
  <path d="M11.5807 7.29592C10.6458 7.29592 9.95716 8.06633 9.95716 9.14796C9.95716 10.2296 10.6458 11 11.5807 11C12.5156 11 13.1996 10.2296 13.1996 9.14796C13.1996 8.06633 12.5156 7.29592 11.5807 7.29592ZM11.5807 10.4133C10.9696 10.4133 10.5363 9.88265 10.5363 9.14796C10.5363 8.41327 10.9696 7.88265 11.5807 7.88265C12.1872 7.88265 12.625 8.41327 12.625 9.14796C12.625 9.88265 12.1872 10.4133 11.5807 10.4133Z" fill="#263344"/>
  <path d="M14.1746 8.79592L14.5348 8.43878C14.8677 8.13776 14.9818 7.90816 14.9818 7.65816C14.9818 7.25 14.6671 7 14.2566 7C13.942 7 13.7596 7.13265 13.6182 7.25L13.7459 7.55612C13.8371 7.47959 13.9921 7.35714 14.2521 7.35714C14.4391 7.35714 14.6215 7.4898 14.6215 7.66837C14.6215 7.80612 14.5668 7.94898 14.2795 8.21429L13.5817 8.86735V9.16327H15V8.80612H14.1746V8.79592Z" fill="#263344"/>
  <path d="M21 9V4M21 4L18.5 6.27273M21 4L23.5 6.27273" stroke="#263344"/>
  <path d="M30 9V4M30 4L27.5 6.27273M30 4L32.5 6.27273" stroke="#263344"/>
  <path d="M39 9V4M39 4L36.5 6.27273M39 4L41.5 6.27273" stroke="#263344"/>
  <path d="M28.5 20L38.5 20" stroke="#263344" stroke-linecap="round"/>
  <path d="M28.5 25L38.5 25" stroke="#263344" stroke-linecap="round"/>
  <path d="M28.5 31C28.5 30.4477 28.9477 30 29.5 30H37.5C38.0523 30 38.5 30.4477 38.5 31V45H28.5V31Z" fill="white" stroke="#263344"/>
</svg>
  <!-- eslint-enable -->
</template>
<script>
export default {
  name: 'AtomsIconsBuildng',
  props: {
    color: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
  $class-name: 'AtomsIconsBuilding';
</style>
