<template>
  <div class="filters__wrapper">
    <div
      v-for="filter in filters"
      :key="filter.field"
      class="filters__item"
    >
      {{ getFilterChipsText(filter) }}
      <button
        class="filters__delete"
        type="button"
        @click="handleDeleteFilter(filter ? filter.field : filter)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UsedFilters',
  components: {},
  props: {
    statusOptions: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Array,
      default: () => [
        { field: 'fieldName', value: 'HKV' },
        { field: 'fieldName2', value: 'HKV2' },
        { field: 'fieldName3', value: 'HKV3' },
      ],
    },
  },
  methods: {
    handleDeleteFilter(field) {
      this.$emit('delete-filter', field);
    },
    getFilterChipsText(filter) {
      if (!filter) return '';
      return (['status'].includes(filter.field)
      && this.statusOptions.find(({ value }) => value === filter.value))
        ? this.statusOptions.find(({ value }) => value === filter.value).text
        : filter.value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .filters {
    &__wrapper {
      display: flex;
      align-items: center;
    }
    &__item {
      font-size: var(--font-size);
      padding: 6px 0 6px 9px;
      color: var(--neutral-800);
      background: #dddfe6;
      border-radius: 3px;
      display: flex;
      align-items: center;
      &:not(:last-of-type) {
        margin-right: 12px;
      }
    }
    &__delete {
      background: transparent;
      border: none;
      padding: 0 9px;
      width: 26px;
      height: 16px;
      position: relative;
      &:before, &:after {
        font-size: 12px;
        background: var(--primary-500);
        content: '';
        height: 1.5px;
        width: 10px;
        position: absolute;
        left: 50%;
        top: 50%;
      }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
</style>
