<template>
  <div class="wrapper">
    <UsedFilters
      :filters="filters"
      :status-options="statusOptions"
      @delete-filter="handleDeleteFilter"
    />
    <div class="wrapper--right">
      <div class="vertical-divider" />
      <RowsPerPageFilter
        :table-type="tableType"
        :value="value"
        @update-rows-per-page="handleRowsPerPageChange"
      />
    </div>
  </div>
</template>

<script>
import RowsPerPageFilter from '@/components/vueTableComponents/RowsPerPageFilter';
import UsedFilters from '@/components/vueTableComponents/UsedFilters';

export default {
  name: 'FiltersRow',
  components: {
    RowsPerPageFilter,
    UsedFilters,
  },
  props: {
    tableType: {
      validator: (type) => ['main', 'sidebar', 'custom'].includes(type),
      default: 'main',
    },
    value: {
      type: Object,
    },
    filters: Array,
    statusOptions: Array,
  },
  methods: {
    handleRowsPerPageChange(newValue) {
      this.$emit('update-rows-per-page', newValue);
    },
    handleDeleteFilter(field) {
      this.$emit('delete-filter', field);
    },
  },
};
</script>

<style lang="scss" scoped>
  .wrapper {
    padding: 0 1.25rem;
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--right {
      display: flex;
      align-items: center;
    }
  }
  .vertical-divider {
    height: 36px;
    width: 1px;
    background: #dddfe6;
    margin: 0 20px;
  }
</style>
