<template>
  <div v-tooltip="setTooltip">
    {{ props.row[props.column.field] }}
  </div>
</template>
<script>
export default {
  name: 'TextwithTooltipSlot',
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    setTooltip() {
      const tooltipText = this.props.column.tooltip;
      const value = this.props.row[this.props.column.mapTooltipValue];
      return tooltipText.replace(`${value}`, value);
    },
  },
};
</script>
