import { render, staticRenderFns } from "./CustomTableFooter.vue?vue&type=template&id=0e7bd268&scoped=true&"
import script from "./CustomTableFooter.vue?vue&type=script&lang=js&"
export * from "./CustomTableFooter.vue?vue&type=script&lang=js&"
import style0 from "./CustomTableFooter.vue?vue&type=style&index=0&id=0e7bd268&prod&lang=scss&scoped=true&"
import style1 from "./CustomTableFooter.vue?vue&type=style&index=1&id=0e7bd268&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e7bd268",
  null
  
)

export default component.exports