<template>
  <div class="nav-item dropdown no-arrow">
    <a
      id="userDropdown"
      class="nav-link dropdown-toggle"
      data-bs-toggle="dropdown"
      data-cy="user-dropdown"
      href="#"
      role="button"
    >
      <div class="nav-link__user-info">
        <span
          class="mr-2 d-lg-inline user-info__username text-dark"
          v-text="username()"
        />
        <span class="mr-2 d-lg-inline user-info__role">{{
          formattedCurrentRoles
        }}</span>
      </div>

      <svg
        fill="none"
        height="50"
        viewBox="0 0 200 200"
        width="50"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          fill="white"
          height="60"
          width="60"
        />
        <circle
          cx="100"
          cy="100"
          fill="#ECECEC"
          r="87"
        />
        <!-- eslint-disable -->
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M119.411 80.1807C119.411 91.5188 110.611 100.702 99.7457 100.702C88.8806 100.702 80.0803 91.5188 80.0803 80.1807C80.0803 68.8426 88.8806 59.6592 99.7457 59.6592C110.611 59.6592 119.411 68.8426 119.411 80.1807ZM60.4131 131.484C60.4131 117.837 86.6172 110.962 99.7438 110.962C112.87 110.962 139.075 117.837 139.075 131.484V141.745H60.4131V131.484Z"
          fill="#1F447C"
        />
        <!-- eslint-enable -->
      </svg>
    </a>
    <div
      aria-labelledby="userDropdown"
      class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
    >
      <RoleSwitchPanel @change-role="handleChangeRole" />
      <form class="pr-2 row">
        <toggle-button
          class="col-3 offset-1 p-2"
          :height="15"
          :value="consentGiven"
          :width="25"
          @input="enableCookies($event)"
        />
        <span class="px-1 text-dark col-7 p-2">
          <a
            class="text d-inline-block align-middle"
            href="https://www.kugu-home.com/datenschutz/"
            target="_blank"
          >Nutzungsdaten</a>
          erheben
        </span>
      </form>

      <div
        v-if="keycloak.hasResourceRole('manage-users', 'realm-management')"
        class="row"
      >
        <atoms-icons
          class="col-4 offset-1 fa-sm fa-fw text-gray-400 p-2 m-0"
          icon="kgs kgs-wrench"
        />
        <a
          class="text-primary col-7 p-2"
          :href="
            this.conf.VUE_APP_AUTHENTICATION.server_url +
              '/admin/' +
              this.currentRealm +
              '/console/#/realms/' +
              this.currentRealm +
              '/users'
          "
          target="_blank"
        >
          Nutzerverwaltung
        </a>
      </div>

      <div
        v-if="auth.hasUserKeycloakRole('Vollzugriff')"
        class="row"
      >
        <atoms-icons
          class="col-4 offset-1 fa-sm fa-fw text-gray-400 p-2 m-0"
          icon="fas fa-network-wired"
        />
        <router-link
          class="text-primary col-7 p-2"
          :to="'/eras-2-connection'"
        >
          ERAS2/Absys Verbindung
        </router-link>
      </div>

      <div
        v-if="auth.hasUserKeycloakRole('Alpha-Feature')"
        class="row"
      >
        <atoms-icons
          class="col-4 offset-1 fa-sm fa-fw text-gray-400 p-2 m-0"
          icon="fas fa-network-wired"
        />
        <router-link
          class="text-primary col-7 p-2"
          :to="'/external-credentials'"
        >
          Externe Zugangsdaten
        </router-link>
      </div>

      <div class="row">
        <atoms-icons
          class="col-4 offset-1 fa-sm fa-fw text-gray-400 p-2 m-0"
          icon="fas fa-user-cog"
        />
        <a
          class="text-primary col-7 p-2"
          :href="
            this.conf.VUE_APP_AUTHENTICATION.server_url +
              '/realms/' +
              this.currentRealm +
              '/account/'
          "
          target="_blank"
        >
          Konto
        </a>
      </div>
      <div
        v-if="checkUserHasHeizungRole()"
        class="row"
      >
        <atoms-icons
          class="col-4 offset-1 fa-sm fa-fw text-gray-400 p-2 m-0"
          icon=" kgs kgs-bell"
        />
        <router-link
          class="text-primary col-7 p-2"
          :to="'/notification-management'"
        >
          Benachrichtigungs-einstellungen
        </router-link>
      </div>

      <a
        class="text-dark row no-underline"
        href="#"
        @click="logUserOut"
      >
        <i
          class="col-4 offset-1 kg kg-log-out fa-sm fa-fw text-gray-400 p-2 m-0"
          data-cy="user-logout"
        />
        <span class="col-7 p-2">Abmelden</span>
      </a>
    </div>
  </div>
</template>

<script>
import RoleSwitchPanel from '@/components/TopBarComponents/RoleSwitchPanel';
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index';
import Auth from '@/utils/auth';
import { currentRealm } from '@/main';

import {
  pluginOptions,
} from '@/../vue.config';

export default {
  name: 'UserInfo',
  components: {
    RoleSwitchPanel,
    AtomsIcons,
  },
  data() {
    return {
      conf: pluginOptions,
      currentRealm: currentRealm(),
      currentRole: '',
      consentGiven: false,
      keycloak: window.keycloak,
      auth: Auth,
      sizeMenu: {
        width: '250px',
        height: '230px',
      },
      baseHeight: 230,
      username() {
        const userData = Auth.getUserData();
        if (userData) {
          if (userData.given_name && userData.family_name) {
            return `${userData.given_name} ${userData.family_name}`;
          }
          return userData.email;
        }
        return '';
      },
    };
  },
  computed: {
    showRoleSwitchPanel() {
      return this.keycloak.hasResourceRole('Alpha-Feature', 'kugu-portal');
    },
    formattedCurrentRoles() {
      const roleDisplayLength = this.currentRole.length;
      const rolesCount = this.currentRole.split(', ').length;
      return roleDisplayLength > 40 || rolesCount > 2
        ? `${rolesCount} Rollen`
        : this.currentRole;
    },
  },
  methods: {
    logUserOut() {
      Auth.setUserLoggedOut();
    },
    enableCookies(event) {
      if (this.$matomo !== undefined) {
        if (event) {
          this.$matomo.rememberConsentGiven();
        } else {
          this.$matomo.forgetConsentGiven();
        }
      }
      localStorage.setItem('cookie-consent', event);
    },
    handleChangeRole(currentRole) {
      this.currentRole = currentRole;
    },
    checkUserHasHeizungRole() {
      // eslint-disable-next-line max-len
      return this.hasUserKeycloakRole('Heizungs-Betrieb', 'Heizungs-Contracting', 'Heizungs-Helpdesk', 'Heizungs-Wartung');
    },
  },
  created() {
    const consentGivenLocalStorage = localStorage.getItem('cookie-consent');
    this.consentGiven = false;
    if (consentGivenLocalStorage === 'true') {
      this.consentGiven = true;
      if (this.$matomo !== undefined) {
        this.$matomo.rememberConsentGiven();
      }
      localStorage.setItem('cookie-consent', true);
    }
    // by default enable consent except if it's portal-demo
    if (consentGivenLocalStorage === null && currentRealm() !== 'Portal-Demo') {
      this.consentGiven = true;
      if (this.$matomo !== undefined) {
        this.$matomo.rememberConsentGiven();
      }
      localStorage.setItem('cookie-consent', true);
    }
  },
};
</script>
<style scoped lang="scss">

.dropdown-menu {
  width: 250px !important;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  left: auto;
  right: 36px;
}
.topbar .topbar-divider {
  border-right: none;
}

.text-dark {
  color: var(--neutral) !important;
}

.nav-link {
  &__user-info {
    display: grid;
    text-align: right;
  }
}

.user-info {
  &__role {
    color: var(--primary) !important;
    font-size: 14px;
    line-height: 1.71;
  }
  &__username {
    font-size: 14px;
    line-height: 1.71;
  }
}
</style>
