import { reactive } from 'vue';

export const state = reactive({
  centralHeatings: {
    dashboard: {
      topFlopNutzungsgradTable: {},
    },
  },

});
