<template>
  <span>
    <a :href="props.row.file_path">{{ props.row.file_name }}</a>
  </span>
</template>

<script>
export default {
  name: 'FileSlot',
  props: {
    props: Object,
  },
};
</script>
