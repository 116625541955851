<template>
  <span
    v-cy
    v-tooltip="props.row[props.column.field + '_tooltip']"
    :data-testid="props.column.label + '-Status'"
  >
    <router-link
      v-if="withLink == true && props.row[props.column.field]"
      :class="props.row[props.column.field].router_text == getLockedState() ? 'read-only not-allowed': ''"
      :to="props.row[props.column.field].router_text != getLockedState() && props.row[props.column.field].router_text ?
        '/property/' + props.row.property_id + props.row[props.column.field].router_link : ''"
    >
      <span class="d-none">{{ props.row[props.column.field].router_text }}</span>
      <atoms-icons
        :id="props.row[props.column.field].router_text != getLockedState() ? '' : 'not-allowed'"
        :class="[
          'd-inline-block w-100',
          status ? `text-${status.color}` : 'text-secondary',
          props.column.leftAligned ? '' : 'centered'
        ]"
        :icon="status ? `${status.symbol}` : 'kgs kgs-hourglass'"
      />
    </router-link>

    <span v-else>
      <span class="d-none">
        {{ (Array.isArray(props.row[props.column.field]) &&
          props.row[props.column.field].length > 0) ?
          props.row[props.column.field][0] : props.row[props.column.field]
        }}
      </span>
      <span :class="`d-inline-block w-100 ${props.column.leftAligned ? '' : 'centered'} `">
        <atoms-icons
          :class="status ? `text-${status.color}` : 'text-secondary'"
          :icon="status ? `${status.symbol}` : 'kgs kgs-hourglass'"
        />
        <span
          v-if="props.row.hasOwnProperty('status_text')"
          class="pl-3"
        >{{ props.row.status_text }}</span>
        <span
          v-if="typeof status == 'string' || typeof status == 'number'"
          class="pl-0"
        >{{ status }}</span>
        <span
          v-if="Array.isArray(props.row[props.column.field] && props.row[props.column.field].length > 1)"
          class="pl-3"
        >{{ props.row[props.column.field][1] }}</span>
      </span>
    </span>
  </span>
</template>

<script>
import constants from '@/utils/constants';
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index';

export default {
  name: 'StatusSlot',
  components: {
    AtomsIcons,
    // MultipleStates
  },
  props: {
    props: Object,
    withLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      return this.getStatusCode(this.props.row[this.props.column.field]);
    },
  },
  methods: {
    getLockedState() {
      return constants.State.LOCKED;
    },
    getStatusCode(num) {
      if (typeof (num) === 'object') {
        return constants.StatusCodes[num ? num.router_text : 'null']
          ? constants.StatusCodes[num ? num.router_text : 'null']
          : num;
      } return constants.StatusCodes[num] ? constants.StatusCodes[num] : num;
    },
  },
};
</script>
