/* eslint-disable */
// Dashboard Abrechnung => Requires at least one of ["Hausverwaltung", "Vollzugriff", "Heizkostenabrechnung"]
// Dashboard Heizsysteme => Requires at least one of ['Hausverwaltung', 'Vollzugriff', 'Heizkostenabrechnung', 'Heizungs-Betrieb']
// Dashboard Rauchmelder => Requires "Rauchmelder-Administration"
// Dashboard Digitaler Heizungskeller => Requires at least one of ['Hausverwaltung', 'Vollzugriff', 'Heizkostenabrechnung', 'Heizungs-Betrieb'] AND additionally requires the role "Alpha-Feature"

import auth from '@/utils/auth';

export default [
  {
    name: 'Dashboard Abrechnung',
    role: () => auth.hasUserKeycloakRole('Hausverwaltung', 'Vollzugriff', 'Heizkostenabrechnung'),
  },
  {
    name: 'Dashboard Rauchmelder',
    role: () => auth.hasUserKeycloakRole('Rauchmelder-Administration'),
  },
  {
    name: 'Dashboard Digitaler Heizungskeller',
    role: () => auth.hasUserKeycloakRole('Heizungs-Betrieb', 'Heizungs-Contracting', 'Heizungs-Helpdesk', 'Heizungs-Wartung'),
  },
];
/* eslint-enable */
