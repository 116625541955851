import { readonly } from 'vue';
import { state } from '@/store/central-heating/table/state';
import { methods } from '@/store/central-heating/table/methods';
import { getters } from '@/store/central-heating/table/getters';

export const centralHeatingTable = readonly({
  state,
  methods: methods(state),
  getters: getters(state),
});
