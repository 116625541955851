<template>
  <nav
    :class="[
      'navbar navbar-expand navbar-light z-100 bg-white pl-3 pr-3 topbar static-top border-bottom',
      { closeMenu: closeMenu },
    ]"
  >
    <!-- Sidebar Toggle (Topbar) -->
    <button
      v-if="!hideHumburger"
      id="sidebarToggleTop"
      class="btn btn-link d-md-none rounded-circle mr-3"
      @click="toggleSidebar()"
    >
      <atoms-icons icon="kg kg-menu" />
    </button>
    <div
      v-if="isPortalValid"
      :class="'w-100' + (!$parent.isMobile ? ' pl-3' : '')"
    >
      <div
        v-if="!$parent.isMobile || !$parent.showSidebar"
        :class="($parent.isMobile ? 'nav-mobile-view' : '') + ' w-100'"
      >
        <template v-if="isUseV2">
          <div
            v-for="(component, index) in this.getBreadcrumbs"
            :key="index"
            class="nav-link pt-4 pl-0"
          >
            <template v-if="getNames.length > 1 && index === getBreadcrumbs.length - 1">
              <dropdown-button
                button-type="dropdown"
                class="dropdown-handler"
                :is-mobile="$parent.isMobile"
                :label="getLabel"
                :options="getNames"
                show-icon-close="caret-up"
                show-icon-open="caret-down"
                @clicked="(value) => changeRoute(value)"
              />
            </template>
            <template v-else>
              <router-link
                class="text-md text-black"
                :class="setBoldClass(index)"
                :to="component.linkTo"
              >
                {{ component.text }}
              </router-link>
              <span
                v-if="isNotALastElement(index)"
                class="text-dark"
                style="padding: 0 15px !important"
              >/</span>
            </template>
          </div>
        </template>
        <template v-else>
          <template v-if="this.pathComponents.length <= 1 && this.$route.path === '/home'">
            <div>
              <dropdown-button
                v-if="dashboardTypes.length > 1"
                button-type="dashboard-type"
                class="dropdown-handler"
                cy-salt="__changeDashboard"
                :is-mobile="$parent.isMobile"
                :label="dashboardType"
                :options="dashboardTypes"
                show-icon-close="caret-up"
                show-icon-open="caret-down"
                @clicked="(value) => changeDashboardType(value)"
              />
              <span
                v-else
                class="font-size-14 text-black font-weight-bold"
              >{{ dashboardType }}</span>
            </div>
          </template>
          <template v-else>
            <div
              v-for="(p, key) in this.pathComponents"
              :key="key"
              class="nav-link pt-4 pl-0"
            >
              <router-link
                v-if="key < 3"
                v-cy="`item${key}`"
                :class="getBreadCrumbClass(key, pathComponents)"
                :to="'/' + getRouterLink(key)"
              >
                {{ isNumeric(p) ? getDisplayedBreadcrumb(key) : getTranslation(p) }}
                {{ key == 1 && topbarNumber ? `(${topbarNumber})` : null }}
              </router-link>
              <span
                v-else-if="pathComponents[2] === 'central-heating'"
                :class="getBreadCrumbClass(key, pathComponents)"
              >{{ centralHeatingName }}</span>
              <DropdownButton
                v-else-if="dropdownLabel"
                button-type="dropdown"
                class="dropdown-handler"
                :is-mobile="$parent.isMobile"
                :label="dropdownLabel"
                :options="getContent()"
                show-icon-close="caret-up"
                show-icon-open="caret-down"
                @clicked="(value) => changeRoute(value)"
              />
              <span
                v-if="key < pathComponents.length - 1"
                id="text-dark"
                style="padding: 0 15px !important"
              >/</span>
            </div>
          </template>
        </template>
      </div>
    </div>
    <div class="topbar-divider d-sm-block ml-auto" />
    <UserInfo />
  </nav>
</template>

<script>
import api from '@/utils/api';
import UserInfo from '@/components/TopBarComponents/UserInfo';
import { globalStorage } from '@/main';
import translations from '@/utils/lookups/translations.json';
import DropdownButton from '@/components/DropdownButton';
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index';
import Dashboard from '@/dictionaries/dashboard.js';

export default {
  name: 'Topbar',
  components: {
    AtomsIcons,
    UserInfo,
    DropdownButton,
  },
  props: {
    info: {},
    isPortalValid: Boolean,
    hideHumburger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keycloak: window.keycloak,
      pathComponents: [],
      pathComponentsWithParams: [],
      translations,
      showDropdown: false,
      desktopDropdownContent: [],
      encodedDropdownContent: [],
      dropdownLabel: '',
      centralHeatingName: '',
      dashboardType: '',
      topbarNumber: null,
      changedRole: false,
      dashboardTypes: [],
      closeMenu: false,
    };
  },

  watch: {
    $route() {
      this.setDeviceOrder(this.encodedDropdownContent);
      this.getNavItems();
      this.encodeDeviceId();
      this.clearTopbarNumber();
    },
    encodedDropdownContent() {
      this.encodeDeviceId();
      this.setDeviceOrder(this.encodedDropdownContent);
    },
    dashboardType() {
      this.eventHub.$emit('changeDashboardType', this.dashboardType);
    },
  },
  methods: {
    getContent() {
      return this.$parent.isMobile ? this.mobileDropdownContent : this.desktopDropdownContent;
    },

    setBoldClass(index) {
      return index === this.getBreadcrumbs.length - 1 ? 'font-weight-bold' : '';
    },

    isNotALastElement(index) {
      return index < this.getBreadcrumbs.length - 1;
    },

    getBreadCrumbClass(key, pathComponents) {
      /* eslint-disable */
      const classString = 'text-md text-black ';
      if (key == pathComponents.length - 1) return `${classString}font-weight-bold`;
      /* eslint-enable */
      return classString;
    },
    clearTopbarNumber() {
      this.topbarNumber = null;
    },
    getTranslation(value) {
      return this.translations[value] ? this.translations[value] : value;
    },
    isNumeric(value) {
      /* eslint-disable */
      return !isNaN(value);
      /* eslint-enable */
    },
    getDisplayedBreadcrumb(key) {
      var parameterName = this.pathComponentsWithParams[key + 1].replace(':', '');
      var breadcrumb = globalStorage[parameterName].breadcrumb;
      return breadcrumb;
    },
    getNavItems() {
      this.pathComponents = this.$route.path.split('/').slice(1);
      this.pathComponentsWithParams = this.$route.matched[0].path.split('/');
    },
    toggleSidebar() {
      this.$parent.showSidebar = !this.$parent.showSidebar;
    },
    getRouterLink(key) {
      var routerLink = '';

      for (var index in this.pathComponents) {
        routerLink += index > key ? '' : `${this.pathComponents[index]}/`;
      }
      return routerLink.slice(0, -1);
    },
    changeRoute(route) {
      const routeId = this.encodedDropdownContent.filter(
        (item) => item.displayName.replace(/\s/g, '') === route.replace(/\s/g, ''),
      )[0].id;
      this.eventHub.$emit('changeDevice', routeId);
      this.$router.push(`${routeId}`);
    },
    getDevices() {
      const propertyId = this.pathComponents[1];
      const subpageType = this.pathComponents[2];
      const moduleType = subpageType === 'smoke-detector' ? '?type=smoke-detector' : '';
      if (propertyId && this.location) {
        const endpoint = `hardware/property/${propertyId}${moduleType}`;
        api.get(endpoint, {}, (items) => {
          const siblingDevicesArray = items;
          this.getDropdownContent(siblingDevicesArray);
        });
      }
    },

    setDeviceOrder(arr) {
      if (!this.pathComponents[3]) {
        return;
      }

      const arrayWithoutChosenItem = arr.filter((item) => item.id !== parseInt(this.pathComponents[3]));
      if (arrayWithoutChosenItem.length === arr.length) {
        return;
      }
      const chosenItem = arr.filter((item) => item.id === parseInt(this.pathComponents[3]))[0];
      this.desktopDropdownContent = [chosenItem, ...arrayWithoutChosenItem].map(({ displayName }) => displayName);
    },
    getDropdownContent(siblingDevicesArray) {
      this.encodedDropdownContent = siblingDevicesArray.map(({
        hardware_id, oms_id, room, type_id,
      }) => ({
        id: hardware_id,
        displayName: `${room}-${type_id}-${oms_id}`,
      }));
    },
    encodeDeviceId() {
      if (this.pathComponents[3]) {
        const encodedObject = this.encodedDropdownContent.filter(
          (item) => item.id === parseInt(this.pathComponents[3]),
        )[0];
        if (encodedObject) {
          this.dropdownLabel = encodedObject.displayName;
        }
      }
    },
    changeDashboardType(newType) {
      const oldType = localStorage.getItem('dashboardType');
      if (oldType !== newType) {
        localStorage.setItem('dashboardType', newType);
        this.dashboardType = newType;
      }
    },
    getDashboardTypes() {
      const types = [];
      Dashboard.map((item) => {
        if (item.role()) types.push(item.name);
      });
      this.dashboardType = types[0];
      this.dashboardTypes = types;
    },
  },
  computed: {
    mobileDropdownContent() {
      const pathComponentsLength = this.pathComponents.length;
      let tempArray = this.pathComponents;
      if (pathComponentsLength > 3) {
        tempArray = tempArray.slice(0, 3);
      }
      const mappedArray = tempArray.map(
        (item, key) => (this.isNumeric(item) ? this.getDisplayedBreadcrumb(key) : this.getTranslation(item)),
      );
      return [...mappedArray, ...this.desktopDropdownContent];
    },

    getNames() {
      return this.$store.breadcrumbs.getters.getNames();
    },

    getLabel() {
      return this.$store.breadcrumbs.getters.getLabel();
    },

    getBreadcrumbs() {
      const breadcrumbs = this.$store.breadcrumbs.getters.getBreadcrumbs();
      const howManyRoutes = this.$route.path.split('/').filter((route) => route !== '').length;
      const parseMeta = () => {
        this.$store.breadcrumbs.methods.reset();
        return [{
          text: this.$route.meta.title,
          linkTo: this.$route.path,
        }];
      };
      return breadcrumbs.length > 0 && howManyRoutes > 1 ? breadcrumbs : parseMeta();
    },

    isUseV2() {
      try {
        return this.$route.meta.breadcrumbs.usev2;
      } catch (e) {
        return false;
      }
    },
  },
  created() {
    this.getDashboardTypes();
    this.eventHub.$on('close-menu', (isClosed) => (this.closeMenu = isClosed));
    this.eventHub.$on('updateDashboardType', (newDashboardType) => (this.dashboardType = newDashboardType));
    this.eventHub.$on('topbar-number', (topbarNumber) => (this.topbarNumber = topbarNumber));
    this.eventHub.$on('updateLocation', (value) => {
      this.location = value;
      this.getDevices();
    });
    this.eventHub.$on('change-role', () => this.getDashboardTypes());
  },
  mounted() {
    const savedDashboardType = localStorage.getItem('dashboardType');
    if (typeof savedDashboardType !== 'undefined' && savedDashboardType !== 'undefined') {
      this.dashboardType = savedDashboardType;
    } else {
      this.dashboardType = this.dashboardTypes[0];
      localStorage.setItem('dashboardType', this.dashboardTypes[0]);
    }
  },
  updated() {
    this.getRouterLink();
  },
};
</script>

<style lang="scss" scoped>
i {
  font-size: 24px;
}

.pull-right {
  float: right !important;
}

#text-dark {
  color: grey !important;
}

.text-black {
  color: black !important;
}

.valid .navbar {
  &.closeMenu {
    transform: translateY(0);
  }
}

.navbar {
  transition: all 0.5s;
  transform: translateY(0);

  &.closeMenu {
    transform: translateY(-8.8rem);
  }
}

#sidebarToggleTop {
  padding-top: 10px;
  display: block !important;
}

.navbar .nav-link {
  display: inline-block;
  padding: 0.5rem 0 0 !important;
}

.nav-mobile-view .nav-link {
  display: none;
}

.nav-mobile-view :last-child {
  display: inline-block;
}

.dropdown-icon {
  color: #000 !important;
}
</style>
