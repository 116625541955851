/* eslint-disable */
const listenCronOptions = [
  { label: 'Benutzerdefiniert', value: '' },
  { label: 'Alle 5 Minuten', value: '0 0/5 * * * *' },
  { label: 'Alle 2 Stunden', value: '0 0 0/2 * * *' },
  { label: 'Alle 6 Stunden', value: '0 0 0,6,12,18 * * *' },
  { label: 'Zweimal täglich', value: '0 0 0,12 * * *' },
  { label: 'Einmal täglich', value: '0 0 12 * * *' },
  { label: 'Einmal wöchentlich', value: '0 0 12 * * 1' },
  { label: 'Zweimal monatlich', value: '0 0 12 1,16 * *' },
  { label: 'Einmal monatlich', value: '0 0 12 7 * *' },
];

const omsCtMeterCodes = {
  'Alle Gerätetypen': '*',
  'HKV/Heizkostenverteiler': '08',
  'KWZ/Kaltwasserzähler': '16',
  'WWZ/Warmwasserzähler bis 90°C': '06',
  'WMZ/Wärmemengenzähler': '04',
  'WMZ/Wärmemengenzähler, Einlass': '0C',
  'RWM/Rauchwarnmelder': '1A',
  'GZ/Gaszähler': '03',
  'SZ/Stromzähler': '02',
  'WZ/Wasserzähler': '07',
  'Funkumsetzer (zählerseitig)': '37',
  'KMZ/Kältemengenzähler, Auslass': '0A',
  'KMZ/Kältemengenzähler, Einlass': '0B',
  'Kombinierter KWZ/WMZ Kälte- und Wärmemengenzähler': '0D',
  'Heißwasserzähler über 90°C': '15',
  Abwasserzähler: '28',
  Trennschalter: '20',
  Ventil: '21',
  'Kundengerät (display device)': '25',
  'Communication Controller': '31',
  'Unidirektionaler Repeater': '32',
  'Bidirektionaler Repeater': '33',
  'Funkumsetzer (systemseitig)': '36',
  Ölzähler: '01',
  Dampfmesser: '05',
  Druckluft: '09',
  'Bus-/Systemkomponente': '0E',
  'Reserviert: Verbrauchsmesser': '10,11,12,13',
  Heizwert: '14',
  'Wasserregister mit zwei Registern': '17',
  Druckmessgerät: '18',
  'AD-Wandler': '19',
  'Raumsensor (z.B. Temperatur, Luftfeuchtigkeit)': '1B',
  Gaswarngerät: '1C',
  'Reserviert: Sensoren': '1D,1E,1F',
  'Reserviert: Schaltgeräte': '22,23,24',
  Kundengerät: '26,27',
  Abfall: '29',
  'Reserviert: Kohlendioxid': '2A',
  'Reserviert: Umwelt-Messgeräte': '2B,2C,2D,2E,2F',
  'Unbekannter Zählertyp': '0F',
  Sonstige: '00',
};

const AESKeyInfo = `Bitte stellen Sie sicher, 
dass der Schlüssel aus genau 32 hexadezimalen Zeichen (0-9,a-f) = 16 Bytes besteht`;

export default {

  defaultTimePeriod: {
    PERIOD_LAST_HOUR: 0,
    PERIOD_LAST_24_HOUR: 1,
    PERIOD_LAST_7_DAY: 2,
    PERIOD_LAST_30_DAY: 3,
    PERIOD_LAST_365_DAY: 4,
    PERIOD_ALL: 5,
  },
  rawInformation: {
    status_data_collector: 'Datensammler',
    connected_module_status_data_collector: 'Datensammler (verbundenes Modul)',
    status_byte: 'Status-Byte',
    connected_module_status_byte: 'Status-Byte (verbundenes Modul)',
    radio_manufacturer: 'Link-Layer: Hersteller',
    radio_type: 'Link-Layer: Gerätetyp',
    radio_version: 'Link-Layer: Version',
    radio_id: 'Link-Layer: Seriennummer',
    meter_id: 'Application Layer: Seriennummer',
    meter_manufacturer: 'Application Layer: Hersteller',
    meter_type: 'Application Layer: Gerätetyp',
    meter_version: 'Application Layer: Version',
    expected_data_interval_min: 'Minimal erwartetes Empfangs-Intervall',
    expected_data_interval_max: 'Maximal erwartetes Empfangs-Intervall',
    verbosity_treatment: 'Behandlung über-häufiger Empfang'
  },
  verbosity_treatment: {
    0: 'Verwerfen',
    1: 'Nur letzten Empfangszeitpunkt aktualisieren, Inhalt verwerfen',
    2: 'Speichern, nicht anzeigen',
    3: 'Vollständige Verarbeitung',
  },

  timePeriod: [
    {label: 'Heizperiode', value: 0, valueName: 'heating-period', group: "360m", periodAmount: 5},
    {label: 'Jahr', value: 1, valueName: 'year', group: "180m", periodAmount: 5},
    {label: 'Quartal', value: 2, valueName: 'quarter', group: "180m", periodAmount: 5},
    {label: 'Monat', value: 3, valueName: 'month', group: "180m", periodAmount: 10},
    {label: 'Woche', value: 4, valueName: 'week', group: "180m", periodAmount: 10},
],

  legends: {
    min: 'Min',
    avg: 'Durchschnitt',
    max: 'Max',
  },

  State: {
    null: 30,
    LOCKED: 10,
    SUCCESS: 20,
    PENDING: 30,
    WARNING: 40,
    CRITICAL: 50,
  },
  deviceType: {
    collector: {
      color: 'blue',
      tooltip:
        'Spezifikationen:<br> \
       - empfängt wM-Bus Daten von allen oder whitelisteten Geräten <br>\
       - schläft über längere Intervalle (~10min) <br>\
       - leitet bis zu 50 Bytes aus wM-Bus Frames weiter<br>\
       - beinhaltet wM-Bus RSSI am ende jedes LoRa Frames <br>\
       - Betterielaufzeit von ~ 10 Jahren',
    },
    scanner: {
      color: 'yellow',
      tooltip:
        'Spezifikationen:<br> \
       - empfängt wM-Bus Daten von allen oder whitelisteten Geräten <br>\
       - schläft über kurze Intervalle (~2min) <br>\
       - leitet bis zu 50 Bytes aus wM-Bus Frames weiter<br>\
       - beinhaltet wM-Bus RSSI am ende jedes LoRa Frames <br>\
       - Betterielaufzeit von ~ 2-3 Monate',
    },
    lobaro: {
      color: 'green',
      tooltip:
        'Spezifikationen:<br> \
       - empfängt wM-Bus Daten von allen oder whitelisteten Geräten <br>\
       - schläft über längere Intervalle (~10min) <br>\
       - komplette wM-Bus Frames weiter<br>\
       - Betterielaufzeit von ~ 10 Jahren',
    },
  },
  StatusCodes: {
    null: {
      fill: '#808080',
      color: 'secondary',
      statusText: 'Warten',
      alphaColor: '#858796',
      alphaIcon: 'kg kg-check-circle',
      icon: 'kgs kgs-hourglass text-secondary',
      symbol: 'kgs kgs-hourglass',
    },
    5: {
      fill: '#808080',
      color: 'secondary',
      statusText: 'N/A',
      alphaColor: '#858796',
      icon: 'kg kg-minus color-secondary',
      alphaIcon: 'kg kg-check-circle',
      symbol: 'kg kg-minus',
    },
    10: {
      fill: '#808080',
      color: 'secondary',
      alphaColor: '#858796',
      statusText: 'Empfang',
      alphaIcon: 'kg kg-check-circle',
      icon: 'kgs kgs-lock color-secondary',
      symbol: 'kgs kgs-lock',
    },
    20: {
      fill: '#93D86E',
      color: 'success',
      alphaColor: '#D0FBDA',
      label: 'success',
      statusText: 'Empfang',
      alphaIcon: 'kg kg-check-circle',
      icon: 'kg kg-check text-success',
      symbol: 'kg kg-check',
    },
    30: {
      fill: '#808080',
      color: 'secondary',
      alphaColor: '#858796',
      statusText: 'Warten',
      icon: 'kgs kgs-hourglass text-secondary',
      alphaIcon: 'fas fa-circle-notch',
      symbol: 'kgs kgs-hourglass',
    },
    40: {
      fill: '#FFC300',
      color: 'warning',
      alphaColor: '#FBD666',
      label: 'warning',
      statusText: 'Störung',
      icon: 'kg kg-exclamation text-warning',
      alphaIcon: 'kg kg-x-circle',
      symbol: 'kg kg-exclamation',
    },
    50: {
      fill: '#dc3544',
      alphaColor: '#EB9C69',
      color: 'danger',
      label: 'danger',
      statusText: 'Fehler',
      icon: 'kg kg-x text-danger',
      alphaIcon: 'kg kg-x-circle',
      symbol: 'kg kg-x',
    },
  },
  optimisations: ['summer_shutdown', 'heating_curve', 'pump_efficiency', 'nightdrop', 'energy_efficiency'],
  importCategories: {
    newImport: 'new_import',
    radiatisImport: 'radiatis_csv',
    qundis: 'digitaler_lieferschein_qundis',
    aquamess: 'digitaler_lieferschein_aquamess',
    innotas: 'digitaler_lieferschein_innotas',
    kamstrup: 'digitaler_lieferschein_kamstrup',
    zenner: 'digitaler_lieferschein_zenner',
    consumptionApp: 'consumption_app',
  },
  FileType: {
    techem_technical_documentation: 'pdf',
    create_devices_and_units: 'csv',
    create_devices_for_units: 'csv',
    add_year_end_values_to_devices: 'csv',
    new_import: 'csv',
    new_import_units_and_tenants: 'csv',
    new_import_devices: 'csv',
    new_import_tenants: 'csv',
    new_import_readings: 'csv',
    radiatis_csv: 'csv',
    digitaler_lieferschein_qundis: 'csv',
    digitaler_lieferschein_aquamess: 'csv',
    digitaler_lieferschein_innotas: 'csv',
    digitaler_lieferschein_zenner: 'csv',
    digitaler_lieferschein_kamstrup: 'xml',
    consumption_app: true,
    upload_document: true,
    arge_document: 'dat',
  },
  FilePath: {
    techem_technical_documentation: 'pdf',
    create_devices_and_units: '/upload/geraete_example.csv',
    create_devices_for_units: '/upload/import-devices-example.csv',
    add_year_end_values_to_devices: '/upload/import-values-example.csv',
    new_import: '/upload/import-devices.csv',
  },
  FileExamples: {
    create_devices_and_units:
      ' \
WE;Lage;Strasse;Hausnummer;Hausnr2;PLZ;Ort;Geraete-Nr;Raum;Geraetetyp;Nutzer;BeGeRf;Aufgang\
WE 1;EG;Große Musterstr;43;;40001;Musterstadt;61210172;Küche;FHKV;-;1;Hinterhaus\
WE 1;EG;Große Musterstr;43;;40001;Musterstadt;61210184;Wohnzimmer;FHKV;-;2;Hinterhaus\
WE 1;EG;Große Musterstr;43;;40001;Musterstadt;61210188;Bad;FHKV;-;3;\
WE 1;EG;Große Musterstr;43;;40001;Musterstadt;61210189;Schlafzimmer;FHKV;-;4;\
WE 2;1. OG;Große Musterstr;43;;40001;Musterstadt;61210085;Bad;FHKV;-;5;\
WE 2;1. OG;Große Musterstr;43;;40001;Musterstadt;61210110;Küche;FHKV;-;6;\
WE 2;1. OG;Große Musterstr;43;;40001;Musterstadt;61210115;Wohnzimmer;FHKV;-;7;\
WE 2;1. OG;Große Musterstr;43;;40001;Musterstadt;61210131;Schlafzimmer;FHKV;-;8;\
WE 3;2. OG;Große Musterstr;43;;40001;Musterstadt;61210086;Wohnzimmer;FHKV;-;9;\
    ',

    create_devices_for_units:
      'NENr;Geraetetyp;Raum;Geraete-Nr\
                                          1;FHKV;S;63054169\
                                          1;FHKV;Z;63054170\
                                          1;FHKV;W;63054178\
                                          1;FHKV;K;63054171\
                                          1;WWZ;B;56667383\
                                          1;KWZ;B;56773477\
                                          2;FHKV;W;63054230\
                                          2;FHKV;K;63054187\
                                          2;WWZ;B;40434480\
                                          2;KWZ;B;40369734\
                                          3;FHKV;W;63054186\
                                          3;FHKV;K;10730556',
    add_year_end_values_to_devices:
      'Geraete-Nr;Stichtagswert;Ablesedatum\
                                                63054178;7353;2018-12-31\
                                                63058778;3384;2018-12-31\
                                                63058168;3194;2018-12-31\
                                                63056737;4587;2018-12-31\
                                                63058820;3331;2018-12-31',
    new_import_readings: `Geraete-Nr;Ablesetyp;Ablesewert;Ablesedatum
    99901111;HA;500;31.12.2020
    99901111;HA;500;31.12.2020`,
    new_import_tenants: `NE;NutzerIDARGE;NETyp;EinzugsDatum;AuszugsDatum;Vorname;Nachname;Anrede;Titel
    1;IM00100195234;Privat;31.01.2020;30.11.2020;Beispielvorname;Beispielnachname;Frau;Dr.
    1;;Privat;31.01.2020;;;Beispielnachname;;
    `,
    new_import_devices: `NE;NutzerIDARGE;Raum;Pos;Geraetetyp;Kgesamt;Eichjahr;EinbauDatum;Ausbaudatum;Geraete-Nr;Funk-Nr;Funk Artikel;Ablesewert;Aes-key
    1;IM00100195234;WZ;1;13110166;1;31.01.2025;31.01.2020;31.01.2025;99901111;99901111;13110166;500;1A1A
    1;;WZ;1;13110166;1;;31.01.2020;;99901111;;;500;
    `,
    new_import_units_and_tenants: `Strasse;Hausnummer;PLZ;Ort;NE;Lage;NETyp;FlaecheQm;FlaecheHeizung;FlaecheWarmwasser;FlaecheNebenkosten;NutzerIDARGE;EinzugsDatum;Vorname;Nachname;Anrede;Titel
    Beispielstraße;1;11111;Beispielort;1;EG1;Privat;50;50;50;50;IM00100195234;31.01.2020;Beispielvorname;Beispielnachname;Frau;Dr.
    Beispielstraße;1;11111;Beispielort;1;EG1;Privat;50;50;50;50;;31.01.2020;;Beispielnachname;;
    `,
    new_import:
      'Strasse;Hausnummer;PLZ;Ort;NE;BeGeRf;Lage;NETyp;EinzugsDatum;AuszugsDatum;Anrede;Titel;Vorname;Nachname;NutzerIDARGE;FlaecheQm;FlaecheHeizung;FlaecheWarmwasser;FlaecheNebenkosten;Raum;Geraetetyp;Eichjahr;EinbauDatum;AusbauDatum;Geraete-Nr;AbleseDatum;Ablesewert;Kgesamt;Aes-key;Pos;Funk-Nr;Funk-Artikel\
       Beispielstr;2;50001;Beispielstadt;1;;EG;2;01.01.2020;;;;Allgemeine;Nutzeinheit;;60;55;55;50;Wohnzimmer;HKV;2017;17.01.2001;;99901234;17.02.2005;714;;;1;8,98823E+18;23100290\
       Beispielstr;2;50001;Beispielstadt;1;1;EG;2;01.01.2020;31.01.2020;;;Allgemeine;Nutzeinheit;ID-01;60;55;55;50;Wohnzimmer;HKV;2017;17.01.2001;;99901235;17.02.2005;714;1;2369894DD64D58C3FD2EF94AA437B4C4;2;482601;18130169',
  },
  internalNote: {
    0: {
      icon: 'times',
      color: 'danger',
    },
    1: {
      icon: 'exclamation',
      color: 'warning',
    },
    2: {
      icon: 'check',
      color: 'success',
    },
  },
  crontabExplanations: {
    '0 0 4,10,16,22 * * *': 'Alle 6 Stunden',
    '0 0 10,22 * * * ': 'Alle 12 Stunden',
    '0 0 10 * * *': 'Einmal täglich',
  },
  ColorIconMapping: {
    success: 'check',
    danger: 'times',
    warning: 'exclamation',
    secondary: 'hourglass-half',
  },
  omsCtMeterCodes: {
    'Kein Filter': '*',
    'HKV/Heizkostenverteiler': '08',
    'KWZ/Kaltwasserzähler': '16',
    'WWZ/Warmwasserzähler bis 90°C': '06',
    'WMZ/Wärmemengenzähler': '04',
    'WMZ/Wärmemengenzähler, Einlass': '0C',
    'RWM/Rauchwarnmelder': '1A',
    'GZ/Gaszähler': '03',
    'SZ/Stromzähler': '02',
    'WZ/Wasserzähler': '07',
    'Funkumsetzer (zählerseitig)': '37',
    'KMZ/Kältemengenzähler, Auslass': '0A',
    'KMZ/Kältemengenzähler, Einlass': '0B',
    'Kombinierter KWZ/WMZ Kälte- und Wärmemengenzähler': '0D',
    'Heißwasserzähler über 90°C': '15',
    Abwasserzähler: '28',
    Trennschalter: '20',
    Ventil: '21',
    'Kundengerät (display device)': '25',
    'Communication Controller': '31',
    'Unidirektionaler Repeater': '32',
    'Bidirektionaler Repeater': '33',
    'Funkumsetzer (systemseitig)': '36',
    Ölzähler: '01',
    Dampfmesser: '05',
    Druckluft: '09',
    'Bus-/Systemkomponente': '0E',
    'Reserviert: Verbrauchsmesser': '10,11,12,13',
    Heizwert: '14',
    'Wasserregister mit zwei Registern': '17',
    Druckmessgerät: '18',
    'AD-Wandler': '19',
    'Raumsensor (z.B. Temperatur, Luftfeuchtigkeit)': '1B',
    Gaswarngerät: '1C',
    'Reserviert: Sensoren': '1D,1E,1F',
    'Reserviert: Schaltgeräte': '22,23,24',
    Kundengerät: '26,27',
    Abfall: '29',
    'Reserviert: Kohlendioxid': '2A',
    'Reserviert: Umwelt-Messgeräte': '2B,2C,2D,2E,2F',
    'Unbekannter Zählertyp': '0F',
    Sonstige: '00',
  },
  deviceTypeToUnit: {
    HKV: 'VE',
    GZ: 'm³',
    WZ: 'm³',
    KWZ: 'm³',
    WWZ: 'm³',
    SZ: 'Wh',
    WMZ: 'Wh',
  },
  allowedImageFileExtensions: ['.tif', '.tiff', '.bmp', '.jpg', '.jpeg', '.gif', '.png', '.svg'],
  allowedImageFileTypes: ['image/jpeg', 'image/bmp', 'image/gif', 'image/png', 'image/svg+xml', 'image/tiff'],
  UnitType: {
    0: 'Leerstand',
    1: 'Gewerblich',
    2: 'Privat',
    3: 'Allgemeinnutzung',
    null: 'Leerstand',
  },
  DataCollectorConfigs: {
    listenCron: 'Aufwach-Intervall',
    cmodeDurSec: 'Horchdauer C1/T1-Modus (Sekunden)',
    smodeDurSec: 'Horchdauer S1-Modus (Sekunden)',
    mFilter: 'Hersteller-Filter',
    typFilter: 'Gerätetyp-Filter',
    devFilter: 'Geräte-Filter',
  },
  omsCtMeterCodes: omsCtMeterCodes,
  omsCtMeterCodeLookup: Object.keys(omsCtMeterCodes).reduce((ret, key) => {
    ret[omsCtMeterCodes[key]] = key;
    return ret;
  }, {}),
  listenCronOptions: listenCronOptions,
  cronLookup: listenCronOptions.reduce((obj, item) => ({ ...obj, [item.value]: item.label }), {}),
  UnitTypeRev: {
    Leerstand: 0,
    Gewerblich: 1,
    Privat: 2,
    Allgemeinnutzung: 3,
  },
  // TODO : cnien - we are currently not aware what the value 0 represents, ERAS has no Label for it
  reduction_type: {
    1: 'Tage',
    2: 'Gradtage',
    3: 'Keine Reduzierung',
  },
  heating_type: {
    0: 'Allgemein',
    1: 'Sonderkosten Heizung',
    2: 'Sonderkosten Warmwasser',
    3: 'Kaltwasser für Warmwasser',
    4: 'Keine Umlage',
  },
  fuel_medium: {
    1: 'unbekannt',
    2: 'Erdgas H (m³)',
    3: 'Erdgas L (m³)',
    5: 'Flüssiggas P (kg)',
    7: 'Fernwärme (MWh)',
    8: 'Fernwärme (kWh)',
    9: 'Nahwärme (kWh)',
    11: 'Heizöl EL (l)',
    13: 'Koks (kg)',
    14: 'Braunkohle (kg)',
    15: 'Steinkohle (kg)',
    16: 'Holz lufttrocken (kg)',
    17: 'Holzpellets (kg)',
    18: 'Holzhackschnitzel (Schüttraummeter)',
    24: 'Strom (kWh)',
    25: 'Erdgas (kWh)',
  },
  heatCostTypes: [
    {
      label: 'Allgemein',
      value: '0',
    },
    {
      label: 'Sonderkosten Heizung',
      value: '1',
    },
    {
      label: 'Sonderkosten Warmwasser',
      value: '2',
    },
    {
      label: 'Kaltwasser für Warmwasser',
      value: '3',
    },
    {
      label: 'keine Umlage',
      value: '4',
    },
  ],

  keywordIcons: {
    tenants: 'users',
    properties: 'home',
    central_heatings: 'fire',
    smoke_detectors: 'bullseye',
    customers: 'user',
    units: 'key',
    devices: 'tachometer-alt',
  },

  monthAbbreviations: {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'Mai',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Okt',
    11: 'Nov',
    12: 'Dez',
  },
  unitOptions: [
    {
      short: 'L',
      label: 'Liter',
      value: 'Liter',
    },
    {
      short: 'kg',
      label: 'Kilogramm',
      value: 'Kilogramm',
    },
    {
      short: 'm3',
      label: 'Kubikmeter',
      value: 'Kubikmeter',
    },
    {
      short: 'Stck',
      label: 'Stück',
      value: 'Stück',
    },
    {
      short: 'kW',
      label: 'Kilowatt',
      value: 'Kilowatt',
    },
    {
      short: 'mW',
      label: 'Megawatt',
      value: 'Megawatt',
    },
    {
      short: 'gW',
      label: 'Gigawatt',
      value: 'Gigawatt',
    },
    {
      short: 'kWh',
      label: 'Kilowattstunden',
      value: 'Kilowattstunden',
    },
    {
      short: 'MWh',
      label: 'Megawattstunden',
      value: 'Megawattstunden',
    },
    {
      short: 'GWh',
      label: 'Gigawattstunden',
      value: 'Gigawattstunden',
    },
    {
      short: 'T',
      label: 'Tonnen',
      value: 'Tonnen',
    },
    {
      short: 'Rm',
      label: 'Raummeter',
      value: 'Raummeter',
    },
    {
      short: 'Eht',
      label: 'Einheiten',
      value: 'Einheiten',
    },
    {
      short: 'm2',
      label: 'Quadratmeter',
      value: 'Quadratmeter',
    },
    {
      short: 'gj',
      label: 'Gigajoule',
      value: 'Gigajoule',
    },
    {
      short: 'mj',
      label: 'Megajoule',
      value: 'Megajoule',
    },
  ],
  distribution_in: {
    0: 'Nebenkosten',
    1: 'Heizbetriebskosten',
    2: 'Warmwasserkosten',
  },
  distribution_for: {
    1: 'Alle Nutzer',
    2: 'Spezifischer Nutzer',
    3: 'Nutzeinheit',
  },
  distribution_out: {
    1: 'Heizfläche',
    2: 'Nebenkostenfläche',
    3: 'Warmwasserfläche',
    4: 'Anzahl Personen',
    5: 'Anzahl Räume',
    6: 'Miteigentumsanteil',
    7: 'Wohneinheit',
    8: 'Nutzer',
    9: 'Warmwasserverbrauch (m³)',
    10: 'Kaltwasserverbrauch (m³)',
    11: 'Wasserverbrauch (m³)',
    12: 'Geräte (Miete)',
    13: 'Geräte (Wartung)',
    14: 'Zugeordnete Geräte',
    15: 'Individuelle Vorgabe Nutzer',
    16: 'Zugeordnete Verbrauchsgruppen',
    17: 'Personen-Tage',
  },
  modules: {
    billing: 'Abrechnung',
    hardware: 'Hardware',
    smoke_detector: 'Rauchmelder',
    central_heating: 'Digitaler Heizungskeller',
  },
  stateTranslations: {
    billing_state: 'Abrechnung',
    hardware_state: 'Empfang',
    cho_state: 'Optimierung',
    smoke_detector_state: 'Rauchmelder',
    smoke_detector_protocol_status: 'Wartungsprotokolle',
  },
  smokeDetectorDetails: {
    last_saved_state_notification: 'Letzte gespeicherte Zustandsmeldung',
    damage_with_affect_on_functionality: 'Funktionsrelevante Beschädigung',
    energy_supply: 'Energieversorgung',
    remaining_operation_time: 'Verbleibende Betriebsdauer',
    function_of_the_smoke_sensors: 'Funktion der Rauchsensorik',
    function_of_the_warning_signal: 'Funktion des Warnsignals',
    removal: 'Demontage',
    smoke_openings_blocked_or_free: 'Raucheindringöffnungen',
    surrounding_of_0d5m: 'Umgebung von 0,5m',
  },
  smokeDetectorStatusLabels: {
    damage_with_affect_on_functionality: {
      20: 'keine',
      30: 'nicht abrufbar',
      50: 'liegt vor',
    },
    energy_supply: {
      20: 'gewährleistet',
      30: 'nicht abrufbar',
      50: 'beeinträchtigt',
    },
    function_of_the_smoke_sensors: {
      20: 'gewährleistet',
      30: 'nicht abrufbar',
      50: 'gestört',
    },
    function_of_the_warning_signal: {
      20: 'gewährleistet',
      30: 'nicht abrufbar',
      50: 'gestört',
    },
    removal: {
      20: 'installiert',
      30: 'nicht abrufbar',
      40: 'demontiert',
    },
    smoke_openings_blocked_or_free: {
      20: 'frei',
      30: 'nicht abrufbar',
      40: 'nicht frei',
    },
    surrounding_of_0d5m: {
      20: 'frei von Hindernissen',
      30: 'nicht abrufbar',
      40: 'Hindernisse erkannt',
    },
  },
  deviceTypes: {
    Datahub: 'Datenhub',
    Datensammler: 'Malinche',
    HKV: 'Heizkostenverteiler',
    RWM: 'Rauchwarnmelder',
    WWZ: 'Warmwasserzähler',
    KWZ: 'Kaltwasserzähler',
    WZ: 'Wasserzähler',
    WMZ: 'Wärmemengenzähler',
    GZ: 'Gaszähler',
    SZ: 'Stromzähler',
    Allgemein: 'Allgemein',
  },
  SortMapperReception: {
    danger: 0,
    warning: 1,
    success: 3,
    secondary: 2,
  },
  exportTypes: [
    {
      label: 'Radiatis',
      value: 'Radiatis',
      visibleFor: ['Alpha-Feature', 'Messreihen-Analyse'],
      additionalFields: ['encoding'],
    },
    {
      label: 'KUGU CSV',
      value: 'KUGUCSV',
      visibleFor: ['Alpha-Feature', 'Messreihen-Analyse'],
    },
    {
      label: 'KUGU CSV: Geräte (für Gerätetausch)',
      value: 'KUGUCSVDevices',
      visibleFor: ['Alpha-Feature', 'Messreihen-Analyse', 'Rauchmelder-Administration'],
    },
  ],
  exportEndpoints: {
    Radiatis: (propertyId, encoding) => {
      return [
        `files/property/${propertyId}/export`,
        {
          type: 'radiatis_csv_export',
          encoding: encoding,
        },
      ];
    },
    KUGUCSV: (propertyId) => {
      return [
        `files/property/${propertyId}/csv/export`,
        {
          type: 'kugu_csv_export',
        },
      ];
    },
    KUGUCSVDevices: (propertyId) => {
      return [
        `files/property/${propertyId}/csv/export`,
        {
          type: 'kugu_devices_csv_export',
        },
      ];
    },
  },
  staticRoles: {
    allRoles: 'Alle Rollen',
    default: 'Alle Rollen',
  },
  meterPriorityMeasurements: {
    HKV: 'HKV-Verbrauchseinheiten Heizkörpernutzung',
    WWZ: 'm³ Warmwasserverbrauch',
    KWZ: 'Kaltwasserverbrauch',
    GZ: 'Gasverbrauch',
    WMZ: 'Wärmeenergie',
    WZ: 'm³ Warmwasserverbrauch',
    SZ: 'Stromverbrauch',
  },
  // units as defined in
  // https://gitlab.kugu-home.com/kugu/product-digital-property/portal-backend/-/blob/hotfix-v1.16.0/utilities/constants.py#L530
  unitFormating: {
    '° Celcius': {
      short: '°C',
      conversion: null,
      higherConversion: null,
    },
    'm³': {
      short: 'm³',
      conversion: null,
      higherConversion: null
    },
    Liter: {
      short: 'l',
      conversion: null,
      higherConversion: null,
    },
    Kilogramm: {
      short: 'kg',
      conversion: null,
      higherConversion: null,
    },
    Tonnen: {
      short: 't',
      conversion: null,
      higherConversion: null,
    },
    Kubikmeter: {
      short: 'm³',
      conversion: null,
      higherConversion: null,
    },
    'Kubikmeter pro Stunde': {
      short: 'm³/h',
      conversion: null,
      higherConversion: null,
    },
    Stück: {
      short: 'Stk',
      conversion: null,
      higherConversion: null,
    },
    Watt: {
      short: 'W',
      conversion: 'kW',
      higherConversion: 'MW',
    },
    Kilowatt: {
      short: 'kW',
      conversion: 'MW',
      higherConversion: 'GW',
    },
    kW: {
      short: 'kW',
      conversion: 'MW',
      higherConversion: 'GW',
    },
    Wh: {
      short: 'Wh',
      conversion: 'kWh',
      higherConversion: 'MWh',
    },
    Wattstunden: {
      short: 'Wh',
      conversion: 'kWh',
      higherConversion: 'MWh',
    },
    Kilowattstunden: {
      short: 'kWh',
      conversion: 'MWh',
      higherConversion: 'GWh',
    },
    kWh: {
      short: 'kWh',
      conversion: 'MWh',
      higherConversion: 'GWh',
    },
    Megawattstunden: {
      short: 'MWh',
      conversion: 'GWh',
      higherConversion: null,
    },
    Gigawattstunden: {
      short: 'GWh',
      conversion: null,
      higherConversion: null,
    },
    'Raummeter/Ster': {
      short: 'rm',
      conversion: null,
      higherConversion: null,
    },
    Quadratmeter: {
      short: 'm²',
      conversion: null,
      higherConversion: null,
    },
    Megajoule: {
      short: 'MJ',
      conversion: 'GJ',
      higherConversion: null,
    },
    Gigajoule: {
      short: 'GJ',
      conversion: null,
      higherConversion: null,
    },
    Verbrauchseinheiten: {
      short: 'VE',
      conversion: null,
      higherConversion: null,
    },
     // TODO: this should be fixed in future as we return different/mutiple VE type
    'VE': {
      short: 'VE',
      conversion: null,
      higherConversion: null,
    },
    Ster: {
      short: 'st',
      conversion: null,
      higherConversion: null,
    },
    Schüttraummeter: {
      short: 'srm',
      conversion: null,
      higherConversion: null,
    },
    Unbekannt: {
      short: 'unb',
      conversion: null,
      higherConversion: null,
    },
  },
  measureTypeNotToShort: ['m³/h', 'm³', '%', '℃', 'VE', 'Liter', 'Tonnen', 'Stück', 'Kilowatt'],
  generalSearch: 'search',
  uviStates: {
    30: 'In Vorbereitung',
    40: 'Aktiv für Verwaltung',
    20: 'uVI aktiv',
    50: 'uVI deaktiviert',
  },
  communicationTypes: {
    KEINE: {
      key: 0,
      value: 'Keine',
    },
    POST: {
      key: 2,
      value: 'Postversand',
    },
    EMAIL: {
      key: 3,
      value: 'Nur E-Mail (Kein Postversand)',
    },
    NO_RADIO_MODULE: {
      key: 4,
      value: 'Keine Funkmodule',
    }
  },
  targetUnitByDeviceType: {
    Allgemein: 'VE',
    Datensammler: 'Unbestimmt',
    FM: 'Keine Ablesung möglich',
    GZ: 'm³',
    HKV: 'VE',
    Pumpe: 'Unbestimmt',
    RWM: 'Unbestimmt',
    SZ: 'kWh',
    WMZ: 'kWh',
    WZ: 'm³',
    KWZ: 'm³',
    WWZ: 'm³',
  },
  dataInletMessageTypes: [
    'structured',
    'lobaro',
    'chirpstack',
    'scs',
    'sonexa',
    'elementiot',
    'niota',
    'loriot',
    'rawlora',
    'rawoms'
  ],
  tenantErrors: {
    0: 'In dieser Nutzeinheit wurden Überschneidungen in der Bewohnerabfolge gefunden!',
    1: 'In dieser Nutzeinheit wurden Lücken zwischen Bewohnern gefunden!',
    2: 'In dieser Nutzeinheit fehlt ein aktueller Bewohner/Leerstand!',
    3: 'In dieser Nutzeinheit wurden fehlende Auszugsdaten gefunden!',
    4: 'Diese Nutzeinheit ist leer! Bitte legen Sie ggf. einen Leerstand an.'
  },
  deviceDescriptionOptions: [
    { value: '[Gesamtwärme]', label: '[Gesamtwärme]' },
    { value: '[Kessel]', label: '[Kessel]' },
    { value: '[Heizwärme]', label: '[Heizwärme]' },
    { value: '[Heizkreis]', label: '[Heizkreis]' },
    { value: '[Warmwasser]', label: '[Warmwasser]' },
    { value: '[Nutzeinheit-Warmwasser]', label: '[Nutzeinheit-Warmwasser]' },
    { value: '[Wärmelieferung]', label: '[Wärmelieferung]' },
    { value: '[Nutzeinheit]', label: '[Nutzeinheit]' },
    { value: '[Sonstiges]', label: '[Sonstiges]' },
  ],
  AESKeyInfo,
  invalidAESKey: `Ungültiger AES-Schlüssel. ${AESKeyInfo}`,
  matomoConfigs: {
    livePortal: {
      siteId: 2,
      containerId: 'HFoMPERX',
    },
    stagingPortal:  {
      siteId: 5,
      containerId: 'rsZLMA0Z',
    },
  },

  recommendations: {
    avg_efficiency: 'Mittelwert',
    max_efficiency: 'Maximalwert',  
    min_efficiency: 'Minimalwert',
    min_efficiency: 'Minimalwert',
    last_detection: 'Letzte_Erkennung',
  },

  hardwareTabsUrl: {
    types: [
      "detail", 
      "measurement",
      "timeframe"
    ],
    detail: {
      device: 'Geräte Details',
      radio: 'Funkmodul',
      measuringpoint: 'Messstelle',
      readings: 'Ablesungen',
      radiodata: 'Funk-Daten',
      catalog: 'Katalog-Daten',
      rawdata: 'Roh-Informationen',
      confhistory: 'Konfigurations-Chronik',
      lifetime: 'Lebensdauer',
      status: 'Statusdetails',
      rawstatus: 'Unformatierte Details',
    },
  }

};
/* eslint-enable */
