<template>
  <div class="w-100 p-4">
    <div
      v-if="errorType && errorStaticData[`_${errorType}`]"
      v-cy
      class="w-100 error__container"
    >
      <img
        height="250"
        :src="`/images/errors/${errorType}.svg`"
        :style="errorType === 'noPermission' ? 'width: 30%' : ''"
        width="30%"
      >
      <div class="error__content">
        <h2 class="error__title">
          {{ errorStaticData[`_${errorType}`].title }}
        </h2>
        <span class="error__message">{{ errorStaticData[`_${errorType}`].message }}</span>
      </div>
      <template v-for="(button, index) in errorStaticData[`_${errorType}`].buttons">
        <router-link
          v-if="button.href"
          :key="index"
          class="primary-button mr-2"
          :to="button.href"
        >
          {{ button.label }}
        </router-link>
        <button
          v-else-if="button.onClick"
          :key="index"
          class="primary-button mr-2"
          @click="button.onClick()"
        >
          {{ button.label }}
        </button>
      </template>
    </div>
    <div
      v-else-if="customError"
      class="w-100 error__container"
    >
      <img
        height="250"
        :src="`/images/errors/customError.svg`"
        width="30%"
      >
      <div class="error__content">
        <h2 class="error__title">
          {{ customError.title }}
        </h2>
        <span
          v-if="customError.message"
          class="error__message"
        >{{ customError.message }}</span>
      </div>
      <div v-if="customError.button && customError.button.permission">
        <slot name="left-side-button" />
        <button
          v-if="customError.button.action"
          class="primary-button"
          @click="customError.button.action"
        >
          {{ customError.button.label }}
        </button>
        <router-link
          v-if="customError.button.href"
          class="primary-button no-underline d-inline-block"
          :to="customError.button.href"
        >
          {{ customError.button.label }}
        </router-link>
        <slot name="right-side-button" />
      </div>
    </div>
    <div
      v-else
      class="p-4 m4 card w-100"
    >
      <div class="card-body p-4">
        <p class="font-weight-bold pb-1">
          Entschuldigung!
        </p>
        Der folgende Fehler ist aufgetreten:<br>
        <span
          v-if="error"
          class="text-danger py-4"
        >{{ error.data }}</span>
        <span
          v-else-if="componentError"
          class="text-danger py-4"
        >Netzwerkfehler {{ componentError }}</span>
        <span
          v-else
          class="text-danger py-4"
        >{{ errorMessage }}</span>
        <span
          v-if="error && error.toString().includes('401')"
          class="error__refresh-info"
        >Sie sind nicht mehr authentifiziert, bitte laden Sie diese Seite neu,
          um fortzufahren.</span>
      </div>
    </div>
    <information-panel v-if="showModal">
      <div class="p-4">
        <div>
          <atoms-icons
            class="float-right cursor-pointer"
            icon="kg kg-x "
            @click="showModal = false"
          />
        </div>
        <p class="font-weight-bold text-primary">
          Eine kurze Bitte
        </p>
        Um uns dabei zu helfen, den Ursprung des Problems so genau wie möglich zu erörtern,
        kopieren Sie die folgenden Fehlerdetails und fügen Sie sie in Ihre Support-Anfrage ein:
        <p
          id="error-context"
          class="p-3 px-5 my-3 bg-black-300 error-context"
        >
          <atoms-icons
            v-tooltip="'In die Zwischenablage kopieren'"
            class="float-right cursor-pointer"
            icon="kg kg-paperclip"
            @click="copyErrorDetails"
          />
          <span class="pull-left">{</span><br>
          <span class="font-weight-bold pt-1">"status":</span>
          "{{ context ? context.status : "500" }}",<br>
          <span class="font-weight-bold pt-1">"message":</span>
          "{{ context && context.data ? context.data.message : "unavailable" }}",<br>
          <span class="font-weight-bold pt-1">"method":</span>
          "{{ context && context.config ? context.config.method : "unavailable" }}",<br>
          <span class="font-weight-bold pt-1">"url":</span>
          "{{ context && context.config ? context.config.url : "unavailable" }}", <br>
          <span class="font-weight-bold pt-1">"parameters":</span>
          <br>{{ context && context.config ? context.config.params : "unavailable" }}, <br>
          <span class="font-weight-bold pt-1">
            "technical specifications":</span> <br>"{{ appVersion }}" <br>
          <span class="pull-left">}</span>
        </p>
        <div class="my-3">
          <a
            class="primary-button"
            href="#/support"
            target="_blank"
          >Zum Support Formular</a>
        </div>
      </div>
    </information-panel>
  </div>
</template>

<script>
import toastUtils from '@/utils/toastUtils';
import InformationPanel from '@/atomic-design-components/atoms/InformationPanel.vue';
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index';

export default {
  name: 'ErrorCard',
  components: {
    InformationPanel,
    AtomsIcons,
  },
  props: {
    error: [String, Object],
    componentError: {
      type: String,
      default: undefined,
    },
    context: Object,
    errorMessage: {
      type: String,
      default: '',
    },
    customError: {
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      appVersion: window.navigator.appVersion,
      definedErrorTypes: ['401', '404', 'noPermission', '500', 'invalidProperty'],
      errorStaticData: {
        _401: {
          title: 'Fehlende Berechtigung',
          /* eslint-disable */
          message: 'Sollte uns ein Fehler unterlaufen sein und Sie eigentlich Zugriff auf diese Seite haben, bitte kontaktieren Sie unseren Support.',
          /* eslint-enable */
          buttons: [
            {
              label: 'Support kontaktieren',
              href: `/support?source=${encodeURIComponent(window.location.href)}`,
            },
          ],
        },
        _500: {
          title: 'Es liegt nicht an Ihnen, es liegt an uns.',
          /* eslint-disable */
          message: 'Uns liegen interne Schwierigkeiten vor. Bitte versuchen Sie es erneut. Bei Fortbestehen des Problems kontaktieren Sie bitte unseren Support.',
          /* eslint-enable */
          buttons: [
            {
              label: 'Support kontaktieren',
              onClick: () => this.showSupportModal(),
            },
          ],
        },
        _invalidProperty: {
          title: 'Ungültige Liegenschaft',
          message: 'Diese Liegenschaft existiert nicht.',
          buttons: [
            {
              label: 'Zurück zum Dashboard',
              href: '/home',
            },
            {
              label: 'Support kontaktieren',
              href: `/support?source=${encodeURIComponent(window.location.href)}`,
            },
          ],
        },
        _noPermission: {
          title: 'Funktion ausgeblendet',
          message: 'Diese Funktion ist in den momentan gewählten Rollen nicht enthalten.',
          buttons: [
            {
              label: 'Funktionen aller Rollen anzeigen',
              onClick: () => this.resetRoles(),
            },
          ],
        },
        _404: {
          title: 'Seite nicht gefunden',
          /* eslint-disable */
          message: 'Unter dieser URL ist in unserem Portal nichts zu finden. Suchen Sie etwas, das eigentlich hier sein sollte? Dann kontaktieren Sie unseren Support.',
          /* eslint-enable */
          buttons: [
            {
              label: 'Zurück zum Dashboard',
              href: '/home',
            },
            {
              label: 'Support kontaktieren',
              href: `/support?source=${encodeURIComponent(window.location.href)}`,
            },
          ],
        },
      },
    };
  },
  computed: {
    errorType() {
      if (this.error) {
        let errorStatus = '';
        if (this.error.status) {
          errorStatus = this.error.status;
        } else {
          errorStatus = this.error;
        }
        return this.definedErrorTypes
          .map((error) => errorStatus && errorStatus.toString().includes(error)) ? errorStatus.toString() : null;
      } return null;
    },
  },
  methods: {
    copyErrorDetails() {
      const copyText = document.getElementById('error-context').textContent;
      const textArea = document.createElement('textarea');
      textArea.textContent = copyText;
      document.body.append(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      toastUtils.show('In Zwischenablage kopiert', 'success');
    },
    showSupportModal() {
      this.showModal = true;
    },
    resetRoles() {
      this.eventHub.$emit('resetRoles');
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
    &__refresh-info {
        display: block;
        padding-top: 20px;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__content {
        text-align: center;
        ;
        width: 100%;
        padding: 32px 0;
        display: grid;
        grid-row-gap: 16px;
    }

    &__title {
        font-size: 20px;
        font-weight: normal;
        margin-bottom: 0;
        text-align: center;

    }

    &__message {
        font-size: 14px;
        ;
        font-weight: normal;
    }
}

.no-underline {
    text-decoration: none;
}
.pull-left {
    position: relative;
    left: -20px;
}
</style>
