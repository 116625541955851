<template>
  <div data-testid="information-panel">
    <div class="panel-background">
      <div class="panel">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InformationPanel',
};
</script>
<style scoped>
.panel-background {
  position: fixed;
  background-color: var(--neutral);
  opacity: 0.15;
  top:0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}
.panel {
  position: fixed;
  z-index: 5000;
  top: 20vh;
  left: 30vw;
  width: 50vw;
  max-height: 70vh;
  overflow-y: auto;
  z-index: 100;
  background: var(--neutral-100);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);

  box-shadow: var(--box-shadow);
}
</style>
