var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.props.row[_vm.props.column.field + '_tooltip']),expression:"props.row[props.column.field + '_tooltip']"}],attrs:{"data-testid":_vm.props.column.label + '-Status'}},[(_vm.withLink == true && _vm.props.row[_vm.props.column.field])?_c('router-link',{attrs:{"to":_vm.props.row[_vm.props.column.field].router_text != _vm.getLockedState()
      ? '/property/' + _vm.props.row.property_id + _vm.props.row[_vm.props.column.field].router_link
      : ''}},[_c('span',{staticClass:"d-none"},[_vm._v(_vm._s(_vm.props.row[_vm.props.column.field].router_text))]),_c('i',{class:[
        'd-inline-block w-100 centered',
        _vm.status ? `${_vm.status.symbol}` : '',
        _vm.status ? `text-${_vm.status.color}` : 'text-secondary'],attrs:{"id":_vm.props.row[_vm.props.column.field].router_text != _vm.getLockedState() ? '' : 'not-allowed'}})]):_c('span',[_c('span',{staticClass:"d-none"},[_vm._v(" "+_vm._s((Array.isArray(_vm.props.row[_vm.props.column.field]) && _vm.props.row[_vm.props.column.field].length > 0) ? _vm.props.row[_vm.props.column.field][0] : _vm.props.row[_vm.props.column.field])+" ")]),_c('span',{staticClass:"d-inline-block w-100 centered"},[_c('i',{class:[
          _vm.status !== null ? `${_vm.status.symbol}` : '',
          _vm.status ? `text-${_vm.status.color}` : 'text-secondary']}),(_vm.props.row.hasOwnProperty('status_text'))?_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.props.row.status_text))]):_vm._e(),(Array.isArray(_vm.props.row[_vm.props.column.field] && _vm.props.row[_vm.props.column.field].length > 1))?_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.props.row[_vm.props.column.field][1]))]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }