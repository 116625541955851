<template>
  <!-- eslint-disable -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="35"
    viewBox="0 0 36 35"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g>
        <g>
          <g transform="translate(-1044 -121) translate(1045 122)">
            <g>
              <path
                fill="#FFF"
                d="M26.88 13.44c0 7.422-6.018 13.44-13.44 13.44C6.017 26.88 0 20.862 0 13.44 0 6.017 6.017 0 13.44 0c7.422 0 13.44 6.017 13.44 13.44z"
              />
              <path
                stroke="#263344"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width=".96"
                d="M26.88 13.44c0 7.422-6.018 13.44-13.44 13.44C6.017 26.88 0 20.862 0 13.44 0 6.017 6.017 0 13.44 0c7.422 0 13.44 6.017 13.44 13.44zM14.88 12L20.16 6.72M2.88 13.44L4.8 13.44M3.684 9.399L5.458 10.134M5.973 5.973L7.331 7.331M9.399 3.684L10.133 5.458M13.44 2.881L13.44 4.801M17.482 3.685L16.746 5.458M23.196 9.401L21.422 10.134M23.999 13.442L22.08 13.441M23.195 17.483L21.422 16.747M3.684 17.481L5.458 16.746"
              />
              <path
                stroke="#263344"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width=".96"
                d="M8.64 22.08L18.24 22.08 18.24 19.2 8.64 19.2zM15.36 13.44c0 1.06-.86 1.92-1.92 1.92s-1.92-.86-1.92-1.92.86-1.92 1.92-1.92 1.92.86 1.92 1.92z"
              />
            </g>
            <rect
              width="25"
              height="12"
              x=".5"
              y="21.5"
              fill="#FFF"
              stroke="#263344"
              rx="2"
            />
            <path
              fill="#1F2D3D"
              fill-rule="nonzero"
              d="M10.398 31.088c.71 0 1.273-.158 1.692-.473.42-.314.629-.749.629-1.302 0-.499-.172-.915-.514-1.249-.343-.334-.89-.613-1.64-.839-.407-.123-.715-.255-.925-.395-.21-.141-.314-.315-.314-.523 0-.223.09-.402.268-.539.179-.136.43-.204.752-.204.334 0 .593.081.778.244.184.162.276.39.276.683h1.319c0-.384-.1-.726-.297-1.028-.198-.302-.475-.536-.833-.701-.357-.166-.764-.248-1.221-.248-.454 0-.864.076-1.229.228-.364.153-.644.364-.839.635-.195.271-.292.58-.292.925 0 .665.363 1.194 1.09 1.587.266.143.628.289 1.085.437.457.148.774.289.95.422.175.133.263.324.263.573 0 .229-.088.407-.264.534-.175.128-.42.191-.734.191-.843 0-1.265-.353-1.265-1.059H7.81c0 .413.106.777.319 1.09.212.314.52.56.925.74.404.18.852.27 1.344.27zM18.234 31v-1.06h-3.226l3.156-4.583v-.755h-4.8v1.067h3.16l-3.164 4.558V31h4.874z"
            />
          </g>
          <path
            fill="#263344"
            d="M26.614 6L32.755 6 27.716 14.263 34.024 14.263 20.791 29 25.04 18.395 20 18.395z"
            transform="translate(-1044 -121) translate(1045 122)"
          />
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>
<script>
export default {
  name: 'AtomsIconsMetersElectrical',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
};
</script>
