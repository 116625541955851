<template>
  <span class="cursor-pointer">
    <span
      v-if="!editingActive"
      class="d-inline-block w-100"
      @click="toggleEditing"
    >{{ getValue || '&nbsp;' }}</span>
    <span
      v-else
      class="d-inline-block w-100"
    >
      <input
        class="d-inline-block"
        :type="props.column.inputType"
        v-model="value"
      >
      <i
        class="d-inline-block centered kg kg-x-circle text-danger"
        style="margin-left: 20px"
        @click="toggleEditing"
      />
      <i
        class="d-inline-block centered kg kg-check text-primary"
        style="margin-left: 20px"
        @click="finishEditing"
      />
    </span>
  </span>
</template>
<script>
export default {
  name: 'EditableInputSlot',
  data: () => ({
    editingActive: false,
    value: null,
  }),
  props: {
    props: Object,
  },
  computed: {
    getValue() {
      if (this.props.column.getValue) {
        return this.props.column.getValue(this.props.row);
      }
      return this.value;
    },
  },
  created() {
    this.initValue();
  },
  methods: {
    initValue() {
      if (this.props.default) {
        this.value = this.props.default;
      } else if (this.props.inputType === 'text') {
        this.value = '';
      }
    },
    toggleEditing() {
      this.editingActive = !this.editingActive;
    },
    finishEditing() {
      this.editingActive = false;
      this.$parent.$parent.$emit('value-saved', this.value, this.props.row);
    },
  },
};
</script>
