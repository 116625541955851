<template>
  <InputFrame
    ref="frame"
    :disabled="inputField.disabled || false"
    :error-message="inputField.errorMessage"
    :label="inputField.label"
    :label-size="inputField.labelSize"
    :label-tooltip="inputField.tooltip"
  >
    <input
      ref="input"
      :class="{'hide-arrows': hideArrows}"
      lang="de-DE"
      pattern="[0-9]+([,][0-9]{1,2})?"
      :step="inputField.step ? inputField.step : 0.01"
      type="number"
      @blur="handleBlur"
      @input="save"
      @keydown="checkInput($event)"
      v-model="value"
    >
    <span
      v-if="inputField.unit"
      class="input-unit"
    >{{ inputField.unit }}</span>
  </InputFrame>
</template>

<script>
import InputFrame from './InputFrame.vue';

export default {
  name: 'NumericInput',
  components: {
    InputFrame,
  },
  props: {
    inputField: Object,
    hideArrows: {
      type: Boolean,
      default: false,
    },
    customValue: Number,
  },
  data() {
    return {
      value: '',
    };
  },
  watch: {
    inputField: {
      handler() {
        this.setPlaceholder();
      },
      deep: true,
    },
    customValue() {
      this.value = this.customValue;
    },
  },
  created() {
    this.setPlaceholder();
    if (this.inputField.defaultValue) {
      this.value = this.inputField.defaultValue;
      this.save();
    }
  },
  methods: {
    setPlaceholder() {
      if (this.inputField.hasOwnProperty('placeholder')) {
        this.value = Math.round(this.inputField.placeholder * 100) / 100;
        this.save();
      }
    },
    async validate() {
      const valid = await this.inputField.validate(this.value, this.inputField.required);
      if (!valid) {
        this.$refs.frame.showErrorMessage();
        this.$refs.input.classList.add('invalid');
        return false;
      }
      this.$refs.frame.hideErrorMessage();
      this.$refs.input.classList.remove('invalid');
      return true;
    },
    checkInput(event) {
      if (event.key === '.' && this.inputField.step === 1) {
        event.preventDefault();
      } else if (event.key === 'e') {
        event.preventDefault();
      }
    },
    save() {
      if (!this.inputField.saveOnBlur) {
        if (this.inputField.triggers) {
          if (this.value) {
            this.$emit('triggered', this.value, this.inputField.triggers);
          }
        }
        this.$emit('input', this.value);
      }
    },
    handleBlur() {
      if (this.inputField.saveOnBlur) {
        if (this.inputField.triggers) {
          if (this.value) {
            this.$emit('triggered', this.value, this.inputField.triggers);
          }
        }
        this.$emit('input', this.value);
      }
    },
  },
};
</script>

<style>
.input-unit {
    position: relative;
    top: -33px;
    right: 20px;
    float: right;
}

.hide-arrows {
    -moz-appearance: textfield;
}

.hide-arrows::-webkit-inner-spin-button,
.hide-arrows::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
    -webkit-appearance: none;
    appearance: textfield;
}
</style>
