<template>
  <span v-cy>
    <span
      v-for="(row,i) in props.row.degree_of_utilisation"
      :key="i"
      v-tooltip="getTooltip(props.row.degree_of_utilisation)"
    >
      <span
        v-cy="`item${i}`"
        class="d-block"
      >
        <i
          class="fas fa-circle pr-2"
          :style="setColor(row.color)"
        />
        {{ row.label }}: {{ row.value }} {{ row.value === 'N/A' ? '' : row.unit }}
      </span>
    </span>
  </span>
</template>

<script>
import utils from '@/utils/utils';

export default {
  name: 'CountersWithChangeSlot',
  props: {
    props: Object,
  },

  methods: {
    getTooltip(row) {
      const change1 = row[0].value === 'N/A' || row[1].value === 'N/A'
        ? 'N/A'
        : utils.formatNumber(parseFloat(row[1].value) - parseFloat(row[0].value));
      const change2 = row[1].value === 'N/A' || row[2].value === 'N/A'
        ? 'N/A'
        : utils.formatNumber(parseFloat(row[2].value) - parseFloat(row[1].value));
      const change1form = `
        <span class="text-${utils.getColorForFormattedNumber(change1)}">
          ${change1}${change1 === 'N/A' ? '' : row[1].unit}
        </span>
      `;
      const change2form = `
        <span class="text-${utils.getColorForFormattedNumber(change2)}">
          ${change2}${change2 === 'N/A' ? '' : row[2].unit}
        </span>
      `;
      return `
        <span class="font-weight-bold text-white mb-3">
          Änderung
        </span>
        <br>
        ${row[0].label}: ${row[0].value}${row[0].value === 'N/A' ? '' : row[0].unit}
        <br>
        ${row[1].label}: ${change1form}<br>${row[2].label}: ${change2form}
      `;
    },

    setColor(color) {
      return {
        color,
      };
    },
  },
};
</script>
<style scoped>
  i {
    font-size: 0.5rem;
  }
</style>
