<template>
  <div
    v-tooltip="errorMessage && errorMessage"
    :class="['input-slot p-0', {'input-slot__error': error}]"
  >
    <span
      v-if="options.length == 0"
      class="p-0"
    >
      <div
        v-if="props.formattedRow.isUneditable == true"
        class="p-0 cursor-text text-black-400"
      >
        Keine Eingabe möglich.
      </div>
      <input
        v-else
        :class="['input-slot-text p-0', (textValue && textValue.includes('gültig')) ? 'placeholder-danger' : '' ]"
        @change="emitSelection"
        v-model="textValue"
      >
    </span>
    <div v-else>
      <multiselect
        v-if="shown"
        class="p-0"
        deselect-label=""
        label="label"
        no-options="Keine Einträge zur Auswahl"
        :options="options"
        placeholder="auswählen"
        select-label="Auswählen"
        selected-label=""
        track-by="value"
        @input="emitSelection"
        @remove="emitRemoval"
        v-model="value"
      >
        <template slot="placeholder">
          <span :class="[label == 'Zuweisen' ? 'text-lightgrey' : 'text-black']">{{ label }}</span>
        </template>
        <span
          v-if="!arrowShown"
          slot="caret"
        >
        <!--this span removes the little arrow from the cell--></span>
      </multiselect>
      <p
        v-else
        v-tooltip="tooltip"
        :class="'text-' + hiddenColor + ' p-2 cursor-disabled'"
      >
        {{ label }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputSlot',
  props: {
    props: Object,
    arrowShown: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    tooltip: {
      type: String,
      default: '',
    },
    hiddenColor: {
      type: String,
      default: 'lightgrey',
    },
    label: String,
    shown: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      value: null,
      error: false,
      textValue: '',
      errorMessage: '',
    };
  },
  methods: {
    setValue(val) {
      this.value = val;
    },
    reset() {
      this.value = null;
    },
    emitSelection(value) {
      this.$emit('selected', value, this.props);
    },
    emitRemoval(value) {
      this.$emit('removed', value);
    },
  },
  watch: {
    textValue() {
      this.eventHub.$emit('updateInput', this.props.formattedRow, this.props.column.field, this.textValue);
    },
  },
  mounted() {
    const errorSymbol = '#err%';
    const fieldValue = this.props.formattedRow[this.props.column.field];
    if (fieldValue && typeof fieldValue === 'string' && fieldValue.includes(errorSymbol)) {
      const [newValue, errorMessage] = fieldValue.split(errorSymbol);
      this.textValue = newValue;
      this.errorMessage = errorMessage;
      this.error = true;
    } else {
      this.textValue = fieldValue;
    }
  },
};
</script>

<style lang="scss">
.input-slot .multiselect__input,
.multiselect__input:active,
.multiselect__input:focus {
    width: 90% !important;
    height: inherit;
    border: none !important;
}

.input-slot .multiselect__tags {
    border: none !important;
    color: black !important;
}

.input-slot .multiselect__content-wrapper {
    position: absolute;
    display: block;
    z-index: 1000;
    background: white;
    max-height: 200px !important;
    width: inherit !important;
    max-width: 150px !important;
    overflow: scroll !important;
}

.input-slot .multiselect__option span {
    width: inherit !important;
    text-overflow: ellipsis;
}

.input-slot-text {
    color: black !important;
    display: block;
    border: none !important;
    padding: 5px !important;
    height: inherit !important;
    width: 100% !important;
    background: white !important;
}

.input-slot-text:focus {
    border: none !important;
}

.input-slot-text::placeholder,
.input-slot-text:-ms-input-placeholder,
.input-slot-text::-ms-input-placeholder {
    opacity: 1;
    /* Firefox */
}

.input-slot__error {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        height: calc(100% + 16px);
        width: calc(100% + 16px);
        background: var(--danger);
        left: -8px;
        top: -8px;
        z-index: 0;
        pointer-events: none;
    }
    input {
        position: relative;
        z-index: 2;
        color: #fff !important;
        background: transparent !important;
    }
}
input.placeholder-danger {
    color: var(--danger) !important;
    background: var(--danger-100) !important;
    border: 1px solid var(--danger) !important;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
