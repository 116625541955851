<template>
  <div
    :class="[blockClassName]"
  >
    <template v-if="!itIsLibaryIcon">
      <component
        :is="setIcon"
        :color="color"
        :status="status"
      />
    </template>
    <atoms-icons-font-libary
      v-else
      :color="color"
      :icon="icon"
      :modifiers="modifiers"
      @click="$emit('click')"
    />
  </div>
</template>
<script>
import AtomsIconsHealth from '@/atomic-design-components/atoms/icons/Health';
import AtomsIconsRadiator from '@/atomic-design-components/atoms/icons/Radiator';
import AtomsIconsBoiler from '@/atomic-design-components/atoms/icons/Boiler';
import AtomsIconsFloorplan from '@/atomic-design-components/atoms/icons/FloorPlan';
import AtomsIconsHeatSign from '@/atomic-design-components/atoms/icons/HeatSign';
import AtomsIconsBuilding from '@/atomic-design-components/atoms/icons/Building';
import AtomsIconsMeter from '@/atomic-design-components/atoms/icons/Meter';
import AtomsIconsTool from '@/atomic-design-components/atoms/icons/Tool';
import AtomsIconsPlan from '@/atomic-design-components/atoms/icons/Plan';
import AtomsIconsSnoozing from '@/atomic-design-components/atoms/icons/Snoozing';
import AtomsIconsOutlineArrow from '@/atomic-design-components/atoms/icons/OutlineArrow';
import AtomsIconsVariantsSnoozingTiny from '@/atomic-design-components/atoms/icons/variants/snoozing/Tiny';
import AtomsIconsMetersWaterCold from '@/atomic-design-components/atoms/icons/meters/water/Cold';
import AtomsIconsMetersSmokeDetector from '@/atomic-design-components/atoms/icons/meters/SmokeDetector';
import AtomsIconsMetersElectrical from '@/atomic-design-components/atoms/icons/meters/Electrical';
import AtomsIconsMetersWaterHot from '@/atomic-design-components/atoms/icons/meters/water/Hot';
import AtomsIconsMetersWater from '@/atomic-design-components/atoms/icons/meters/Water';
import AtomsIconsMetersHeatVolume from '@/atomic-design-components/atoms/icons/meters/heat/Volume';
import AtomsIconsMetersHeatAllocator from '@/atomic-design-components/atoms/icons/meters/heat/Allocator';
import AtomsIconsMetersGas from '@/atomic-design-components/atoms/icons/meters/Gas';
import AtomsIconsFontLibary from '@/atomic-design-components/atoms/icons/FontLibary';
import AtomsIconsBuildingMap from '@/atomic-design-components/atoms/icons/BuildingMap';

export default {
  name: 'AtomsIcons',
  props: {
    icon: {
      type: String,
      required: true,
    },

    status: {
      type: String,
      default: '',
    },

    color: {
      type: String,
      default: undefined,
    },

    modifiers: {
      type: String,
      default: '',
    },
  },

  components: {
    AtomsIconsHealth,
    AtomsIconsBuilding,
    AtomsIconsMeter,
    AtomsIconsTool,
    AtomsIconsPlan,
    AtomsIconsSnoozing,
    AtomsIconsMetersWaterCold,
    AtomsIconsMetersWaterHot,
    AtomsIconsMetersSmokeDetector,
    AtomsIconsMetersElectrical,
    AtomsIconsMetersWater,
    AtomsIconsMetersHeatVolume,
    AtomsIconsMetersGas,
    AtomsIconsMetersHeatAllocator,
    AtomsIconsVariantsSnoozingTiny,
    AtomsIconsOutlineArrow,
    AtomsIconsFontLibary,
    AtomsIconsRadiator,
    AtomsIconsHeatSign,
    AtomsIconsFloorplan,
    AtomsIconsBoiler,
    AtomsIconsBuildingMap,
  },

  computed: {
    isFontAwesome() {
      return this.icon.includes('fa');
    },

    isKuguIcon() {
      return this.icon.includes('kg');
    },

    itIsLibaryIcon() {
      return this.icon.includes('fa') || this.icon.includes('kg');
    },

    setIcon() {
      return this.icons[this.icon];
    },
  },

  data() {
    return {
      icons: {
        health: 'AtomsIconsHealth',
        radiator: 'AtomsIconsRadiator',
        heat_sign: 'AtomsIconsHeatSign',
        floor_plan: 'AtomsIconsFloorplan',
        boiler: 'AtomsIconsBoiler',
        building: 'AtomsIconsBuilding',
        tool: 'AtomsIconsTool',
        meter: 'AtomsIconsMeter',
        plan: 'AtomsIconsPlan',
        snoozing: 'AtomsIconsSnoozing',
        snoozing_tiny: 'AtomsIconsVariantsSnoozingTiny',
        kwz: 'AtomsIconsMetersWaterCold',
        wwz: 'AtomsIconsMetersWaterHot',
        rwm: 'AtomsIconsMetersSmokeDetector',
        sz: 'AtomsIconsMetersElectrical',
        wz: 'AtomsIconsMetersWater',
        wmz: 'AtomsIconsMetersHeatVolume',
        gz: 'AtomsIconsMetersGas',
        hkv: 'AtomsIconsMetersHeatAllocator',
        outline_arrow: 'AtomsIconsOutlineArrow',
        building_map: 'AtomsIconsBuildingMap',
      },
    };
  },
};
</script>
<style lang="scss">
  $class-name: 'AtomsIcons';

  .#{$class-name} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
