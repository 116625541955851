<template>
  <div>
    <div :class="`p-relative ${small? 'px-0 py-2' : 'p-2'}`">
      <div :class="`bar-container pill-shape-m bg-black-100 ${small? 'small' : ''}`">
        <div
          v-for="(values, key, _i) in data"
          :key="_i"
          :class="`d-inline-block bg-${values.color} bar-part`"
          :style="`width: ${values.value}%`"
        >
          <p class="text-white d-inline-block w-100 lift-by-5px small-text text-center">
            {{ values.text }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    data: Object,
    small: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 100,
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
    sortByColor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      setUp: false,
    };
  },
};
</script>

<style scoped>
.bar-part {
    height: 16px;
    position: relative;
}

.small {
    height: 10px;
}
.bar-container {
    width: 100%;
    position: relative;
}
.lift-by-5px {
    position: relative;
    top: -5px
}
</style>
