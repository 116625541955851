import { globalStorage } from '@/main';

const buildDataObject = (text, linkTo) => ({ text, linkTo });

const URLS = {
  PROPERTIES: () => '/property',
  PROPERTY: (id) => `/property/${id}`,
  PROPERTY_TENANT: (id) => `/property/${id}/tenants`,
  PROPERTY_BILLING: (id) => `/property/${id}/billing`,
  PROPERTY_SMOKE_DETECTORS: (id) => `/property/${id}/smoke-detector`,
  PROPERTY_SMOKE_DETECTOR: (propertyId, smokeDetectorId) => `/property/${propertyId}/smoke-detector/${smokeDetectorId}`,
  PROPERTY_DOCUMENT: (propertyId) => `/property/${propertyId}/document`,
  PROPERTY_IMPORT: (propertyId) => `/property/${propertyId}/import`,
  HARDWARES: (id) => `/property/${id}/hardware`,
  HARDWARE: (propertyId, hardwareId) => `/property/${propertyId}/hardware/${hardwareId}`,
  CENTRAL_HEATINGS: (id) => `/property/${id}/central-heating`,
  CENTRAL_HEATING: (propertyId, centralHeatingId) => `/property/${propertyId}/central-heating/${centralHeatingId}`,
  BILLING: () => '/billing',
  EXTERNAL_CREDENTIALS: () => '/external-credentials',
  ERAS2_CONNECTION: () => '/eras-2-connection',
  CUSTOMERS: () => '/customer',
  CLIENTS: () => '/clients',
  CLIENT: (id) => `/clients/${id}`,
  PROPERTY_SETUP_PIPELINE: (id) => `property/${id}/setup-pipeline`,
  HARDWARE_MANAGER: () => '/hardware-manager',
  HARDWARE_MANAGER_LIFETIME_BATTERY: () => '/hardware-manager/lifetime-calculator',
  HARDWARE_MANAGER_WMBUS: () => '/hardware-manager/wmbus-decoder',
  HARDWARE_MANAGER_DATA_INLETS: () => '/hardware-manager/data-inlets',
  HARDWARE_MANAGER_RECEPTIONS: () => '/hardware-manager/reception',
  HARDWARE_MANAGER_RECEPTION: (id) => `/hardware-manager/reception/${id}`,
  HARDWARE_MANAGER_DEVICE_INBOX: () => '/hardware-manager/device-inbox',
  SUPPORT: () => '/support',
  SUPPORT_RELEASE_NOTES: () => '/support/releases',
  SUPPORT_FAQ: () => '/support/faq',
  IMPORT: () => '/import',
  IMPRINT: () => '/imprint',
  BILLING_SOFTWARE: () => '/billing-software',
  NOTIFICATION_MANAGMENT: () => '/notification-management',
  SMOKE_DETECTORS: () => '/smoke-detector-manager',
};

const LINKS = {
  PROPERTIES: () => buildDataObject('Liegenschaften', URLS.PROPERTIES()),
  PROPERTY: (street, id) => buildDataObject(street, URLS.PROPERTY(id)),
  PROPERTY_TENANT: (id) => buildDataObject('Bewohner', URLS.PROPERTY_TENANT(id)),
  PROPERTY_BILLING: (id) => buildDataObject('Abrechnung', URLS.PROPERTY_BILLING(id)),
  PROPERTY_SMOKE_DETECTORS: (id) => buildDataObject('Rauchmelder', URLS.PROPERTY_SMOKE_DETECTORS(id)),
  /* eslint-disable */
  PROPERTY_SMOKE_DETECTOR: (propertyId, smokeDetectorId, nameOfDevice) => buildDataObject(nameOfDevice, URLS.PROPERTY_SMOKE_DETECTOR(propertyId, smokeDetectorId)),
  /* eslint-enable */
  PROPERTY_SETUP_PIPELINE: (propertyId) => buildDataObject('Erstaufnahme', URLS.PROPERTY_SETUP_PIPELINE(propertyId)),
  PROPERTY_DOCUMENT: (propertyId) => buildDataObject('Dokumente', URLS.PROPERTY_DOCUMENT(propertyId)),
  HARDWARES: (id) => buildDataObject('Hardware', URLS.HARDWARES(id)),
  HARDWARE: (name, propertyId, hardwareId) => buildDataObject(name, URLS.HARDWARE(propertyId, hardwareId)),
  CENTRAL_HEATING: (id, choId) => buildDataObject('Digitaler Heizungskeller', URLS.CENTRAL_HEATING(id, choId)),
  CENTRAL_HEATINGS: (propertyId) => buildDataObject('Gas-Zentralheizung', URLS.CENTRAL_HEATINGS(propertyId)),
  PROPERTY_IMPORT: (propertyId) => buildDataObject('Import', URLS.PROPERTY_IMPORT(propertyId)),
  BILLING: () => buildDataObject('Abrechnung', URLS.BILLING()),
  EXTERNAL_CREDENTIALS: () => buildDataObject('Externe Zugangsdaten', URLS.EXTERNAL_CREDENTIALS()),
  ERAS2_CONNECTION: () => buildDataObject('ERAS2 Verbindung', URLS.ERAS2_CONNECTION()),
  CUSTOMERS: () => buildDataObject('Kunden', URLS.CUSTOMERS()),
  CLIENTS: () => buildDataObject('Mandaten', URLS.CLIENTS()),
  CLIENT: (nameOfCompany, clientId) => buildDataObject(nameOfCompany, URLS.CLIENT(clientId)),
  HARDWARE_MANAGER: () => buildDataObject('Hardware-Manager', URLS.HARDWARE_MANAGER()),
  HARDWARE_MANAGER_LIFETIME_BATTERY: () => buildDataObject('Lebensdauer', URLS.HARDWARE_MANAGER_LIFETIME_BATTERY()),
  HARDWARE_MANAGER_WMBUS: () => buildDataObject('WMBus Decoder', URLS.HARDWARE_MANAGER_WMBUS()),
  SUPPORT: (label = 'Hilfe') => buildDataObject(label, URLS.SUPPORT()),
  SUPPORT_RELEASE_NOTES: () => buildDataObject('Neuigkeiten', URLS.SUPPORT_RELEASE_NOTES()),
  SUPPORT_FAQ: () => buildDataObject('Fragen & Antworten', URLS.SUPPORT_FAQ()),
  HARDWARE_MANAGER_DATA_INLETS: () => buildDataObject('Daten-Eingang', URLS.HARDWARE_MANAGER_DATA_INLETS()),
  HARDWARE_MANAGER_RECEPTIONS: () => buildDataObject('Empfangsanalyse (Beta)', URLS.HARDWARE_MANAGER_RECEPTIONS()),
  /* eslint-disable */
  HARDWARE_MANAGER_RECEPTION: (street, propertyId) => buildDataObject(street, URLS.HARDWARE_MANAGER_RECEPTION(propertyId)),
  /* eslint-enable */
  HARDWARE_MANAGER_DEVICE_INBOX: () => buildDataObject('Neue Geräte', URLS.HARDWARE_MANAGER_DEVICE_INBOX()),
  IMPORT: () => buildDataObject('Import', URLS.IMPORT()),
  IMPRINT: () => buildDataObject('Impressum', URLS.IMPRINT()),
  NOTIFICATION_MANAGMENT: () => buildDataObject('Benachrichtigungseinstellungen', URLS.NOTIFICATION_MANAGMENT()),
  BILLING_SOFTWARE: () => buildDataObject('Abrechnungssoftware', URLS.BILLING_SOFTWARE()),
  SMOKE_DETECTORS: () => buildDataObject('Rauchmelder', URLS.SMOKE_DETECTORS()),
};

export const BREADCRUMBS = {
  HARDWARE_DETAIL: {
    func: ({
      propertyId, hardwareId, street, nameOfDevice,
    }) => [
      LINKS.PROPERTIES(),
      LINKS.PROPERTY(street, propertyId),
      LINKS.HARDWARES(propertyId),
      LINKS.HARDWARE(nameOfDevice, propertyId, hardwareId),
    ],
    dropdown: {
      endpoint: (context) => `hardware/property/${context.propertyId}`,
      label: (context) => context.nameOfDevice,
      func: (data) => data.map(({
        hardware_id, oms_id, room, type_id,
      }) => ({
        id: hardware_id,
        displayName: `${room}-${type_id}-${oms_id}`,
      })),
    },
  },

  HARDWARE_OVERVIEW: {
    func: ({ propertyId, street }) => [
      LINKS.PROPERTIES(),
      LINKS.PROPERTY(street, propertyId),
      LINKS.HARDWARES(propertyId),
    ],
  },

  CENTRAL_HEATING_DETAIL: {
    func: ({ propertyId, centralHeatingId, street }) => [
      LINKS.PROPERTIES(),
      LINKS.PROPERTY(street, propertyId),
      LINKS.CENTRAL_HEATINGS(propertyId),
      LINKS.CENTRAL_HEATING(propertyId, centralHeatingId),
    ],
    getData: (context) => ({
      centralHeatingId: context.choId,
      propertyId: context.propertyId,
      street: globalStorage.currentProperty.street,
    }),
  },

  CENTRAL_HEATING_OVERVIEW: {
    func: ({ propertyId, street }) => [
      LINKS.PROPERTIES(),
      LINKS.PROPERTY(street, propertyId),
      LINKS.CENTRAL_HEATINGS(propertyId),
    ],
  },

  PROPERTY: {
    func: ({ propertyId, street }) => [
      LINKS.PROPERTIES(),
      LINKS.PROPERTY(street, propertyId),
    ],
  },

  PROPERTY_IMPORT: {
    func: ({ propertyId, street }) => [
      LINKS.PROPERTIES(),
      LINKS.PROPERTY(street, propertyId),
      LINKS.PROPERTY_IMPORT(propertyId),
    ],
  },

  PROPERTY_BILLING: {
    func: ({ propertyId, street }) => [
      LINKS.PROPERTIES(),
      LINKS.PROPERTY(street, propertyId),
      LINKS.PROPERTY_BILLING(propertyId),
    ],
  },

  PROPERTY_SMOKE_DETECTOR: {
    func: ({
      propertyId, deviceId, street, nameOfDevice,
    }) => [
      LINKS.PROPERTIES(),
      LINKS.PROPERTY(street, propertyId),
      LINKS.PROPERTY_SMOKE_DETECTORS(propertyId),
      LINKS.PROPERTY_SMOKE_DETECTOR(propertyId, deviceId, nameOfDevice),
    ],
    dropdown: {
      endpoint: (context) => `hardware/property/${context.propertyId}?type=smoke-detector&start=current`,
      label: (context) => context.nameOfDevice,
      func: (data) => data.map(({
        hardware_id, oms_id, room, type_id,
      }) => ({
        id: hardware_id,
        displayName: `${room}-${type_id}-${oms_id}`,
      })),
    },
  },

  PROPERTY_SMOKE_DETECTORS: {
    func: ({ propertyId, street }) => [
      LINKS.PROPERTIES(),
      LINKS.PROPERTY(street, propertyId),
      LINKS.PROPERTY_SMOKE_DETECTORS(propertyId),
    ],
  },

  PROPERTY_TENANT: {
    func: ({ propertyId, street }) => [
      LINKS.PROPERTIES(),
      LINKS.PROPERTY(street, propertyId),
      LINKS.PROPERTY_TENANT(propertyId),
    ],
  },

  PROPERTY_DOCUMENT: {
    func: ({ propertyId, street }) => [
      LINKS.PROPERTIES(),
      LINKS.PROPERTY(street, propertyId),
      LINKS.PROPERTY_DOCUMENT(propertyId),
    ],
  },

  PROPERTY_SETUP_PIPELINE: {
    func: ({ propertyId, street }) => [
      LINKS.PROPERTIES(),
      LINKS.PROPERTY(street, propertyId),
      LINKS.PROPERTY_SETUP_PIPELINE(propertyId),
    ],
  },

  CLIENT: {
    func: ({ clientId, nameOfCompany }) => [
      LINKS.CLIENTS(),
      LINKS.CLIENT(nameOfCompany, clientId),
    ],
  },

  HARDWARE_MANAGER_LIFETIME_BATTERY: {
    func: () => [
      LINKS.HARDWARE_MANAGER(),
      LINKS.HARDWARE_MANAGER_LIFETIME_BATTERY(),
    ],
  },

  HARDWARE_MANAGER_WMBUS: {
    func: () => [
      LINKS.HARDWARE_MANAGER(),
      LINKS.HARDWARE_MANAGER_WMBUS(),
    ],
  },

  HARDWARE_MANAGER_DATA_INLETS: {
    func: () => [
      LINKS.HARDWARE_MANAGER(),
      LINKS.HARDWARE_MANAGER_DATA_INLETS(),
    ],
  },

  HARDWARE_MANAGER_RECEPTIONS: {
    func: () => [
      LINKS.HARDWARE_MANAGER(),
      LINKS.HARDWARE_MANAGER_RECEPTIONS(),
    ],
  },

  HARDWARE_MANAGER_RECEPTION: {
    func: ({ street, propertyId }) => [
      LINKS.HARDWARE_MANAGER(),
      LINKS.HARDWARE_MANAGER_RECEPTIONS(),
      LINKS.HARDWARE_MANAGER_RECEPTION(street, propertyId),
    ],
  },

  HARDWARE_MANAGER_DEVICE_INBOX: {
    func: () => [
      LINKS.HARDWARE_MANAGER(),
      LINKS.HARDWARE_MANAGER_DEVICE_INBOX(),
    ],
  },

  SUPPORT_RELEASE_NOTES: {
    func: () => [
      LINKS.SUPPORT(),
      LINKS.SUPPORT_RELEASE_NOTES(),
    ],
  },

  SUPPORT_FAQ: {
    func: () => [
      LINKS.SUPPORT(),
      LINKS.SUPPORT_FAQ(),
    ],
  },

  SUPPORT_CONTACT: {
    func: () => [
      LINKS.SUPPORT(),
      LINKS.SUPPORT('Kontakt'),
    ],
  },
};
