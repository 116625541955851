<template>
  <input-frame
    id="inactive-border"
    ref="frame"
    :disabled="inputField.disabled || false"
    :error-message="inputField.errorMessage"
    :label="inputField.label"
    :label-size="inputField.labelSize"
    :label-tooltip="inputField.tooltip"
    :label-weight="inputField.labelWeight"
    :links="inputField.links"
    :required="required"
    :reset-button="inputField.resetButton || false"
    @resetModel="clearModel"
  >
    <multiselect
      :key="options.length"
      ref="select"
      :allow-empty="false"
      class="multiselector"
      data-testid="select-input"
      deselect-label=""
      :disabled="disabled"
      label="label"
      :no-options="
        inputField.hasOwnProperty('noOptions') ? inputField.noOptions: 'Keine Auswahlmöglichkeit.'"
      :options="this.inputField.canBeEmpty ? [this.resetField, ...this.options] : this.options"
      :placeholder="inputField.placeholder ? inputField.placeholder.label : 'auswählen'"
      :searchable="searchable"
      select-label=""
      selected-label=""
      tag-placeholder=""
      track-by="value"
      @close="handleClose"
      @input="save"
      @search-change="handleChange"
      v-model="selected"
    >
      <span slot="noOptions">Liste ist leer.</span>
      <span
        v-if="inputField.optionalSelect == true"
        slot="noResult"
        class="text-secondary"
      >
        Ihre Option wird hinzugefügt
      </span>
      <span
        v-else
        slot="noResult"
      >Nichts gefunden.</span>
    </multiselect>
    <vue-table
      v-if="showTable"
      class="border mt-3"
      :columns="tableColumns"
      data-testid="unfolded-table"
      :rows="tableRows"
      :select-options="selectOptions"
      :title="tableTitle"
      @on-selected-rows-change="selectionChanged"
    />
  </input-frame>
</template>

<script>
import InputFrame from '@/components/formInputs/InputFrame.vue';

export default {
  name: 'SelectInput',
  components: {
    'input-frame': InputFrame,
    VueTable: () => import('@/components/VueTable.vue'),
  },
  props: {
    inputField: Object,
    searchable: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
    required: Boolean,
    resetButton: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => ({
        value: null,
        label: '',
        tableData: null,
      }),
    },
  },
  data() {
    return {
      defaultValue: this.value,
      selected: this.value,
      placeholder: this.inputField.placeholder,
      selectedRows: [],
      showTable: false,
      tableTitle: '',
      tableRows: [],
      tableColumns: [],
      selectOptions: {
        enabled: true,
        selectionText: 'ausgewählt',
        clearSelectionText: 'löschen',
      },
      options: [],
      resetField: {
        label: '- - -',
        value: ':reset',
      },
      selectTypedValue: '',
      costAttribute: 'cost_type',
    };
  },
  watch: {
    value() {
      this.selected = this.value;
    },
    tableColumns() {
      this.showTable = false;
      window.setTimeout(() => this.showTable = true, 50);
    },
    inputField: {
      deep: true,
      handler() {
        this.setDefaultsAndPlaceholders();
      },
    },
    selected() {
      if (this.selected) {
        if (this.selected.value === this.resetField.value) {
          this.selected = {};
        }
        if (this.inputField.attributeName === this.costAttribute) {
          this.eventHub.$emit('change-costs-form-type');
        }
      }
    },
  },
  created() {
    this.options = this.inputField.canBeEmpty ? [this.resetField, ...this.inputField.options]
      : this.inputField.options.filter((x) => x.value !== null);

    this.setDefaultsAndPlaceholders();
  },
  methods: {
    setDefaultsAndPlaceholders() {
      let i = null;
      if (this.inputField.defaultValue) {
        if (this.inputField.optionalSelect) {
          this.selected = {
            label: this.inputField.defaultValue,
            value: this.inputField.defaultValue,
          };
        } else {
          this.selected = this.inputField.defaultValue;
        }
        this.save(i);
      }
      // set selected to placeholder
      if (this.inputField.hasOwnProperty('placeholder')) {
        if (this.inputField.placeholder !== null) {
          i = this.inputField.options.findIndex(
            (x) => x.value === this.inputField.placeholder,
          );
          if (this.inputField.options[i]) {
            this.selected = this.inputField.options[i];
            this.save(i);
          }
        }
      }
      if (this.inputField.hasOwnProperty('placeholderIndex')) {
        if (this.inputField.placeholderIndex !== null) {
          this.selected = this.inputField.options[
            this.inputField.placeholderIndex
          ];
          this.save(i);
        }
      }
    },
    closeOptions() {
      this.$refs.select.deactivate();
    },
    fillCurrentlySelectedRows(index) {
      const selected = {};
      if (
        this.inputField.options[index]
                && this.inputField.options[index].hasOwnProperty('currentlySelected')
      ) {
        if (
          JSON.parse(this.inputField.options[index].currentlySelected)
        ) {
          JSON.parse(this.inputField.options[index].currentlySelected).map(
            (x) => {
              selected[x.id] = x;
            },
          );
        }
        for (const i in this.tableRows) {
          if (selected.hasOwnProperty(this.tableRows[i].id)) this.$set(this.tableRows[i], 'vgtSelected', true);
        }
      }
    },
    selectionChanged(params) {
      this.selectedRows = params.selectedRows.map((x) => x.id);
      this.$emit('input', this.selectedRows, this.selected.pointer || this.inputField.pointer);
      this.save();
    },
    async validate() {
      const valid = await this.inputField.validate(this.selected.value, this.inputField.required);
      if (!valid) {
        this.$refs.frame.showErrorMessage();
        this.$refs.select.$el.removeAttribute('id');
        this.$refs.select.$el.id = 'invalid';
        return false;
      }
      this.$refs.frame.hideErrorMessage();
      this.$refs.select.$el.removeAttribute('id');
      this.$refs.select.$el.id = 'inactive-border';
      return true;
    },
    handleChange(query) {
      if (this.inputField.optionalSelect) {
        this.selectTypedValue = query;
      }
      this.save();
    },
    handleClose() {
      if (this.inputField.optionalSelect && this.selectTypedValue) {
        this.selected = {
          label: this.selectTypedValue,
          value: this.selectTypedValue,
        };
      }
    },
    clearModel() {
      this.selected = this.defaultValue;
    },
    save(i = null) {
      if (this.inputField.triggers) {
        if (this.selected) {
          this.$emit(
            'triggered',
            this.selected.value,
            this.inputField.triggers,
          );
        }
      }
      const self = this;

      setTimeout(() => {
        if (self.selected) {
          if (self.selected.unfoldsTable === true) {
            self.tableTitle = self.selected.tableData.title;
            self.tableRows = self.selected.tableData.rows;
            self.tableColumns = self.selected.tableData.columns;
            self.showTable = true;
            self.selectedRows = [];
          } else {
            self.showTable = false;
            self.selectedRows = [];
          }
          if (self.inputField.unfoldsTable && i) self.fillCurrentlySelectedRows(i);

          self.$emit(
            'input',
            self.selectedRows.length > 0 ? [self.selected.value, self.selectedRows]
              : self.selected.value,
            self.selected.pointer,
          );
          self.$emit('input', self.selected.value, self.selected.pointer || self.inputField.pointer);
        } else self.$emit('input', self.selected.value, self.selected.pointer || self.inputField.pointer);
      }, 50);
    },
  },
};
</script>
