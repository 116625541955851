export const AtomsButtonsAlphaMixin = {
  props: {
    type: {
      type: String,
      default: 'primary',
    },

    text: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
