export default {
  searchFn(row, col, cellValue, searchTerm) {
    const term = searchTerm.replace(/\s/g, '').toLowerCase();
    if (cellValue !== null) {
      if (cellValue.hasOwnProperty('router_text')) {
        return (cellValue.router_text || '').toString().toLowerCase().replace(/\s/g, '').includes(term);
      }
      return (cellValue || '').toString().toLowerCase().replace(/\s/g, '').indexOf(term) !== -1;
    }
    return '';
  },
};
