export default {
  recentlyRemovedRows: 'recentlyRemovedRows',
  createActionButton(button, type, tr) {
    const buttonEl = document.createElement('button');
    buttonEl.innerHTML = button.label;
    buttonEl.classList.add('inline-notification__button--action');
    buttonEl.onclick = () => {
      const pr = button.action();
      if (type === 'success') {
        Promise.all([pr]).then(() => {
          tr.remove();
        });
      }
    };
    return buttonEl;
  },
  createCancelButton(cb) {
    const cancelEl = document.createElement('i');
    cancelEl.classList.add('kg');
    cancelEl.classList.add('kg-x');
    cancelEl.classList.add('inline-notification__button--cancel');

    cancelEl.onclick = () => cb();

    return cancelEl;
  },
  createIcon(icons, type) {
    const iconEl = document.createElement('i');
    iconEl.classList.add('kg');
    iconEl.classList.add(`kg-${icons[type]}`);
    iconEl.classList.add(`color-${type}`);
    iconEl.classList.add('inline-notification__icon');
    return iconEl;
  },
  createMessage(message) {
    const messageEl = document.createElement('p');
    messageEl.innerHTML = message;
    messageEl.classList.add('inline-notification__message');
    return messageEl;
  },
  createLink(link) {
    const linkEl = document.createElement('a');
    linkEl.innerHTML = link.label;
    linkEl.setAttribute('href', link.href);
    linkEl.classList.add('inline-notification__message');
    return linkEl;
  },
  createInlineNotification(options, columnCount, id) {
    const {
      type, message, link, button,
    } = options;
    const icons = {
      success: 'check',
      error: 'times',
    };

    const tr = document.createElement('tr');
    const td = document.createElement('td');
    const container = document.createElement('div');
    const messageContainer = document.createElement('div');
    const buttonsContainer = document.createElement('div');

    const buttonEl = this.createActionButton(button, type, tr);
    const cancelEl = this.createCancelButton(() => {
      tr.remove();
      this.removeRowInfo(id);
    });
    const iconEl = this.createIcon(icons, type);
    const messageEl = this.createMessage(message);
    const linkEl = this.createLink(link);

    messageContainer.classList.add('inline-notification__message-container');
    messageContainer.appendChild(iconEl);
    messageContainer.appendChild(messageEl);
    messageContainer.appendChild(linkEl);

    buttonsContainer.classList.add('inline-notification__button');
    buttonsContainer.appendChild(buttonEl);
    buttonsContainer.appendChild(cancelEl);

    container.appendChild(messageContainer);
    container.appendChild(buttonsContainer);
    container.classList.add('inline-notification__container');

    td.classList.add('inline-notification');
    td.appendChild(container);

    td.setAttribute('colspan', columnCount);
    tr.appendChild(td);
    return tr;
  },
  visibleColumnsCount(columns) {
    return columns.filter((col) => !col.hidden).length;
  },
  displayNotification(currentRow, options, columns, id, hasSelectColumn = false, absoluteColCount = null) {
    const undoTime = 25;

    const columnCount = absoluteColCount || this.visibleColumnsCount(columns) + (hasSelectColumn ? 1 : 0);
    const notification = this.createInlineNotification(options, columnCount, id);
    currentRow.parentNode.insertBefore(notification, currentRow);
    if (options.type === 'success') {
      setTimeout(() => {
        this.removeRowInfo(id);
        notification.remove();
      }, undoTime * 1000);
    }
  },
  saveRowInfo(id, info) {
    let payload = {
      [`id_${id}`]: info,
    };
    const currentInfo = JSON.parse(localStorage.getItem(this.recentlyRemovedRows));
    if (currentInfo) {
      payload = { ...payload, ...currentInfo };
    }
    localStorage.setItem(this.recentlyRemovedRows, JSON.stringify(payload));
  },
  removeRowInfo(id) {
    const definedId = `id_${id}`;
    const currentInfo = JSON.parse(localStorage.getItem(this.recentlyRemovedRows));
    const payload = {};
    Object.keys(currentInfo).forEach((key) => {
      if (key !== definedId) {
        payload[definedId] = currentInfo[definedId];
      }
    });
    localStorage.setItem(this.recentlyRemovedRows, JSON.stringify(payload));
  },
  clearSavedInfo() {
    localStorage.removeItem(this.recentlyRemovedRows);
  },
  createFormData(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData[key] = data[key];
    });
    return formData;
  },
};
