<template>
  <div
    v-cy
    :data-testid="`${tableId}-table`"
  >
    <FiltersRow
      v-if="usePagination && !hideFilters"
      :key="currentPath"
      :filters="savedFilters"
      :status-options="statusOptions"
      :table-type="tableType"
      :value="rowsPerPageFilterValue"
      @delete-filter="handleDeleteFilter"
      @update-rows-per-page="handleRowsPerPageChange"
    />
    <vue-good-table
      :key="goodTableKey"
      ref="goodTable"
      class="table mb-0 pb-0 rounded-bottom overflow-hidden"
      :columns="mappedColumns"
      :group-options="{
        enabled: grouped,
        headerPosition: 'top',
      }"
      :pagination-options="pagination"
      :rows="tableRows"
      :search-options="search"
      :select-options="selectOptions ? selectOptions : {}"
      :sort-options="getSortOptions"
      @on-column-filter="saveFilters"
      @on-page-change="onPageChange"
      @on-search="handleSearch"
      @on-selected-rows-change="selectionChanged"
      @on-sort-change="saveSorts"
    >
      <div
        slot="selected-row-actions"
        class="row w-100 p-0 m-0"
      >
        <slot name="actions" />
      </div>
      <template
        slot="table-header-row"
        slot-scope="props"
        class="row w-100"
      >
        <span class="d-block">
          <div
            class="d-inline-block pl-2 custom-line-height"
            v-html="props.row.label"
          />
          <div
            v-if="props.row.button"
            class="d-inline-block float-right basic-button slim ml-auto mr-0"
            @click="emitter(props.row.button.calls, props.row.button.parameter)"
          >
            {{ props.row.button.label }}
          </div>
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <FoldAndExpandSlot
          v-if="props.column.field == 'details'"
          :props="props"
          @emit="displayDetailedInfos"
        />
        <CostSlot
          v-else-if="props.column.field == 'direct_costs'"
          :props="props"
        />
        <BadgeListSlot
          v-else-if="props.column.field == 'badgelist'"
          :props="props"
        />
        <FileSlot
          v-else-if="props.column.field == 'file_path'"
          :props="props"
        />
        <CopyOptionsSlot
          v-else-if="props.column.type == 'copyOptions'"
          :props="props"
        />
        <TenantReadingSlot
          v-else-if="props.column.type == 'tenantReading'"
          :props="props"
          @emitted="emitter"
        />
        <StatusSlot
          v-else-if="
            props.column.field == 'billing_state' ||
              props.column.field == 'cho_state' ||
              props.column.field == 'smoke_detector_state' ||
              props.column.field == 'hardware_state'
          "
          :props="props"
          :with-link="props.column.withLink === undefined ? true : props.column.withLink"
        />
        <ConditionalStatusIcon
          v-else-if="props.column.isConditionalStatusField"
          :props="props"
          :with-link="false"
        />
        <SubStatusIcon
          v-else-if="props.column.substatus"
          :props="props"
        />
        <StatusSlot
          v-else-if="props.column.field == 'status' || props.column.isStatusField"
          :props="props"
          :with-link="false"
        />
        <WithDropdownSlot
          v-else-if="props.formattedRow[props.column.field] && props.formattedRow[props.column.field].dropdown"
          :props="props"
        />
        <WithIconSlot
          v-else-if="props.column.icon"
          :props="props"
        />
        <LinkSlot
          v-else-if="
            props.formattedRow[props.column.field] &&
              (props.formattedRow[props.column.field].hasOwnProperty('router_link') ||
                props.formattedRow[props.column.field].hasOwnProperty('external_link'))
          "
          :props="props"
        />
        <ButtonSlot
          v-else-if="props.column.field == 'button'"
          :props="props"
        />
        <InputSlot
          v-else-if="props.column.type == 'input' || props.column.page === 'new_import'"
          :props="props"
          @selected="onInput"
        />
        <PasswordSlot
          v-else-if="props.column.field == 'password' || props.column.field == 'public_key'"
          :props="props"
        />
        <TextWithTooltipSlot
          v-else-if="props.column.type === 'textWithTooltip'"
          :props="props"
        />
        <DevicesSlot
          v-else-if="props.column.type === 'list'"
          :props="props"
        />
        <ActionsSlot
          v-else-if="props.column.field == 'select' && !(props.column.hidden == true)"
          :columns-count="visibleColumnsCount"
          :props="props"
          :show="false"
          @emitted="emitter"
        />
        <SplitCellSlot
          v-else-if="props.column.type == 'divided'"
          ref="splitCell"
          :name="props.column.field"
          :props="props"
          @emitted="emitter"
        />
        <ChangeIndicatorSlot
          v-else-if="props.column.field == 'change'"
          :props="props"
          @emitted="emitter"
        />
        <EditableInputSlot
          v-else-if="props.column.type == 'editableInput'"
          :props="props"
        />
        <ProgressBarSlot
          v-else-if="props.column.type == 'progressBar'"
          :props="props"
        />
        <GetValueSlot
          v-else-if="props.column.type == 'getValue'"
          :props="props"
        />
        <IconLabelSlot
          v-else-if="props.column.type == 'IconAndLabel'"
          :props="props"
        />
        <SelectSlot
          v-else-if="props.column.field == 'selectInput'"
          :props="props"
        />
        <DatepickerSlot
          v-else-if="props.column.type == 'dateInput'"
          :input-field="props"
          @valueSaved="valueSaved"
        />
        <ChoOptimizationSlot
          v-else-if="props.column.type == 'optimization'"
          :props="props"
        />
        <CountersWithChangeSlot
          v-else-if="props.column.type == 'degree_of_utilisation'"
          :props="props"
        />
        <span
          v-else-if="props.column.field == 'html_message'"
          v-html="props.formattedRow[props.column.field]"
        />
        <span
          v-else-if="
            props.formattedRow[props.column.field] &&
              typeof props.formattedRow[props.column.field].indexOf == 'function' &&
              props.formattedRow[props.column.field].indexOf('<br data-line-break>') > 0
          "
          v-tooltip="props.column.getTooltip ? props.column.getTooltip(props.row) : props.column.tooltip"
          v-html="props.formattedRow[props.column.field]"
        >
          {{ require('../../public/images/theme/portal_kugu/logo.png') }}</span>

        <ListSlot
          v-else-if="props.column.field == 'sources'"
          class="px-3 d-inline-block"
          field="sources"
          :props="props"
        />
        <span
          v-else-if="props.column.field == 'lastest_value_time'"
          v-tooltip="{ content: props.row.sources_cnt, loadingContent: 'Wird geladen...' }"
          class="px-3 d-inline-block cursor-pointer"
        >
          {{ props.row.lastest_value_time.length > 0 ? dateUtils.format(props.row.lastest_value_time, true) : '' }}
        </span>

        <InputSlot
          v-else-if="props.column.type == 'deviceInboxInput'"
          :key="changeListener"
          :ref="`${props.row.device_id}_${props.column.field}`"
          :class="{ 'read-only': readOnlyMode }"
          :label="getLabel(props.row, props.column.field)"
          :options="getOptions(props.column.field, props.row)"
          :props="props"
          :shown="isEditable(props.row, props.column.field != 'property')"
          :tooltip="'Bitte fügen Sie zuerst eine Liegenschaft hinzu'"
          @selected="saveSingleSelect(props.column.field, props.row.device_id, ...arguments)"
        />

        <SaveAndResetSlot
          v-else-if="props.column.field == 'saveAndReset'"
          :props="props"
          :read-only-mode="readOnlyMode"
          @reset="resetSingleSelect"
          @saved="sendSingleSelect"
        />
        <span
          v-else-if="props.column.horizontalPadding"
          v-tooltip="props.column.getTooltip ? props.column.getTooltip(props.row) : props.column.tooltip"
          class="px-3 d-inline-block"
          v-html="props.formattedRow[props.column.field]"
        />

        <span
          v-else-if="props.column.field == 'calibrated_date'"
          v-tooltip="!props.formattedRow[props.column.field].hasDefinedDuration ? props.column.tooltip : null"
        >
          <span v-if="!props.formattedRow[props.column.field].hasDefinedDuration">[</span>
          <span v-html="props.formattedRow[props.column.field].calibrationYear" />
          <span v-if="!props.formattedRow[props.column.field].hasDefinedDuration">]</span>
        </span>
        <span
          v-else
          v-tooltip="props.column.getTooltip ? props.column.getTooltip(props.row) : props.column.tooltip"
          v-html="props.formattedRow[props.column.field]"
        />
      </template>
      <div
        slot="emptystate"
        class="text-center py-2 table-button"
      >
        <div v-if="hasFilters && isFilterable && savedFilters.length">
          <div class="empty-state">
            <atoms-icons icon="kg kg-search" />
            <span class="empty-state__message">{{ emptyStateText }}</span>
          </div>
          <div>
            <LoadingIndicator v-if="loading" />
            <button
              v-else
              class="primary-button"
              @click="returnToRecentSearch"
            >
              Filter zurücksetzten
            </button>
          </div>
        </div>
        <div v-else>
          {{ emptyText }}
        </div>
      </div>
    </vue-good-table>
    <CustomTableFooter
      v-if="usePagination"
      :footer-options="footerOptions"
      @change-page="changeTablePage"
    />
    <TableToggleButton
      v-if="groupedButton"
      :key="`toggle${goodTableKey}`"
      :class="'toggleButton' + _uid"
      :grouped="grouped"
      @toggleTableType="toggleTableType"
    />
    <div
      :key="`action${goodTableKey}`"
      class="action-button"
    >
      <div
        v-if="hideHeader"
        class="action-button__wrapper"
      >
        <h6 class="m-0 font-weight-bold text-primary align-middle action-button__title">
          <span
            v-if="titleExtension && !tableError"
            class="pl-4 font-weight-normal"
            v-html="titleExtension ? titleExtension : ''"
          />
          <span
            v-if="tableError"
            class="pl-4 error font-weight-normal"
            v-html="tableError ? tableError : ''"
          />
        </h6>
        <DatePickerButton
          v-if="datePicker"
          :picked="datePickerDate"
          :tabs="datePickerTabs"
          @datePickerEmit="datePickerEmit"
          @picked="datePicked"
        />
        <button
          v-if="button"
          :class="`${buttonType}-button mr-2 action-button__button`"
          :data-testid="buttonText + '-button'"
          @click="emitButtonClicked"
        >
          <div
            v-if="loadingButton"
            :class="`spinner-border spinner-border-sm text-${buttonType == 'primary' ? 'white' : 'black-300'}`"
            role="status"
          />
          <span
            v-else
            :class="`text-${buttonType === 'primary' ? 'white' : 'black'}`"
          >{{ buttonText }}</span>
        </button>
        <div v-if="groupedButton === true" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import dateUtils from '@/utils/dateUtils';
import WithIconSlot from '@/components/vueTableComponents/WithIconSlot';
import WithDropdownSlot from '@/components/vueTableComponents/WithDropdownSlot';
import CountersWithChangeSlot from '@/components/vueTableComponents/CountersWithChangeSlot';
import IconLabelSlot from '@/components/vueTableComponents/IconLabelSlot';
import TenantReadingSlot from '@/components/vueTableComponents/TenantReadingSlot';
import TableToggleButton from '@/components/TableToggleButton';
import savedTableFilter from '@/utils/savedTableFilter';
import DevicesSlot from '@/components/vueTableComponents/DevicesSlot';
import TextWithTooltipSlot from '@/components/vueTableComponents/TextWithTooltip';
import FiltersRow from '@/components/vueTableComponents/FiltersRow';
import CustomTableFooter from '@/components/vueTableComponents/CustomTableFooter';
import ListSlot from '@/components/vueTableComponents/ListSlot';
import SaveAndResetSlot from '@/components/vueTableComponents/SaveAndResetSlot';
import api from '@/utils/api';
import LoadingIndicator from '@/components/LoadingIndicator';
import ConditionalStatusIcon from '@/components/vueTableComponents/ConditionalStatusIcon';
import SubStatusIcon from '@/components/vueTableComponents/SubStatusIcon';
import utils from '@/utils/utils';
import searching from '@/utils/searching';
import tooltip from '@/utils/tooltip';
import tableOptions from '@/utils/tableOptions';
import ChoOptimizationSlot from '@/components/vueTableComponents/ChoOptimizationSlot';
import CostSlot from '@/components/vueTableComponents/CostSlot';
import BadgeListSlot from '@/components/vueTableComponents/BadgeListSlot';
import CopyOptionsSlot from '@/components/vueTableComponents/CopyOptionsSlot';
import SplitCellSlot from '@/components/vueTableComponents/SplitCellSlot';
import FoldAndExpandSlot from '@/components/vueTableComponents/FoldAndExpandSlot';
import ActionsSlot from '@/components/vueTableComponents/ActionsSlot';
import ChangeIndicatorSlot from '@/components/vueTableComponents/ChangeIndicatorSlot';
import ButtonSlot from '@/components/vueTableComponents/ButtonSlot';
import PasswordSlot from '@/components/vueTableComponents/PasswordSlot';
import ProgressBarSlot from '@/components/vueTableComponents/ProgressBarSlot';
import StatusSlot from '@/components/vueTableComponents/StatusSlot';
import FileSlot from '@/components/vueTableComponents/FileSlot';
import InputSlot from '@/components/vueTableComponents/InputSlot';
import LinkSlot from '@/components/vueTableComponents/LinkSlot';
import GetValueSlot from '@/components/vueTableComponents/GetValueSlot';
import DatePickerButton from '@/components/DatePickerButton';
import EditableInputSlot from '@/components/vueTableComponents/EditableInputSlot';
import SelectSlot from '@/components/vueTableComponents/SelectSlot';
import DatepickerSlot from '@/components/vueTableComponents/DatepickerSlot';
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index';

export default {
  name: 'VueTable',
  components: {
    CostSlot,
    AtomsIcons,
    BadgeListSlot,
    CountersWithChangeSlot,
    ChoOptimizationSlot,
    FoldAndExpandSlot,
    TenantReadingSlot,
    DatePickerButton,
    ActionsSlot,
    ProgressBarSlot,
    InputSlot,
    LinkSlot,
    DatepickerSlot,
    ChangeIndicatorSlot,
    StatusSlot,
    IconLabelSlot,
    ButtonSlot,
    PasswordSlot,
    SplitCellSlot,
    FileSlot,
    CopyOptionsSlot,
    GetValueSlot,
    EditableInputSlot,
    SelectSlot,
    WithIconSlot,
    WithDropdownSlot,
    TableToggleButton,
    FiltersRow,
    CustomTableFooter,
    ListSlot,
    SaveAndResetSlot,
    LoadingIndicator,
    ConditionalStatusIcon,
    DevicesSlot,
    TextWithTooltipSlot,
    SubStatusIcon,
  },
  props: {
    statusOptions: Array,
    unitOptions: Object,
    roomOptions: Array,
    propertyOptions: Array,
    grouped: Boolean,
    groupedButton: Boolean,
    tableError: String,
    rows: Array,
    isFilterable: {
      type: Boolean,
      default: true,
    },
    hideFilters: {
      type: Boolean,
      default: false,
    },
    columns: Array,
    datePickerDate: String,
    datePicker: {
      default: false,
      type: Boolean,
    },
    methodToUpdateToUpdateFooter: {
      type: String,
      default: 'default',
    },
    tableId: {
      default: '',
      type: String,
    },
    datePickerTabs: {
      type: Array,
      default() {
        return ['Gespeichert', 'Durchlaufend'];
      },
    },
    sortOptions: {
      type: Object,
      default: () => ({}),
    },
    selectOptions: Object,
    search: {
      type: Object,
      default: () => ({
        enabled: true,
        placeholder: 'Durchsuchen',
        defaultValue: 'x',
        trigger: 'input',
      }),
    },
    button: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonType: {
      type: String,
      default: 'basic',
    },
    titleExtension: String,
    hideHeader: {
      type: Boolean,
      default: false,
    },
    loadingButton: Boolean,
    tableType: {
      validator: (type) => ['main', 'sidebar'].includes(type),
      default: 'main',
    },
    emptyText: {
      type: String,
      default: 'Keine Einträge',
    },
    emptyStateText: {
      type: String,
      default: 'Entschuldigung, wir konnten keine Geräte finden, die Ihren Suchkriterien entsprechen.',
    },
    usePagination: {
      type: Boolean,
      default: true,
    },
    currentPage: {
      type: Number,
      default: null,
    },
  },

  watch: {
    observeRowCount(val) {
      if (val) {
        this.$emit('count-row-updated', val);
        this.updateFooterOptions();
      }
    },
  },

  data() {
    return {
      loading: false,
      pending: {},
      dateUtils,
      readOnlyMode: false,
      changeListener: false,
      sortIdentifier: '',
      sortField: '',
      sortType: '',
      utils,
      tooltip,
      pagination: tableOptions.pagination,
      goodTableKey: tableOptions.pagination.perPage,
      savedTableFilter,
      observeRowCount: undefined,
      rowsPerPageKey: 'rowsPerPage',
      rowsPerPageFilterValue: null,
      rowsPerPageDefaultValues: {
        main: 50,
        sidebar: 10,
      },
      currentPath: this.$route ? this.$route.path : '',
      savedFilters: [],
      mappedColumns: [],
      footerOptions: {
        prevText: null,
        ofText: null,
        nextText: null,
        rowsCount: null,
        totalRowsCount: null,
        currentPerPage: null,
        currentPage: this.currentPage,
      },
    };
  },
  computed: {
    tableRows() {
      return this.rows.filter((row) => row !== undefined);
    },
    getSortOptions() {
      let finalSortOptions = this.sortOptions;
      if (!this.sortOptions.hasOwnProperty('constantInitialSort')) {
        finalSortOptions = this.sortIdentifier
          ? {
            enabled: true,
            initialSortBy: {
              field: this.sortField,
              type: this.sortType,
            },
          }
          : {
            enabled: true,
          };
      }
      return finalSortOptions;
    },
    actionButtonRef() {
      return document.getElementById('actionButton');
    },
    visibleColumnsCount() {
      return this.mappedColumns.filter((col) => !col.hidden).length;
    },
    hasFilters() {
      return this.mappedColumns.some((col) => col.filterOptions) || this.search.enabled;
    },
  },
  methods: {
    getLabel(row, type) {
      return row[type] ? row[type].label : 'hi';
    },
    toggleTableType(checked) {
      this.$emit('toggleTableType', checked);
      setTimeout(() => {
        this.updateFooterOptions();
      }, 100);
    },
    handleSearch(event) {
      const searchPhrase = event.searchTerm;
      this.observeRowCount = event.rowCount;
      const columnFilters = JSON.parse(localStorage.getItem(`${window.location.pathname}-${this.tableId}-filters`));
      if (searchPhrase && searchPhrase.length > 1) {
        localStorage.setItem(`${window.location.pathname}-${this.tableId}-search`, JSON.stringify(searchPhrase));
        this.updateSavedFiltersRow({
          ...columnFilters,
          searchBar: searchPhrase,
        });
      } else {
        localStorage.setItem(`${window.location.pathname}-${this.tableId}-search`, '');
        this.updateSavedFiltersRow({
          ...columnFilters,
          searchBar: '',
        });
      }
      this.changeTablePage(1);
      setTimeout(() => {
        const totalRowCount = this.$refs.goodTable ? this.$refs.goodTable.totalRowCount : 0;
        this.observeRowCount = this.$refs.goodTable ? this.$refs.goodTable.totalRowCount : 0;
        if (totalRowCount > 0) {
          const stringTemplate = `${window.location.pathname}-${this.tableId}-recent-search`;
          if (searchPhrase && searchPhrase.length > 1) {
            localStorage.setItem(stringTemplate, JSON.stringify(searchPhrase));
          } else {
            localStorage.setItem(stringTemplate, '');
          }
        }
      }, 1000);
    },
    datePicked(v, tabName) {
      this.$emit('picked', v, tabName);
    },
    datePickerEmit(v, functionName, tabName) {
      this.$emit('datePickerEmit', v, functionName, tabName);
    },
    emitButtonClicked() {
      this.$emit('emitButtonClicked');
    },
    onPageChange(value) {
      this.$emit('onPageChange', value);
    },
    onInput(value, column) {
      this.$emit('onInput', value, column);
    },
    showColumn(fieldName) {
      if (this.mappedColumns.length > 0) {
        const i = utils.getIndex(this.mappedColumns, 'field', fieldName);
        this.$set(this.mappedColumns[i], 'hidden', false);
        this.$set(this.mappedColumns, i, this.mappedColumns[i]);
      }
    },
    unselectAllInternal() {
      this.$refs.goodTable.unselectAllInternal();
    },

    hideColumn(fieldName) {
      if (this.mappedColumns.length > 0) {
        const i = utils.getIndex(this.mappedColumns, 'field', fieldName);
        this.$set(this.mappedColumns[i], 'hidden', true);
        this.$set(this.mappedColumns, i, this.mappedColumns[i]);
      }
    },
    selectionChanged(params) {
      this.$emit('on-selected-rows-change', params);
    },
    displayDetailedInfos(item, event, name) {
      this.$emit('clicked', item, event, name);
    },
    emitter(functionName, rowInfo, parameters = null) {
      this.$emit('emitter', functionName, rowInfo, parameters);
    },
    valueSaved(value, row) {
      this.$emit('valueSaved', value, row);
    },
    saveFilters(filters) {
      const { columnFilters } = filters;
      localStorage.setItem(`${window.location.pathname}-${this.tableId}-filters`, JSON.stringify(columnFilters));
      const searchFilter = JSON.parse(localStorage.getItem(`${window.location.pathname}-${this.tableId}-search`));
      this.updateSavedFiltersRow(
        searchFilter
          ? {
            ...columnFilters,
            searchBar: searchFilter,
          }
          : columnFilters,
      );
      this.$emit('filtered', filters);
      setTimeout(() => {
        const totalRowCount = this.$refs.goodTable ? this.$refs.goodTable.totalRowCount : 0;
        if (totalRowCount > 0) {
          const stringTemplate = `${window.location.pathname}-${this.tableId}-recent-filters`;
          localStorage.setItem(stringTemplate, JSON.stringify(columnFilters));
        }
      }, 100);
    },
    saveSorts(item) {
      this.$emit('sorted', item);
    },
    addCosts() {
      this.$emit('addCosts');
    },
    updateRowsPerPageValue(newValue) {
      if (!this.$refs.goodTable) return;
      this.$refs.goodTable.$options.propsData.paginationOptions.perPage = newValue;
      this.goodTableKey = newValue;
    },
    getSavedRowsPerPageValue(key) {
      const savedRowsPerPageValue = this.savedTableFilter.get(key, this.tableId);
      return savedRowsPerPageValue ? parseInt(savedRowsPerPageValue) : savedRowsPerPageValue;
    },
    saveRowsPerPageValue(key, newValue) {
      const currentRowsPerPageValue = this.getSavedRowsPerPageValue(key);
      if (currentRowsPerPageValue !== newValue) {
        this.savedTableFilter.save(key, this.tableId, newValue);
      }
    },
    updateRowsPerPageFilterValue(newValue) {
      this.rowsPerPageFilterValue = {
        label: `${newValue}`,
        value: newValue,
      };
    },
    handleRowsPerPageChange(newValue) {
      if (!this.$refs.goodTable) return;
      const currentRowsPerPageValue = this.$refs.goodTable.$options.propsData.paginationOptions.perPage;
      if (currentRowsPerPageValue !== newValue) {
        this.updateRowsPerPageValue(newValue);
        this.saveRowsPerPageValue(this.rowsPerPageKey, newValue);
      }
      this.changeTablePage(1);
    },
    manageRowsPerPageFilter() {
      if (!this.usePagination) return;
      const currentSavedRowsPerPageValue = this.getSavedRowsPerPageValue(this.rowsPerPageKey);
      const newValue = currentSavedRowsPerPageValue || this.rowsPerPageDefaultValues[this.tableType];
      this.updateRowsPerPageValue(newValue);
      this.updateRowsPerPageFilterValue(newValue);
    },
    mountAdditionalButtons() {
      if (this.groupedButton) {
        if (this.groupedButton) {
          const search = document.querySelectorAll('.vgt-global-search__input.vgt-pull-left');
          const toggleTableButton = document.querySelectorAll(`.toggleButton${this._uid}`);
          search.forEach((searchBar) => searchBar.insertBefore(toggleTableButton[0], searchBar.firstChild));
          toggleTableButton.forEach((item, index) => {
            if (index > 0) item.remove();
          });
        }
      }
      if (this.hideHeader) {
        const searchBarList = document.querySelectorAll('.vgt-global-search');
        const actionButtonList = document.querySelectorAll('.action-button');
        searchBarList.forEach((searchBar, index) => searchBar.appendChild(actionButtonList[index]));
      }
    },
    handleDeleteFilter(field) {
      const savedFilters = localStorage.getItem(`${window.location.pathname}-${this.tableId}-filters`);
      if (savedFilters) {
        const parsedFilters = JSON.parse(savedFilters);
        this.savedFilters = Object.keys(parsedFilters).map((key) => {
          this.mappedColumns = this.mappedColumns.map((col) => {
            if (col.field === key && col.filterOptions) {
              col.filterOptions.filterValue = parsedFilters[key];
            }
            if (col.field === field && col.filterOptions) {
              col.filterOptions.filterValue = '';
            }
            return col;
          });
        });
      }
      if (field === 'searchBar' && this.$refs.goodTable) {
        setTimeout(() => {
          this.$refs.goodTable.globalSearchTerm = ' ';
          this.savedFilters = this.savedFilters.filter((savedFilter) => savedFilter.field !== field);
          setTimeout(() => {
            this.$refs.goodTable.globalSearchTerm = null;
          }, 100);
        }, 100);
      }
      this.changeTablePage(1);
    },
    updateSavedFiltersRow(dataObject) {
      this.savedFilters = Object.keys(dataObject)
        .map((key) => ({
          field: key,
          value: dataObject[key],
        }))
        .filter(({ value }) => value !== '');
      this.changeTablePage(1);
    },
    returnToRecentSearch() {
      this.loading = true;
      setTimeout(() => {
        const savedSearch = localStorage.getItem(`${window.location.pathname}-${this.tableId}-recent-search`);
        if (savedSearch || savedSearch === '') {
          const parsedSearch = savedSearch ? JSON.parse(savedSearch) : '';
          if (this.$refs.goodTable) this.$refs.goodTable.globalSearchTerm = parsedSearch;
          this.savedFilters = this.savedFilters.filter((savedFilter) => savedFilter.field !== 'searchBar');
        }
        const recentdFilters = localStorage.getItem(`${window.location.pathname}-${this.tableId}-recent-filters`);
        if (recentdFilters) {
          const parsedFilters = JSON.parse(recentdFilters);
          this.savedFilters = Object.keys(parsedFilters).map((key) => {
            this.mappedColumns = this.mappedColumns.map((col) => {
              if (col.field === key && col.filterOptions) {
                col.filterOptions.filterValue = parsedFilters[key];
              }
              return col;
            });
          });
        }
        if (!savedSearch && savedSearch !== '' && !recentdFilters) {
          if (this.$refs.goodTable) this.$refs.goodTable.globalSearchTerm = '';
          this.savedFilters = {};
        }
        this.loading = false;
      }, 100);
    },
    updateFooterOptions() {
      if (!this.usePagination) return;
      const table = this.$refs.goodTable;
      if (table) {
        const processedRows = table.processedRows;
        const groupedRow = processedRows.map((row) => row.children.length);
        const groupedRowCount = groupedRow.length;
        const methodsForTotalCounts = {
          default: () => groupedRow.reduce((prev, next) => (prev += next), 0) + groupedRowCount,
          hardware: () => groupedRow.reduce((prev, next) => (prev += next), 0) + groupedRowCount,
        };
        const totalCount = methodsForTotalCounts[this.methodToUpdateToUpdateFooter]();
        const func = () => (processedRows.length > 0 ? processedRows[0].children.length : 0);
        const methods = {
          default: () => (this.grouped ? totalCount : func()),
          hardware: () => (this.grouped ? totalCount : func()),
        };

        this.footerOptions = {
          prevText: table.prevText,
          ofText: table.ofText,
          nextText: table.nextText,
          rowsCount: methods[this.methodToUpdateToUpdateFooter](),
          totalRowsCount: table.rows.length,
          currentPerPage: table.$options.propsData.paginationOptions.perPage,
          currentPage: table.currentPage,
        };
      }
    },

    forceChangePage(page) {
      this.$refs.goodTable.changePage(page);
      this.updateFooterOptions();
    },

    changeTablePage(newPage) {
      const table = this.$refs.goodTable;
      if (table) {
        this.$emit('custom-change-page', newPage);
        table.changePage(newPage);
      }
      setTimeout(() => {
        this.updateFooterOptions();
      }, 100);
    },
    saveSingleSelect(type, device_id, value) {
      const i = utils.getIndex(this.rows, 'device_id', device_id);
      if (type === 'property') {
        if (this.rows[i].existing === 1) {
          this.rows[i].property_changed = true;
        }
        this.resetUnitAndRoom(i);
      }
      const row = this.rows[i];
      row[type] = value;
      Vue.set(this.pending, device_id, row); // Save the selection as pending but not sent yet
      this.rows[i].saveAndReset = true; // show buttons in last column
      this.$refs[`${device_id}_${type}`].setValue(value);
      Vue.set(this.selectOptions, 'enabled', false); // hide checkboxes
    },
    sendSingleSelect(prop) {
      const i = utils.getIndex(this.rows, 'device_id', prop.row.device_id);
      this.rows[i].saveAndReset = false;
      const formData = [this.getFormDataEntry(this.rows[i])];
      const currentRow = document.querySelectorAll('tbody tr')[i];

      api.put(`device-inbox/property/${this.rows[i].property.value}`, formData, null, null, () => {
        const device = formData[0];

        const options = {
          type: 'success',
          message: `${device.id} wurde zugeordnet.`,
          link: {
            label: `Geräteübersicht für ${this.rows[i].property.label}`,
            href: `#/property/${this.rows[i].property.value}/hardware`,
          },
          button: {
            label: 'Rückgängig machen',
            action: () => this.undoAssignment(device.id, i),
          },
        };

        if (device.room) {
          this.inlineNotifications.saveRowInfo(device.id, device);
          this.inlineNotifications.displayNotification(currentRow, options, [...this.columns, {}], device.id);
          this.assignedDevices.push(this.rows[i]);
          /* eslint-disable */
          this.rows.splice(i, 1);
          /* eslint-enable */
        }
      });

      Vue.set(this.selectOptions, 'enabled', true);
      Vue.set(this.rows, 0, this.rows[0]);
      delete this.pending[prop.row.device_id];
    },
    resetSingleSelect(prop) {
      Vue.delete(this.pending, prop.row.device_id);
      const i = this.rows.findIndex((obj) => obj.device_id === prop.row.device_id);
      Vue.set(this.rows[i], 'room', {
        value: null,
        label: 'Zuweisen',
      });
      Vue.set(this.rows[i], 'unit', {
        value: null,
        label: 'Zuweisen',
      });
      Vue.set(this.rows[i], 'property', {
        value: null,
        label: 'Zuweisen',
      });
      /*  eslint-disable */
      this.rows[i].saveAndReset = false; // hide edit buttons
      /* eslint-enable */
      Vue.set(this.selectOptions, 'enabled', true);
      this.changeListener = !this.changeListener;
    },
    getFormDataEntry(rowData) {
      return {
        id: rowData.device_id ? rowData.device_id : null,
        existing: rowData.existing,
        geraete_id: rowData.geraete_id ? rowData.geraete_id : null,
        property_changed: rowData.property_changed,
        type: rowData.type,
        property: rowData.property.value ? parseInt(rowData.property.value) : null,
        unit: rowData.unit.value ? parseInt(rowData.unit.value) : null,
        room: rowData.room.value ? parseInt(rowData.room.value) : null,
      };
    },
    getSourcesCnt(row) {
      return row.sources_cnt;
    },
    isEditable(row, checkEditability) {
      if (checkEditability) {
        let a;
        if (this.pending) {
          a = this.pending[row.id] ? this.pending[row.id].property.value !== null : false;
        }
        const b = row.property ? row.property.value !== null : false;
        return a || b;
      }
      return true;
    },
    resetUnitAndRoom(i) {
      this.changeListener = !this.changeListener;
      Vue.set(this.rows[i], 'room', {
        value: null,
        label: 'Zuweisen',
      });
      Vue.set(this.rows[i], 'unit', {
        value: null,
        label: 'Zuweisen',
      });
    },
    getOptions(type, row) {
      if (type === 'room') {
        return this.roomOptions;
      }
      if (type === 'unit') {
        return this.getUnitOption(row);
      }
      if (type === 'property') {
        return this.propertyOptions;
      }
      return undefined;
    },
    getUnitOption(row) {
      const i = utils.getIndex(this.rows, 'device_id', row.id);
      let propId = null;
      if (row.id && this.pending[row.id]) {
        propId = this.pending[row.id].property.value;
      } else {
        propId = this.rows[i].property.value;
      }
      return (
        this.unitOptions[propId] || [
          {
            value: null,
            label: 'Keine Nutzeinheiten vorhanden',
          },
        ]
      );
    },
  },
  created() {
    this.mappedColumns = this.columns;
    /* eslint-disable */
    this.search.searchFn = searching.searchFn;
    /* eslint-enable */
    const self = this;
    if (this.$route) {
      const pageName = window.location.pathname;
      self.sortIdentifier = typeof localStorage.getItem(pageName) === 'string'
        ? JSON.parse(localStorage.getItem(pageName))
        : localStorage.getItem(pageName);

      self.sortField = self.sortIdentifier ? self.sortIdentifier[0].field : null;
      self.sortType = self.sortIdentifier ? self.sortIdentifier[0].type : null;
    }
    window.onbeforeunload = () => {
      this.$destroy();
    };
  },
  updated() {
    this.mountAdditionalButtons();
  },
  mounted() {
    this.updateFooterOptions();
    this.manageRowsPerPageFilter();
    this.mountAdditionalButtons();

    this.$watch(
      () => {
        if (!this.$refs.goodTable) return null;
        return this.$refs.goodTable.globalSearchTerm || null;
      },
      (val) => {
        const searchTerm = JSON.stringify(val);
        localStorage.setItem(`${window.location.pathname}-${this.tableId}-search`, searchTerm);
      },
    );

    const savedSearch = localStorage.getItem(`${window.location.pathname}-${this.tableId}-search`);
    setTimeout(() => {
      if (savedSearch) {
        const parsedSearch = JSON.parse(savedSearch);
        if (this.$refs.goodTable) this.$refs.goodTable.globalSearchTerm = parsedSearch;
        this.updateFooterOptions();
      }
    }, 100);

    const savedFilters = localStorage.getItem(`${window.location.pathname}-${this.tableId}-filters`);
    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);
      this.savedFilters = Object.keys(parsedFilters).map((key) => {
        this.mappedColumns = this.mappedColumns.map((col) => {
          if (col.field === key && col.filterOptions) {
            col.filterOptions.filterValue = parsedFilters[key];
          }
          return col;
        });
        return {
          field: key,
          value: parsedFilters[key],
        };
      });
    }
  },
};
</script>

<style lang="scss">
.hide-checkbox-select {
  display: none;
}

.action-button {
  display: flex;
  justify-content: flex-end;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__title {
    padding-right: 16px;
  }
}

.error {
  font-size: 14px;
  color: var(--danger);
}

.empty-state {
  margin-bottom: 16px;

  &__message {
    margin-left: 12px;
  }
}
.float-right {
  float: right !important;
}
.custom-line-height {
  line-height: 32px;
}
</style>
