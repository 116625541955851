<template>
  <div
    v-if="showPanel"
    v-cy
    class="panel__wrapper"
  >
    <div class="panel__grid">
      <h3 class="panel__header">
        Rollen
      </h3>
      <button
        v-cy="`button--Alle-Rollen`"
        :class="['panel__role-button', { 'panel__role-button--active': currentRoles.includes(allRolesButton) }]"
        @click="handleAllRolesChange(allRolesButton)"
      >
        {{ allRolesButton }}
      </button>
      <button
        v-for="role in avaibleRoles"
        :key="role.name"
        v-cy="`button--${role.name}`"
        :class="['panel__role-button', { 'panel__role-button--active': currentRoles.includes(role.name) }]"
        @click="handleRoleChange(role.name)"
      >
        {{ role.name }}
      </button>
    </div>
    <hr>
  </div>
</template>

<script>
import constants from '@/utils/constants';

export default {
  name: 'RoleSwitchPanel',
  data() {
    return {
      avaibleRoles: [],
      currentRoles: '',
      allRolesButton: constants.staticRoles.allRoles,
      currentRolesPath: `${window.keycloak.tokenParsed.email}-currentPortalRole`,
      forbiddenRoles: [
        'Schreib-Berechtigung',
        'Lesezugriff auf alle Liegenschaften',
      ],
    };
  },
  computed: {
    showPanel() {
      return this.avaibleRoles.length;
    },
  },
  watch: {
    currentRoles() {
      this.saveCurrentRoles(this.currentRoles);
      this.$emit('change-role', this.currentRoles.join(', '));
      this.eventHub.$emit('change-role');
    },
  },
  methods: {
    getAllAvaibleRolesFormatted() {
      /* eslint-disable */
      this.avaibleRoles = window.keycloak.tokenParsed.resource_access['kugu-portal']
        .roles
        .filter((role) => !this.forbiddenRoles.includes(role))
        .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
        .map((role) => ({ name: role }));
      /* eslint-enable */
    },
    checkCurrentRole() {
      const avaibleRoles = [...this.avaibleRoles.map(({ name }) => name), this.allRolesButton];
      this.currentRoles.forEach((currentRole) => {
        if (!avaibleRoles.includes(currentRole)) {
          this.handleRoleChange(currentRole);
        }
      });
    },
    saveCurrentRoles(currentRoles) {
      localStorage.setItem(this.currentRolesPath, JSON.stringify(currentRoles));
    },
    getCurrentRoles() {
      if (!window.keycloak.hasResourceRole('Alpha-Feature', 'kugu-portal')) {
        this.handleAllRolesChange(this.allRolesButton);
        return;
      }
      const currentRoles = JSON.parse(localStorage.getItem(this.currentRolesPath));
      const setCurrentRole = (currentRoles instanceof Array && currentRoles.length) ? currentRoles : [];
      this.currentRoles = currentRoles ? setCurrentRole : [this.allRolesButton];
    },
    handleRoleChange(roleName) {
      const currentDetailRoles = this.currentRoles.filter((role) => role !== this.allRolesButton);
      if (this.currentRoles.includes(roleName)) {
        this.currentRoles = currentDetailRoles.filter((role) => role !== roleName);
      } else {
        this.currentRoles = [...currentDetailRoles, roleName];
      }
    },
    handleAllRolesChange(roleName) {
      this.currentRoles = [roleName];
    },
    checkRoleToken() {
      const currentRoleExpireToken = localStorage.getItem('roleExpireToken');
      const currentTime = new Date().getTime();
      const tokenExpireTimeInHours = 24;
      const tokenExpireTime = currentTime + tokenExpireTimeInHours * 60 * 60 * 1000;
      if (!currentRoleExpireToken || currentRoleExpireToken < currentTime) {
        this.handleAllRolesChange('Alle Rollen');
        localStorage.setItem('roleExpireToken', tokenExpireTime);
      } else {
        localStorage.setItem('roleExpireToken', tokenExpireTime);
      }
    },
  },
  created() {
    this.getAllAvaibleRolesFormatted();
    this.eventHub.$on('resetRoles', () => this.handleAllRolesChange(this.allRolesButton));
  },
  mounted() {
    this.getCurrentRoles();
    this.checkCurrentRole();
    this.checkRoleToken();
  },
};
</script>

<style lang="scss" scoped>
.panel {
  &__wrapper {
    padding: 0 16px;
  }
  &__grid {
    display: grid;
    grid-row-gap: 16px;
    line-height: 1;
  }
  &__header {
    color: var(--neutral-700);
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 4px 0 0 0;
  }
  &__role-button {
    background: transparent;
    text-align: left;
    border: none;
    padding: 0;
    color: var(--neutral-800);
    font-weight: 14px;
    &--active {
      font-weight: 600;
      color: var(--primary);
    }
  }
}
</style>
