export default {
  props: {
    theme: {
      type: String,
      default: '',
    },
  },

  computed: {
    blockClassName() {
      return this.$options.name;
    },
  },

  methods: {
    childrenClassName(childrenName, modifiers = []) {
      const baseClass = `${this.blockClassName}__${childrenName}`;

      if (!Array.isArray(modifiers)) {
        return `${baseClass} ${baseClass}--${modifiers}`;
      }

      return modifiers.length === 0
        ? `${baseClass}`
        : `${baseClass} ${modifiers.map((modifier) => `${baseClass}--${modifier}`).join(' ')}`;
    },
  },
};
