import moment from 'moment';
import constants from './constants';
import dateUtils from './dateUtils';

export default {
  forHardwareOverview(deviceOverview) {
    let tooltip = '';
    if (!deviceOverview) return 'Keine Geräte';
    for (const [k, v] of Object.entries(deviceOverview)) {
      tooltip += `${k} : ${v.received}/${v.expected}<br>`;
    }
    return tooltip;
  },
  forDeviceIcon(device) {
    return `<b class="text-white">${constants.deviceTypes[device.type_id] || 'Unbekannt'}</b><br>
        Gerätenummer: ${device.oms_id}<br>
        Lage: ${device.location}<br>
        Empfangene Nachrichten: ${device.message_counts || 0}<br>
        Letzte Nachricht: ${moment(dateUtils.formatToDateString(device.time_of_last_message)).format('DD.MM.YYYY')}`;
  },

};
