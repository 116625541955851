import { getChartGroupPeriod } from '@/utils/getChartPeriod';
import { globalStorage } from '@/main.js';
import moment from 'moment';
import api from './api';

export default {
  getYesterdayDate() {
    return new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  },
  dateToYYMMDDFormatString(date) {
    return date.toISOString().slice(0, 19).replace('T', ' ');
  },
  dateFromYYMMDDFormatString(date) {
    const dateTimeParts = date.split(/[- :]/);
    dateTimeParts[1]--;
    return new Date(...dateTimeParts);
  },
  isToday(someDate) {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate()
      && someDate.getMonth() === today.getMonth()
      && someDate.getFullYear() === today.getFullYear()
    );
  },
  getDateRepresentation(option) {
    const format = function (date) {
      const fullDate = new Date(Date.parse(date));
      const year = fullDate.getFullYear().toString().substr(-2);
      return `${fullDate.getDate()}.${fullDate.getMonth() + 1}.${year}`;
    };
    return `${format(option.from)} - ${format(option.to)}`;
  },
  compareDates(date1, date2) {
    if (date1 instanceof Date && date2 instanceof Date) {
      const sameYear = date1.getFullYear() === date2.getFullYear();
      const sameMonth = date1.getMonth() === date2.getMonth();
      const sameDay = date1.getDate() === date2.getDate();
      if (sameDay && sameMonth && sameYear) return true;
      return false;
    }
    throw new Error("Parameters are not both of type 'Date'");
  },
  formatForForm(datestring) {
    const ddmmyy = datestring.split('.');
    return `${ddmmyy[1]}.${ddmmyy[0]}.${ddmmyy[2]}`;
  },
  pointsToDashDate(date) {
    const array = date.split('.');
    return `${array[2]}-${array[1]}-${array[0]}`;
  },
  formatToDateString(date, withTime = false) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    let time = '';
    if (withTime) {
      const h = d.getHours();
      const m = d.getMinutes();
      const s = d.getSeconds();
      time = ` ${h > 9 ? h : `0${h}`}:${m > 9 ? m : `0${m}`}:${s > 9 ? s : `0${s}`}`;
    }
    return [year, month, day].join('-') + time;
  },
  getYearFromDate(dateString) {
    if (dateString) {
      return dateString.split('-')[0];
    }
    return '';
  },
  getFromTo(from, to) {
    return this.format(from) + (to ? ' - ' : '') + this.format(to);
  },
  isValidDate(d) {
    return d instanceof Date && !Number.isNaN(d);
  },
  germanFormToDate(d) {
    if (d) {
      const segments = d.split('.');
      const date = new Date(`${segments[2]}-${segments[1]}-${segments[0]}`);
      if (Number.isNaN(date.getTime())) return null;
      return date;
    }
    return '';
  },
  parseDate(dateString) {
    const dateTime = dateString.split(' ');
    const dateParts = dateTime[0].split('.');
    const time = dateTime[1];
    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${time}`;
  },
  format(dateString, withTime = false) {
    if (dateString && typeof dateString === 'object') dateString = this.formatToDateString(dateString, true);
    if (dateString) {
      // if (dateString.includes('Z')) dateString = dateString.replace('Z', ' ')
      if (dateString.includes('T')) dateString = dateString.replace('T', ' ');
      const specs = { year: 'numeric', month: '2-digit', day: '2-digit' };
      if (withTime) {
        specs.hour = '2-digit';
        specs.minute = '2-digit';
      }
      return new Date(dateString).toLocaleString('de-DE', specs);
    }
    return '';
  },
  getDifferenceBetweenDatesInDays(datesObject) {
    const { min, max } = datesObject;
    const dayRepresentationInMilliseconds = 24 * 60 * 60 * 1000;
    const minDate = min / dayRepresentationInMilliseconds;
    const maxDate = max / dayRepresentationInMilliseconds;
    const dayDifference = Math.round(maxDate - minDate);
    return dayDifference;
  },
  getDifferenceBetweenDatesInMinuteFormat(datesObject) {
    const dayDifference = this.getDifferenceBetweenDatesInDays(datesObject);
    const minuteFormatDifference = getChartGroupPeriod(dayDifference);
    return minuteFormatDifference;
  },
  formatDateToMySQL(date) {
    const newDate = new Date(date);
    const strHour = date.twoDigitsFormat(newDate.getHours());
    const strMinute = date.twoDigitsFormat(newDate.getUTCMinutes());
    const strSecond = date.twoDigitsFormat(newDate.getSeconds());
    const strYear = newDate.getFullYear();
    const strMonth = this.twoDigitsFormat(`${newDate.getMonth() + 1}`);
    const strDay = this.twoDigitsFormat(`${newDate.getDate()}`);
    const strTime = `${strHour}:${strMinute}:${strSecond}`;
    const strDate = [strYear, strMonth, strDay].join('-');

    return `${strDate}T${strTime}Z`;
  },
  generateHeatingPeriods(years, group = '1d') {
    return years.map((year) => ({
      label: `Heizperiode ${year}/${year + 1}`,
      value: {
        start: `${year}-10-01T00:00:00`,
        end: `${year + 1}-04-30T23:59:59`,
        group,
      },
    }));
  },
  toDBFormat(d, withTime = true) {
    const format = (x) => (x < 10 ? `0${x}` : `${x}`);
    const date = `${d.getFullYear()}-${format(d.getMonth() + 1)}-${format(d.getDate())}`;
    const time = `T${format(d.getHours())}:${format(d.getMinutes())}:${format(d.getSeconds())}`;
    return withTime ? date + time : date;
  },
  generateMonths(years, group = '180m') {
    const months = [];
    years.forEach((year) => {
      [...Array(12).keys()].reverse().forEach((month) => {
        const start = new Date(year, month, 1, 0, 0, 0);
        const lastDay = new Date(year, month + 1, 0, 0, 0, 0).getDate();
        const end = new Date(year, month, lastDay, 23, 59, 59);
        months.push({
          label: `${start.toLocaleString('de-DE', { month: 'short' })} ${year}`,
          value: {
            start: this.toDBFormat(start),
            end: this.toDBFormat(end),
            group,
          },
        });
      });
    });
    return months;
  },
  generateQuarters(years, group = '6h') {
    const quarters = [];
    years.forEach((year) => {
      [4, 3, 2, 1].forEach((quarter) => {
        const startOfQuarter = quarter * 3 - 2 < 10 ? `0${quarter * 3 - 2}` : quarter * 3 - 2;
        const endOfQuarter = quarter * 3 < 10 ? `0${quarter * 3}` : quarter * 3;
        const lastDayOfQuarter = endOfQuarter === 3 || endOfQuarter === 12 ? 31 : 30;

        quarters.push({
          label: `Q${quarter} ${year}`,
          value: {
            start: `${year}-${startOfQuarter}-01T00:00:00`,
            end: `${year}-${endOfQuarter}-${lastDayOfQuarter}T23:59:59`,
            group,
          },
        });
      });
    });
    return quarters;
  },
  generateYears(years, group = '1d') {
    return years.map((year) => ({
      label: `${year}`,
      value: {
        start: `${year}-01-01T00:00:00`,
        end: `${year}-12-31T23:59:59`,
        group,
      },
    }));
  },
  generatePeriodOptions(types = ['Heizperiode', 'Jahr', 'Quartal', 'Monat']) {
    const mapping = {
      Heizperiode: 'HeatingPeriod',
      Quartal: 'Quarter',
      Jahr: 'Year',
      Monat: 'Month',
    };
    const lastFiveYears = [...Array(5).keys()].map((x) => new Date().getFullYear() - x);
    const periods = {};
    types.forEach(
      (t) => (periods[t] = this[`generate${mapping[t]}s`](lastFiveYears).filter(
        (option) => new Date(option.value.start) < new Date(),
      )),
    );
    return periods;
  },
  addNDays(oldDate, n) {
    const d = new Date(oldDate);
    return new Date(d.getFullYear(), d.getMonth(), d.getDate() + n);
  },
  getLastMonth(nullIndexed = false) {
    // getMonth() is by definition nullindexed, e.g. jan = 0, feb = 1 etc.
    // this method returns a not nullindexed month by default

    const currentMonth = new Date().getMonth();
    const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    return nullIndexed ? lastMonth : lastMonth + 1;
  },
  getLastMonthName() {
    const lastMonth = this.getLastMonth();
    return moment(lastMonth, 'M').locale('de').format('MMMM');
  },
  getDropdownOptionsForLastNYears(n) {
    const d = new Date();
    const lastYears = Array.from(Array(n).keys()).map((i) => d.getFullYear() - i);
    return lastYears.map((year) => ({ label: year, value: year }));
  },
  getDropdownOptionsForMonths(format = 'long') {
    const formatMonth = (month) => {
      const d = new Date(2000, month);
      return d.toLocaleString('de-DE', { month: format });
    };
    return Array.from(Array(12).keys()).map((i) => ({ label: formatMonth(i), value: i + 1 }));
  },
  async getPeriods(callback = () => {}, scope = 'unscoped') {
    // in case periods have already been retrieved for this scope
    // get from globalstorage

    if (globalStorage.periods && globalStorage.periods[scope]) {
      callback(globalStorage.periods[scope]);
    } else {
      const params = {
        scope,
      };
      await api.get('periods', params, (periods) => {
        if (!globalStorage.periods) globalStorage.periods = {};
        globalStorage.periods[scope] = periods;
        callback(periods);
      });
    }
  },
  toUTC(localTime) {
    function padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    }
    const d = new Date(localTime);
    const t = [
      padTo2Digits(d.getUTCHours()),
      padTo2Digits(d.getUTCMinutes()),
      padTo2Digits(d.getUTCSeconds()),
    ].join(':');
    const date = d.toISOString().slice(0, 10);
    return `${date}T${t}`;
  },

  getformattedDate(format, date) {
    return moment(date).locale('de').format(format);
  },
  generateMaxRange(date, type) {
    return moment(date).add(1, type).valueOf();
  },
  getDateWithformat(type, date, count) {
    return {
      min: moment(date).subtract(count, type).valueOf(),
      max: this.generateMaxRange(date, type),
    };
  },
  getDateWithformatForQuarter(date, count) {
    return {
      min: moment(date).subtract(count, 'Q').valueOf(),
      max: this.generateMaxRange(date, 'Q'),
    };
  },

  getDateWithformatForHeatingPeriod(date, count) {
    const selectedYear = moment(date).format('YYYY');
    const years = [];
    for (let year = 2023; year >= selectedYear - count; year--) {
      years.push(year);
    }
    const minYears = this.generateHeatingPeriods(years).pop();
    const maxYears = this.generateHeatingPeriods(years).shift();
    return {
      min: moment(minYears.value.start).valueOf(),
      max: moment(maxYears.value.start).valueOf(),
    };
  },

  getDatetimeByTimeDelta(type, date, count) {
    const actions = {
      month: () => this.getDateWithformat(type, date, count),
      year: () => this.getDateWithformat(type, date, count),
      'heating-period': () => this.getDateWithformatForHeatingPeriod(date, count),
      quarter: () => this.getDateWithformatForQuarter(date, count),
      /* eslint-disable */
      week: () => this.getDateWithformat(type, date, count),
      /* eslint-enable */
    };
    return actions[type]();
  },
  /* eslint-disable */
  format(value, appendTime = false, dateFormat = "DD.MM.YYYY") {
    if (appendTime) dateFormat += " HH:mm"
    return value !== null ? moment(value).locale('de').format(dateFormat) : null;
  },
  /* eslint-enable */
  formatBasedOnType(type, value) {
    const actions = {
      month: () => this.format(value, false, 'MMMM'),
      year: () => this.format(value, false, 'YYYY'),
      /* eslint-disable */
      'heating-period': () => `Heizperiode ${this.format(value, false, 'YYYY')}/${moment(value).add(1, 'year').format('YYYY')}`,
      /* eslint-enable */
      quarter: () => this.format(value, false, '[Q]Q-Y'),
      week: () => `W${this.format(value, false, 'ww')}`,
    };
    return actions[type]();
  },

};
