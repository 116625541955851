import Vue from 'vue';
import VueGtagPlugin, { bootstrap } from 'vue-gtag';
import Router from '@/router';

const gtagID = 'G-D4DJ88NEDC';
const disableStr = `ga-disable-${gtagID}`;

Vue.use(
  VueGtagPlugin,
  {
    bootstrap: false,
    config: { id: gtagID },
  },
  Router,
);

const cookieconsentConfig = () => {
  const checkCookie = (string) => document.cookie.indexOf(string) > -1;
  const handleGtagCookie = (flag) => {
    document.cookie = `ga-disable-${gtagID}=${flag}; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;
    window[disableStr] = flag;
  };

  const toggleOptInOut = (type) => {
    if (type) {
      if (checkCookie(disableStr)) handleGtagCookie(false);
    } else if (!checkCookie(`${disableStr}=true`)) handleGtagCookie(true);
  };
  return {
    type: 'opt-out',
    palette: {
      popup: { background: '#fff' },
      button: { background: '#000' },
    },
    content: {
      /* eslint-disable */
			message:
				"Wir möchten die Nutzung unseres Portals besser verstehen und Ihre Nutzungserfahrung verbessern. Mit Klick auf “Akzeptieren” helfen Sie uns durch den Einsatz von anonymer Tracking-Technologie die Funktionen der Website stetig zu verbessern.",
			/* eslint-enable */
      dismiss: 'Ablehnen',
      deny: 'Ablehnen',
      allow: 'Akzeptieren',
      link: '',
      href: '',
    },
    onStatusChange() {
      toggleOptInOut(this.hasConsented());
      if (this.hasConsented()) {
        bootstrap();
      }
    },
  };
};

export default cookieconsentConfig;
