import { methods } from '@/store/graphs/methods';

export const getters = (state) => ({
  hasStoreChartData() {
    return state.hasStoreChartData;
  },
  getGraphSeriesBasedonNameAndPeriod(measurementName, period, choId) {
    const { [measurementName]: graphType } = state.graphSeries;
    if (graphType === undefined) {
      return;
    }

    const foundSeriesWithChId = graphType.graphSeries.find((element) => element.choId === choId);
    if (!foundSeriesWithChId) {
      return;
    }
    /* eslint-disable */
    const foundSeries = graphType.graphSeries.find((element) => methods().isPeriodEqual(period, element) && methods().isGroupEqual(period, element));
    /* eslint-disable */
    if (!foundSeries) {
      return;
    }
    return foundSeries;
  },
  getGraphSeries() {
    return state.graphSeries;
  },

  getNightDropAnnotations(measurementName, choId) {
    const { [measurementName]: graphType } = state.graphSeries;
    if (graphType === undefined) {
      return false;
    }
    const foundSeries = graphType.graphSeries.find((element) => element.choId === choId && element['annotations'] !== undefined);
    return foundSeries
  }
});
