<template>
  <section class="banner">
    <p class="banner__content">
      <slot />
    </p>
  </section>
</template>

<script>
export default {
  name: 'HeadBanner',
  data() {
    return {
      sticky: false,
    };
  },
  created() {

  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
  .banner {
    background: var(--danger-100);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid var(--white);
    top: 0;
    z-index: 999;
    position: relative;

    &__content {
      margin-bottom: 0;
      text-align: center;
      font-size: 14px;
      color: var(--danger)
    }
  }
</style>
