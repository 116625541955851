var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{directives:[{name:"cy",rawName:"v-cy"}],staticClass:"linkslot"},[(
      _vm.props.formattedRow[_vm.props.column.field].hasOwnProperty('router_link') &&
        _vm.props.formattedRow[_vm.props.column.field]['router_link'] != ''
    )?_c('a',{attrs:{"href":'#' + _vm.props.formattedRow[_vm.props.column.field].router_link}},[_vm._v(_vm._s(_vm.props.formattedRow[_vm.props.column.field].router_text))]):(_vm.props.formattedRow[_vm.props.column.field]['external_link'] != '')?_c('a',{attrs:{"href":'http://' + _vm.props.formattedRow[_vm.props.column.field].external_link,"target":"_blank"}},[_vm._v(_vm._s(_vm.props.formattedRow[_vm.props.column.field].router_text))]):_c('span',[_vm._v(_vm._s(_vm.props.formattedRow[_vm.props.column.field]['router_text']))]),(_vm.internal)?_c('span',{},[(_vm.note.status)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Installationsstatus (Alpha)'),expression:"'Installationsstatus (Alpha)'"}],staticClass:"cursor-help ml-3"},[_c('i',{class:`cursor-help
        kg
        kg-${_vm.constants.internalNote[_vm.note.status].icon} text-${_vm.constants.internalNote[_vm.note.status].color}`})]):_vm._e()]):_vm._e(),_c('CommentTooltip',{attrs:{"comment":{
      internalComment: _vm.props.formattedRow[_vm.props.column.field].internalComment,
      comment: _vm.props.formattedRow[_vm.props.column.field].comment
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }