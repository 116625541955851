<template>
  <svg
    fill="none"
    height="12"
    viewBox="0 0 24 12"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1L2 11H22L12 1Z"
      fill="white"
      stroke="#E0E6ED"
    />
  </svg>
</template>
<script>

export default {
  name: 'AtomsIconsOutlineArrow',
};
</script>
