<template>
  <span
    v-if="showTooltip"
    class="ml-3"
  >
    <span

      v-tooltip="{ content: tooltipContent, class: 'tooltipClasses'}"
      class="ml-3 cursor-help cursor-help-bubble-icon "
    >
      <atoms-icons
        class="text-black-600"
        icon="kgs kgs-comment-dots"
      />
    </span>
  </span>
</template>

<script>
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index';

export default {
  name: 'CommentTooltip',
  components: {
    AtomsIcons,
  },
  props: {
    comment: Object,
  },
  computed: {
    showTooltip() {
      return (this.comment.internalComment && this.internal) || this.comment.comment;
    },
    tooltipContent() {
      return `
      <div class="comment">
        ${this.comment.comment ? `
          <div>
            <span class="comment-header">Kommentar</span>
            <span class="comment-body">${this.comment.comment}</span>
          </div>
        ` : ''}
        ${(this.internal && this.comment.internalComment) ? `
          <div>
            <span class="comment-header">Interner Kommentar (Alpha)</span>
            <span class="comment-body">${this.comment.internalComment}</span>
          </div>
        ` : ''}
      </div>
      `;
    },
    internal() {
      return this.hasUserKeycloakRole('Alpha-Feature');
    },
  },
};
</script>

<style lang="scss">

.cursor-help-bubble-icon {
  display: inline-block;
}

.cursor-help {
  cursor: help !important;
}
  .tooltip-large{
    width: fit-content !important;
  }
  .comment {
    display: grid;
    grid-row-gap: 15px;
    white-space: pre-wrap;
    &-header {
      color: white;
      display: block;
      font-weight: bold;
    }
    &-body {
      display: block;
      color: white;
      font-size: 14px;
    }
  }
</style>
