import Vue from 'vue';
import VueMatomo from 'vue-matomo';
import Router from '@/router';
import { validateKuguEmailAddress } from '@/utils/validators';
import { isStagingEnv, isDevEnv } from '@/utils/utils';
import constants from './constants';

export const setUpMatomoDashboard = () => {
  // see https://github.com/AmazingDreams/vue-matomo for more configuration options
  const matomoConfigs = {
    host: 'https://stats.kugu-home.com',
    trackerFileName: 'matomo',
    router: Router,
    enableLinkTracking: true,
    requireConsent: true,
    trackInitialView: true,
    disableCookies: false,
    enableHeartBeatTimer: false,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: [],
  };

  if (!validateKuguEmailAddress(window.keycloak.idTokenParsed.email) && !isStagingEnv && !isDevEnv) {
    matomoConfigs.siteId = constants.matomoConfigs.livePortal.siteId;
    Vue.use(VueMatomo, matomoConfigs);
  } else if (isStagingEnv) {
    matomoConfigs.siteId = constants.matomoConfigs.stagingPortal.siteId;
    Vue.use(VueMatomo, matomoConfigs);
  }
};

export const setUpMatomoTagManager = function (containerId) {
  // The matomo tag manager is independent from the matomo setup
  // in main.js and serves the purpose of tracking individual events
  // across the site
  var _mtm = (window._mtm = window._mtm || []);
  _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });

  (function () {
    var url = 'https://stats.kugu-home.com/js/container_';
    var d = document;
    var g = d.createElement('script');
    var s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = `${url}${containerId}.js`;
    s.parentNode.insertBefore(g, s);
  }());
};
