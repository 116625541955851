import { readonly } from 'vue';
import { state } from '@/store/central-heating/dashboard/state';
import { methods } from '@/store/central-heating/dashboard/methods';
import { getters } from '@/store/central-heating/dashboard/getters';

export const centralHeatingDashboard = readonly({
  state,
  methods: methods(state),
  getters: getters(state),
});
