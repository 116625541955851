<template>
  <div>
    <input
      ref="readingInput"
      type="text"
      @input="setReading"
      v-model="value"
    >
  </div>
</template>

<script>
export default {
  name: 'TenantReadingSlot',
  props: {
    props: Object,
  },
  data() {
    return {
      value: this.props.row.reading ? (`${this.props.row.reading}`).replace('.', ',') : '',
    };
  },
  methods: {
    getReformattedValue() {
      if (this.value) {
        if ((`${this.value}`).includes('.') && (`${this.value}`).includes(',')) {
          return this.value.slice().replace('.', '').replace(',', '.');
        } return this.value.slice().replace(',', '.');
      } return null;
    },
    setReading() {
      const formattedValue = this.getReformattedValue();

      const parseable = formattedValue === ''
        || ([...`${this.value.slice()}`].filter((x) => x === ',').length <= 1 && parseFloat(formattedValue));
      if (parseable) {
        this.$refs.readingInput.classList.remove('input-warning');
        this.$emit('emitted', 'setManually', this.props.row, formattedValue);
      } else {
        this.$refs.readingInput.classList.add('input-warning');
      }
    },
  },
  watch: {
    props: {
      handler() {
        if (this.props.row.pickedDate) {
          this.value = (`${this.props.row.reading}`).replace('.', ',');
        }
      },
      deep: true,
    },
  },

};
</script>
<style scoped>
.input-warning {
  color: var(--warning) !important;
  border-color: var(--warning) !important;
}
</style>
