<template>
  <div
    :class="[blockClassName, ...modifier]"
  >
    <slot />
  </div>
</template>
<script>
export default {
  name: 'AbstractCard',
  props: {
    modifier: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss">
  $class-name: 'AbstractCard';

  .#{$class-name} {
    padding: var(--spacing-l);
    background-color: var(--white);
    box-shadow: var(--shadow-el-200);
    border-radius: 6px;

    &.heightlight {
      padding: var(--spacing-xl);
      border-radius: 6px;
    }

    &.big {
      padding: var(--spacing-xl);
      box-shadow: 0px 1px 3px 1px rgba(60, 72, 88, 0.4);
    }

    &.inline {
      display: inline-block;
    }

    &.fullHeight {
      height: 100%;
    }

    &.small {
      padding: var(--spacing-m);
    }

    &.no-space {
      padding: 0;
    }

    &.flat {
      background-color: inherit;
    }

    &.thin {
      box-shadow: inherit;
    }
  }
</style>
