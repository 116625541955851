<template>
  <span
    class="fa-indicator"
    style="cursor:pointer;"
    @click="emit(props.formattedRow, $event, props.row.__import_id ? props.row.__import_id : null)"
  >
    <span
      v-if="props.formattedRow.details == 'Schließen'"
      class="d-inline-block w-100 text-center"
    >
      <atoms-icons
        class="color-black-700"
        icon="kg kg-chevron-down"
        modifiers="medium"
      />
    </span>
    <span
      v-else
      class="d-inline-block w-100 text-center"
    >
      <atoms-icons
        class="color-black-700"
        icon="kg kg-chevron-right"
        modifiers="medium"
      />
    </span>
  </span>
</template>

<script>
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index';

export default {
  name: 'FoldAndExpandSlot',
  components: {
    AtomsIcons,
  },
  props: {
    props: Object,
  },
  methods: {
    emit(formattedRow, e, name) {
      this.$emit('emit', formattedRow, e, name);
    },
  },
};
</script>

<style scoped>
 .fa-indicator.fa-indicator-down {
     transform: rotate(90deg);
     display: block;
 }
 .fa-indicator{
     transform: rotate(0deg);
     display: block;
 }
 </style>
