<template>
  <button
    v-cy="`__${text}`"
    :class="[blockClassName, type, 'font-body-regular']"
    :disabled="disabled"
    @click="$emit('click')"
  >
    {{ text }}
  </button>
</template>
<script>
import { AtomsButtonsAlphaMixin } from '@/atomic-design-components/atoms/buttons/v2/mixin';

export default {
  name: 'AtomsButtonsAlphaStandardButton',
  mixins: [
    AtomsButtonsAlphaMixin,
  ],
};
</script>
<style lang="scss">
  $class-name: 'AtomsButtonsAlphaStandardButton';

  .#{$class-name} {
    padding: var(--spacing-xs) var(--spacing-m);
    border-radius: 6px;
    border: 0;
    transition: 0.3s;

    &.primary {
      background-color: var(--primary-500);
      color: #fff;

      &:hover {
        background-color: var(--primary-400);
      }

      &:focus {
        background-color: var(--primary-300);
      }

      &:active {
        background-color: var(--primary-700);
      }

      &:disabled {
        background-color: var(--neutral-400);
        color: var(--neutral--700);
      }
    }

    &.danger {
      background-color: var(--orange-500);
      color: var(--neutral-900);

      &:hover {
        background-color: var(--orange-400);
      }

      &:focus {
        background-color: var(--orange-500);
      }

      &:active {
        background-color: var(--orange-600);
      }

      &:disabled {
        background-color: var(--neutral-400);
        color: var(--neutral)
      }
    }

    &.critical {
      background-color: var(--red-500);
      color: var(--white);

      &:hover {
        background-color: var(--red-400);
      }

      &:focus {
        background-color: var(--red-500);
      }

      &:active {
        background-color: var(--red-700);
      }

      &:disabled {
        background-color: var(--neutral-400);
        color: var(--neutral)
      }
    }

    &.plain {
      background-color: transparent;
      color: var(--primary-500);

      &:hover {
        background-color: var(--neutral-100);
      }

      &:focus {
        background-color: transparent;
      }

      &:active {
        background-color: var(--neutral-300);
      }

      &:disabled {
        background-color: transparent;
        color: var(--neutral-700);
      }
    }
  }
</style>
