import axios from 'axios';
import { pluginOptions } from '@/../vue.config';
// import { globalStorage } from '@/main';
import moment from 'moment';
import auth from './auth';
// import api from './api';
import utils from './utils';

const options = pluginOptions.VUE_APP_API;

export const isCorrectFormatForImprint = async (val) => {
  if (val.length === 0) {
    return true;
  }
  const itIsIncludesAddress = val.includes('# Anschrift');
  const itIsIncludesDisclaimer = val.includes('# Haftungsausschluss');
  const itIsStartWithAddress = val.startsWith('# Anschrift');
  return itIsIncludesAddress && itIsIncludesDisclaimer && itIsStartWithAddress;
};
const isNumeric = (val) => /^\d+$/.test(val);
const isFloat = (val) => {
  var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
  if (!floatRegex.test(val)) return false;

  val = parseFloat(val);
  if (isNaN(val)) return false;
  return true;
};
const numberValidation = async function (type, x, isRequired) {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${auth.getUserAccessToken()}`,
      },
      params: {
        number: x,
      },
    };
    const serverUrl = options.server_url.replace(/\/?$/, '/');
    const resp = await axios.get(`${serverUrl}validator/${type}-number`, headers);
    if (resp.status === 200) {
      if (isRequired) return resp.data;

      if (x) return resp.data;
      return true;
    }
    return -1;
  } catch (err) {
    window.console.log(err);
    return -1;
  }
};

export const paramPeriod = async (x) => /^(?!\d*$|[d]*$)[d\d]+$/.test(x);

const stringInput = async function (x, isRequired) {
  if (isRequired) return !!(x && typeof `${x}` === 'string');

  if (x) return typeof `${x}` === 'string';
  return true;
};

const email = async function (value, isRequired) {
  /* eslint-disable */
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  /* eslint-enable */

  if (!value && !isRequired) {
    return true;
  }
  return re.test(value.toLowerCase());
};

export default {
  async customerNumber(x, isRequired) {
    const isNumberAvaible = await numberValidation('customer', x, isRequired);
    const isFieldFulfilled = isNumberAvaible ? await stringInput(x, isRequired) : true;
    return {
      errorState: !isFieldFulfilled,
      warningState: !isNumberAvaible,
    };
  },
  async aesKeyInput(x, isRequired) {
    if (isRequired || x) return utils.isValidAESKey(x);
    return true;
  },

  isCorrectDate(x) {
    if (!x) return false;
    return moment(x.split(' ')[0], 'YYYY-MM-DD', true).isValid();
  },

  async objectNumber(x, isRequired) {
    const isNumberAvaible = await numberValidation('object', x, isRequired);
    const isFieldFulfilled = isNumberAvaible ? await stringInput(x, isRequired) : true;
    return {
      errorState: !isFieldFulfilled,
      warningState: !isNumberAvaible,
    };
  },

  async notEmpty(x) {
    return x.toString().length > 0;
  },
  stringInput,
  email,
  async dateInput(x, isRequired) {
    if (isRequired && !x) return false;
    x = new Date(x);
    if (isRequired) return !!(x && x instanceof Date && isFinite(x));
    if (x) return !!(x && x instanceof Date && isFinite(x));
    return true;
  },
  async numberInput(x, isRequired) {
    if (isRequired) return !!(x === 0 || (x && typeof parseFloat(x) === 'number'));
    if (x) return !!(x === 0 || (x && typeof parseFloat(x) === 'number'));
    return true;
  },
  async booleanInput(x, isRequired) {
    if (isRequired) return typeof x === 'boolean';
    return true;
  },
  async listNotEmpty(x, isRequired) {
    if (isRequired) {
      return Array.isArray(x) && x.length > 0;
    }
    return true;
  },
  async zipCode(x, isRequired) {
    if (isRequired) return !!(x && typeof parseFloat(x) === 'number' && x.toString().length === 5);
    if (x) return typeof x === 'number' ? true : false && x.toString().length === 5;
    return true;
  },
  async roundedKiloWattHours(x, isRequired = false) {
    if (isRequired || x) {
      if (x.includes(',')) return false;
      if (x.length > 3) {
        const isValidNumber = !isNaN(parseInt(x.replace('.', '')));
        const separationPointSetCorrectly = x.split('.').length === 2 && x.split('.')[1].length === 3;
        return x.includes('.') && isValidNumber && separationPointSetCorrectly;
      }
      return true;
    }
    return true;
  },
  async systemTemperature(x, isRequired = false) {
    if (isRequired || x) {
      const components = x.split('/');
      if (components.length === 2) {
        return components.every((item) => item.trim().length <= 3 && isNumeric(item.trim().replace(',', '.')));
      }
      return false;
    }
    return true;
  },
  async year(x, isRequired = false) {
    if (isRequired || x) {
      const isUnknown = x === 'Unbekannt';
      return isUnknown || (isNumeric(x) && parseInt(x) < new Date().getFullYear() + 3 && parseInt(x) > 1900);
    }
    return true;
  },
  async dataInletEndpoint(x, isRequired) {
    if (isRequired || x) {
      const m = x.match(/^[a-zA-Z0-9]*$/g); // Array(2) ["abc","abc"] match all
      return !!m && x.length <= 64;
    }
    return true;
  },
  async argeUnitNumber(x, isRequired) {
    if (isRequired || x) {
      return x.length <= 20;
      // NOTE: following validation has been removed
      // due to lack of clarity in requirements
      // and feedback from RMD, see
      // https://kugu.atlassian.net/browse/KUGU-3594
      // let v = false;
      // const tenant_id = globalStorage.singlySelectedTenantID;
      // await api.get(
      //   'validator/arge-unit-number',
      //   { arge_unit_nr: x, tenant_id },
      //   (isValid) => {
      //     v = isValid;
      //   },
      //   () => {
      //     v = false;
      //   },
      // );
      // return v;
    }
    return true;
  },
  async germanNumberFormat(x, isRequired = false) {
    if (isRequired || x) {
      if (!isNumeric(x.trim().replace('.', '').replace(',', ''))) return false;
      if (!x.includes(',')) return false;
      // check that dot is used as thousander separator (e.g. in 10.000,00), not digit indicator
      if (x.split('.').length > 1 && x.split('.')[1].length <= 3) return false;
      return isFloat(x.replace('.', '').replace(',', '.'));
    }
    return true;
  },
};

export const validateKuguEmailAddress = (email) => {
  // eslint-disable-next-line
  const kuguEmailStructure = /^[a-z.]+@kugu-home.com$/;
  return kuguEmailStructure.test(email);
};
