<template>
  <i
    :class="[blockClassName, `${icon} ${modifiers}`]"
    :style="{color: color}"
    @click="$emit('click')"
  />
</template>
<script>
export default {
  name: 'AtomsIconsFontLibary',
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: undefined,
    },
    modifiers: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
  $class-name: 'AtomsIconsFontLibary';

  .#{$class-name} {
    &.small {
      font-size: 12px;
    }

    &.medium {
      font-size: 24px;
    }

    &.big {
      font-size: 32px;
    }

    &.veryBig {
      font-size: 56px;
    }
  }
</style>
