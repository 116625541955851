<template>
  <li
    v-tooltip="isRestricted ? 'Für die gewählte Rolle nicht verfügbar': ''"
    :class="blockClassName"
  >
    <router-link
      :class="[getTextClass(), 'link-with-icon', getBorder()]"
      :disabled="isRestricted"
      tag="button"
      :to="link.to"
    >
      <atoms-icons
        v-if="link.icon"
        :class="[getTextClass(link), childrenClassName('iconWrapper')]"
        :icon="`${link.solid? 'kgs' : 'kg'} ${link.solid ? 'kgs' : 'kg'}-${link.icon}`"
      />
      <span
        :class="[getTextClass(), getFontClass()]"
      >
        {{ link.name }}</span>
    </router-link>
  </li>
</template>

<script>
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index';

export default {
  // TODO: @c.nienkerke - 1h -  remove bootstrap grid system once new grid system is setup!
  name: 'ContentNavLink',
  components: {
    AtomsIcons,
  },
  props: {
    link: {
      type: Object,
      validator(x) {
        return x.hasOwnProperty('name') && x.hasOwnProperty('active') && x.hasOwnProperty('to');
      },
    },
  },

  computed: {
    isRestricted() {
      return this.link.restrictedTo === undefined ? false : !this.hasUserKeycloakRole(this.link.restrictedTo);
    },

    isActive() {
      return this.$route.query.page === this.link.page;
    },
  },

  methods: {
    getFontClass() {
      if (this.isActive) return 'font-body-regular';
      return '';
    },
    getTextClass() {
      if (this.isRestricted) return 'text-black-500 no-interaction';
      if (this.isActive) return 'text-primary';
      return 'text-black-800';
    },
    getBorder() {
      if (this.isActive) return 'border-bottom-primary';
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
li {
    display: inline;
}

button {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: transparent;
    border: 0;
}

button:hover {
    cursor: pointer;
    text-decoration: none;
    background-color: var(--neutral-200)
}

button:active {
    background-color: var(--neutral-300)
}

.ContentNavLink__iconWrapper {
    margin: 0 8px;
    display: flex;
    align-items: center;
}

.link-with-icon {
    display: flex;
    padding: var(--spacing-m);
    .scrolled &{
      padding-top: 13px;
      padding-bottom: 13px;
    }
}

.no-interaction {
    cursor: not-allowed;
}
</style>
