export default [
  {
    name: 'tab',
    config: {
      title: 'Heizsystem',
      links: [
        {
          name: 'Übersicht',
          icon: 'heart',
          active: false,
          page: 'overview',
          to: '?page=overview',
        },
        {
          name: 'Überwachung',
          icon: 'tachometer',
          solid: true,
          page: 'monitoring',
          active: false,
          to: '?page=monitoring',
        },
        {
          name: 'Optimierung',
          icon: 'line-chart',
          page: 'optimization',
          active: false,
          to: '?page=optimization',
        },
        {
          name: 'Steuerung',
          icon: 'cog',
          active: false,
          page: 'control',
          restrictedTo: 'Alpha-Feature',
          to: '?page=control',
        },
        {
          name: 'Komponenten',
          icon: 'puzzle',
          solid: true,
          active: false,
          restrictedTo: 'Heizungs-Betrieb',
          page: 'components',
          to: '?page=components',
        },
        {
          name: 'Nachhaltigkeit',
          icon: 'leaf',
          active: false,
          restrictedTo: 'Alpha-Feature',
          page: 'sustainability',
          to: '?page=sustainability',
        },
        /*
    {
      name: 'Alarm',
      icon: 'bell',
      active: false,
      restrictedTo: 'Alpha-Feature',
      to: this.$route.fullPath,
    }, */
        {
          name: 'Chronik',
          icon: 'history',
          page: 'chronicle',
          active: false,
          restrictedTo: 'Alpha-Feature',
          to: '?page=chronicle',
        },
        {
          name: 'Contracting',
          icon: 'building',
          page: 'contracting',
          active: false,
          restrictedTo: 'Alpha-Feature',
          to: '?page=contracting',
        },
      ],
    },
  },
];
