<template>
  <div class="wrapper mb-4">
    <div
      v-if="tabs.length > 1"
      class="row m-0"
    >
      <!-- eslint-disable -->
      <div
        :class="[
          'col text-center p-2 cursor-pointer',
          tab == currentTab ? 'bg-white' : 'bg-black-200',
        ]"
        v-tooltip="
          tab == 'Gespeichert'
            ? 'Werte, die zu einem Stichtag im Gerät gespeichert sind. <br> Zum Beispiel Jahresstichtagswert oder Monatswert.'
            : tab == 'Durchlaufend'
              ? 'Aktuelle Messwerte, die von dem Gerät zum Messzeitpunkt gesendet wurden.'
              : ''
        "
        v-for="(tab, i) in tabs"
        :key="i"
        @click="changeCurrentTab(i, tab)"
      >
        {{ tab }}
          <!-- eslint-enable -->
      </div>
    </div>
    <span
      v-for="(tab, i) in tabs"
      :key="i"
    >
      <DatePicker
        v-show="tab == currentTab"
        :attributes="extras ? extras[i] : undefined"
        :available-dates="dates ? dates[i] : undefined"
        :initial-date="initialDate"
        @picked="picked"
      />
    </span>

    <div
      v-if="buttons.length > 0"
      class="row m-0 bg-white mb-2 border-top pt-2"
    >
      <div
        v-for="(button, j) in buttons"
        :key="j"
        class="col-12 py-1"
      >
        <span
          v-tooltip="pickedDate ? '' : 'Wählen Sie zuerst ein Datum'"
          :class="`${button.type}-button ${
            pickedDate ? '' : 'disabled'
          } float-right`"
          @click="emits(button.emits)"
        >
          {{ button.text }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker';

export default {
  name: 'ExtendedDatePicker',
  props: {
    extras: Array,
    dates: Array,
    initialDate: String,
    buttons: {
      type: Array,
      default() {
        return [
          {
            type: 'primary',
            text: 'Datum für alle übernehmen',
            emits: 'overwriteAll',
          },
        ];
      },
    },
    tabs: {
      type: Array,
      default() {
        return ['Gespeichert', 'Durchlaufend'];
      },
    },
  },
  data() {
    return {
      tabIndex: 0,
      pickedDate: null,
    };
  },
  computed: {
    currentTab() {
      return this.tabs[this.tabIndex];
    },
  },
  components: {
    DatePicker,
  },
  methods: {
    emits(emits) {
      if (this.pickedDate) {
        this.$emit(
          'datePickerEmit',
          this.pickedDate,
          emits,
          this.currentTab,
          this.tabIndex,
        );
      }
    },
    picked(day) {
      if (this.buttons.length > 0) {
        this.pickedDate = day;
      } else this.$emit('picked', day, this.currentTab, this.tabIndex);
    },
    changeCurrentTab(i) {
      this.tabIndex = i;
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 258px !important;
  background: white;
  border: 1px solid var(--neutral-200);
  border-radius: var(--border-radius);
  height: fit-content;
  display: inline-block;
}
</style>
