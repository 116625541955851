<template>
  <span class="row">
    <progress-bar
      :class="values.label ? 'col-7' : 'col-12'"
      :data="progressBarData"
      :small="true"
    />
    <span
      v-if="values.label"
      class="col-5"
    >{{ values.total }} {{ values.label }}</span>
  </span>
</template>

<script>
import ProgressBar from '@/components/ProgressBar.vue';

export default {
  components: { ProgressBar },
  name: 'ProgressBarSlot',
  props: {
    props: Object,
  },
  computed: {
    values() {
      return this.props.formattedRow[this.props.column.field];
    },
    progressBarData() {
      const res = {};
      for (const [k, v] of Object.entries(this.values)) {
        if (k !== 'total' && k !== 'label') {
          res[k] = { value: (v / this.values.total) * 100, color: k };
        }
      }
      return res;
    },
  },
  data() {
    return {

    };
  },
};
</script>
