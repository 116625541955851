import Vue from 'vue';

export default {
  show(message, type = 'success', duration = 3000) {
    Vue.$toast.open({
      message,
      type,
      duration,
    });
  },
};
