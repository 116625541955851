<template>
  <div
    v-cy
    :class="[slim ? 'slim-frame' : 'frame', disabled ? 'read-only opacity-50' : '', getVisibility]"
    :data-testid="label"
  >
    <div
      v-if="!checkbox"
      class="row"
    >
      <span
        :class="[
          links.length > 0 ? 'col-5' : 'col-12',
          'label',
          `font-${labelWeight}`,
          `font-size-${labelSize}`,
          'flex',
        ]"
      >
        <span v-html="frameLabel" />
        <atoms-icons
          v-if="labelTooltip"
          v-tooltip="labelTooltip"
          class="tooltip"
          color="var(--kugu-blue-400)"
          icon="kgs kgs-info-circle"
        />
        <button
          v-if="resetButton"
          :class="['reset-button', 'font-body-regular']"
          @click="resetModel"
        >
          zurücksetzen
        </button>
      </span>
      <div
        v-if="links.length > 0"
        class="col-7"
      >
        <a
          v-for="(link, i) in links"
          :key="i"
          v-cy="`link`"
          :class="`pl-1 font-${labelWeight}  font-size-${labelSize}`"
          :href="link.value"
          target="_blank"
        >{{ link.label }}</a>
      </div>
    </div>
    <slot />
    <small
      v-if="warning"
      class="text-warning"
    >
      <atoms-icons
        class="text-warning"
        icon="kg kg-exclamation"
      />
      {{ warningMessage }}
    </small>
    <small
      v-else-if="invalid"
      class="text-danger d-flex"
    >
      <atoms-icons
        class="text-danger"
        icon="kg kg-exclamation"
      />
      {{ errorMessage }}
    </small>
  </div>
</template>
<script>
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index';

export default {
  name: 'InputFrame',
  components: {
    AtomsIcons,
  },
  props: {
    checkbox: { type: Boolean, default: false },
    visibleFor: { type: String, default: 'all' },
    links: {
      type: Array,
      default() {
        return [];
      },
    },
    slim: { type: Boolean, default: false },
    label: String,
    labelTooltip: String,
    labelWeight: {
      default: 'normal',
      validator: (weight) => ['normal', 'bold'].includes(weight),
    },
    labelSize: {
      default: '14',
      validator: (size) => ['8', '10', '12', '14', '16'].includes(size),
    },
    required: Boolean,
    disabled: { type: Boolean, default: false },
    errorMessage: String,
    warningMessage: String,
    resetButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      invalid: false,
      warning: false,
    };
  },
  computed: {
    frameLabel() {
      return this.required
        ? `${this.label} <i class="badge-required text-danger font-size-12">*notwendig</i>`
        : this.label;
    },
  },
  methods: {
    getVisibility() {
      if (this.visibleFor === 'all') return '';

      if (this.hasUserKeycloakRole(this.visibleFor)) return '';
      return 'd-none';
    },
    showErrorMessage() {
      this.invalid = true;
    },
    hideErrorMessage() {
      this.invalid = false;
    },
    showWarningMessage() {
      this.warning = true;
    },
    hideWarningMessage() {
      this.warning = false;
    },
    resetModel() {
      this.$emit('resetModel');
    },
  },
};
</script>
<style scoped>
.label {
  display: block;
  text-align: left;
  padding-bottom: 12px;
}
.font-bold {
  font-weight: 600;
}
a {
  font-size: 14px;
  float: right;
}
.frame {
  display: inline-block;
  width: 100%;
  padding: 10px 0;
  margin: 0;
}
.slim-frame {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
}
input {
  width: 100%;
  display: inline-block;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reset-button {
  line-height: 0;
  border: 0;
  color: var(--neutral-800);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--kugu-black);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--kugu-black);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--kugu-black);
}

.reset-button {
  background-color: transparent;
}
</style>
<style>
.badge-required {
  color: #d97244;
  font-style: italic;
}
</style>
