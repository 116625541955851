var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"navbar-nav bg-gradient-primary sidebar sidebar-dark accordion",attrs:{"id":"accordionSidebar"}},[_c('li',{staticClass:"center-horizontally"},[_c('a',{staticClass:"sidebar-brand",attrs:{"href":_vm.keepUrlParams('/home')}},[_c('img',{staticClass:"logo",attrs:{"id":"realm-logo","height":"60px","src":_vm.appLogo,"width":"60%"}})])]),_vm._l((this.links),function(item,key){return _c('li',{key:key,class:[_vm.markIfActive(item.route), ' nav-item']},[(item.title === 'Seperator')?_c('hr',{staticClass:"sidebar-divider"}):_vm._e(),(item.title !== 'Seperator' && (item.role !== undefined ? item.role : true))?_c('router-link',{staticClass:"nav-link",class:_vm.colorContrastClassObjectFG,attrs:{"to":item.route}},[_c('div',{staticClass:"d-flex align-items-center"},[(item.icon)?_c('atoms-icons',{attrs:{"icon":item.icon}}):_vm._e(),_c('span',{style:({
            color: _vm.colorContrastClassObjectFG == 'white-contrasted' ? 'white' : 'black',
          })},[_vm._v(" "+_vm._s(item.title)+" ")])],1)]):_vm._e(),(_vm.currentPath.includes(item.childrenShowOn))?_c('div',{class:[_vm.currentPath.includes(item.route) ? 'display' : '', 'collapse']},[_c('div',{staticClass:"bg-white py-2 collapse-inner rounded"},[(_vm.isRouteParamSet('propertyId'))?_c('a',{class:'d-block collapse-header sidebar-link text-break ',attrs:{"href":_vm.buildHref()}},[('/property/' + _vm.currentId == _vm.currentPath)?_c('span',{staticClass:"font-weight-bold text-primary h7 capitalized d-block w-100"},[_vm._v(_vm._s(_vm.currentAddress))]):_c('span',{staticClass:"font-weight-normal text-dark h7 capitalized d-block w-100"},[_vm._v(_vm._s(_vm.currentAddress))])]):_vm._e(),_c('hr',{staticClass:"divider-grey"}),_vm._l((_vm.getPropertyChildren || []),function(child,key2){return _c('div',{key:key2},[(child.separator)?_c('h6',{staticClass:"ml-3 mb-0 py-2 text-black-700 text-uppercase d-block font-weight-bold text-black-700 text-10"},[_vm._v(" "+_vm._s(child.title)+" ")]):_vm._e(),(!child.separator)?_c('a',{class:[
              _vm.markIfActive(_vm.keepUrlParams(child.route, true)),
              'd-flex align-items-center',
              'collapse-item',
              child.icon ? 'text-black-700' : '',
            ],attrs:{"href":_vm.keepUrlParams(child.route)}},[(child.icon)?_c('atoms-icons',{staticClass:"text-black-700 pl-3",attrs:{"icon":child.icon}}):_vm._e(),_vm._v(" "+_vm._s(child.title)+" ")],1):_vm._e()])})],2)]):_vm._e(),(
        _vm.auth.hasUserKeycloakRole('Geräte-Administration', 'Beta-Feature', 'Alpha-Feature') &&
          _vm.currentPath.includes(item.showHardwareChildrenOn)
      )?_c('div',{class:[_vm.currentPath.includes(item.route) ? 'display' : '', 'collapse']},[_c('div',{staticClass:"bg-white py-2 collapse-inner rounded"},_vm._l((_vm.hardwareManagerLinks),function(item,i){return _c('span',{key:i},[(_vm.auth.hasUserKeycloakRole(...item.roles))?_c('router-link',{class:_vm.getSubNavigationClass(item.to),attrs:{"to":item.to},on:{"click":function($event){item.clickEvent || ''}}},[_c('span',{domProps:{"innerHTML":_vm._s(item.text)}})]):_vm._e()],1)}),0)]):_vm._e(),(_vm.currentPath.includes(item.showSupportChildrenOn))?_c('div',{class:[_vm.currentPath.includes(item.route) ? 'display' : '', 'collapse']},[_c('div',{staticClass:"bg-white py-2 collapse-inner rounded"},_vm._l((_vm.supportLinks),function(item,i){return _c('span',{key:i},[(_vm.auth.hasUserKeycloakRole(...item.roles))?_c('router-link',{class:_vm.getSubNavigationClass(item.to),attrs:{"to":item.to},on:{"click":function($event){item.clickEvent || ''}}},[_c('span',{domProps:{"innerHTML":_vm._s(item.text)}})]):_vm._e()],1)}),0)]):_vm._e(),(
        _vm.auth.hasUserKeycloakRole('Heizungs-Contracting') && _vm.currentPath.includes(item.showCentralHeatingChildrenOn)
      )?_c('div',{class:[_vm.currentPath.startsWith('/central-heating') ? 'display' : '', 'collapse']},[_c('div',{staticClass:"bg-white py-2 collapse-inner rounded"},[_c('router-link',{class:[_vm.markIfActive('/central-heating/contracting'), 'collapse-item'],attrs:{"to":"/central-heating/contracting"}},[_vm._v(" Contracting ")])],1)]):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }