<template>
  <span
    v-tooltip="props.row[props.column.field + '_tooltip']"
    :data-testid="props.column.label + '-Status'"
  >
    <router-link
      v-if="withLink == true && props.row[props.column.field]"
      :to="props.row[props.column.field].router_text != getLockedState()
        ? '/property/' + props.row.property_id + props.row[props.column.field].router_link
        : ''"
    >
      <span class="d-none">{{ props.row[props.column.field].router_text }}</span>
      <i
        :id="props.row[props.column.field].router_text != getLockedState() ? '' : 'not-allowed'"
        :class="[
          'd-inline-block w-100 centered',
          status ? `${status.symbol}` : '',
          status ? `text-${status.color}` : 'text-secondary']"
      />
    </router-link>

    <span v-else>
      <span class="d-none">
        {{
          (Array.isArray(props.row[props.column.field]) && props.row[props.column.field].length > 0) ?
            props.row[props.column.field][0] : props.row[props.column.field]
        }}
      </span>
      <span class="d-inline-block w-100 centered ">
        <i
          :class="[
            status !== null ? `${status.symbol}` : '',
            status ? `text-${status.color}` : 'text-secondary']"
        />
        <span
          v-if="props.row.hasOwnProperty('status_text')"
          class="pl-3"
        >{{ props.row.status_text }}</span>
        <span
          v-if="Array.isArray(props.row[props.column.field] && props.row[props.column.field].length > 1)"
          class="pl-3"
        >{{ props.row[props.column.field][1] }}</span>
      </span>
    </span>
  </span>
</template>

<script>
import constants from '@/utils/constants';

export default {
  name: 'ConditionalStatusSlot',
  props: {
    props: Object,
    withLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      return this.getStatusCode(this.props.row[this.props.column.field]);
    },
  },
  methods: {
    getLockedState() {
      return constants.State.LOCKED;
    },
    getStatusCode(num) {
      if (typeof (num) === 'object') {
        return constants.StatusCodes[num ? num.router_text : 'null'];
      }
      return constants.StatusCodes[num];
    },
  },
};
</script>
