import axios from 'axios';
import { eventHub } from '@/main';
import { pluginOptions } from '@/../vue.config';
import auth from './auth';

const api = pluginOptions.VUE_APP_API;

export default {
  get(uri = '', params = {}, callback = () => {}, errorCallback = () => {}) {
    // make sure the server_url ends with an trailing slash
    api.server_url = api.server_url.replace(/\/?$/, '/');
    const settings = {
      method: 'get',
      url: `${api.server_url}${uri}`,
      headers: {
        Authorization: `Bearer ${auth.getUserAccessToken()}`,
      },
    };
    if (params && params.isPdf) {
      settings.responseType = 'blob';
      settings.headers.Accept = 'application/pdf';
      params = { ...params };
      delete params.isPdf;
    }
    settings.params = params;
    return axios(settings)
      .then((response) => {
        if (response.status !== 200) {
          // refresh page on error response
          /* if (response.status === 401) {
          window.location.reload();
        } */
          errorCallback(response.status);
          eventHub.$emit('fetch-error', response.status);
          // return auth.setUserLoggedOut();
        }
        sessionStorage.setItem('fetchError', true);
        return callback(response.data);
      })
      .catch((error) => {
        if (settings.responseType !== 'blob') {
          eventHub.$emit('fetch-error', error);
          return errorCallback(error.response);
        }
        return error.response.data.text().then((errorMessage) => {
          eventHub.$emit('fetch-error', errorMessage);
          return errorCallback(errorMessage);
        });
      });
  },
  downloadPDF(
    uri = '',
    filename = 'file.pdf',
    params = {},
    callback = () => {},
    errorCallback = () => {},
  ) {
    api.server_url = api.server_url.replace(/\/?$/, '/');
    return axios({
      method: 'get',
      url: `${api.server_url}${uri}`,
      params,
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${auth.getUserAccessToken()}`,
      },
      // eslint-disable-next-line consistent-return
    })
      .then((response) => {
        if (response.status !== 200) {
          // refresh page on error response
          /* if (response.status === 401) {
          window.location.reload();
        } */
          errorCallback();
          eventHub.$emit('fetch-error', response.status);
          // return auth.setUserLoggedOut();
        }
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();

        callback();
      })
      .catch((error) => {
        window.console.error(error);
        errorCallback(error.response);
        eventHub.$emit('fetch-error', error);
      });
  },
  getFile(
    uri = '',
    params = {},
    callback = () => {},
    errorCallback = () => {},
  ) {
    // make sure the server_url ends with an trailing slash
    api.server_url = api.server_url.replace(/\/?$/, '/');
    return axios({
      method: 'get',
      responseType: 'arraybuffer',
      url: `${api.server_url}${uri}`,
      params,
      headers: {
        Authorization: `Bearer ${auth.getUserAccessToken()}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // refresh page on error response
          /* if (response.status === 401) {
          window.location.reload();
        } */
          errorCallback(response.status);
          eventHub.$emit('fetch-error', response.status);
          // return auth.setUserLoggedOut();
        }
        sessionStorage.setItem('fetchError', true);
        callback(response.data);
      })
      .catch((error) => {
        window.console.error(error);
        errorCallback(error.response);
        eventHub.$emit('fetch-error', error);
      });
  },

  post(
    uri = '',
    data = {},
    parameters = null,
    headers = null,
    callback = () => {},
    errorCallback = () => {},
  ) {
    // make sure the server_url ends with an trailing slash
    api.server_url = api.server_url.replace(/\/?$/, '/');
    return axios({
      method: 'post',
      url: `${api.server_url}${uri}`,
      data,
      headers: headers || {
        Authorization: `Bearer ${auth.getUserAccessToken()}`,
      },
      params: parameters,
    })
      .then((response) => {
        if (response.status !== 200) {
          errorCallback(response);
          eventHub.$emit('fetch-error', response.status);
          // return auth.setUserLoggedOut();
        }
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error.response);
        eventHub.$emit('fetch-error', error);
      });
  },
  put(
    uri = '',
    data = {},
    parameters = null,
    headers = null,
    callback = () => {},
    errorCallback = () => {},
  ) {
    // make sure the server_url ends with an trailing slash
    api.server_url = api.server_url.replace(/\/?$/, '/');
    return axios({
      method: 'put',
      url: `${api.server_url}${uri}`,
      data,
      params: parameters || {},
      headers: headers || {
        accept: 'application/json',
        Authorization: `Bearer ${auth.getUserAccessToken()}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          errorCallback(response.data);
          eventHub.$emit('fetch-error', response);
          // return auth.setUserLoggedOut();
        }
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error.response);
        eventHub.$emit('fetch-error', error);
      });
  },
  delete(uri = '', params = {}, callback = () => {}, errorCallback = () => {}) {
    api.server_url = api.server_url.replace(/\/?$/, '/');
    return axios({
      method: 'delete',
      url: `${api.server_url}${uri}`,
      data: params,
      headers: {
        Authorization: `Bearer ${auth.getUserAccessToken()}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // refresh page on error response
          /* if (response.status === 401) {
            window.location.reload();
          } */
          errorCallback(response);
          eventHub.$emit('fetch-error', response.status);
          // return auth.setUserLoggedOut();
        }
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
        eventHub.$emit('fetch-error', error);
      });
  },
};
