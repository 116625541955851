<template>
  <span>
    <span
      v-for="(item,i) in props.row[name]"
      :key="i"
      :class="[!(i == (props.row[name].length-1)) ? 'border-bottom' : '', 'p-2 d-block']"
      v-html="item"
    />
  </span>
</template>

<script>
export default {
  name: 'SplitCellSlot',
  props: {
    props: Object,
    name: String,
  },

  mounted() {
    this.$el.parentNode.classList.add('no-padding');
  },
};
</script>
