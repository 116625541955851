<template>
  <span
    ref="actionTrigger"
    v-click-outside="hideDropdown"
    class="cursor-pointer"
  >
    <span
      v-if="props.row.select.length > 0 && !isLoading"
      class="d-inline-block position-relative text-left pr-2"
    >

      <div>
        <p
          class=" m-0 actionSlotWrapper"
          @click="toggleDropdown"
        >{{ label }}
          <atoms-icons
            v-if="show"
            class="color-black-700"
            icon="kg kg-chevron-down"
          />
          <atoms-icons
            v-else
            class="color-black-700"
            icon="kg kg-chevron-right"
          />
        </p>
      </div>
      <div
        v-if="show"
        class="dropdown"
      >
        <ul class="dropdown__list mt-3">

          <li
            v-for="(p, index) in props.row.select"
            :key="index"
            class="dropdown__item"
          >

            <a
              v-if="p.calls"
              :class="['dropdown__link d-flex', {'cursor-pointer': !p.disabled}]"
              role="button"
              @click="callAction(p, props, $event)"
            >
              <span class="icon text-primary pr-2">
                <atoms-icons
                  class="line-height"
                  :icon="`kg kg-${p.icon}`"
                />
              </span>
              <span class="text">{{ p.label }}</span>
            </a>

            <router-link
              v-if="p.link"
              :class="['dropdown__link d-flex', {'cursor-pointer': !p.disabled}]"
              target="_blank"
              :to="p.link"
            >
              <span class="icon text-primary pr-2">
                <atoms-icons
                  class="line-height"
                  :icon="`kg kg-${p.icon}`"
                />
              </span>
              <span class="text">{{ p.label }}</span>
            </router-link>

            <div
              v-if="p.parameters"
              class="mb-2 mx-2 row"
            >
              <div
                v-for="(param, i) in p.parameters"
                :key="i"
                class="cursor-pointer text-white bg-primary border-radius mx-1 p-1 col"
                @click="emit(p.calls, props.row, param.value, $event)"
              >
                {{ param.label }}
              </div>
            </div>

          </li>
        </ul>
      </div>

    </span>
    <loading-indicator
      v-else-if="isLoading"
      :no-layout="true"
      :small="true"
    />
    <span
      v-else
      class="text-secondary"
    >Keine Aktionen</span>
  </span>
</template>

<script>
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default {
  name: 'ActionsSlot',
  data() {
    return {
      show: false,
      isLoading: false,
    };
  },
  components: {
    LoadingIndicator,
    AtomsIcons,
  },
  props: {
    props: Object,
    label: {
      type: String,
      default: 'Auswählen',
    },
    columnsCount: Number,
  },
  created() {
    this.eventHub.$on('inlineNotification-success', (event) => {
      this.getCurrentRow(event);
    });
  },
  methods: {
    callAction(p, props, ev) {
      if (p.parameters) {
        // TODO: what is this for?
      } else this.emit(p.calls, props.row, null, ev);
      if (p.loadOnClick === true) this.isLoading = true;
    },
    emit(functionName, rowInfo, parameter = null, event) {
      this.eventHub.$emit('deleteCost', event);
      this.$emit('emitted', functionName, rowInfo, parameter);
    },
    hideDropdown(e) {
      if (!e.target.closest("[data-id='deny-outside-click']")) {
        this.show = false;
      }
    },
    toggleDropdown() {
      this.show = !(this.show);
    },
  },
};
</script>

<style lang="scss">

    .actionSlotWrapper {
      display: flex;
      align-items: center;
    }

.inline-notification {
    background-color: var(--neutral-100);
    min-height: 35px;

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &__icon {
        font-size: 13px;
    }

    &__message {
        text-align: center;
        ;
        font-size: 14px;
        padding-left: 6px;
        margin-bottom: 0 !important;

        &--link {
            color: var(--primary);
        }

        &-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }

    &__button {
        display: flex;
        align-items: center;

        &--action {
            color: var(--primary);
            font-size: 14px;
            background: transparent;
            border: none;
            transition: background-color 0.2s ease-in-out;

            &:hover {
                background-color: var(--neutral-300);
            }
        }

        &--cancel {
            color: var(--primary) !important;
            font-size: 13px;
            padding: 6px 8px;
            margin-left: 6px;
            border-radius: 50%;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out;

            &:hover {
                background-color: var(--neutral-300);
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.dropdown {
    position: relative;
    left: -9px;
    top: calc(100% + 10px);
    min-width: calc(100% + 17px);
    z-index: 9;

    &__list {
        min-width: 100%;
        list-style: none;
        width: max-content;
        border: 1px solid var(--neutral-500);
        padding: 0;
    }

    &__item {
        width: 100%;
        display: block;
        cursor: default;
        background-color: #fff;
        text-align: left;
        font-weight: normal;
        background: var(--neutral-200);
        border-bottom: 1px solid var(--neutral-500);
        font-size: 13px;
        line-height: 1.43;

        &:last-of-type {
            border: none;
        }
    }

    &__link {
        padding: 10px;
        display: block;
    }

    &__trigger {
        position: relative;
    }

}
</style>
