<template>
  <div>
    <a
      v-if="collapsible"
      :aria-controls="collapseId"
      class="card-header card-header py-1 d-flex flex-row align-items-center justify-content-between"
      data-bs-toggle="collapse"
      :href="'#' + collapseId"
      role="button"
      @click="showMore"
    >
      <h6 :class="['p-0 font-weight-bold text-primary', button ? 'my-3' : 'my-1']">
        {{ title }} <span
          class="pl-4 font-weight-normal"
          cy-data="title-extension"
          v-html="titleExtension ? titleExtension : ''"
        />
      </h6>
      <slot
        class="w-100 float-right"
        name="header-before-button"
      />
      <a
        v-if="button"
        v-tooltip="buttonTooltip"
        :class="`${buttonType}-button ${buttonDisabled ? 'disabled' : ''} mr-2`"
        role="button"
        @click="emitButtonClicked"
      >
        <span
          v-if="loadingButton"
          :class="`spinner-border spinner-border-sm text-${buttonType == 'primary' ? 'white': 'black-300'}`"
          role="status"
        /><span
          v-else
          :class="'text-' + buttonType == 'primary' ? 'white': 'black'"
          :data-testid="title + '-button'"
        >{{ buttonText }}</span>
      </a>
    </a>
    <div
      v-else
      class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
    >
      <div class="row w-100">
        <h6
          :class="[
            'col-s-12 align-middle my-0 font-weight-bold text-primary',
            button ? 'my-3' : 'my-1',
            hasSlot('header-before-button') ? 'col-lg-4': 'col-lg-10'
          ]"
        >
          <span
            class="text-primary"
            v-html="highlightText(title)"
          /> <span
            class="pl-4 font-weight-normal"
            v-html="titleExtension ? titleExtension : ''"
          />
        </h6>

        <div class="card-header-wrapper col-s-12, col-lg-8">
          <slot
            v-if="hasSlot('header-before-button')"
            class="col-m-6 col-s-12"
            name="header-before-button"
          />
          <div
            v-if="button"
            :class="`col-m-4 col-lg-4 col-s-12 p-0`"
          >
            <a
              v-tooltip="buttonTooltip"
              :class="`w-100 ${buttonType}-button d-inline-block ${buttonDisabled ? 'disabled' : ''}`"
              role="button"
              @click="emitButtonClicked"
            >
              <div
                v-if="loadingButton"
                :class="`spinner-border spinner-border-sm text-${buttonType === 'primary' ? 'white': 'black-300'}`"
                role="status"
              /><span
                v-else
                :class="`text-${buttonType === 'primary' ? 'white': 'black'}`"
                :data-testid="title + '-button'"
              >{{ buttonText }}</span>
            </a>
          </div>
        </div>
      </div>
      <div
        v-if="loading"
        class="spinner-border spinner-border-sm mr-1"
        role="status"
      >
        <span class="sr-only m-auto d-block w-100">Wird geladen...</span>
      </div>
      <div
        v-if="!loading && headerStatus"
        role="status"
      >
        <atoms-icons
          :class="`text-${constants.StatusCodes[headerStatus].color}`"
          :icon="`kg kg-${constants.StatusCodes[headerStatus].symbol}`"
        />
      </div>
      <div
        v-if="closeable"
        class="col-m-2 col-lg-2 col-s-12 mt-3"
      >
        <closing-button
          class="float-right"
          :small="true"
          @close="$emit('closed')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index';
import constants from '@/utils/constants';
import ClosingButton from '@/components/ClosingButton.vue';

export default {
  name: 'CardHeader',
  components: {
    ClosingButton,
    AtomsIcons,
  },
  data() {
    return {
      constants,
    };
  },
  props: {
    closeable: Boolean,
    searchPhrase: String,
    titleExtension: String,
    alreadyCollapsed: {
      type: Boolean,
      default: false,
    },
    title: String,
    loading: Boolean,
    loadingButton: Boolean,
    button: {
      type: Boolean,
      default: false,
    },
    headerStatus: {
      default: null,
      type: Number,
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonTooltip: {
      type: String,
      default: '',
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: String,
      default: 'basic',
    },
    collapsible: {
      type: Boolean,
      default: false,
    },
    collapseId: {
      type: String,
      default: '',
    },
  },
  methods: {
    hasSlot(name = 'default') {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    emitButtonClicked() {
      if (!this.buttonDisabled) this.$emit('emitButtonClicked');
    },
    showMore() {
      this.$emit('collapseAction');
    },
    highlightText(text) {
      if (text) return text.replace(this.searchPhrase, `<span class="highlited">${this.searchPhrase}</span>`);
      return '';
    },
  },
};
</script>

<style scoped>
.card-header-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>
