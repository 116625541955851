<template>
  <div
    v-if="!(trackingConsentSet)"
    id="banner"
    class="position-fixed bg-white border-rectangle col-8 offset-3 rounded-bottom start-animation"
  >
    <div class="m-4">
      <p>
        Um unsere App für Sie noch besser zu machen, sammeln wir Daten zum Nutzerverhalten.
        Wir nutzen dazu den Service von
        <a
          href="#"
          target="_blank"
        >
          Matomo
        </a>.
      </p>
      <p>
        Klicken Sie auf "Cookies akzeptieren" wenn Sie mit der
        Datenverarbeitung via Matomo einverstanden sind.
      </p>
      <div
        class="primary-button my-4"
        colorscheme="fullSuccess"
        @click="giveConsent(true)"
      >
        Cookies akzeptieren
      </div>
      <div
        class="basic-button my-4"
        @click="giveConsent(false)"
      >
        Ablehnen
      </div>
    </div>
  </div>
</template>

<script>
const localStorageConsent = 'cookie-consent';

export default {
  name: 'ConsentBanner',

  data() {
    return {
      trackingConsentSet: localStorage.getItem(localStorageConsent) || false,
    };
  },
  methods: {
    giveConsent(given) {
      if (given) {
        localStorage.setItem(localStorageConsent, true);
        this.$matomo.rememberConsentGiven();
      }
      document.getElementById('banner').classList.remove('start-animation');
      document.getElementById('banner').classList.add('hidden');
    },
  },
};
</script>

<style scoped>
.hidden {
    top: 0px;
    animation-name: slideback;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.start-animation {
    top: -500px;
    animation-name: slide;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

@keyframes slide {
    from {
        top: -500px;
    }

    to {
        top: 0px;
    }
}

@keyframes slideback {
    from {
        top: 0px;
    }

    to {
        top: -500px;
    }
}
</style>
