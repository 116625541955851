<template>
  <div>
    <span
      v-if="props.row.saveAndReset == true"
      class="m-0 row"
    >
      <span
        v-tooltip="readOnlyMode && 'Keine Berechtigung'"
        :class="['primary-button slim assign col m-1', {'disabled':readOnlyMode}]"
        @click="save"
      >
        <i
          v-if="showCheck"
          class="kg kg-check text-white"
        />
        <div
          v-else
          class="spinner-border spinner-border-sm text-white"
          role="status"
        />
      </span>
      <span
        v-tooltip="readOnlyMode && 'Keine Berechtigung'"
        :class="['basic-button col slim redo m-1',{'disabled':readOnlyMode}]"
        @click="reset"
      >
        <i
          v-if="showReset"
          class="kg kg-redo"
        />
        <div
          v-else
          class="spinner-border spinner-border-sm"
          role="status"
        />
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'SaveAndResetSlot',
  props: {
    props: Object,
    readOnlyMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showCheck: true,
      showReset: true,
    };
  },
  methods: {
    save() {
      if (!this.readOnlyMode) {
        this.$emit('saved', this.props);
        // this.showCheck = false
      }
    },
    reset() {
      if (!this.readOnlyMode) {
        this.$emit('reset', this.props);
        // this.showReset = false
      }
    },
  },
};
</script>
