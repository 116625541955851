<template>
  <span
    v-if="props.row[field]"
    class="cursor-pointer"
  >
    <span class="d-inline-block position-relative text-left pr-2">
      <div>
        <p
          v-for="(item, i) in props.row[field]"
          :key="i"
          class="p-0 m-0"
        >
          <span
            v-if="!item.oms_id"
            v-tooltip="getTooltipContent(item)"
          >
            {{ item.device_name || item.id || 'Unbekannt' }} {{ item.property_name ? '-' + item.property_name : '' }}
          </span>
          <!--
            TODO - m.ruescher - 0.5h - correct this url
            with the dynamic property as soon as the backend returns it
          -->
          <router-link
            v-if="item.oms_id"
            v-tooltip="getTooltipContent(item)"
            class="no-underline"
            :to="getLink(item)"
          >
            <span
              class="text-primary"
            >{{ item.device_name || 'Unbekannt' }} - {{ item.property_name || 'Unbekannt' }}
            </span>
          </router-link>
        </p>
      </div>
    </span>
  </span>
</template>

<script>
export default {
  name: 'ListSlot',
  data() {
    return {
      show: false,
    };
  },
  props: {
    field: String,
    props: Object,
  },
  methods: {
    getLink(item) {
      return `/property/${item.property_id}/hardware/${item.oms_id}`;
    },
    getTooltipContent(item) {
      return {
        content: `
          Datahub: ${item.datahub_id || 'Unbekannt'} <br/>
          Name: ${item.device_name} <br/>
          Signalstärke: ${item.signal_strength} <br/>
          Liegenschaft: ${item.property_id} <br/>
          `,
        html: true,
      };
    },
    hideDropdown() {
      this.show = false;
    },
    showDropdown() {
      this.show = !(this.show);
    },
  },
};
</script>
