import { reactive } from 'vue';

export const state = reactive({
  centralHeatings: {
    table: {
      counter: 0,
    },
  },

});
