<template>
  <!-- eslint-disable -->
  <svg
    :class="blockClassName"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="childrenClassName('outsidePath', [status])"
      d="M1.0001 1H21.418V20.5349H1.0001V1Z"
      stroke-miterlimit="10"
      stroke-linejoin="round"
    />
    <path
      :class="childrenClassName('checkmark', [status])"
      d="M3.66211 6.95135L5.43758 8.65003L9.87624 4.40332"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      :class="childrenClassName('checkmark', [status])"
      d="M3.66211 14.5964L5.43758 16.2951L9.87624 12.0483"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.6514 7.78857H18.7532H11.6514Z"
      fill="white"
    />
    <path
      :class="childrenClassName('line', [status])"
      d="M11.6514 7.78857H18.7532"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.6514 15.4297H18.7532H11.6514Z"
      fill="white"
    />
    <path
      :class="childrenClassName('line', [status])"
      d="M11.6514 15.4297H18.7532"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <!-- eslint-enable -->
</template>
<script>
export default {
  name: 'AtomsIconsPlan',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
  $class-name: 'AtomsIconsPlan';

  .#{$class-name} {
    .#{$class-name}__outsidePath {
      &--success {
        fill: var(--green-400);
        stroke: var(--green-700);
      }

      &--critical {
        fill: var(--red-100);
        stroke: var(--red-700);
      }

      &--warning {
        fill: var(--orange-300);
        stroke: var(--orange-700);
      }

      &--locked, &--null {
        fill: var(--neutral-400);
        stroke: var(--neutral-700);
      }
    }

    .#{$class-name}__checkmark {
      &--success {
        stroke: var(--green-700);
      }

      &--warning {
        stroke: var(--orange-700);
      }

      &--critical {
        stroke: var(--red-700);
      }

      &--locked, &--null {
        stroke: var(--neutral-700);
      }
    }

    .#{$class-name}__line {
      &--success {
        stroke: var(--green-700);
      }

      &--warning {
        stroke: var(--orange-700);
      }

      &--critical {
        stroke: var(--red-700);
      }

      &--locked, &--null {
        stroke: var(--neutral-700);
      }
    }
  }
</style>
