import Vue from 'vue';
import VueRouter from 'vue-router';
import CentralHeatingsTab from '@/router/configurations/property/central-heating';
import RedirectService from '@/utils/services/auth/redirect';

Vue.use(VueRouter);

const Router = new VueRouter({
  routes: [{
    path: '/',
    redirect: '/home',
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Login',
    },
    component: () => import('../urls/Login.vue'),
  },
  {
    path: '/home',
    name: 'Dashboard',
    meta: {
      title: 'Startseite',
      roles: [
        'Vollzugriff',
        'Hausverwaltung',
        'Rauchmelder-Administration',
        'Heizkostenabrechnung',
        'Heizungs-Betrieb',
        'Heizungs-Contracting',
        'Heizungs-Wartung',
        'Heizungs-Helpdesk',
        'Alpha-Feature',
      ],
      layoutConfig: {
        container: 'dashboard',
      },
    },
    component: () => import('../urls/dashboard/Dashboard.vue'),
  },
  {
    path: '/tenants/form',
    name: 'TenantsForm',
    meta: {
      title: 'Bewohner',
      layout: 'simple',
    },
    component: () => import('../atomic-design-components/templates/property/tenants/Form.vue'),
  },
  {
    path: '/property/:propertyId/tenants',
    name: 'Tenants',
    meta: {
      title: 'Bewohner',
      roles: [
        'Hausverwaltung',
        'Heizkostenabrechnung',
      ],
      layoutConfig: {
        container: 'table',
      },
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../atomic-design-components/templates/property/tenants/Overview.vue'),
  },
  {
    path: '/billing',
    name: 'Billings',
    meta: {
      title: 'Abrechnung',
      roles: ['Beta-Feature', 'Heizkostenabrechnung'],
      layoutConfig: {
        container: 'table',
      },
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../atomic-design-components/templates/billing/Overview.vue'),
  },
  {
    path: '/property/:propertyId',
    name: 'Property Detail',
    meta: {
      title: 'Liegenschaft',
      roles: ['Vollzugriff',
        'Heizkostenabrechnung',
        'Hausverwaltung',
        'Rauchmelder-Administration',
        'Mandanten-Administration',
      ],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/property/Detail.vue'),
  },
  {
    path: '/property/:propertyId/central-heating',
    name: 'Property - Central Heating Optimization',
    meta: {
      title: 'Heizsysteme',
      roles: ['Heizungs-Betrieb', 'Vollzugriff'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/property/central-heating/CentralHeatingOverview.vue'),
  },
  {
    path: '/property/:propertyId/central-heating/:choId',
    name: 'Property - Central Heating Detail',
    meta: {
      title: 'Heizsystem',
      roles: ['Heizungs-Betrieb', 'Vollzugriff'],
      stickyComponents: CentralHeatingsTab,
      layoutConfig: {
        container: 'dashboard',
      },
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/property/central-heating/CentralHeatingDetail.vue'),
  },
  {
    path: '/property/:propertyId/billing',
    name: 'Property - Billing',
    meta: {
      title: 'Abrechnung',
      roles: ['Vollzugriff', 'Hausverwaltung', 'Heizkostenabrechnung'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/property/billing/Billing.vue'),
  },
  {
    path: '/property/:propertyId/hardware',
    name: 'Property - Hardware Overview',
    meta: {
      title: 'Hardware',
      module: 'hardware',
      roles: ['Vollzugriff', 'Heizkostenabrechnung'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/property/hardware/HardwareOverview.vue'),
  },
  {
    path: '/property/:propertyId/hardware/:hardwareId',
    name: 'Property - Hardware Detail',
    meta: {
      title: 'Gerät',
      roles: ['Vollzugriff', 'Heizkostenabrechnung'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/property/hardware/HardwareDetail.vue'),
  },
  {
    path: '/property/:propertyId/smoke-detector',
    name: 'Property - Smoke Detector Overview',
    meta: {
      title: 'Rauchmelder',
      module: 'smoke_detector',
      roles: ['Vollzugriff', 'Rauchmelder-Administration', 'Heizkostenabrechnung'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/property/hardware/smoke-detector/SmokeDetectorOverview.vue'),
    props: {
      onlySmokeDetectors: true,
    },
  },
  {
    path: '/external-credentials',
    name: 'External credentials',
    meta: {
      title: 'Externe Zugangsdaten',
      roles: ['Alpha-Feature'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/ExternalCredentials.vue'),
  },
  {
    path: '/eras-2-connection',
    name: 'Eras 2 Connection',
    meta: {
      title: 'ERAS2/Absys Verbindung',
      roles: ['Vollzugriff'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/Eras2Connection.vue'),
  },
  {
    path: '/customer',
    name: 'Customer Overview',
    meta: {
      title: 'Kunden',
      roles: ['Vollzugriff', 'Heizkostenabrechnung'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/customer/CustomerOverview.vue'),
  },
  {
    path: '/clients',
    name: 'client Overview',
    meta: {
      title: 'Mandanten',
      roles: ['Mandanten-Administration'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('@/urls/clients/ClientsOverview.vue'),
  },
  {
    path: '/clients/:clientId',
    name: 'client detail',
    meta: {
      title: 'Mandant',
      roles: ['Mandanten-Administration'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('@/urls/clients/ClientsDetail.vue'),
  },
  {
    path: '/customer/:customerId',
    name: 'Customer Detail',
    meta: {
      title: 'Kunde',
      roles: ['Vollzugriff', 'Heizkostenabrechnung'],
    },
    component: () => import('../urls/customer/CustomerDetail.vue'),
  },
  {
    path: '/central-heating',
    name: 'Central Heating',
    meta: {
      title: 'Heizsysteme',
      roles: ['Heizungs-Betrieb', 'Vollzugriff', 'Heizungs-Contracting'],
      layoutConfig: {
        container: 'table',
      },
    },
    component: () => import('../atomic-design-components/templates/central-heating/Overview.vue'),
  },
  {
    path: '/central-heating/contracting',
    name: 'Central Heating',
    meta: {
      title: 'Heizsysteme Contracting',
      roles: ['Heizungs-Contracting'],
    },
    component: () => import('../urls/central-heating/CentralHeatingContracting.vue'),
  },
  {
    path: '/setup',
    meta: {
      title: 'Erstaufnahme',
      roles: ['Alpha-Feature'],
      breadcrumbs: {
        usev2: true,
      },
    },
    name: 'Setup',
    component: () => import('../urls/property/PropertySetup.vue'),
  },
  {
    path: '/property/:propertyId/setup-pipeline',
    title: 'Setup Pipeline',
    meta: {
      title: 'Erstaufnahme',
      roles: ['Alpha-Feature'],
      breadcrumbs: {
        usev2: true,
      },
    },
    name: 'Setup Pipeline',
    component: () => import('../urls/property/PropertySetupPipeline.vue'),
  },
  {
    path: '/property/:propertyId/smoke-detector/:hardwareId',
    name: 'Property - Smoke Detector Detail',
    meta: {
      title: 'Rauchmelder',
      roles: ['Vollzugriff', 'Rauchmelder-Administration', 'Heizkostenabrechnung'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/property/hardware/smoke-detector/SmokeDetectorDetail.vue'),
  },
  {
    path: '/property/:propertyId/document',
    name: 'Documents',
    meta: {
      title: 'Dokumente',
      roles: ['Vollzugriff', 'Hausverwaltung', 'Rauchmelder-Administration', 'Heizkostenabrechnung'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/property/DocumentOverview.vue'),
  },
  {
    path: '/property/:propertyId/import',
    name: 'Import per Property',
    meta: {
      title: 'Import',
      roles: ['Vollzugriff', 'Rauchmelder-Administration', 'Heizkostenabrechnung'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/property/ImportPerProperty.vue'),
  },
  {
    path: '/hardware-manager',
    name: 'Hardware-Manager',
    meta: {
      title: 'Hardware-Manager',
      roles: ['Vollzugriff', 'Geräte-Administration', 'Heizkostenabrechnung'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/hardware-manager/HardwareManager.vue'),
  },
  {
    path: '/hardware-manager/lifetime-calculator',
    name: 'Lebensdauer',
    meta: {
      title: 'Lebensdauer',
      roles: ['Geräte-Administration'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/hardware-manager/BatteryCalculatorPanel.vue'),
  },
  {
    path: '/hardware-manager/wmbus-decoder',
    name: 'WMBus Decoder',
    meta: {
      title: 'WMBus Decoder',
      roles: ['Geräte-Administration'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/hardware-manager/WMBusDecoder.vue'),
  },
  {
    path: '/support/releases',
    name: 'Neuigkeiten',
    meta: {
      title: 'Neuigkeiten',
      roles: ['Vollzugriff'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/ReleaseNotes.vue'),
  },
  {
    path: '/hardware-manager/data-inlets',
    name: 'DataInlets',
    meta: {
      title: 'Daten-Eingang',
      roles: ['Vollzugriff', 'Geräte-Administration'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/hardware-manager/DataInlets.vue'),
  },
  {
    path: '/hardware-manager/reception',
    name: 'ReceptionManager',
    meta: {
      title: 'Empfangsanalyse (Beta)',
      roles: ['Beta-Feature'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/hardware-manager/ReceptionManager.vue'),
  },
  {
    path: '/hardware-manager/device-inbox',
    name: 'Device Inbox',
    meta: {
      title: 'Neue Geräte',
      roles: ['Geräte-Administration'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/hardware-manager/DeviceInbox.vue'),
  },
  {
    path: '/hardware-manager/reception/:propertyId',
    name: 'ReceptionDetail',
    meta: {
      title: 'Empfangsanalyse (Beta)',
      roles: ['Beta-Feature'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/hardware-manager/ReceptionDetail.vue'),
  },
  {
    path: '/frame/user-manager/:userName',
    name: 'UserManagement',
    meta: {

      title: 'User Management',
      layout: 'empty',
      roles: ['Vollzugriff'],
    },
    component: () => import('../urls/frame/Management.vue'),
  },
  {
    path: '/import',
    name: 'Import',
    meta: {
      title: 'Import',
      roles: ['Vollzugriff', 'Hausverwaltung'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/Import.vue'),
  },
  {
    path: '/imprint',
    name: 'Imprint',
    meta: {
      title: 'Impressum & Datenschutz',
      roles: ['Vollzugriff'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/Imprint.vue'),
  },
  {
    path: '/support',
    name: 'support',
    meta: {
      title: 'Hilfe',
      roles: [],
    },
    component: () => import('../urls/Support.vue'),
  },
  {
    path: '/support/contact',
    name: 'contact',
    meta: {
      title: 'Hilfe',
      roles: [],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/Contact.vue'),
  },
  {
    path: '/support/faq',
    name: 'faq',
    meta: {
      title: 'Hilfe',
      roles: [],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/FAQ/FAQ.vue'),
  },
  {
    path: '/billing-software',
    name: 'Billing Software',
    meta: {
      title: 'Abrechnungs-Software',
      roles: ['Vollzugriff'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/BillingSoftware.vue'),
  },
  {
    path: '/public/uploads',
    name: 'Uploads',
    meta: {
      title: 'Uploads',
      roles: ['Vollzugriff'],
    },
  },
  {
    path: '/notification-management',
    name: 'Benachrichtigungseinstellungen',
    meta: {
      title: 'Benachrichtigungseinstellungen',
      roles: ['Alpha-Feature'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/NotificationManagement.vue'),
  },
  {
    path: '/smoke-detector-manager',
    name: 'Smoke detectors',
    meta: {
      title: 'Rauchmelder',
      roles: ['Rauchmelder-Administration'],
      breadcrumbs: {
        usev2: true,
      },
    },
    component: () => import('../urls/SmokeDetectorsManager.vue'),
  },
  {
    path: '/property',
    name: 'Properties',
    meta: {
      title: 'Liegenschaften',
      roles: [
        'Vollzugriff',
        'Hausverwaltung',
        'Rauchmelder-Administration',
        'Heizkostenabrechnung',
        'Mandanten-Administration',
        'Heizungs-Betrieb',
        'Alpha-Feature',
      ],
      breadcrumbs: {
        usev2: true,
      },
      layoutConfig: {
        container: 'table',
      },
    },

    component: () => import('../atomic-design-components/templates/property/Overview.vue'),
  },
  {
    path: '*',
    redirect: '/home',
  },
  ],
});

Router.beforeEach((to, from, next) => {
  RedirectService.isAllowToHomePage(Router, to);
  return next();
});

export default Router;
