<template>
  <div :class="blockClassName">
    <div
      v-for="(item, index) in this.props.row[this.props.column.field]"
      :key="index"
      :class="childrenClassName('item')"
    >
      <i
        v-tooltip="`${item.label || ''}: ${getStatusText(item.value)}`"
        :class="[`${getIcon(item.value)}`, `text-${getColor(item.value)}, mr-2`]"
      />
    </div>
  </div>
</template>

<script>
import constants from '@/utils/constants';

export default {
  name: 'ConditionalStatusSlot',
  props: {
    props: Object,
    withLink: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getIcon(statusCode) {
      return constants.StatusCodes[statusCode].icon;
    },

    getColor(statusCode) {
      return constants.StatusCodes[statusCode].color;
    },

    getStatusText(statusCode) {
      return constants.StatusCodes[statusCode].statusText;
    },
  },
};
</script>
<style lang="scss">
  $class-name: 'ConditionalStatusSlot';

  .#{$class-name} {
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    .#{$class-name}__item {
      width: 25%;
    }
  }
</style>
