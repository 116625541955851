<template>
  <!-- eslint-disable -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="35"
    viewBox="0 0 38 35"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g>
        <g>
          <g transform="translate(-1109 -121) translate(1110 122)">
            <g>
              <path
                fill="#FFF"
                d="M26.88 13.44c0 7.422-6.018 13.44-13.44 13.44C6.017 26.88 0 20.862 0 13.44 0 6.017 6.017 0 13.44 0c7.422 0 13.44 6.017 13.44 13.44z"
              />
              <path
                stroke="#263344"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width=".96"
                d="M26.88 13.44c0 7.422-6.018 13.44-13.44 13.44C6.017 26.88 0 20.862 0 13.44 0 6.017 6.017 0 13.44 0c7.422 0 13.44 6.017 13.44 13.44zM14.88 12L20.16 6.72M2.88 13.44L4.8 13.44M3.684 9.399L5.458 10.134M5.973 5.973L7.331 7.331M9.399 3.684L10.133 5.458M13.44 2.881L13.44 4.801M17.482 3.685L16.746 5.458M23.196 9.401L21.422 10.134M23.999 13.442L22.08 13.441M23.195 17.483L21.422 16.747M3.684 17.481L5.458 16.746"
              />
              <path
                stroke="#263344"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width=".96"
                d="M8.64 22.08L18.24 22.08 18.24 19.2 8.64 19.2zM15.36 13.44c0 1.06-.86 1.92-1.92 1.92s-1.92-.86-1.92-1.92.86-1.92 1.92-1.92 1.92.86 1.92 1.92z"
              />
            </g>
            <rect
              width="25"
              height="12"
              x=".5"
              y="21.5"
              fill="#FFF"
              stroke="#263344"
              rx="2"
            />
            <path
              fill="#233447"
              fill-rule="nonzero"
              d="M10.394 31.088c.527 0 1.008-.078 1.441-.235.434-.157.77-.377 1.007-.662v-2.553h-2.518v.971h1.2v1.103c-.211.211-.565.317-1.06.317-.492 0-.865-.172-1.12-.514-.255-.343-.383-.85-.383-1.52v-.41c.003-.665.122-1.166.356-1.502.235-.337.576-.506 1.024-.506.352 0 .628.085.829.255.2.17.33.438.389.804h1.283c-.08-.691-.333-1.218-.76-1.58-.428-.361-1.021-.542-1.78-.542-.545 0-1.02.123-1.426.371-.406.248-.717.605-.932 1.072-.215.468-.323 1.02-.323 1.66v.434c.006.621.122 1.162.35 1.622.227.46.549.81.966 1.052.418.242.903.363 1.457.363zM18.533 31v-1.06h-3.226l3.155-4.583v-.755h-4.798v1.067h3.16l-3.165 4.558V31h4.874z"
            />
          </g>
          <path
            fill="#263344"
            d="M31.169 5c-1.534.923-2.94 2.773-2.94 4.667 0 1.552.682 3.122 1.764 4.083.897-.8 1.58-2.258 1.764-3.5 2.935 1.358 4.702 4.461 4.702 7.583 0 4.51-3.685 8.167-8.23 8.167C23.683 26 20 22.344 20 17.833 20 11.528 24.992 6.026 31.169 5z"
            transform="translate(-1109 -121) translate(1110 122)"
          />
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>
<script>
export default {
  name: 'AtomsIconsMetersGas',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
};
</script>
