<template>
  <span
    class="d-inline-block w-100"
    v-html="getValue || '&nbsp;'"
    @click="toggleEditing"
  />
</template>
<script>
export default {
  name: 'GetValueSlot',
  data: () => ({
    editingActive: false,
    value: null,
  }),
  props: {
    props: Object,
  },
  computed: {
    getValue() {
      if (this.props.column.getValue) {
        return this.props.column.getValue(this.props.row);
      }
      return this.value;
    },
  },
  created() {
    this.initValue();
  },
  methods: {
    initValue() {
      if (this.props.default) {
        this.value = this.props.default;
      } else if (this.props.inputType === 'text') {
        this.value = '';
      }
    },
    toggleEditing() {
      this.editingActive = !this.editingActive;
    },
    finishEditing() {
      this.editingActive = false;
      this.$parent.$parent.$emit('value-saved', this.value, this.props.row);
    },
  },
};
</script>
