<template>
  <!-- eslint-disable -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g>
        <g>
          <g transform="translate(-763 -123) translate(764 124)">
            <g>
              <path
                fill="#FFF"
                d="M26.88 13.44c0 7.422-6.018 13.44-13.44 13.44C6.017 26.88 0 20.862 0 13.44 0 6.017 6.017 0 13.44 0c7.422 0 13.44 6.017 13.44 13.44z"
              />
              <path
                stroke="#263344"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width=".96"
                d="M26.88 13.44c0 7.422-6.018 13.44-13.44 13.44C6.017 26.88 0 20.862 0 13.44 0 6.017 6.017 0 13.44 0c7.422 0 13.44 6.017 13.44 13.44zM14.88 12L20.16 6.72M2.88 13.44L4.8 13.44M3.684 9.399L5.458 10.134M5.973 5.973L7.331 7.331M9.399 3.684L10.133 5.458M13.44 2.881L13.44 4.801M17.482 3.685L16.746 5.458M23.196 9.401L21.422 10.134M23.999 13.442L22.08 13.441M23.195 17.483L21.422 16.747M3.684 17.481L5.458 16.746"
              />
              <path
                stroke="#263344"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width=".96"
                d="M8.64 22.08L18.24 22.08 18.24 19.2 8.64 19.2zM15.36 13.44c0 1.06-.86 1.92-1.92 1.92s-1.92-.86-1.92-1.92.86-1.92 1.92-1.92 1.92.86 1.92 1.92z"
              />
            </g>
            <rect
              width="25"
              height="12"
              x=".5"
              y="21.5"
              fill="#FFF"
              stroke="#263344"
              rx="2"
            />
            <path
              fill="#1F2D3D"
              fill-rule="nonzero"
              d="M5.303 31l1.041-4.28L7.386 31h1.327l1.42-6.398H8.819l-.862 4.552-1.05-4.552H5.791l-1.055 4.544-.866-4.544H2.556L3.976 31h1.327zm7.87 0l1.042-4.28L15.257 31h1.327l1.42-6.398h-1.315l-.861 4.552-1.05-4.552H13.66l-1.054 4.544-.866-4.544h-1.314L11.847 31h1.327zm10.165 0v-1.06h-3.225l3.155-4.583v-.755h-4.799v1.067h3.16l-3.164 4.558V31h4.873z"
            />
          </g>
          <path
            fill="#263344"
            d="M34 17.12c0 3.8-3.134 6.88-7 6.88s-7-3.08-7-6.88C20 13.322 27 3 27 3s7 10.321 7 14.12z"
            transform="translate(-763 -123) translate(764 124)"
          />
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>
<script>
export default {
  name: 'AtomsIconsMeterWaterHot',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
};
</script>
