<template>
  <span v-tooltip="props.row[props.column.field + '_tooltip']">
    <div class="slot-wrapper">
      <div
        v-click-outside="closeDropdown"
        clasS="pl-2 dropdown__trigger"
      >
        <div
          class="dropdown__text"
          @click="toggleDropdown"
        >
          <span>
            <nobr>
              <i
                v-if="props.row[props.column.field].icon"
                :class="
                  'pr-2 kg kg-' +
                    (typeof (
                      getStatusCode(props.row[props.column.field].icon) !== 'undefined'
                    )
                      ? getStatusCode(props.row[props.column.field].icon).symbol
                      : 'question') +
                    ' text-' +
                    (typeof getStatusCode(props.row[props.column.field].icon) !==
                      'undefined'
                      ? getStatusCode(props.row[props.column.field].icon).color
                      : 'secondary')
                "
              />
              {{ props.row[props.column.field].text }}
            </nobr>
          </span>
          <i
            v-if="props.row[props.column.field].dropdown"
            :class="['kg kg-chevron-down dropdown__icon', {open: showDropdown}]"
          />
        </div>
        <div
          v-if="props.row[props.column.field].dropdown"
          class="dropdown"
        >
          <ul
            v-if="showDropdown"
            class="dropdown__list"
          >
            <li
              v-for="(item, key) in props.row[props.column.field].dropdown"
              :key="key"
              class="dropdown__item"
            >
              <i
                :class="
                  'pr-2 kg kg-' +
                    (typeof (
                      getStatusCode(item.icon) !== 'undefined'
                    )
                      ? getStatusCode(item.icon).symbol
                      : 'question') +
                    ' text-' +
                    (typeof getStatusCode(item.icon) !==
                      'undefined'
                      ? getStatusCode(item.icon).color
                      : 'secondary')
                "
              />
              {{ item.text }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import constants from '@/utils/constants';

export default {
  name: 'WithDropdownSlot',
  props: {
    props: Object,
    withLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    closeDropdown() {
      this.showDropdown = false;
    },
    getLockedState() {
      return constants.State.LOCKED;
    },
    getStatusCode(num) {
      if (typeof num === 'object') {
        return constants.StatusCodes[num[0]];
      } return constants.StatusCodes[num];
    },
  },
};
</script>

<style lang="scss" scoped>
.slot-wrapper {
  cursor: pointer;
}
.dropdown{
  position: relative;
  left: -9px;
  top: calc(100% + 10px);
  min-width: calc(100% + 17px);
  z-index: 9;
  max-width: 300px;

  &__list {
    max-width: 100%;
    list-style: none;
    width: max-content;
    border: 1px solid var(--neutral-500);
    padding: 0;
  }

  &__item {
    width: 100%;
    display: block;
    cursor: default;
    background-color: #fff;
    text-align: left;
    font-weight: normal;
    padding: 10px;
    background: var(--neutral-200);
    border-bottom: 1px solid var(--neutral-500);
    font-size: 14px;
    line-height: 1.43;

    &:last-of-type {
      border: none;
    }
  }

  &__trigger {
    position: relative;
  }

  &__text {
    padding-right: 15px;
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 3px;
    &.open {
      transform: rotateX(180deg);
    }
  }
}
</style>
