export default {
  props: {
    cyEnable: {
      type: Boolean,
      default: false,
    },
    cySalt: {
      type: [Number, String],
      default: undefined,
    },
  },

  data() {
    return {
      cyOnce: false,
    };
  },

  methods: {
    cyRun(once = true) {
      if (window.Cypress && !this.cyOnce) this.eventHub.$emit('view-loaded');
      this.cyOnce = once;
    },
  },
};
