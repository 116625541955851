<template>
  <!-- eslint-disable -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="31"
    viewBox="0 0 26 31"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g>
        <g transform="translate(-1219 -125) translate(1219 125)">
          <rect
            width="13"
            height="22"
            x="6.5"
            y=".5"
            fill="#FFF"
            stroke="#263344"
            rx="3"
          />
          <rect
            width="25"
            height="12"
            x=".5"
            y="18.5"
            fill="#FFF"
            stroke="#263344"
            rx="2"
          />
          <path
            fill="#1F2D3D"
            fill-rule="nonzero"
            d="M5.912 28v-2.742h2.57V28h1.319v-6.398H8.482v2.592h-2.57v-2.592H4.593V28h1.319zm6.359 0v-1.828l.685-.738L14.6 28h1.568l-2.337-3.555 2.272-2.843H14.48l-1.63 2.105-.58.795v-2.9h-1.319V28h1.319zm7.448 0l2.228-6.398H20.48l-1.45 4.812-1.441-4.812h-1.463L18.344 28h1.375z"
          />
          <path
            fill="#FFF"
            stroke="#263344"
            d="M9.5 7.5H16.5V10.5H9.5z"
          />
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>
<script>
export default {
  name: 'AtomsIconsMetersHeatVolume',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
};
</script>
