<template>
  <!-- eslint-disable -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="35"
    viewBox="0 0 36 35"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g>
        <g>
          <g transform="translate(-1166 -121) translate(1167 122) translate(0 .963)">
            <g>
              <path
                fill="#FFF"
                d="M12.4 0c6.844 0 12.4 5.556 12.4 12.4s-5.556 12.4-12.4 12.4S0 19.244 0 12.4 5.556 0 12.4 0z"
              />
              <path
                stroke="#263344"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.12"
                d="M12.4 0c6.844 0 12.4 5.556 12.4 12.4s-5.556 12.4-12.4 12.4S0 19.244 0 12.4 5.556 0 12.4 0z"
              />
              <path
                stroke="#263344"
                stroke-dasharray="17.412 4.353 0 0"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.12"
                d="M12.4 2.533c5.446 0 9.867 4.421 9.867 9.867 0 5.446-4.421 9.867-9.867 9.867-5.446 0-9.867-4.421-9.867-9.867 0-5.446 4.421-9.867 9.867-9.867z"
              />
              <path
                stroke="#263344"
                stroke-dasharray="17.412 4.353 0 0"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.12"
                d="M12.4 5.3c3.919 0 7.1 3.181 7.1 7.1 0 3.919-3.181 7.1-7.1 7.1-3.919 0-7.1-3.181-7.1-7.1 0-3.919 3.181-7.1 7.1-7.1z"
              />
              <path
                stroke="#263344"
                stroke-dasharray="17.412 4.353 0 0"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.12"
                d="M12.4 8.24c2.296 0 4.16 1.864 4.16 4.16 0 2.296-1.864 4.16-4.16 4.16-2.296 0-4.16-1.864-4.16-4.16 0-2.296 1.864-4.16 4.16-4.16z"
              />
            </g>
            <rect
              width="25"
              height="12"
              x=".5"
              y="20.5"
              fill="#FFF"
              stroke="#263344"
              rx="2"
            />
            <path
              fill="#1F2D3D"
              fill-rule="nonzero"
              d="M4.14 30v-2.342h1.05L6.39 30h1.416v-.062L6.42 27.324c.384-.173.67-.405.86-.697.188-.291.283-.655.283-1.092 0-.615-.205-1.091-.615-1.428-.41-.337-.993-.505-1.75-.505H2.823V30h1.319zm1.064-3.41H4.141v-1.92H5.2c.354 0 .617.086.789.259.17.173.257.41.257.712 0 .296-.091.528-.273.696-.181.169-.438.253-.769.253zM10.895 30l1.042-4.28L12.978 30h1.327l1.42-6.398H14.41l-.862 4.552-1.05-4.552h-1.116l-1.055 4.544-.866-4.544H8.148L9.568 30h1.327zm6.86 0v-1.749l-.132-3.015L19.346 30h.905l1.727-4.768-.132 3.019V30h1.323v-6.398h-1.732l-1.634 4.64-1.644-4.64h-1.722V30h1.318z"
            />
          </g>
          <path
            fill="#263344"
            stroke="#263344"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.12"
            d="M25.832 5h-.013C23.87 6.955 18 8.538 18 8.538c0 6.23.391 11.709 7.826 14.462 7.435-2.753 7.826-8.231 7.826-14.462 0 0-5.87-1.583-7.82-3.538z"
            transform="translate(-1166 -121) translate(1167 122)"
          />
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>
<script>
export default {
  name: 'AtomsIconsMeterSmokeDetector',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
};
</script>
