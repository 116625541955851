export const getters = (state) => ({
  getNames() {
    return state.names;
  },

  getBreadcrumbs() {
    return state.breadcrumbs;
  },

  getLabel() {
    return state.label;
  },
});
