import { readonly } from 'vue';
import { state } from '@/store/graphs/state';
import { methods } from '@/store/graphs/methods';
import { getters } from '@/store/graphs/getters';

export const graphs = readonly({
  state,
  methods: methods(state),
  getters: getters(state),
});
