const config = {
  mobile: {
    start: 0,
    end: 1023,
  },
  tablet: {
    start: 1024,
    end: 1335,
  },
  laptop: {
    start: 1335,
    end: 1919,
  },
  desktop: {
    start: 1920,
    end: 2519,
  },
  extraWide: {
    start: 2560,
  },
};

const calculateCurrentTypeView = () => {
  if (window.$config.mobile.end > window.innerWidth) {
    return 'mobile';
  } if (window.$config.tablet.end > window.innerWidth) {
    return 'tablet';
  } if (window.$config.laptop.end > window.innerWidth) {
    return 'laptop';
  } if (window.$config.desktop.end > window.innerWidth) {
    return 'desktop';
  }
  return 'extraWide';
};

const isMobile = () => {
  window.addEventListener('resize', () => {
    window.$eventHub.$emit('window-resize', {
      typeView: calculateCurrentTypeView(),
      currentWidth: window.innerWidth,
    });
  });
  return window.$config.mobile.end > window.innerWidth;
};

window.$config = config;
window.$media = {
  isMobile: () => isMobile(),
  typeView: () => calculateCurrentTypeView(),
  currentWidth: window.innerWidth,
};
