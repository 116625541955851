<template>
  <div :class="`row`">
    <div :class="`col-12 centered ${noLayout ? 'p-0 m-0 pl-3' : 'mt-4  pt-4 pb-4'}  text-${color}`">
      <div
        :class="`spinner-border ${small ? 'spinner-border-sm': ''} text-${color}`"
        role="status"
      >
        <span class="sr-only m-auto d-block w-100">Wird geladen...</span>
      </div>
    </div>
    <div
      v-if="loadingMessage"
      :class="`col-8 offset-2 mb-4 text-${color} text-center`"
    >
      {{ loadingMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingIndicator',
  props: {
    loadingMessage: String,
    color: {
      type: String, default: 'primary',
    },
    small: {
      type: Boolean, default: false,
    },
    noLayout: {
      type: Boolean, default: false,
    },
  },
};
</script>
<style scoped>
.button {
  cursor: pointer;
}
.centered {
  margin: auto
}
</style>
