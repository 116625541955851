<template>
  <div
    v-if="loaded"
    :key="editingActive"
    class="w-100"
  >
    <span
      v-if="!editingActive"
      :key="fieldInfo.column.reading"
      v-tooltip="getTooltip()"
      :class="getClass(rowIsEditable)"
      @click="openPicker"
    >
      {{ getValue() }}
      <i
        v-if="rowIsEditable"
        :class="'kg kg-calendar float-right pt-1 pl-1 initialToggle' + _uid"
      />
    </span>
    <span
      v-else-if="editingActive"
      :class="'d-inline-block w-100 initialToggle' + _uid"
    >
      <ExtendedDatePicker
        v-click-outside="closeDatePicker"
        :buttons="[]"
        :dates="fieldInfo.row.datePickerDates"
        :extras="fieldInfo.row.datePickerAttributes"
        :initial-date="inputField.initialDate"
        :tabs="inputField.row.tabs"
        @picked="onDatePick"
      />
    </span>
  </div>
</template>

<script>
import ExtendedDatePicker from '@/components/ExtendedDatePicker';

export default {
  name: 'DatepickerSlot',
  components: {
    ExtendedDatePicker,
  },
  props: {
    inputField: Object,
  },
  data() {
    return {
      editingActive: false,
      fieldInfo: null,
      value: null,
      loaded: false,
    };
  },
  watch: {
    editingActive() {
      this.getValue();
    },
    inputField: {
      handler() {
        this.getValue();
      },
      deep: true,
    },
  },
  computed: {
    rowIsEditable() {
      if (this.fieldInfo.column.isEditable) {
        return this.fieldInfo.column.isEditable(this.fieldInfo.row);
      } return false;
    },
  },
  beforeMount() {
    this.initValue();
  },
  methods: {
    getValue() {
      if (this.fieldInfo.column.getValue) {
        return this.fieldInfo.column.getValue(this.fieldInfo.row);
      }
      return this.value;
    },
    getClass(isEditable) {
      if (isEditable) return `p-2 w-100 d-inline-block cursor-pointer initialToggle${this._uid}`;
      return '';
    },
    closeDatePicker(ev) {
      if (ev.target.className) {
        if (!ev.target.className.includes(`initialToggle${this._uid}`)) {
          this.editingActive = false;
        }
      }
    },
    getTooltip() {
      if (this.fieldInfo.column.getTooltip) {
        return this.fieldInfo.column.getTooltip(this.fieldInfo.row);
      }
      return '';
    },
    onDatePick(value, tabName, tabIndex) {
      this.value = value;
      this.editingActive = false;
      this.fieldInfo.row.pickedDate = {
        date: value.date,
        from: tabName,
        tabIndex,
      };
      this.$parent.$parent.$emit(
        'value-saved',
        this.value.attributes,
        this.fieldInfo.row,
      );
    },
    initValue() {
      this.fieldInfo = this.inputField;

      if (this.fieldInfo.default) {
        this.value = this.fieldInfo.default;
      } else if (this.fieldInfo.inputType === 'text') {
        this.value = '';
      }
      this.loaded = true;
    },
    getFormatedOption(option) {
      if (this.fieldInfo.column.getFormatedOption) {
        return this.fieldInfo.column.getFormatedOption(option);
      }
      return option;
    },
    openPicker() {
      if (this.rowIsEditable) this.editingActive = true;
    },
  },
};
</script>

<style>
.pretty {
    color: 'red' !important;
}
</style>
