<template>
  <span style="cursor:pointer;">
    <span
      v-if="!editingActive"
      v-tooltip="getTooltip()"
      class="d-inline-block w-100"
      @click="toggleEditing"
    >{{ getValue || '&nbsp;' }}</span>
    <span
      v-else
      class="d-inline-block w-100"
    >
      <select
        class="d-inline-block"
        required
        @change="onSelectionChange()"
        v-model="value"
      >
        <option
          disabled
          hidden
          selected
          :value="null"
        >Lesedatum auswählen</option>
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option"
        >{{ getFormatedOption(option) }}</option>
      </select>
    </span>
  </span>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'SelectSlot',
  data: () => ({
    editingActive: false,
    value: null,
    options: [],
  }),
  props: {
    props: Object,
  },
  computed: {
    getValue() {
      if (this.props.column.getValue) {
        return this.props.column.getValue(this.props.row);
      }
      return this.value;
    },
  },
  created() {
    this.initValue();
  },
  methods: {
    getTooltip() {
      if (this.props.column.getTooltip) {
        return this.props.column.getTooltip(this.props.row);
      }
      return '';
    },
    onSelectionChange() {
      this.editingActive = false;
      Vue.set(this.props.row, 'selectedOption', this.value);
    },
    initValue() {
      if (this.props.default) {
        this.value = this.props.default;
      } else if (this.props.inputType === 'text') {
        this.value = '';
      }
    },
    getFormatedOption(option) {
      if (this.props.column.getFormatedOption) {
        return this.props.column.getFormatedOption(option);
      }
      return option;
    },
    toggleEditing() {
      if (this.props.column.isEditable(this.props.row)) {
        this.editingActive = !this.editingActive;
        this.options = this.props.row.options;
      }
    },
    finishEditing() {
      this.editingActive = false;
      this.$parent.$parent.$emit('value-saved', this.value, this.props.row);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
