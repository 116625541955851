export default {
  computed: {
    isFullRemote() {
      return this.hasUserKeycloakRole('Vollzugriff');
    },
    isSmokeDetector() {
      return this.hasUserKeycloakRole('Rauchmelder-Administration');
    },
    isPropertyManager() {
      return this.hasUserKeycloakRole('Hausverwaltung');
    },
    isBiller() {
      return this.hasUserKeycloakRole('Heizkostenabrechnung');
    },
  },
};
