<template>
  <div :class="blockClassName">
    <div :class="[childrenClassName('title'), { separator: separator }, { primaryTitle: primaryTitle }]">
      {{ title }}
    </div>
    <div :class="childrenClassName('content')">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'PanelWrapper',
  props: {
    title: String,
    primaryTitle: {
      type: Boolean,
      default: true,
    },
    separator: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
$class-name: 'PanelWrapper';

.#{$class-name} {
  box-shadow: var(--box-shadow);
  background: white;
  border: var(--border);
  border-radius: var(--border-radius-l);

  .#{$class-name}__content {
    padding: var(--spacing-xl);
  }
  .#{$class-name}__title {
    font-size: var(--title-size);
    padding: var(--spacing-xl);

    &.separator {
      border-bottom: var(--border);
    }
    &.primaryTitle {
      color: var(--primary);
      font-size: var(--font-16);
    }
  }
}
</style>
