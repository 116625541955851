<template>
  <div>
    <v-calendar
      ref="calendar"
      :attributes="attrs"
      :available-dates="availableDates"
      :disabled-dates="disabledDates"
      :first-day-of-week="2"
      :hightlight="true"
      locale="de"
      popover="{'background-color': 'red'}"
      @dayclick="emitDate"
    >
      <div
        slot="day-popover"
        slot-scope="{ attributes }"
        class="pretty"
      >
        <span class="text-white">{{ attributes[0].customData.description }} </span>
      </div>
    </v-calendar>
  </div>
</template>

<script>
import colors from '@/utils/colors';

export default {
  name: 'DatePicker',
  props: {
    availableDates: Array,
    attributes: Array,
    initialDate: String,
  },
  data() {
    return {
      attrs: this.attributes || [],
      disabledDates: (this.availableDates && this.availableDates.length === 0) ? {
        weekdays: [1, 2, 3, 4, 5, 6, 7],
      } : [],
    };
  },
  methods: {
    emitDate(day) {
      this.removeInitialHighlight();
      this.$emit('picked', day);
    },
    removeInitialHighlight() {
      const isInitial = (element) => element.key === 'initial';
      const i = this.attrs.findIndex(isInitial);
      if (i > -1) {
        this.attrs.splice(i, 1);
      }
    },

    modifyDate() {
      if (this.initialDate) {
        this.$refs.calendar.showPageRange(new Date(this.initialDate));
        const highlight = {
          highlight: {
            color: 'gray',
            fillMode: 'light',
          },
          key: 'initial',
          fillMode: 'outline',
          content: colors.primary,
          dates: new Date(this.initialDate),
        };

        this.attrs.push(highlight);
      } else if (this.availableDates && this.availableDates.length > 0) {
        const lastDate = this.availableDates[0];
        this.$refs.calendar.showPageRange(lastDate.start ? new Date(lastDate.start) : new Date());
      }
    },
  },

  watch: {
    initialDate() {
      this.modifyDate();
    },
  },

  mounted() {
    this.modifyDate();
  },

};
</script>

<style lang="css" scoped>
.vc-border {
    border: none !important
}

.vc-rounded-lg {
    border-radius: 0;
}
</style>
