<template>
  <!-- eslint-disable -->
  <svg
    width="5"
    height="6"
    viewBox="0 0 5 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="blockClassName"
  >
    <path
      :class="childrenClassName('path', [status])"
      d="M2.05457 4.70795H4.7C4.86569 4.70795 5 4.84226 5 5.00795V5.7C5 5.86569 4.86569 6 4.7 6H0.3C0.134315 6 0 5.86569 0 5.7V5.12515C0 5.05955 0.0215018 4.99576 0.0612127 4.94354L2.83408 1.2976H0.383519C0.217833 1.2976 0.0835189 1.16328 0.0835189 0.997597V0.3C0.0835189 0.134315 0.217834 0 0.383519 0H4.61091C4.7766 0 4.91091 0.134315 4.91091 0.3V0.746177C4.91091 0.877271 4.86798 1.00476 4.78867 1.10914L2.05457 4.70795Z"
    />
  </svg>
  <!-- eslint-enable -->
</template>
<script>
export default {
  name: 'AtomsIconsVariantsSnoozingTiny',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
  $class-name: 'AtomsIconsVariantsSnoozingTiny';

  .#{$class-name} {
    .#{$class-name}__path {
      &--success, &--warning, &--critical, &--locked, &-null, &--snoozing {
        fill: var(--neutral-700);
      }
    }
  }
</style>
