export default {
  pagination: {
    enabled: true,
    nextLabel: 'Nächste',
    prevLabel: 'Vorherige',
    rowsPerPageLabel: 'Einträge pro Seite',
    ofLabel: 'von',
    pageLabel: 'Seite',
    allLabel: 'Alle',
    perPage: 50,
    perPageDropdown: [25, 50, 75, 100],
  },
  searchbar: {
    enabled: true,
    placeholder: 'Durchsuchen',
  },
};
