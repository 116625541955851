<template>
  <div
    ref="navtab"
    :class="['tab-navigation border-bottom', {'toTop': toTop}]"
  >
    <div class="page-content">
      <h2 class="font-display-large color-neutral-900">
        {{ config.title }}
      </h2>
      <ul ref="list">
        <content-nav-link
          v-for="(link, i) in currentLinks"
          :key="i"
          :link="link"
        />
        <li
          v-if="showMore"
          class="show-more"
        >
          <molecules-navigations-sub-menu :sub-menu-links="submenuLinks" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import MoleculesNavigationsSubMenu from '@/atomic-design-components/molecules/navigations/SubMenu';
import ContentNavLink from '@/atomic-design-components/atoms/ContentNavLink.vue';

export default {
  components: {
    ContentNavLink,
    MoleculesNavigationsSubMenu,
  },
  name: 'ContentNavigation',
  props: {
    config: Object,
  },

  data() {
    return {
      currentScrollPosition: 0,
      displayed: false,
      toTop: false,
      currentLinks: this.config.links,
      showMore: false,
      submenuLinks: [],
    };
  },

  destroyed() {
    this.displayed = false;
  },

  /* eslint-disable */
  mounted() {
    this.displayed = true;
    this.handleScroll();
  
    !window.$media.isMobile() && this.buildSubmenu();
    this.handleResize();
  },
  /* eslint-enable */

  methods: {
    handleResize() {
      window.addEventListener('resize', () => {
        if (window.$media.isMobile()) {
          this.currentLinks = this.config.links;
          this.submenuLinks = [];
          return;
        }

        if (!this.displayed) return;
        this.submenuLinks = [];
        this.currentLinks = this.config.links;
        this.buildSubmenu();
      });
    },

    handleScroll() {
      window.addEventListener('scroll', () => {
        if (window.scrollY > 100) {
          document.body.classList.add('scrolled');
        } else {
          document.body.classList.remove('scrolled');
        }
      });
    },

    buildSubmenu() {
      const clientWidth = this.$refs.list.clientWidth - 100;
      let widthElements = [];
      const elementsToSubMenu = [];
      let currentValue = 0;
      let diff = 0;
      this.$refs.list.childNodes.forEach((item) => {
        if (item.childNodes.length) {
          widthElements.push({
            to: item.childNodes[0].href,
            name: item.childNodes[0].textContent.trim(),
            width: item.clientWidth,
          });
        }
      });

      widthElements = widthElements.reverse();
      let sum = 0;
      widthElements.forEach((item) => sum += item.width);
      if (sum - 100 < clientWidth) {
        this.currentLinks = this.config.links;
        this.showMore = false;
        return;
      }

      diff = sum - clientWidth;
      this.showMore = true;

      for (let i = 1; i < widthElements.length; i++) {
        if (currentValue > diff) {
          break;
        }

        widthElements.active = true;
        elementsToSubMenu.push(widthElements[i]);
        currentValue += widthElements[i].width;
      }

      elementsToSubMenu.forEach((item) => {
        this.submenuLinks.push(this.currentLinks.find((link) => link.name === item.name));
        this.currentLinks = this.currentLinks.filter((link) => link.name !== item.name);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@include mq(lg) {
  .scrolled .tab-navigation {
    padding-top: 0px;
  }
}

@include mq(xl) {
  #wrapper .tab-navigation {
    height: 220px;
    padding-top: 120px;
  }
}

ul {
  list-style: none;
  position: relative;
  transition: all 0.3s;
  display: inline-flex;
  align-items: center;
  top: 0;
  margin: 0 0 0 0;
  padding: 0;
  width: 100%;
  overflow-x: auto;
  overflow-y: visible;
}

.show-more {
  position: relative;
}

.scrolled .tab-navigation {
  padding-top: 0;
  box-shadow: 0px 0px 0px 0px;
}

.color-neutral-900 {
  color: var(--neutral-900);
}

.valid .tab-navigation.toTop {
  padding-top: 6rem;
}

.tab-navigation {
  transform: translateY(0px);
  padding-top: 36px;
  left: var(--sidebar-width);
  transition: .5s;
  z-index: 400;
  background: white;
}

h2 {
    margin-left: var(--spacing-l);
    text-align: left;
    display: none;
    transition: all .3s ease-in-out;
}

@include mq(md) {
  h2{
    display: block;
  }
  .scrolled h2 {
    @include chHeadingTransition;
  }
}

@media (min-width: 1023px) {
  .page-content {
    padding-left: 24px;
  }

  h2 {
    display: inline;
    margin-right: 20px;
    padding-left: 24px;
    flex: 1;
    align-items: center;
    display: flex;
    margin-bottom: 15px;
  }

  ul {
    overflow-x: visible;
    margin: 0 0 0 0;
  }

  h2 {
    margin-left: 0;
  }

  @include mq('xxl') {
    .page-content {
      padding-left: 0;
      max-width: 1340px;
      margin: auto;
    }
  }
}
</style>
