<template>
  <div
    v-cy
    :class="['card', { 'mb-4 p-0 mx-0 mt-4': !hideHeader }]"
    :data-testid="tableId + '-table'"
  >
    <CardHeader
      v-if="!hideHeader"
      :button="button"
      :button-text="buttonText"
      :button-type="buttonType"
      :collapse-id="collapseId"
      :collapsible="collapsible"
      :loading-button="loadingButton"
      :title="title"
      :title-extension="titleExtension"
      @emitButtonClicked="emitButtonClicked"
    >
      <template #header-before-button>
        <slot name="before-button-card" />
      </template>
    </CardHeader>
    <div
      :id="collapseId"
      :class="'getter card-body p-0 ' + collapsed"
    >
      <p
        v-if="subtitle"
        class="bg-black-200 font-weight-bold p-3 m-0"
      >
        {{ subtitle }}
      </p>

      <slot name="table-tabs" />
      <slot name="table-head" />
      <VueTable
        :key="grouped"
        ref="table"
        :button="button"
        :button-text="buttonText"
        :button-type="buttonType"
        :class="{ 'hidden-header': hideHeader }"
        :columns="tableColumns"
        :current-page="currentPage"
        :date-picker="datePicker"
        :date-picker-date="datePickerDate"
        :date-picker-tabs="datePickerTabs"
        :empty-state-text="emptyStateText"
        :empty-text="emptyText"
        :grouped="grouped"
        :grouped-button="groupedDetails"
        :hide-filters="hideFilters"
        :hide-header="hideHeader"
        :loading-button="loadingButton"
        :method-to-update-to-update-footer="methodToUpdateToUpdateFooter"
        :rows="tableRows"
        :search="search"
        :select-options="checkBoxSettings"
        :sort-options="sortSettings"
        :status-options="statusOptions"
        :table-error="tableError"
        :table-id="tableId"
        :table-type="tableType"
        :title-extension="titleExtension"
        :use-pagination="usePagination"
        @addCosts="addCosts"
        @clicked="clicked"
        @count-row-updated="emitRowUpdated"
        @custom-change-page="onPageChange"
        @datePickerEmit="datePickerEmit"
        @emitButtonClicked="emitButtonClicked"
        @emitter="emitter"
        @filtered="filtered"
        @on-selected-rows-change="onSelectionChange"
        @onInput="onInput"
        @onPageChange="onPageChange"
        @picked="datePicked"
        @sorted="sorted"
        @toggleTableType="toggleTableType"
        @value-saved="valueSaved"
      />
    </div>
  </div>
</template>

<script>
import CardHeader from '@/components/CardHeader';
import VueTable from '@/components/VueTable';
import sorting from '@/utils/sorting';

export default {
  name: 'TableCard',
  components: {
    CardHeader,
    VueTable,
  },
  computed: {
    checkBoxSettings() {
      const disabled = {
        enabled: false,
      };
      if (this.selectOptions) {
        if (this.selectOptions.groupedOnly) {
          if (this.grouped) return this.selectOptions;
        } else if (this.selectOptions.unGroupedOnly) {
          if (!this.grouped) return this.selectOptions;
        } else return this.selectOptions;
      }
      return disabled;
    },
    collapsed() {
      const col = this.alreadyCollapsed ? 'collapse' : 'null';
      return col;
    },
    groupedButtonContent() {
      return this.grouped ? 'Tabelle einklappen' : 'Tabelle ausklappen';
    },
    tableRows() {
      return this.grouped ? this.groupedRows : this.rows;
    },
    sortSettings() {
      return this.grouped ? {
        enabled: false,
      }
        : this.sortOptions;
    },
    tableColumns() {
      return this.grouped
        ? this.checkColumns(this.groupedColumns)
        : this.checkColumns(this.columns);
    },
  },
  data() {
    return {
      defaultCols: [],
      grouped: this.groupedButton,
    };
  },
  props: {
    statusOptions: Array,
    tableError: String,
    emptyText: String,
    tableId: {
      type: String,
      default: '',
    },
    groupedButton: {
      type: Boolean,
      default: false,
    },
    groupedDetails: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    datePickerTabs: {
      type: Array,
      default() {
        return ['Gespeichert', 'Durchlaufend'];
      },
    },
    currentPage: {
      type: Number,
      default: null,
    },
    hideFilters: {
      type: Boolean,
      default: false,
    },
    titleExtension: String,
    sortOptions: {
      type: Object,
      default() {
        return {
          enabled: true,
        };
      },
    },
    loadingButton: {
      type: Boolean,
      default: false,
    },
    columns: Array,
    groupedColumns: Array,
    subtitle: String,
    rows: Array,
    groupedRows: Array,
    datePicker: {
      default: false,
      type: Boolean,
    },
    datePickerDate: String,
    title: {
      default: '',
      type: String,
    },
    search: Object,
    selectOptions: Object,
    button: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonType: {
      type: String,
      default: 'basic',
    },
    methodToUpdateToUpdateFooter: {
      type: String,
      default: 'default',
    },
    alreadyCollapsed: {
      type: Boolean,
      default: false,
    },
    emptyStateText: {
      type: String,
      default: 'Entschuldigung, wir konnten keine Geräte finden, die Ihren Suchkriterien entsprechen.',
    },
    collapsible: {
      type: Boolean,
      default: false,
    },
    collapseId: {
      type: String,
      default: 'collapseId',
    },
    defaultColumns: {
      type: Boolean,
      default: false,
    },
    tableType: {
      validator: (type) => ['main', 'sidebar'].includes(type),
      default: 'main',
    },
    usePagination: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    checkColumns(columns) {
      if (columns) {
        return columns.filter(
          (col) => col.role === true || col.role === undefined,
        );
      }
      return [];
    },
    toggleTableType() {
      this.grouped = !this.grouped;
      this.$emit('toggleTableType', this.grouped);
    },
    datePickerEmit(v, functionName, tabName) {
      this.$emit('datePickerEmit', v, functionName, tabName);
    },
    datePicked(v, tabName) {
      this.$emit('picked', v, tabName);
    },
    forceChangePage(page) {
      this.$refs.table.forceChangePage(page);
    },
    clearSelection() {
      this.$refs.table.unselectAllInternal();
    },
    onSelectionChange(tabledata) {
      this.$emit('onSelectionChange', tabledata);
    },
    onPageChange(value) {
      this.$emit('onPageChange', value);
    },
    hideColumn(fieldName) {
      this.$refs.table.hideColumn(fieldName);
    },
    showColumn(fieldName) {
      this.$refs.table.showColumn(fieldName);
    },
    emitButtonClicked() {
      this.$emit('buttonClicked');
    },
    emitRowUpdated(value) {
      this.$emit('count-row-updated', value);
    },
    clicked(item, event, name) {
      this.$emit('clicked', item, event, name);
    },
    filtered(item) {
      this.$emit('filtered', item);
    },
    emitter(functionName, rowInfo, parameters = null) {
      if (parameters) this.$emit(functionName, rowInfo, parameters);
      else this.$emit(functionName, rowInfo);
    },
    onInput(value, column) {
      this.$emit('onInput', value, column);
    },
    sorted(item) {
      this.$emit('sorted', item);
    },
    addCosts() {
      this.$emit('addCosts');
    },
    valueSaved(value, row) {
      this.$emit('value-saved', value, row);
    },
  },
  mounted() {
    if (this.defaultColumns) {
      if (this.rows[0]) {
        const cols = [];
        for (const eKey in this.rows[0]) {
          cols.push({
            label: eKey,
            field: eKey,
            sortFn: sorting.sortFn,
            width: eKey === 'Bezeichnung' ? '170px' : undefined,
            // filterOptions: {
            //   placeholder: "Ungefiltert",
            //   filterValue: localStorage.getItem(eKey) ? localStorage.getItem(eKey) : null,
            //   enabled: true, // enable filter for this column
            //   filterFn: filtering.filterObject,
            // }
          });
          for (const col in this.columns) {
            if (this.columns[col].field === eKey) {
              cols.pop();
              cols.push(this.columns[col]);
            }
          }
        }
        this.columns = cols;
      }
    }
    if (this.collapsible === true) {
      const elements = window.document.getElementsByClassName('getter');

      for (const e in elements) {
        if (elements[e].classList) {
          elements[e].classList.add('collapse');
        }
      }
    }
  },
};
</script>

<style lang="scss">
  .hidden-header {
    .vgt-global-search {
      display: grid;
      grid-column-gap: 16px;
      grid-template-columns: 1fr minmax(max-content, 1fr);
      align-items: center;

      &__actions {
          display: none;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .grouped-button {
    width: auto;

    &__wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }
</style>
