import { isNull, includes } from 'lodash';

export default {
  getMainColors() {
    const root = window.getComputedStyle(document.body);
    const primary = root.getPropertyValue('--primary');
    const warning = root.getPropertyValue('--warning');
    const danger = root.getPropertyValue('--danger');
    const secondary = root.getPropertyValue('--secondary');
    return [danger, primary, warning, secondary];
  },
  // sourced via: https://www.sitepoint.com/javascript-generate-lighter-darker-color/
  getPercentageOf(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = '#';
    var c;
    var i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += (`00${c}`).substr(c.length);
    }

    return rgb;
  },
  /* eslint-disable */
  getContrastedColorClass(color = undefined, bgDomSelector, class_type = 'fg') {
    try {
      /* eslint-disable */
      color = (bgDomSelector != undefined && document.querySelector(bgDomSelector)) ? document.querySelector(bgDomSelector).style.backgroundColor : color;
      /* eslint-enable */
      color = (color === undefined || color === null) ? '#ffffff' : color;

      if (!isNull(color)) {
        const rgb = (!includes(color, '#'))
          ? color.substring(color.indexOf('(') + 1, color.length - 1).split(',')
          : color.substring(1, color.length).match(/.{1,2}/g);

        const R = !includes(color, '#') ? parseInt(rgb[0]) : parseInt(rgb[0], 16);
        const G = !includes(color, '#') ? parseInt(rgb[1]) : parseInt(rgb[1], 16);
        const B = !includes(color, '#') ? parseInt(rgb[2]) : parseInt(rgb[2], 16);

        const contrastColorToTheBackground = ((R * 299) + (G * 587) + (B * 114)) / 1000;
        const fgClass = (contrastColorToTheBackground >= 125) ? 'black-contrasted' : 'white-contrasted';
        const bgClass = (contrastColorToTheBackground >= 125) ? 'bg-black' : 'bg-white';

        return class_type === 'fg' ? fgClass : bgClass;
      }
      if (isNull(color)) {
        const fgClass = 'white-contrasted';
        const bgClass = 'bg-white';
        return class_type === 'fg' ? fgClass : bgClass;
      }
    } catch (error) {
      window.console.log(error);
    }
  },
  /* eslint-enable */
};
