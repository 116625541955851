var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"cy",rawName:"v-cy"}],class:[_vm.slim ? 'slim-frame' : 'frame', _vm.disabled ? 'read-only opacity-50' : '', _vm.getVisibility],attrs:{"data-testid":_vm.label}},[(!_vm.checkbox)?_c('div',{staticClass:"row"},[_c('span',{class:[
        _vm.links.length > 0 ? 'col-5' : 'col-12',
        'label',
        `font-${_vm.labelWeight}`,
        `font-size-${_vm.labelSize}`,
        'flex',
      ]},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.frameLabel)}}),(_vm.labelTooltip)?_c('atoms-icons',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.labelTooltip),expression:"labelTooltip"}],staticClass:"tooltip",attrs:{"color":"var(--kugu-blue-400)","icon":"kgs kgs-info-circle"}}):_vm._e(),(_vm.resetButton)?_c('button',{class:['reset-button', 'font-body-regular'],on:{"click":_vm.resetModel}},[_vm._v(" zurücksetzen ")]):_vm._e()],1),(_vm.links.length > 0)?_c('div',{staticClass:"col-7"},_vm._l((_vm.links),function(link,i){return _c('a',{directives:[{name:"cy",rawName:"v-cy",value:(`link`),expression:"`link`"}],key:i,class:`pl-1 font-${_vm.labelWeight}  font-size-${_vm.labelSize}`,attrs:{"href":link.value,"target":"_blank"}},[_vm._v(_vm._s(link.label))])}),0):_vm._e()]):_vm._e(),_vm._t("default"),(_vm.warning)?_c('small',{staticClass:"text-warning"},[_c('atoms-icons',{staticClass:"text-warning",attrs:{"icon":"kg kg-exclamation"}}),_vm._v(" "+_vm._s(_vm.warningMessage)+" ")],1):(_vm.invalid)?_c('small',{staticClass:"text-danger d-flex"},[_c('atoms-icons',{staticClass:"text-danger",attrs:{"icon":"kg kg-exclamation"}}),_vm._v(" "+_vm._s(_vm.errorMessage)+" ")],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }