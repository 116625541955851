<template>
  <span class="row">
    <span class="col-9">{{ shown ? password : Array(password.length+1).join('*') }} </span><i
      :class="['kg', shown ? 'kg-hide': 'kg-show', 'cursor-pointer col-1 pr-2']"
      @click="shown = !shown"
    />
  </span>
</template>

<script>
export default {
  name: 'PasswordSlot',
  props: {
    props: Object,
  },
  data() {
    return {
      shown: false,
      password: this.props.formattedRow[this.props.column.field],
    };
  },
};
</script>
