var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.props.row[_vm.props.column.field + '_tooltip']),expression:"props.row[props.column.field + '_tooltip']"}]},[_c('div',{staticClass:"slot-wrapper"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdown),expression:"closeDropdown"}],attrs:{"clasS":"pl-2 dropdown__trigger"}},[_c('div',{staticClass:"dropdown__text",on:{"click":_vm.toggleDropdown}},[_c('span',[_c('nobr',[(_vm.props.row[_vm.props.column.field].icon)?_c('i',{class:'pr-2 kg kg-' +
                  (typeof (
                    _vm.getStatusCode(_vm.props.row[_vm.props.column.field].icon) !== 'undefined'
                  )
                    ? _vm.getStatusCode(_vm.props.row[_vm.props.column.field].icon).symbol
                    : 'question') +
                  ' text-' +
                  (typeof _vm.getStatusCode(_vm.props.row[_vm.props.column.field].icon) !==
                    'undefined'
                    ? _vm.getStatusCode(_vm.props.row[_vm.props.column.field].icon).color
                    : 'secondary')}):_vm._e(),_vm._v(" "+_vm._s(_vm.props.row[_vm.props.column.field].text)+" ")])],1),(_vm.props.row[_vm.props.column.field].dropdown)?_c('i',{class:['kg kg-chevron-down dropdown__icon', {open: _vm.showDropdown}]}):_vm._e()]),(_vm.props.row[_vm.props.column.field].dropdown)?_c('div',{staticClass:"dropdown"},[(_vm.showDropdown)?_c('ul',{staticClass:"dropdown__list"},_vm._l((_vm.props.row[_vm.props.column.field].dropdown),function(item,key){return _c('li',{key:key,staticClass:"dropdown__item"},[_c('i',{class:'pr-2 kg kg-' +
                  (typeof (
                    _vm.getStatusCode(item.icon) !== 'undefined'
                  )
                    ? _vm.getStatusCode(item.icon).symbol
                    : 'question') +
                  ' text-' +
                  (typeof _vm.getStatusCode(item.icon) !==
                    'undefined'
                    ? _vm.getStatusCode(item.icon).color
                    : 'secondary')}),_vm._v(" "+_vm._s(item.text)+" ")])}),0):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }