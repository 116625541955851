<template>
  <div
    :class="`button ${small? 'small': ''}`"
    @click="close"
  >
    <div class="general backslash" />
    <div class="general slash" />
  </div>
</template>

<script>
export default {
  name: 'ClosingButton',
  methods: {
    close() {
      this.$emit('close');
    },
  },
  props: {
    small: Boolean,
  },
};
</script>
<style scoped>

.button {
    position: relative;
    height: 50px;
    width: 50px;
    background: transparent;
    cursor: pointer;
}
.button.small {
    height: 12px;
    width: 12px;
}
.general {
    position: absolute;
    top: 5px;
    right: 0;
    width: 25px;
    height: 2px;
    background: var(--primary);
    cursor: pointer;
}
.slash {
    transform: rotate(45deg)
}
.backslash {
    transform: rotate(-45deg)
}
</style>
