import { pluginOptions } from '@/../vue.config';
import Api from '@/utils/api';

// import constants from '@/utils/constants';

const authentication = pluginOptions.VUE_APP_AUTHENTICATION;
const update_interval_id = 0;
// let keycloakInitialised = 0;

for (const url in pluginOptions.VUE_APP_KEYCLOAK_REALMS) {
  if (window.location.href.includes(url)) {
    authentication.realm = pluginOptions.VUE_APP_KEYCLOAK_REALMS[url];
  }
}
// TODO: @m.ruescher - ich checked this assignment and it seems, that
// authentication (pluginOptions.VUE_APP_AUTHENTICATION) does not provide a property "realm"
// thus resulting in the localstorage user token being named "auth-access-token-undefined"
// can you have a look into this since you are more familiar with the authentication?
// thx!
authentication.localstorage_user_token += `-${authentication.realm}`;

function setUserLoggedOut() {
  // localStorage.setItem(authentication.localstorage_user_token, '');
  clearInterval(update_interval_id);
  window.keycloak.logout();
  // window.location.reload();
}

for (const url in pluginOptions.VUE_APP_KEYCLOAK_REALMS) {
  if (window.location.href.includes(url)) {
    authentication.realm = pluginOptions.VUE_APP_KEYCLOAK_REALMS[url];
  }
}
export default {
  getUserData() {
    return window.keycloak.tokenParsed || {};
  },
  getUserAccessToken() {
    return window.keycloak ? window.keycloak.token || '' : '';
  },
  getCurrentRealm() {
    return authentication.realm;
  },
  hasUserKeycloakRole(...roles) {
    if (roles.length === 1 && roles[0] === undefined) return true;
    const currentRoles = JSON
      .parse(
        localStorage.getItem(`${window.keycloak.tokenParsed.email}-currentPortalRole`),
      ) || [];
    if (currentRoles.includes('Alle Rollen')) {
      for (const i in roles) {
        if (window.keycloak.hasResourceRole(roles[i], 'kugu-portal')) {
          return true;
        }
      }
    }
    return currentRoles.some((currentRole) => roles.includes(currentRole));
  },

  isUserLoggedIn() {
    const lsUserToken = localStorage.getItem(authentication.localstorage_user_token);
    return lsUserToken;
  },
  hasOneOfKeycloakRealms(keycloak, realmNames) {
    const hasRole = (name) => keycloak.hasResourceRole(name, 'kugu-portal');
    return realmNames.some(hasRole);
  },
  setUserLoggedOut,
  authentication,
};

export const fetchMandant = async function () {
  let response;
  await Api.get('general/mandant', {}, (propData) => {
    response = propData;
  });
  return response;
};
