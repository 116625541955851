<template>
  <span :class="`p-${w}`" />
</template>
<script>
export default {
  name: 'Spacer',
  props: {
    w: {
      default: 2,
      type: Number,
    },
  },
};
</script>
