<template>
  <div>
    <ul
      v-for="(device, index) in props.row.devices"
      :key="index"
    >
      <li>{{ device[props.column.mapField] }}</li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'DevicesSlot',
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style scoped>
  ul {
    list-style: none;
  }
</style>
