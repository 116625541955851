<template>
  <div :class="blockClassName">
    <atoms-icons
      icon="kg kg-dots-vertical-rounded"
      modifiers="medium"
    />
    <span
      :class="childrenClassName('span')"
      @click="showMore = !showMore"
    >
      mehr
    </span>
    <div
      v-if="showMore"
      v-click-outside="close"
      :class="childrenClassName('container')"
    >
      <molecules-cards-abstract>
        <ul :class="childrenClassName('subList')">
          <atoms-content-nav-link
            v-for="(link, i) in subMenuLinks"
            :key="i"
            :class="childrenClassName('link')"
            display="block"
            :link="link"
            @click.native="close"
          />
        </ul>
      </molecules-cards-abstract>
    </div>
  </div>
</template>
<script>
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index.vue';
import MoleculesCardsAbstract from '@/atomic-design-components/molecules/cards/Abstract';
import AtomsContentNavLink from '@/atomic-design-components/atoms/ContentNavLink.vue';

export default {
  name: 'MoleculesNavigationsSubMenu',
  props: {
    subMenuLinks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
  methods: {
    close(e) {
      if (!(e.target.classList && e.target.classList[0] === 'MoleculesNavigationsSubMenu__span')) {
        this.showMore = false;
      }
    },
  },
  components: {
    AtomsIcons,
    AtomsContentNavLink,
    MoleculesCardsAbstract,
  },
};
</script>
<style lang="scss">
$class-name: 'MoleculesNavigationsSubMenu';

.#{$class-name} {
  position: relative;
  z-index: 700;
  list-style: none;
  display: flex;

  .#{$class-name}__container {
    position: absolute;
    overflow: visible;
    top: 40px;
    left: -100px;
  }
  .#{$class-name}__link {
    display: block;
    width: 150px;
  }

  &:hover,
  &:focus {
    .#{$class-name}__span {
      cursor: pointer;
      color: var(--primary);
    }

    .#{$class-name}__container {
      opacity: 1;
      visibility: visible;
    }

    i {
      color: var(--primary);
    }
  }

  i {
    color: var(--neutral-700);
  }

  .#{$class-name}__span {
    color: var(--neutral-700);
    padding-left: 10px;
  }

  .#{$class-name}__subList {
    width: 150px;
    padding: 0;
    display: block;
    margin: 0 !important;
    height: fit-content;

    li a {
      padding-left: 0;
      margin: 0;
    }
  }
}
</style>
