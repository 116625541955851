<template>
  <span>
    <span
      v-for="(entry, i) in props.formattedRow[props.column.field]"
      :key="i"
    >
      <span
        v-tooltip="entry.tooltip"
        class="cursor-help"
      >
        <atoms-icons
          :class="`text-${entry.color ? entry.color : 'black-600'}`"
          :icon="`kg kg-${entry.icon}`"
        />
        {{ entry.label }}
      </span>
    </span>
  </span>
</template>
<script>
import AtomsIcons from '@/atomic-design-components/atoms/icons/Index';

export default {
  name: 'IconLabelSlot',
  components: {
    AtomsIcons,
  },
  props: {
    props: Object,
  },
};
</script>
