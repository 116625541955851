const setCorectlyLabel = (timedelta) => {
  if (timedelta <= 1) {
    return 'now-1d';
  } if (timedelta <= 7) {
    return 'now-7d';
  } if (timedelta > 7 && timedelta <= 30) {
    return 'now-30d';
  } if (timedelta > 30 && timedelta <= 90) {
    return 'now-90d';
  } if (timedelta > 90 && timedelta <= 180) {
    return 'now-180d';
  } if (timedelta > 180 && timedelta <= 365) {
    return 'now-365d';
  } if (timedelta > 345 && timedelta <= 1000) {
    return 'now-1000d';
  }
  return 'all';
};

export const getChartGroupPeriod = (timedelta, smoothen) => {
  let pickedTimePeriod;

  if (`${timedelta}`.includes(':')) {
    timedelta = 'now-30d';
  } else if (Number.parseInt(timedelta) >= 0) {
    timedelta = setCorectlyLabel(timedelta);
  }

  if (!timedelta) {
    window.console.error('timedelta is undefined', this.timedelta);
  }

  const periods = {
    'now-60m': {
      calcGroupValue: () => 1,
      canBeSmooth: false,
    },
    'now-1d': {
      calcGroupValue: () => 1,
      canBeSmooth: false,
    },
    'now-7d': {
      calcGroupValue: () => 15,
      canBeSmooth: true,
    },
    'now-30d': {
      calcGroupValue: () => 15,
      canBeSmooth: true,
    },
    'now-90d': {
      calcGroupValue: () => 60,
      canBeSmooth: true,
    },
    'now-180d': {
      calcGroupValue: () => 60,
      canBeSmooth: true,
    },
    'now-365d': {
      calcGroupValue: () => 180,
      canBeSmooth: true,
    },
    'now-1000d': {
      calcGroupValue: () => 320,
      canBeSmooth: true,
    },
    all: {
      calcGroupValue: () => 320,
      canBeSmooth: true,
    },
  };

  try {
    pickedTimePeriod = periods[timedelta];
  } catch (e) {
    window.console.error('I cant find key for these timeperiod:', this.timedelta);
  }

  const canBeSmoothenValue = pickedTimePeriod.canBeSmooth ? smoothen : false;
  const groupValue = pickedTimePeriod.calcGroupValue() * (canBeSmoothenValue ? 2 : 1);
  return `${groupValue}m`;
};
