import dateUtils from '@/utils/dateUtils';
import moment from 'moment';

const formats = {
  base: { year: 'numeric', month: '2-digit', day: '2-digit' },
  calendar: { year: 'numeric', month: 'short', day: '2-digit' },
};

export const dates = {
  methods: {
    formatDatesToBeValidInJS(unFormattedDate) {
      // in order to be a valid date in js
      // we have to multiply it with 1000
      return unFormattedDate * 1000;
    },
    /* eslint-disable */
		formatToGerman(dateString, withTime = false, format = "base") {
			if (dateString && typeof dateString === "object") dateString = dateUtils.formatToDateString(dateString, true);
			if (dateString) {
				if (dateString.includes("T")) dateString = dateString.replace("T", " ");
				const specs = formats[format];
				if (withTime) {
					specs.hour = "2-digit";
					specs.minute = "2-digit";
				}
				return new Date(dateString).toLocaleString("de-DE", specs);
			}
			return "";
		},
		/* eslint-enable */
    toDBFormat: (d, withTime = true) => dateUtils.toDBFormat(d, withTime),
    getDifferenceBetweenTwoDatesInHours(firstDate, secondDate) {
      /* eslint-disable */
			(firstDate = moment(firstDate)), (secondDate = moment(secondDate));
			/* eslint-enable */
      const duration = moment.duration(firstDate.diff(secondDate));
      return duration.asHours();
    },
  },
};
