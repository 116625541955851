<template>
  <!-- eslint-disable -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="35"
    viewBox="0 0 32 35"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g>
        <g>
          <g transform="translate(-983 -122) translate(984 123)">
            <g>
              <path
                fill="#FFF"
                d="M26.88 13.44c0 7.422-6.018 13.44-13.44 13.44C6.017 26.88 0 20.862 0 13.44 0 6.017 6.017 0 13.44 0c7.422 0 13.44 6.017 13.44 13.44z"
              />
              <path
                stroke="#263344"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width=".96"
                d="M26.88 13.44c0 7.422-6.018 13.44-13.44 13.44C6.017 26.88 0 20.862 0 13.44 0 6.017 6.017 0 13.44 0c7.422 0 13.44 6.017 13.44 13.44zM14.88 12L20.16 6.72M2.88 13.44L4.8 13.44M3.684 9.399L5.458 10.134M5.973 5.973L7.331 7.331M9.399 3.684L10.133 5.458M13.44 2.881L13.44 4.801M17.482 3.685L16.746 5.458M23.196 9.401L21.422 10.134M23.999 13.442L22.08 13.441M23.195 17.483L21.422 16.747M3.684 17.481L5.458 16.746"
              />
              <path
                stroke="#263344"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width=".96"
                d="M8.64 22.08L18.24 22.08 18.24 19.2 8.64 19.2zM15.36 13.44c0 1.06-.86 1.92-1.92 1.92s-1.92-.86-1.92-1.92.86-1.92 1.92-1.92 1.92.86 1.92 1.92z"
              />
            </g>
            <rect
              width="25"
              height="12"
              x=".5"
              y="21.5"
              fill="#FFF"
              stroke="#263344"
              rx="2"
            />
            <path
              fill="#1F2D3D"
              fill-rule="nonzero"
              d="M5.296 31l1.042-4.28L7.379 31h1.328l1.419-6.398H8.812l-.861 4.552-1.05-4.552H5.783L4.73 29.146l-.865-4.544H2.55L3.97 31h1.326zm6.86 0v-1.749l-.132-3.015L13.747 31h.905l1.727-4.768-.131 3.019V31h1.322v-6.398H15.84l-1.635 4.64-1.643-4.64h-1.723V31h1.318zm11.189 0v-1.06h-3.226l3.155-4.583v-.755h-4.798v1.067h3.16l-3.165 4.558V31h4.874z"
            />
          </g>
          <g
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width=".875"
          >
            <path
              fill="#263344"
              stroke="#263344"
              d="M5.688 14.036V2.188c0-.967-.784-1.75-1.75-1.75-.967 0-1.75.784-1.75 1.75v11.848c-1.045.604-1.75 1.732-1.75 3.027 0 1.933 1.568 3.5 3.5 3.5s3.5-1.567 3.5-3.5c0-1.295-.706-2.422-1.75-3.027z"
              transform="translate(-983 -122) translate(984 123) translate(23 6)"
            />
            <path
              stroke="#FFF"
              d="M4.813 17.063c0-.483-.393-.875-.875-.875-.483 0-.876.392-.876.875 0 .482.393.875.876.875.482 0 .874-.393.874-.875zM3.938 16.188L3.938 6.563"
              transform="translate(-983 -122) translate(984 123) translate(23 6)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>
<script>
export default {
  name: 'AtomsIconsMetersHeatVolume',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
};
</script>
