<template>
  <div
    class="pl-2 pr-3"
    @click="toggleSwitch"
  >
    <toggle-button
      v-tooltip="checked ? 'Ausgeklappt gruppiert' : 'Eingeklappt'"
      :color="getPrimaryColor(true)"
      v-model="checked"
    />
  </div>
</template>

<script>
import colors from '@/utils/colors';

export default {
  name: 'TableToggleButton',
  data() {
    return {
      checked: false,
      checked2: false,
    };
  },
  props: {
    grouped: Boolean,
  },
  watch: {
    grouped() {
      this.checked = this.grouped;
    },
  },
  created() {
    this.checked = this.grouped;
  },
  methods: {
    getPrimaryColor(light = false) {
      const color = document.documentElement.style.getPropertyValue('--primary');

      return light ? colors.getPercentageOf(color, 0.7) : color;
    },
    toggleSwitch() {
      setTimeout(() => {
        this.$emit('toggleTableType', this.checked);
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
  .button {
    margin-right: 14px;
    margin-top: 12px;
  }
</style>
