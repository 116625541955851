<template>
  <span>
    <span
      v-for="(v,k, i) in props.row.optimisation"
      :key="i"
      v-tooltip="getLabel(k)"
      v-html="getStatus(v.status)"
    />
  </span>
</template>

<script>
import constants from '@/utils/constants';

export default {
  name: 'ChoOptimizationSlot',
  props: {
    props: Object,
  },
  methods: {
    getLabel(x) {
      const labels = {
        energy_efficiency: 'Energieeffizienz',
        summer_shutdown: 'Sommerbetrieb',
        nightdrop: 'Nachtabsenkung',
        heating_curve: 'Heizkurve',
        pump_efficiency: 'Pumpeneffizienz',
      };
      return labels[x];
    },
    getStatus(x) {
      x = parseInt(x);
      try {
        return `<i class="p-1 kg kg-${constants.StatusCodes[x].symbol} text-${constants.StatusCodes[x].color}"/> `;
      } catch {
        window.console.log(x);
      }
      return '';
    },
  },

};
</script>
