<template>
  <div
    :id="blockClassName"
    :class="blockClassName"
  >
    <div
      ref="stickyWrapper"
      :class="childrenClassName('stickyWrapper')"
    >
      <template v-if="isFullRemote">
        <molecules-notices-head-banner v-if="showError.versionLower || showError.versionHigher">
          {{ showError.versionLower ? bannerErrorMsg.versionLower : bannerErrorMsg.versionHigher }}
          <router-link :to="`/support?source=${encodeURIComponent(originLocation + '/#/eras-2-connection')}`">
            {{
              showError.versionLower
                ? 'Kontaktieren Sie uns gerne falls dieses Problem weiter besteht.'
                : 'Bitte kontatkieren sie uns.'
            }}
          </router-link>
        </molecules-notices-head-banner>
      </template>
      <molecules-notices-head-banner v-if="showError.type">
        {{ bannerErrorMsg.type }}
        <router-link :to="`/support?source=${encodeURIComponent(originLocation + '/#/eras-2-connection')}`">
          Kontaktieren Sie uns gerne falls dieses Problem weiter besteht.
        </router-link>
      </molecules-notices-head-banner>
      <molecules-navigations-topbar
        v-if="isAuthenticated"
        :is-portal-valid="isPortalValid"
      />
      <slot name="sticky" />
    </div>
    <div :class="childrenClassName('sidebarWrapper')">
      <molecules-navigations-sidebar
        v-if="isAuthenticated && isPortalValid"
        :key="changedRole"
        :app-logo="appLogo"
        :contrasted-color="contrastedColor"
      />
    </div>
    <div
      ref="content"
      :class="childrenClassName('content')"
    >
      <div :class="childrenClassName(this.config.container || 'oldContainer')">
        <router-view
          v-if="isPortalValid"
          :key="changedRole"
          :config="config"
        />
        <ErrorCard
          v-else
          error-message="Es ist Ihnen nicht gestattet, diese Seite zu sehen."
        />
      </div>
    </div>
    <div :class="childrenClassName('footer')">
      <molecules-navigations-footer />
    </div>
    <organisms-tenant-terms-and-conditions />
    <ConsentBanner v-if="false" />
  </div>
</template>

<script>
import OrganismsTenantTermsAndConditions from '@/atomic-design-components/organisms/universal/TenantTermsAndConditions';
import ErrorCard from '@/components/ErrorCard';
import inlineNotifications from '@/utils/inlineNotifications';
import MoleculesNoticesHeadBanner from '@/atomic-design-components/molecules/notices/HeadBanner';
import MoleculesNavigationsTopbar from '@/atomic-design-components/molecules/navigations/Topbar';
import MoleculesNavigationsFooter from '@/atomic-design-components/molecules/navigations/Footer';
import ConsentBanner from '@/components/ConsentBanner';
import MoleculesNavigationsSidebar from '@/atomic-design-components/molecules/navigations/Sidebar.vue';
import Auth from '@/utils/auth';
import api from '@/utils/api';
import colors from '@/utils/colors';
import constants from '@/utils/constants';
import { globalStorage } from '@/main';
import { pluginOptions } from '@/../vue.config';
import { setUpMatomoTagManager } from '@/utils/matomo';

export default {
  name: 'TemplatesApp',
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    MoleculesNavigationsSidebar,
    MoleculesNavigationsTopbar,
    MoleculesNoticesHeadBanner,
    MoleculesNavigationsFooter,
    OrganismsTenantTermsAndConditions,
    ErrorCard,
    ConsentBanner,
  },

  data() {
    return {
      originLocation: window.location.origin,
      inlineNotifications,
      contrastedColor: 'white-contrasted',
      isAuthenticated: Auth.isUserLoggedIn(),
      globalStorage,
      showSidebar: window.innerWidth > 1024,
      appLogo: '/images/theme/portal_kugu/logo.png',
      isMobile: false,
      error: null,
      changedRole: false,
      showError: {
        versionLower: false,
        versionHigher: false,
        type: false,
      },
      /* eslint-disable */
      bannerErrorMsg: {
        versionLower:
          'Ihr ERAS2 ist nicht auf dem neusten Stand. Bitte starten Sie ihren ERAS2 Client mit diesem Mandanten neu, damit alle Funktionalitäten im Portal gewährleistet werden können.',
        versionHigher:
          'Sie nutzen eine ungetestete ERAS2-Version. Die volle Funktionalität des Portals kann nicht gewährleistet werden.',
        type: 'Ihre Gerätetypen in ERAS2 ist sind nicht mit dem Portal synchronisiert. Damit können nicht alle Funktionalitäten im Portal gewährleistet werden.',
      },
      /* eslint-enable */
      handleDebouncedScroll: undefined,
      isUserScrolling: false,
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    moveToDownErrorDisplay() {
      return this.showError.versionLower || this.showError.versionHigher;
    },
    showBannerMsg() {
      return this.bannerErrorMsg[this.erasMismatchTypeError];
    },
    portal() {
      const portalAccess = window.keycloak.tokenParsed.portal;
      if (portalAccess === undefined) {
        return [];
      }
      if (typeof portalAccess === 'string') {
        return portalAccess.split(',').map((item) => item.trim());
      }
      if (typeof portalAccess === 'object') {
        const portals = [];
        for (const key in portalAccess) {
          portals.concat(portalAccess[key].split(',').map((item) => item.trim()));
        }
        return portals;
      }
      return '';
    },
    isPortalValid() {
      const portal = this.portal;
      if (portal === '') return false;
      if (portal.length === 0) return true;
      const currentDomain = window.location.hostname;
      const hostname = this.getHostName(pluginOptions.VUE_APP_API.server_url);
      const currentPortal = currentDomain === 'localhost' ? hostname : currentDomain;
      return portal.filter((item) => item === currentPortal).length > 0;
    },
    isUseV2() {
      try {
        return this.$route.meta.breadcrumbs.usev2;
      } catch (e) {
        return false;
      }
    },
  },

  created() {
    this.setGlobally();
    this.checkErasMismatchVersion();
    this.saveUsernameIntoLocalstorage();
    this.contrastedColor = colors.getContrastedColorClass(null, '.bg-gradient-primary');

    this.eventHub.$on('fetch-error', (err) => {
      if (`${err}`.includes('401')) {
        this.error = err;
      }
    });
    this.eventHub.$on('changeDashboardType', () => (this.error = null));
    this.eventHub.$on('change-role', () => (this.changedRole = !this.changedRole));
  },
  mounted() {
    let container = constants.matomoConfigs.stagingPortal.containerId;
    if (process.env.NODE_ENV !== 'staging') container = constants.matomoConfigs.livePortal.containerId;
    setUpMatomoTagManager(container);
  },
  updated() {
    const height = this.$refs.stickyWrapper.offsetHeight;
    this.$refs.content.style.marginTop = `${height}px`;
  },

  watch: {
    error() {
      window.console.log('Error caught:', this.error);
    },
    showSidebar() {
      document.querySelector('body').classList.toggle('hide');
    },
    $route(to) {
      this.inlineNotifications.clearSavedInfo();
      document.title = to.meta.title || 'KUGU';
      this.isAuthenticated = Auth.isUserLoggedIn();
      this.error = null;
    },
  },

  methods: {
    setFavicon(icon) {
      if (icon) {
        const currentFaviconLink = document.getElementById('favicon');
        currentFaviconLink.setAttribute('href', `data:image/png;base64, ${icon}`);
      }
    },

    setGlobally() {
      api.get(
        'general/mandant/settings',
        {},
        (settings) => {
          const appName = settings.app_name || 'Mein Energiemonitor';
          const primary = settings.primary_color;
          this.setFavicon(settings.favicon);
          this.setAllColors(primary, '--primary');
          globalStorage.primaryColor = settings.primary_color;
          globalStorage.favicon = settings.favicon;
          globalStorage.mandantName = settings.mandant_name;
          globalStorage.appName = appName;
          globalStorage.mandantLogo = settings.logo;
          this.contrastedColor = colors.getContrastedColorClass(primary, null);
          if (settings.logo) this.appLogo = `data:image/png;base64, ${settings.logo}`;
        },
        () => {
          const primary = '#1f447c';
          this.setAllColors(primary, '--primary');
          this.contrastedColor = colors.getContrastedColorClass(primary, null);
        },
      );
    },

    setAllColors(color, type) {
      if (color) {
        const root = document.documentElement;
        root.style.setProperty(type, color);

        const gradients = {
          100: 0.6,
          200: 0.5,
          300: 0.4,
          400: 0.3,
          500: 0.2,
          600: 0,
          700: -0.25,
          800: -0.4,
          900: -0.5,
        };

        for (const [key, percentage] of Object.entries(gradients)) {
          root.style.setProperty(`${type}-${key}`, colors.getPercentageOf(color, percentage));
        }
      }
    },
    checkErasMismatchVersion() {
      api.get(
        'general/eras-version',
        {},
        ({ version_comparison, hardware_type_mismatch }) => {
          if (!this.hasUserKeycloakRole('Vollzugriff')) {
            return;
          }
          if (version_comparison === 'lower than desired') {
            this.showError.versionLower = true;
          } else if (version_comparison === 'higher than desired') {
            this.showError.versionHigher = true;
          }

          if (hardware_type_mismatch) {
            this.showError.type = true;
          }
        },
        (error) => window.console.log('Error: ', error),
      );
    },

    saveUsernameIntoLocalstorage() {
      const currentName = localStorage.getItem('username');
      const keycloakUsername = window.keycloak.tokenParsed.name;
      if (!currentName || currentName !== keycloakUsername) {
        localStorage.setItem('username', keycloakUsername);
      }
    },

    getHostName(url) {
      const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (match !== null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
        return match[2];
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
$class-name: 'TemplatesApp';

.hide .#{$class-name} {
  .sidebar {
    width: 0px !important;
    li {
      opacity: 0;
      display: none;
    }
  }

  .sidebar .display.collapse {
    width: 0px;
    overflow: hidden;
  }

  .#{$class-name}__stickyWrapper {
    margin-left: 0px;
    width: 100%;
  }

  .#{$class-name}__content {
    margin-left: 0px;
  }

  .#{$class-name}__sidebarWrapper {
    width: 0px;
  }

  .#{$class-name}__footer {
    margin-left: 0;
  }
}

.#{$class-name} {
  width: 100vw;
  overflow: hidden;
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  .#{$class-name}__footer {
    margin-left: 300px;
    grid-row-start: 2;
    grid-row-end: 3;
    transition: 0.5s;
  }

  .display {
    transition: 0.5s;
  }

  .#{$class-name}__content {
    overflow-x: scroll;
  }

  .#{$class-name}__oldContainer {
    padding-top: var(--spacing-xl);
    padding-left: var(--spacing-xs);
    padding-right: var(--spacing-xs);
  }

  .#{$class-name}__dashboard {
    padding-top: var(--spacing-m);
    padding-left: var(--spacing-m);
    padding-right: var(--spacing-m);
    padding-bottom: var(--spacing-m);
  }

  .#{$class-name}__table {
    padding-top: var(--spacing-m);
    padding-left: var(--spacing-m);
    padding-right: var(--spacing-m);
  }

  @include mq('lg') {
    .#{$class-name}__oldContainer {
      margin-right: var(--spacing-xl);
      margin-left: var(--spacing-xl);
    }

    .#{$class-name}__dashboard {
      padding-left: var(--spacing-m);
      padding-right: var(--spacing-m);
      padding-top: var(--spacing-xl);
      padding-bottom: var(--spacing-xl);
    }

    .#{$class-name}__table {
      padding-top: var(--spacing-xl);
      padding-left: var(--spacing-xxl2);
      padding-right: var(--spacing-xxl2);
    }
  }

  @include mq('xxl') {
    .#{$class-name}__dashboard {
      padding-left: 0;
      padding-right: 0;
      max-width: 1340px;
      margin: auto;
    }

    .#{$class-name}__table {
      padding-top: var(--spacing-xxl2);
    }
  }

  @include mq('xxl2') {
    .#{$class-name}__table {
      padding-left: var(--spacing-xxl4);
      padding-right: var(--spacing-xxl4);
    }
  }

  .#{$class-name}__sidebarWrapper {
    position: fixed;
    top: 0;
    width: 300px;
    overflow-y: auto;
    min-height: 100vh;
    height: 100vh;
    background-image: linear-gradient(180deg, var(--primary) 10%, var(--primary-700) 100%);

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    & {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  .#{$class-name}__content {
    margin-left: 300px;
    min-height: 100%;
    margin-top: 92px;
    transition: 0.5s;
  }

  .#{$class-name}__stickyWrapper {
    position: fixed;
    top: 0%;
    z-index: var(--z-index-default);
    width: calc(100% - 300px);
    margin-left: 300px;
    transition: 0.5s;
  }
}
</style>
