<template>
  <div
    v-if="displayTermsAndConditions"
    :class="blockClassName"
  >
    <molecules-panel-wrapper
      :class="childrenClassName('modal')"
      :title="title"
    >
      <div
        ref="fileContent"
        :class="childrenClassName('fileContentWraper')"
      >
        <div
          v-custom-scroll
          :class="childrenClassName('agb')"
          v-html="formatMarkdown(file)"
        />
      </div>
      <div :class="childrenClassName('buttons')">
        <atoms-standard-button
          :class="childrenClassName('download')"
          text="AGB herunterladen"
          type="secondary"
          @click="downloadTermsAndConditions"
        />
        <atoms-standard-button
          text="Akzeptieren"
          type="primary"
          @click="acceptTermsAndConditions"
        />
      </div>
    </molecules-panel-wrapper>
  </div>
</template>

<script>
import { jsPDF } from 'jspdf';
import api from '@/utils/api';
import AtomsStandardButton from '@/atomic-design-components/atoms/buttons/v2/StandardButton';
import MoleculesPanelWrapper from '@/atomic-design-components/molecules/cards/PanelWrapper';

export default {
  name: 'OrganismsTenantTermsAndConditions',
  components: {
    AtomsStandardButton,
    MoleculesPanelWrapper,
  },
  data() {
    return {
      acceptedTermsAndConditions: true,
      file: null,
      title: 'Bitte lesen und bestätigen Sie unsere AGB',
      userIsTenant: window.keycloak.hasResourceRole('Verbrauchs-App', 'kugu-portal'),
      userIsPropertyManager: window.keycloak.hasResourceRole('Hausverwaltung', 'kugu-portal'),
    };
  },
  created() {
    if (this.userIsTenant && !this.userIsPropertyManager) {
      this.fetchTermsAndConditionsStatus();
    }
  },
  computed: {
    displayTermsAndConditions() {
      return this.file && this.userIsTenant && !this.userIsPropertyManager && !this.acceptedTermsAndConditions;
    },
  },
  methods: {
    fetchTermsAndConditionsStatus() {
      api.get('consumption/terms-and-conditions/acceptance', {}, (resp) => {
        this.acceptedTermsAndConditions = resp.accepted;
        if (this.acceptedTermsAndConditions === false) this.getTermsAndConditionsFile();
      });
    },
    formatMarkdown(fileContent) {
      const marked = require('marked');
      return marked(fileContent);
    },
    downloadTermsAndConditions() {
      /* eslint-disable */
      var doc = new jsPDF();
      /* eslint-enable */
      doc.html(this.$refs.fileContent, {
        callback: (doc) => {
          const today = new Date();
          doc.save(`KUGU_AGB_Stand_${today.getFullYear()}_${today.getMonth() + 1}`);
        },
        margin: [25, 20, 30, 20],
        autoPaging: 'text',
        width: 160,
        windowWidth: 650,
      });
    },
    acceptTermsAndConditions() {
      api.put('consumption/terms-and-conditions/acceptance', null, null, null, () => {});
      this.acceptedTermsAndConditions = true;
    },
    getTermsAndConditionsFile() {
      api.get('files/get_consumption_app_file/de/terms.md', {}, (file) => {
        this.file = file;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$class-name: 'OrganismsTenantTermsAndConditions';

.#{$class-name} {
  position: relative;
  .#{$class-name}__buttons {
    cursor: pointer;
    position: absolute;
    padding: var(--spacing-xl);
    bottom: 0;
    right: var(--spacing-s);
    left: auto;
    padding-right: var(--spacing-m);
  }
  .#{$class-name}__download {
    cursor: pointer;
    margin-right: var(--spacing-m);
  }
  .#{$class-name}__modal {
    box-shadow: var(--box-shadow-heavy);
    position: fixed;
    left: 15vw;
    width: 70vw;
    top: 10vh;
    height: 80vh;
    background: white;
    z-index: var(--z-index-far-front);
  }
  .#{$class-name}__agb {
    height: 63vh;
    padding-right: 5%;
    overflow-y: scroll;
  }

  .#{$class-name}__fileContentWrapper {
    width: 100%;
  }
}
</style>
