<template>
  <footer
    id="footer"
    class="sticky-footer bg-white"
  >
    <div class="container">
      <div class="copyright text-center">
        <span>
          <router-link :to="'/imprint'">
            <span>Impressum</span>
          </router-link>
        </span>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'MoleculesNoticesFooter',
};
</script>
