import Vue from 'vue';
import { isNull } from 'lodash';
import TableCard from '@/components/TableCard';
import constants from './constants';

const TableClass = Vue.extend(TableCard);
const firstNumberPat = /^\d+/g;
const lastNumberPat = /\d+$/g;
const floortypeAndDirectionPat = /([^$\d+]+)/g;
const floorMapper = {
  keller: -1,
  ug: -1,
  eg: 0,
  g: 1,
  og: 1,
  d: 2,
  dg: 2,
  dach: 2,
  unbekannt: 10,
};
const floorLabelLookup = {
  '-1': 'UG',
  0: 'EG',
  1: 'G',
  2: 'DG',
};

class NumberParser {
  constructor(locale) {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
    const numerals = [
      ...new Intl.NumberFormat(locale, { useGrouping: false }).format(
        9876543210,
      ),
    ].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this._group = new RegExp(
      `[${parts.find((d) => d.type === 'group').value}]`,
      'g',
    );
    this._decimal = new RegExp(
      `[${parts.find((d) => d.type === 'decimal').value}]`,
    );
    this._numeral = new RegExp(`[${numerals.join('')}]`, 'g');
    this._index = (d) => index.get(d);
  }

  parse(string) {
    const _string = string;
    const templateString = _string
      .trim()
      .replace(this._group, '')
      .replace(this._decimal, '.')
      .replace(this._numeral, this._index);

    return templateString ? +_string : NaN;
  }
}

export default {
  replacePointWithComma(value) {
    if (value) {
      return value.replace(/\./g, ',');
    }
    return value;
  },

  getFloorTypeNumeric(val) {
    if (val) {
      return floorMapper.hasOwnProperty(val.toLowerCase())
        ? floorMapper[val.toLowerCase()]
        : 100;
    }
    return 100;
  },
  getFloorTypeString(val) {
    return floorLabelLookup[val];
  },
  getBatteryIcon(batteryStats, hasPermission = false) {
    const status = batteryStats.aggregated_battery;
    let icon = '';
    if (!status || status < 0) icon = '<i class="kgs kgs-help-circle text-secondary"></i> unbekannt';
    else if (status > 0 && status <= 25) icon = `<i class="fas fa-battery-quarter text-danger" ></i> ${status}%`;
    else if (status > 25 && status <= 75) icon = `<i class="fas fa-battery-half text-warning" ></i> ${status}%`;
    else if (status > 75 && status <= 0.95) icon = `<i class="fas fa-battery-half text-success" ></i> ${status}%`;
    else icon = `<i class="fas fa-battery-full text-success" ></i> ${status}%`;

    if (hasPermission) {
      let additional = `<small class="pl-2">(Nachrichten: ${
        batteryStats.message_count || 'unbekannt'
      },`;
      additional += ` Batterie-Spannung: ${
        batteryStats.vbat ? `${batteryStats.vbat} V` : 'unbekannt'
      })</small>`;
      icon += additional;
    }
    return icon;
  },
  countValueOccurence(arr, v) {
    const counts = {};
    for (const obj of arr) {
      if (counts[obj[v]]) counts[obj[v]] += 1;
      else counts[obj[v]] = 1;
    }
    return counts;
  },
  getKeyValuesAsString(obj) {
    let str = '';
    for (const [k, v] of Object.entries(obj)) {
      str += `${k} : ${v}, `;
    }
    return str.slice(0, -2);
  },
  getBadgelist(cost, usage = null) {
    let globalDistributionFor;
    if (cost.hasOwnProperty('distribution_for')) {
      if (cost.related_tenants) {
        const tenants = JSON.parse(cost.related_tenants);
        globalDistributionFor = `
		${tenants.length} Nutzer:
		${tenants
    .map((x) => `<br>${x.first_name || ''} ${x.last_name || ''}`)
    .join()}`;
      } else if (cost.related_units) {
        const units = JSON.parse(cost.related_units);
        globalDistributionFor = ` ${units.length} Nutzeinheiten:
	${units.map((x) => `<br>${x.location || ''} ${x.unit_nr || ''}`)}`;
      }
    }
    const getUsageGroupLabel = function (id) {
      if (id) {
        const i = usage.findIndex((e) => `${e.value}` === `${id}`);
        return usage[i] ? usage[i].label : '';
      }
      return '';
    };
    return [
      {
        text: cost.hasOwnProperty('distribution_in')
          ? constants.distribution_in[cost.distribution_in]
          : '',
        styling: 'badge-blue',
        tooltip: 'Umlage in',
      },
      {
        text: cost.hasOwnProperty('distribution_for')
          ? constants.distribution_for[cost.distribution_for]
          : '',
        styling: 'badge-yellow',
        tooltip: `Umlage auf${globalDistributionFor || ''}`,
      },
      {
        text: cost.hasOwnProperty('heating_type')
          ? constants.heating_type[cost.heating_type]
          : '',
        styling: 'badge-blue',
        tooltip: 'Umlage in',
      },
      {
        text: cost.hasOwnProperty('usage_group_id')
          ? getUsageGroupLabel(cost.usage_group_id)
          : '',
        styling: 'badge-yellow',
        tooltip: 'Umlage auf',
      },
      /*
		{
	text: cost.medium ? cost.medium : "",
	styling: "badge-red",
	tooltip: "Energieträger (Brennstoff)",
		}, */
      {
        text: cost.hasOwnProperty('distribution_out')
          ? constants.distribution_out[cost.distribution_out]
          : '',
        styling: 'badge-green',
        tooltip: 'Umlage über (Umlageschlüssel)',
      },
      {
        text: cost.reduction_type
          ? constants.reduction_type[cost.reduction_type]
          : '',
        styling: 'badge-dark-blue',
        tooltip: 'Reduzierung',
      },
    ];
  },
  saveAsTextFile(content, filename) {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`,
    );
    element.setAttribute('download', `${filename}.txt`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  },
  formatDate(dateString, withTime = false) {
    const specs = { year: 'numeric', month: '2-digit', day: '2-digit' };
    if (withTime) {
      specs.hour = '2-digit';
      specs.minute = '2-digit';
    }
    return new Date(dateString).toLocaleString('de-DE', specs);
  },
  formatYear(dateString) {
    const specs = { year: 'numeric' };
    return new Date(dateString).toLocaleString('de-DE', specs);
  },
  getHardwareOverviewTooltip(deviceOverview) {
    let tooltip = '';
    if (!deviceOverview) return 'Keine Geräte';
    for (const [k, v] of Object.entries(deviceOverview)) {
      tooltip += `${k} : ${v.received}/${v.expected}<br>`;
    }
    return tooltip;
  },
  getDeviceIconTooltip(device) {
    return `${constants.deviceTypes[device.type_id]}<br>
	Gerätenummer: ${device.oms_id}<br>
	Lage: ${device.location}<br>
	Empfangene Nachrichten: ${device.message_counts || 0}<br>`;
  },
  getDirectionAndFloortype(floorDir) {
    let [direction, floortype] = ['', ''];

    if (
      floorDir
		&& (floorDir.slice(-1) === 'R'
	|| floorDir.slice(-1) === 'L'
	|| floorDir.slice(-1) === 'M')
    ) {
      direction = floorDir.slice(-1);
      floortype = floorDir.slice(0, -1);
    } else if (floorDir && floorDir.includes('re')) {
      direction = 'R';
      floortype = floorDir.replace('chts', '').replace('re', '');
    } else if (floorDir && floorDir.includes('mi')) {
      direction = 'M';
      floortype = floorDir.replace('tte', '').replace('mi', '');
    } else if (floorDir && floorDir.includes('li')) {
      direction = 'L';
      floortype = floorDir.replace('nks', '').replace('li', '');
    } else {
      floortype = floorDir;
    }

    return [direction, floortype];
  },
  createPassword(n) {
    const pwdChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    return Array(n)
      .fill(pwdChars)
      .map((x) => x[Math.floor(Math.random() * x.length)])
      .join('');
  },
  splitLocationIntoObject(location, street = null, neNr = null) {
    if (location) {
      location = location.toString();
      const strippedLocation = location
        .replace('.', '')
        .replace('-', '')
        .replace(/\s/g, '');
      const floorDir = strippedLocation.match(floortypeAndDirectionPat)
        ? strippedLocation.match(floortypeAndDirectionPat)[0]
        : '';
      const direction = this.getDirectionAndFloortype(floorDir)[0];
      const floortype = this.getDirectionAndFloortype(floorDir)[1];
      const floorTypeNum = this.getFloorTypeNumeric(floortype);
      const aptNum = strippedLocation.match(lastNumberPat)
        ? strippedLocation.match(lastNumberPat)[0]
        : '';
      const typeLabel = this.getFloorTypeString(floorTypeNum)
        ? this.getFloorTypeString(floorTypeNum)
        : floortype;
      const floorNumber = strippedLocation.match(firstNumberPat)
        ? strippedLocation.match(firstNumberPat)[0]
        : '';
      const locationObject = {
        name: location || '',
        street,
        nameCalculated: `${street}${neNr ? ` - ${neNr}` : ''}${
          location ? ` (${location})` : ''
        }`,
        floorNumber,
        floorType: floorTypeNum,
        floorTypeLabel: typeLabel,
        direction,
        apartmentNumber: aptNum,
      };
      return locationObject;
    }
    return {
      name: '',
      street,
      nameCalculated: `${street}${neNr ? ` - ${neNr}` : ''}`,
      neNr,
      floorType: 200,
    };
  },
  getFloorStringRep(floor) {
    if (Number.isNaN(floor)) {
      if (floor === 'null') return 'Etage unbekannt';
      return floor;
    }
    if (parseInt(floor) > 0) return `${floor}.OG`;
    if (parseInt(floor) === 0) return 'EG';
    if (parseInt(floor) < 0) return `${floor}.UG`;
    return '';
  },
  parseNumberByLocale(locale, numberString) {
    return new NumberParser(locale).parse(`${numberString}`);
  },
  formatNumber(number, digits = 2, unit = '') {
    if (number === null) return '';
    const parsedNumber = parseFloat(`${number}`.replace(',', ''));
    if (!isNaN(parsedNumber)) {
      return `${parsedNumber.toLocaleString('de-DE', {
        maximumFractionDigits: digits,
        minimumFractionDigits: digits,
      })} ${unit}`;
    }
    return '';
  },
  getFormData(dataToSend) {
    const formData = {};
    for (const [k, v] of Object.entries(dataToSend)) {
      if (v.value) {
        formData[k] = v.value;
      }
    }
    return formData;
  },
  filterOutCommissionUnit(x) {
    if (x.name) return x.name.toString().toLowerCase() !== 'kommission';
    return true;
  },
  getIndex(array, attr_name, value) {
    return array.findIndex((obj) => obj[attr_name] === value);
  },
  findNestedAttributeValue(array, attribute, value) {
    for (const i in array) {
      for (const [key, entry] of Object.entries(array[i])) {
        if (entry.hasOwnProperty(attribute) && entry[attribute] === value) return [i, key];
      }
    }
    return [-1, null];
  },
  getReceptionAttribute(ratio, attribute) {
    if (ratio < 0.7) {
      return attribute === 'status' ? 'danger' : 'times';
    }
    if (ratio < 0.9) {
      return attribute === 'status' ? 'warning' : 'exclamation';
    }
    return attribute === 'status' ? 'success' : 'check';
  },
  isQualifyingDate(date, billingPeriod) {
    const month = new Date(date).getMonth();
    const day = new Date(date).getDate();
    const targetMonth = new Date(billingPeriod.to).getMonth();
    const targetDday = new Date(billingPeriod.to).getDate();

    return month === targetMonth && day === targetDday;
  },
  countInterimReadings(readings) {
    let sum = 0;
    for (const reading of readings) {
      if (reading.reading_type === 'ZA') sum += 1;
    }
    return sum;
  },
  getInterimReadings(readings) {
    const all = [];
    for (const reading of readings) {
      if (reading.reading_type === 'ZA') all.push(reading);
    }
    return all;
  },
  getRandomBetween(min, max, formatted = true) {
    const random = Math.round(Math.random() * (min * 100 - max * 100 + 1) + max * 100) / 100;
    if (formatted === true) return this.formatNumber(random);
    return random;
  },
  getValidationString(setCnt, toBeSetCnt) {
    const set = setCnt > 0
      ? `<i class='kg kg-check text-success p-2'></i> ${setCnt} abgelesen`
      : '';
    const toBeSet = toBeSetCnt > 0
      ? ` <i class='kg kg-exclamation text-warning p-2'></i> ${toBeSetCnt}  abzulesen`
      : '';
    return set + toBeSet;
  },
  getImage(dbPath, defaultImage = null) {
    // webpack default: @ routes to src folder!
    let image = '';
    try {
      image = require(`@/../public/images/property/${dbPath}`);
    } catch (e) {
      if (defaultImage) image = defaultImage;
      else image = require('@/../public/images/property/no_image.png');
    }
    return image;
  },
  isEmpty(x) {
    return (
      x === 'undefined'
		|| x === null
		|| x === 'null'
		|| x.length <= 0
		|| Number.isNaN(x)
    );
  },
  getValueCounts(arr, valueName) {
    return arr.reduce((p, c) => {
      if (p.hasOwnProperty(c[valueName])) p[c[valueName]]++;
      else p[c[valueName]] = 1;
      return p;
    }, {});
  },
  mapObjectToKeyValueArray(obj) {
    return Object.entries(obj).map((tuple) => {
      const units = {};
      units[tuple[0]] = tuple[1];
      return units;
    });
  },
  setTaskVariables(x) {
    const re = /\{(.*?)\}/g;
    const str = x.task;
    let match;
    let vars = {};
    try {
      vars = x.variables ? JSON.parse(x.variables) : {};
    } catch (e) {
      window.console.error(e);
    }
    while ((match = re.exec(str)) !== null) {
      if (match[1].trim()) {
        const replaceWith = vars[match[1].trim()] !== undefined ? vars[match[1].trim()] : '_';
        x.task = x.task.replace(match[0], replaceWith);
      }
    }
    return x;
  },
  getTaskWithVariables(tasks) {
    return tasks.map((x) => this.setTaskVariables(x));
  },
  getColorForFormattedNumber(number) {
    if (number.startsWith('-')) return 'danger';
    if (number === '0,00') return 'white';
    return 'success';
  },
  checkType(value, type) {
    if (type === 'number') {
      return !Number.isNaN(value);
    }
    return typeof value === type;
  },
  formatSavedValues(savedValues) {
    const dataToSend = {};
    for (const section of savedValues) {
      if (section) {
        for (const [key, value] of Object.entries(section)) {
          dataToSend[key] = value.value;
          if (value.hasOwnProperty('selectedRows')) dataToSend[`${key}Selected`] = value.selectedRows;
        }
      }
    }
    return dataToSend;
  },
  // eslint-disable-next-line consistent-return
  getPath(el) {
    const path = [];
    while (el) {
      path.push(el);
      if (el.tagName === 'HTML') {
        path.push(document);
        path.push(window);
        return path;
      }
      el = el.parentElement;
    }
  },
  twoDigitsFormat(digit) {
    if (digit >= 0 && digit < 10) return `0${digit.toString()}`;
    if (digit > -10 && digit < 0) return `-0${(-1 * digit).toString()}`;
    return digit.toString();
  },
  validate(value, required, type) {
    if (required) {
      if (value === '' || value === null) {
        return false;
      }
      return this.checkType(value, type);
    }
    if (value) {
      return this.checkType(value, type);
    }

    return true;
  },
  containsObject(obj, litOfObj, identifier = null) {
    for (const index in litOfObj) {
      if (
        isNull(identifier)
	&& litOfObj.hasOwnProperty(index)
	&& litOfObj === obj
      ) return true;
      const valueIsEqual = litOfObj[index][identifier] === obj[identifier];
      if (!isNull(identifier) && litOfObj.hasOwnProperty(index) && valueIsEqual) return true;
    }
    return false;
  },
  interimReadingsDisplay(n) {
    if (n === 1) return "<span class='font-weight-bold px-2'>1</span> Zwischenablesung";
    if (n > 1) return `<i class='fas fa-user-friends px-2'></i>${n} Zwischenablesungen`;
    return '';
  },
  saveSorts(params, key) {
    localStorage.setItem(key, JSON.stringify(params));
  },
  getTableInstance(
    title,
    rows,
    columns,
    selectOptions,
    collapsible,
    collapseId,
    alreadyCollapsed,
    button = false,
    buttonText = '',
  ) {
    return new TableClass({
      propsData: {
        title,
        rows,
        columns,
        alreadyCollapsed,
        collapsible,
        collapseId,
        button,
        buttonText,
        selectOptions,
        search: {},
      },
    }).$mount();
  },
  validateKuguEmailAddress(email) {
    // eslint-disable-next-line
	const kuguEmailStructure = /^[a-z.]+@kugu\-home\.com$/;
    return kuguEmailStructure.test(email);
  },
  sanitizeAESKey(key) {
    key = `${key}`.toLowerCase().trim().replaceAll(' ', '').replaceAll('-', '');
    if (key.startsWith('0x')) key = key.substring(2);
    if (key.startsWith('h')) key = key.substring(1);
    if (key.endsWith('h')) key = key.slice(0, -1);
    return key;
  },
  isValidAESKey(key, includeSanitation = true) {
    if (includeSanitation) key = this.sanitizeAESKey(key);
    const hasCorrectLength = key.length === 32;
    const isHexFormat = /^[A-Fa-f0-9]*$/.test(key);
    return hasCorrectLength && isHexFormat;
  },

  /**
	* Returns item in array based on index
	*
	* @param {array} data The array of data.
	* @param {number} index of item in array.
	* @return {number, string} item in array.
	*/

  getDataWithIndex(data, index) {
    return data[index];
  },
};

export const isStagingEnv = window.location.href.includes(
  'staging.portal.kugu.cloud',
);
export const isDevEnv = window.location.href.includes(
  'development.portal.kugu.cloud',
);
export const isDemoEnv = window.location.href.includes(
  'demo.portal.kugu.cloud',
);
